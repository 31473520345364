import React, { useEffect, useState } from 'react';
import {connect} from 'react-redux';
import { Col, Row, Tooltip } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';
import Pagination from '../../components/pagination';
import StatsCard from '../../components/cards/StatsCard';
import { getUserDeviceActivities } from '../../store/actions';
import DevicePerformanceBlock from '../../components/DevicePerformanceBlock';
import BluetoothStatus from '../../components/BluetoothStatus';
import AiResponse from '../../components/aiResponse/AiResponse';
import { buildPaginationData } from '../../utility/utils';
import UserDeviceTable from "../../components/tables/UserDeviceTable";

const DevicePerformance = ({
  loading,
  device,
  deviceActivities,
  getUserDeviceActivities,
  deviceLoading,
  location,
  testDetailsData,
  userProfile,
  isAiFeaturesEnabled,
  userId,
}) => {
  const [ filter, setFilter ] = useState(null);
  const [ sortBy, setSortBy ] = useState(null);
  const [ paginationData, setPaginationData ] = useState(null);
  const [ tooltipOpen1, setTooltipOpen1 ] = useState(false);
  const [ tooltipOpen2, setTooltipOpen2 ] = useState(false);
  const [ tooltipOpen3, setTooltipOpen3 ] = useState(false);
  const [ tooltipOpen4, setTooltipOpen4 ] = useState(false);
  const [ tooltipOpen5, setTooltipOpen5 ] = useState(false);

  const history = useHistory();
  const {filter_by} = queryString.parse(location?.search);

  const toggle = (id) => {
    switch (id) {
      case 1:
        setTooltipOpen1(!tooltipOpen1);
        break;
      case 2:
        setTooltipOpen2(!tooltipOpen2);
        break;
      case 3:
        setTooltipOpen3(!tooltipOpen3);
        break;
      case 4:
        setTooltipOpen4(!tooltipOpen4);
        break;
      case 5:
        setTooltipOpen5(!tooltipOpen5);
        break;
    }
  };

  const fetchData = (page) => {

    let queryParams = { id: userId };

    if (page) {
      queryParams.page = page;
    }

    if (sortBy) {
      queryParams.sort_by = sortBy;
    }

    if (filter) {
      queryParams = {...queryParams, ...filter};
    }

    if (testDetailsData?.guid) {
      const myFilter = {search_by: 'guid', keyword: testDetailsData?.guid};
      queryParams = {...queryParams, ...myFilter};
    }
    getUserDeviceActivities(history, queryParams);
  };

  const systemName = (name) => {
    const lowerName = name?.toLowerCase();
    switch (lowerName) {
      case 'win':
        return 'Windows';
      case ('mac'):
        return 'MacOS';
      case ('cros'):
        return 'Chrome OS';
      case ('lin'):
        return 'Linux';
      default:
        return name;
    }
  };

  const isValidJSON = (text) => {
    try {
      JSON.parse(text);
      return true;
    } catch {
      return false;
    }
  };

  const toGiga = (byte) => {
    return (byte / (1024 * 1024 * 1024)).toFixed(2);
  };

  const memoryCapacity = (str) => {
    const isJson = isValidJSON(str);
    if (isJson) {
      const memoryObject = JSON.parse(str);
      return (`${toGiga(memoryObject?.capacity)} GB`);
    }
    return 'No data';
  };

  const hddInfoResult = (str) => {
    const isJson = isValidJSON(str);
    if (isJson) {
      const hddObject = JSON.parse(str);
      if (hddObject?.length === 0) {
        return 'hide';
      }
      if (typeof hddObject !== 'object') {
        return 'hide';
      }
      return hddObject?.map((item, i) => (
        <div className="device-info-item" key={i}>
          <div className="device-info-name">{item?.name}</div>
          <div className="device-info-value">{`${toGiga(item?.capacity)} GB` || 'No data'}</div>
        </div>
      ));
    }
    return 'hide';
  };

  const batteryStatus = (value) => {
    switch (value) {
      case 0:
        return <span className="text-poor">Poor</span>;
      case 1:
        return <span className="text-good">Good</span>;
      default:
        return <span className="text-custom">Plugged In</span>;
    }
  };

  const memoryStatus = (value) => {
    if (value >= 0 && value <= 18) {
      return <span className="text-good">Average</span>;
    }
    if (value > 18) {
      return <span className="text-poor">High</span>;
    }
    return <span className="text-custom">Not Available</span>;
  };

  const cpuStatus = (value) => {
    if (value >= 0 && value <= 21) {
      return <span className="text-good">Average</span>;
    }
    if (value > 21 && value <= 31) {
      return <span className="text-poor">High</span>;
    }
    if (value > 31) {
      return <span className="text-poor">High</span>;
    }
    return <span className="text-custom">Not Available</span>;
  };

  useEffect(() => {
    if (deviceActivities?.device_activities?.length > 0) {
      setPaginationData(buildPaginationData(deviceActivities));
    }
  }, [deviceActivities]);

  useEffect(() => {
    if (userId) {
      fetchData();
    }
  }, [userId]);

  const onTableSortChange = (sortBy) => {
    if (sortBy) {
      setSortBy(sortBy);
      fetchData();
    }
  };

  const navigateToPage = (page) => {
    fetchData(page);
  };

  const tooltipBlockStyle = {
    width: '100%',
    maxWidth: '224px',
    padding: '24px 16px',
    textAlign: 'left',
  };

  const titleStyle = {
    marginBottom: '8px',
    fontSize: '11px',
    fontWeight: 600,
    lineHeight: '16px',
    textTransform: 'uppercase',
    letterSpacing: '.5px',
  };

  const contentStyle = {
    fontSize: '12px',
    lineHeight: '16px',
  };

  const aiPostData = {
    user_id: deviceActivities?.device_activities?.[0].user_id
  }

  const isUserid = deviceActivities?.device_activities?.[0].user_id

  return (
    <>
      {(isUserid && isAiFeaturesEnabled) && (
        <div className="mb-5">
          <AiResponse
            title="device experience summary"
            type="userDeviceTab"
            data={aiPostData}
          />
        </div>
      )}
      {/* <div className="tab-title">
        <h4 className="card-title">Device Performance
          <span className="time">From {device?.most_recent_activity_date} - {device?.last_activity_date}</span>
          <span>
            <i id="dp" className="mdi mdi-help-circle-outline text-primary ml-2" />
            <Tooltip placement="right" isOpen={tooltipOpen1} target="dp" toggle={() => toggle(1)}>
              These gauges show a rollup of this user’s overall internet experience over this time period
            </Tooltip>
          </span>
        </h4>
        <div className="tab-subtitle">This page provides an overview of this user’s general device performance. It is helpful for identifying chronic issues relating to the function of the users hardware.</div>
      </div> */}

      {device?.bluetooth_name && (
        <DevicePerformanceBlock
          title="Bluetooth Status"
          subtitle="Connection status of bluetooth devices"
        >
          <BluetoothStatus
            title={device?.bluetooth_name}
            value={device?.bluetooth_status}
            isLoading={deviceLoading}
          />
        </DevicePerformanceBlock>
      )}

      <DevicePerformanceBlock
        title="Device Health"
        subtitle="An overview of the device health."
      >
        <Row>
          <Col xs="12" xl="3" className="mb-4">
            <StatsCard
              title={(
                <span>
                  Battery Health
                  <span>
                    <i id="bh" className="mdi mdi-information ml-2" />
                    <Tooltip
                      placement="top"
                      isOpen={tooltipOpen2}
                      target="bh"
                      toggle={() => toggle(2)}
                      style={tooltipBlockStyle}
                    >
                      <h5 style={titleStyle}>What does this mean?</h5>
                      <div style={contentStyle}>
                        This is a measurement of how long this device can last on a full charge. Battery health is considered good if at a full charge it can last for over 90 minutes.
                      </div>
                    </Tooltip>
                  </span>
                </span>
              )}
              text={batteryStatus(device?.avg_battery_health)}
              loading={deviceLoading}
            />
          </Col>
          <Col xs="12" xl="3" className="mb-4">
            <StatsCard
              title={(
                <span>
                  CPU Utilization
                  <span>
                    <i id="cu" className="mdi mdi-information ml-2" />
                    <Tooltip
                      placement="top"
                      isOpen={tooltipOpen3}
                      target="cu"
                      toggle={() => toggle(3)}
                      style={tooltipBlockStyle}
                    >
                      <h5 style={titleStyle}>What does this mean?</h5>
                      <div style={contentStyle}>
                        This is a measurement of how well this device’s processor can keep up with the workflow.
                        Total CPU that chronically reaches 90% utilization is considered “High” and may indicate insufficient performance, an application issue, or a driver issue.
                      </div>
                    </Tooltip>
                  </span>
                </span>
              )}
              text={cpuStatus(device?.avg_cpu_utilization)}
              loading={deviceLoading}
            />
          </Col>
          {device?.avg_cpu_temperature && (
            <Col xs="12" xl="3" className="mb-4">
              <StatsCard
                title={(
                  <span>
                    Average CPU Temperature
                    <span>
                      <i id="act" className="mdi mdi-information ml-2" />
                      <Tooltip
                        placement="top"
                        isOpen={tooltipOpen4}
                        target="act"
                        toggle={() => toggle(4)}
                        style={tooltipBlockStyle}
                      >
                        <h5 style={titleStyle}>What does this mean?</h5>
                        <div style={contentStyle}>
                          Overheating CPUs can cause poor performance and hardware damage.  CPU temperature exceeding 65C is considered high and the hardware should be inspected.
                        </div>
                      </Tooltip>
                    </span>
                  </span>
                )}
                text={<span className="text-good">{`${device?.avg_cpu_temperature} `}&#x2103;</span>}
                loading={deviceLoading}
              />
            </Col>
          )}
          <Col xs="12" xl="3" className="mb-4">
            <StatsCard
              title={(
                <span>
                  Memory Utilization
                  <span>
                    <i id="mu" className="mdi mdi-information ml-2" />
                    <Tooltip
                      placement="top"
                      isOpen={tooltipOpen5}
                      target="mu"
                      toggle={() => toggle(5)}
                      style={tooltipBlockStyle}
                    >
                      <h5 style={titleStyle}>What does this mean?</h5>
                      <div style={contentStyle}>
                        This measurement analyzes how well memory is managed on this device. High memory utilization can cause a device slow down and may indicate the need to upgrade memory or the device.
                      </div>
                    </Tooltip>
                  </span>
                </span>
              )}
              text={memoryStatus(device?.avg_memory_utilization)}
              loading={deviceLoading}
            />
          </Col>
        </Row>
      </DevicePerformanceBlock>

      <DevicePerformanceBlock
        title="Device Details"
        subtitle={`Key hardware specs that may affect User experience.`}
      >
        <div className="device-info">
          <Row>
            <Col xs="12" sm="6" md="3" className="mb-4">
              <div className="device-info-item">
                <div className="device-info-name">Operating System:</div>
                <div className="device-info-value">{systemName(device?.operating_system) || 'No data'}</div>
              </div>
            </Col>
            <Col xs="12" sm="6" md="3" className="mb-4">
              <div className="device-info-item">
                <div className="device-info-name">CPU:</div>
                <div className="device-info-value">{device?.cpu_info || 'No data'}</div>
              </div>
            </Col>
            <Col xs="12" sm="6" md="3" className="mb-4">
              <div className="device-info-item">
                <div className="device-info-name">Memory:</div>
                <div className="device-info-value">{memoryCapacity(device?.memory_info)}</div>
              </div>
            </Col>
            <Col xs="12" sm="6" md="3" className="mb-4">
              <div className="device-info-item">
                <div className="device-info-name">Chrome Version:</div>
                <div className="device-info-value">{device?.chrome_version || 'No data'}</div>
              </div>
            </Col>
          </Row>
        </div>
      </DevicePerformanceBlock>

      {hddInfoResult(device?.hdd_info) !== 'hide' && (
        <DevicePerformanceBlock
          title="Hard Drive Capacity"
          subtitle="A snapshot of all the HDD volumes and their capacity."
        >
          <div className="device-info">
            {hddInfoResult(device?.hdd_info)}
          </div>
        </DevicePerformanceBlock>
      )}

      <DevicePerformanceBlock
        title="Browser"
        subtitle="Browser factors that may affect performance."
      >
        <div className="device-info">
          <Row>
            {/* <Col xs="12" md="6" xl="3">
              <StatsCard
                title={(<span>Avg # of Windows Open</span>)}
                text={(<span className={device?.avg_active_window ? avgWindowsStatus(device?.avg_active_window) : 'text-custom'}>{device?.avg_active_window || 'Not Available'}</span>)}
                loading={deviceLoading}
              />
            </Col> */}
            <Col xs="12" sm="6" md="3" className="mb-4">
              <div className="device-info-item">
                <div className="device-info-name">Avg # of Windows Open</div>
                <div className="device-info-value">{device?.avg_active_window || 'Not Available'}</div>
              </div>
            </Col>
            <Col xs="12" sm="6" md="3" className="mb-4">
              <div className="device-info-item">
                <div className="device-info-name">Avg # of Tabs Open</div>
                <div className="device-info-value">{device?.avg_open_tab || 'Not Available'}</div>
              </div>
            </Col>
            {/* <Col xs="12" md="6" xl="3">
              <StatsCard
                title={(<span>Avg # of Tabs Open</span>)}
                text={(<span className={device?.avg_open_tab ? avgTabsStatus(device?.avg_open_tab) : 'text-custom'}>{device?.avg_open_tab || 'Not Available'}</span>)}
                loading={deviceLoading}
              />
            </Col> */}
          </Row>
        </div>
      </DevicePerformanceBlock>

      <div className="tab-block">
        <Row className="align-items-center">
          <Col xl={12}>
            <div className="table-responsive">
              <UserDeviceTable
                fetch={fetchData}
                data={deviceActivities?.device_activities}
                isLoading={loading}
                onSortChange={onTableSortChange}
                disableSortBy
              />
            </div>
            {(deviceActivities?.device_activities?.length > 0 && paginationData) && <Pagination paginationData={paginationData} navigateTo={navigateToPage} />}
          </Col>
        </Row>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  const { error, loading, data, device } = state.UserStatus;
  const { deviceActivities, loading: deviceLoading } = state.Device;
  const { data: testDetailsData } = state.TestDetails;
  const { data: userProfile } = state.Profile;
  const { ai_features_enabled: isAiFeaturesEnabled } = state.Company.companyDetails;

  return {
    error,
    loading,
    data,
    device,
    deviceActivities,
    deviceLoading,
    testDetailsData,
    userProfile,
    isAiFeaturesEnabled,
  };
};

export default connect(mapStateToProps, {getUserDeviceActivities})(DevicePerformance);
