import {
  GET_APPLICATIONS, GET_APPLICATIONS_SUCCESS, GET_APPLICATIONS_ERROR,
  ADD_APPLICATION, ADD_APPLICATION_SUCCESS, ADD_APPLICATION_ERROR,
  DELETE_APPLICATION, DELETE_APPLICATION_SUCCESS, DELETE_APPLICATION_ERROR,
  UPDATE_APPLICATION, UPDATE_APPLICATION_SUCCESS, UPDATE_APPLICATION_ERROR,
  GET_APPLICATIONS_ACTIVITY, GET_APPLICATIONS_ACTIVITY_SUCCESS, GET_APPLICATIONS_ACTIVITY_ERROR, GET_APPLICATIONS_EVENTS, GET_APPLICATIONS_EVENTS_SUCCESS, GET_APPLICATIONS_EVENTS_ERROR,
} from './actionTypes';

export const getApplications = (payload) => {
  return {
    type: GET_APPLICATIONS,
    payload,
  };
};

export const getApplicationsSuccess = (payload) => {
  return {
    type: GET_APPLICATIONS_SUCCESS,
    payload,
  };
};

export const getApplicationsError = (error) => {
  return {
    type: GET_APPLICATIONS_ERROR,
    error,
  };
};

export const addApplication = (payload) => {
  return {
    type: ADD_APPLICATION,
    payload,
  };
};

export const addApplicationSuccess = (payload) => {
  return {
    type: ADD_APPLICATION_SUCCESS,
    payload,
  };
};

export const addApplicationError = (error) => {
  return {
    type: ADD_APPLICATION_ERROR,
    error,
  };
};

export const deleteApplication = (payload) => {
  return {
    type: DELETE_APPLICATION,
    payload,
  };
};

export const deleteApplicationSuccess = (id) => {
  return {
    type: DELETE_APPLICATION_SUCCESS,
    id,
  };
};

export const deleteApplicationError = (error) => {
  return {
    type: DELETE_APPLICATION_ERROR,
    error,
  };
};

export const updateApplication = (payload) => {
  return {
    type: UPDATE_APPLICATION,
    payload,
  };
};

export const updateApplicationSuccess = (payload) => {
  return {
    type: UPDATE_APPLICATION_SUCCESS,
    payload,
  };
};

export const updateApplicationError = (error) => {
  return {
    type: UPDATE_APPLICATION_ERROR,
    error,
  };
};

export const getApplicationsActivity = (payload) => {
  return {
    type: GET_APPLICATIONS_ACTIVITY,
    payload,
  };
};

export const getApplicationsActivitySuccess = (payload) => {
  return {
    type: GET_APPLICATIONS_ACTIVITY_SUCCESS,
    payload,
  };
};

export const getApplicationsActivityError = (error) => {
  return {
    type: GET_APPLICATIONS_ACTIVITY_ERROR,
    error,
  };
};

export const getApplicationsEvents = (data, history) => {
  return {
    type: GET_APPLICATIONS_EVENTS,
    payload: {
      data,
      history,
    },
  };
};

export const getApplicationsEventsSuccess = (payload) => {
  return {
    type: GET_APPLICATIONS_EVENTS_SUCCESS,
    payload,
  };
};

export const getApplicationsEventsError = (error) => {
  return {
    type: GET_APPLICATIONS_EVENTS_ERROR,
    error,
  };
};
