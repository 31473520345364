/* eslint-disable no-unused-expressions */
/* eslint-disable quote-props */
import React, { useRef, useState, useEffect } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import * as turf from '@turf/turf';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
// eslint-disable-next-line import/no-extraneous-dependencies
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import { Button } from 'reactstrap';
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
import mapboxgl from '!mapbox-gl';
import MapBoxSwitch from './MapBoxSwitch';
import { addCensusLayers, coordinatesGeocoder, removeCensusLayers } from './mapBoxHelper';
import MapBoxPolygons from './MapBoxPolygons';
import './mapbox.scss';

mapboxgl.accessToken = 'pk.eyJ1IjoiZWxpZ2VuY2UiLCJhIjoiY2xvNnhhdjI0MDBkaTJra2FsYzJ4bmZuayJ9.uyIp75x1G3vSDUi5M3higA';

const MapBox = ({
    markers,
    isMapView = false,
    openExportModal,
    onMapMarkerClick,
    noClick = false,
  }) => {
  const themeSave = localStorage.getItem('theme');
  const [themeMode, setThemeMode] = useState(themeSave || 'dark');
  const [mapLayer, setMapLayer] = useState(false);
  const [polygonTemplate, setPolygonTemplate] = useState({});
  const [isFullScreen, setIsFullScreen] = useState(false);
  const mapContainer = useRef(null);
  const map = useRef(null);
  const popup = useRef(null);
  const draw = useRef(null);
  const drawDelete = 'draw.delete';
  const drawUpdate = 'draw.update';

  const addMarkerLayers = () => {
    if (map.current?.getSource('points')) {
      map.current.removeLayer('points');
      map.current.removeSource('points');
      map.current.remove();
    }
    map.current?.addSource('points', {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: markers,
      },
    });
    
    map.current?.addLayer({
      id: 'points',
      source: 'points',
      type: 'circle',
      paint: {
        'circle-radius': 3,
        'circle-radius-transition': {duration: 0},
        'circle-opacity-transition': {duration: 0},
        'circle-color': ['get', 'color'],
        'circle-stroke-color': '#808080',
        'circle-stroke-width': 1,
      },
    });
  };
  
  const mapAddCensusLayers = async () => {
    const censusurls = [
      'https://hubbleiq.github.io/census_tracking_data/data/alameda.geojson',
      'https://hubbleiq.github.io/census_tracking_data/data/los_angeles.geojson',
    ];
    let censusData = [];
    for (let i = 0; i < censusurls.length; i += 1) {
      // eslint-disable-next-line no-await-in-loop
      const response = await fetch(censusurls[i]);
      // eslint-disable-next-line no-await-in-loop
      const responseData = await response.json();
      censusData = censusData.concat(responseData.features);
    }
    map.current.addSource('census-area', {
      'type': 'geojson',
      'data': {
        'type': 'FeatureCollection',
        'name': '2010_Census_Tracts',
        'crs': { 'type': 'name', 'properties': { 'name': 'urn:ogc:def:crs:OGC:1.3:CRS84' } },
        'features': censusData,
      },
    });
    
    addCensusLayers(
      map.current,
      'census-area',
      'census-area-layer',
      'fill',
      {paint: {
          'fill-color': 'rgba(0, 240, 242, 0.5)',
          'fill-outline-color': 'rgba(0, 0, 0, 1)',
        }},
    );
  };
  
  const updateArea = async (e, noSave = false) => {
    if (e.type === drawDelete || e.type === drawUpdate) {
      return;
    }
    
    const drawnPolygon = e.features[0];
    const points = markers;
    
    const pointsInsidePolygon = points.filter((point) => turf.booleanPointInPolygon(point, drawnPolygon.geometry));
    const testIds = pointsInsidePolygon.map((point) => point.properties.test_id);
    const guids = pointsInsidePolygon.filter((point) => point.properties.guid).map((point) => point.properties.guid);
    
    if (testIds.length > 0) {
      openExportModal({
        testIds,
        coordinates: drawnPolygon,
        ...(noSave && {noSave}),
        guids
      });
    }
  };
  
  useEffect(() => {
    if (Object.keys(polygonTemplate).length > 0) {
      draw.current.add({...polygonTemplate});
      const dataToDrawPolygon = {
        type: 'draw.create',
        features: [{
          geometry: polygonTemplate.geometry,
        }],
      };
      updateArea(dataToDrawPolygon, true);
    }
  }, [polygonTemplate]);

  const onMapBoxRemove = () => {
    map.current.off('load');

    if(!noClick) {
      map.current.off('click');
    }
    map.current.off('mouseover');
    map.current.off('mouseout');
    map.current.off('remove');

    if(isMapView) {
      map.current.off('draw.create', updateArea);
      map.current.off(drawDelete, updateArea);
      map.current.off(drawUpdate, updateArea);
    }

    map.current.remove();
  }
  
  useEffect(() => {
    if (map.current) return; // initialize map only once

    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: `mapbox://styles/mapbox/${themeMode}-v10`,
      center: [3, 33],
      zoom: 9,
      minZoom: 1,
      screenSpeed: 60,
      showZoom: true,
    });
    
    if (isMapView) {
      draw.current = new MapboxDraw({
        displayControlsDefault: false,
        controls: {
          polygon: true,
          trash: true,
        },
        defaultMode: 'simple_select',
        userProperties: true,
      });
      
      map.current.addControl(draw.current);
      map.current.addControl(
        new MapboxGeocoder({
          accessToken: mapboxgl.accessToken,
          localGeocoder: coordinatesGeocoder,
          mapboxgl,
          reverseGeocode: true,
        }),
        'top-left',
      );
    }
    
    const nav = new mapboxgl.NavigationControl({
      showZoom: true,
      showCompass: false,
    });
    
    map.current.addControl(nav, 'bottom-right');

    return () => {
      if(map?.current) {
        onMapBoxRemove();
      }
    }
  }, []);
  
  useEffect(() => {
    if (markers?.length > 0) {
      const initialCoordinates = markers[0]?.geometry.coordinates;
      const coordinates = markers?.slice(-1)[0]?.geometry.coordinates;
      map.current.setCenter(coordinates);
      
      map.current.on('load', () => {
        addMarkerLayers();
        
        if (!noClick) {
          map.current?.on('click', 'points', (e) => {
            const {user_id, test_id} = e.features[0].properties;
            if (onMapMarkerClick) {
              onMapMarkerClick(test_id);
            } else {
              window.location.href = `/admin/user-status/${user_id}`;
            }
          });
        }
        
        popup.current = new mapboxgl.Popup({
          closeButton: false,
          closeOnClick: false,
        });
        
        map.current?.on('mouseover', 'points', (e) => {
          map.current.getCanvas().style.cursor = 'pointer';
          // Copy coordinates array.
          const coordinates = e.features[0].geometry.coordinates.slice();
          const {guid, download, upload, session_date: date} = e.features[0].properties;
          // Ensure that if the map is zoomed out such that multiple
          // copies of the feature are visible, the popup appears
          // over the copy being pointed to.
          while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
            coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
          }
          
          popup.current
            .setLngLat(coordinates)
            .setHTML(
              `<div className="marker-popup">
              <h5>
                ${guid}
              </h5>
              <div>
                <span>Download: </span>
                <span>${download} mbps</span>
              </div>
              <div>
                <span>Upload: </span>
                <span>${upload} mbps</span>
              </div>
              <div>
                <span>Date: </span>
                <span>${date}</span>
              </div>
            </div>`,
            )
            .addTo(map.current);
        });
        
        map.current?.on('mouseout', 'points', () => {
          map.current.getCanvas().style.cursor = '';
          popup.current.remove();
        });
        
        if (isMapView) {
          map.current.on('draw.create', updateArea);
          map.current.on(drawDelete, updateArea);
          map.current.on(drawUpdate, updateArea);
        }
      });
    } else {
      map.current.on('load', () => {
        if (map.current?.getSource('points')) {
          map.current.removeLayer('points');
          map.current.removeSource('points');
        }
      });
    }
  }, [markers]);
  
  const isDarkMode = themeMode === 'dark';
  
  const changeThemeMode = () => {
    if (themeMode === 'dark') {
      setThemeMode('light');
    } else {
      setThemeMode('dark');
    }
    setTimeout(() => {
      addMarkerLayers();
      if (mapLayer) {
        mapAddCensusLayers();
      }
    }, 200);
  };
  
  const changeMapLayer = () => {
    setMapLayer((prevState) => !prevState);
    if (mapLayer) {
      removeCensusLayers(map.current, 'census-area');
    } else {
      mapAddCensusLayers();
    }
  };
  
  useEffect(() => {
    localStorage.setItem('theme', `${themeMode}`);
    map.current.setStyle(`mapbox://styles/mapbox/${themeMode}-v10`);
  }, [themeMode]);
  
  const toggleFullScreen = () => {
    setIsFullScreen((prevState) => !prevState);
  };
  
  useEffect(() => {
    map.current.resize();
  }, [isFullScreen]);

  return (
    <div className="story-map map">
      <div className="map-nav">
        <div>
          <MapBoxSwitch
            id="map-mode"
            leftLabel="Light Map"
            rightLabel="Dark Map"
            value={themeMode}
            onHandlerChange={changeThemeMode}
            isChecked={isDarkMode}
          />
        </div>
        {isMapView && (
          <div>
            <MapBoxSwitch
              id="map-layer"
              leftLabel="Default Layer"
              rightLabel="Census Layer"
              value={mapLayer}
              onHandlerChange={changeMapLayer}
              isChecked={mapLayer}
            />
          </div>
        )}
      </div>
      <div className={`map-block-wrapper${isFullScreen ? ' fixed' : ''}`}>
        <div
          ref={mapContainer}
          className="map-container"
          style={{ height: isMapView ? 'calc(100vh - 186px)' : '390px'}}
        >
          {isMapView && <MapBoxPolygons setPolygonTemplate={setPolygonTemplate} />}
          <Button
            type="button"
            color={`fullscreen${isMapView ? '' : ' top-right'}`}
            onClick={toggleFullScreen}
          >
            <i className={`mdi ${isFullScreen ? 'mdi-fullscreen-exit' : 'mdi-fullscreen'}`} />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default MapBox;
