import React from 'react'
import { renderCardItems } from '../../utility/utils'
import results from '../resultsCard/ResultsCard.module.css'

const LatencyCard = ({data, source}) => {
  const dataToRender = data ?? {}

  return (
    <div gap={3} className={results.card_list}>
      <div className={results.card_list_title}>Latency</div>
      {data && renderCardItems(dataToRender, source)}
      {!data && <div>Something went wrong</div>}
    </div>
  )
}

export default LatencyCard