export const FETCH_ADMINS = 'FETCH_ADMINS';
export const FETCH_ADMINS_SUCCESS = 'FETCH_ADMINS_SUCCESS';
export const FETCH_ADMINS_API_ERROR = 'FETCH_ADMINS_API_ERROR';

export const DELETE_ADMIN = 'DELETE_ADMIN';
export const DELETE_ADMIN_SUCCESS = 'DELETE_ADMIN_SUCCESS';
export const DELETE_ADMIN_ERROR = 'DELETE_ADMIN_ERROR';

export const UPDATE_ADMIN_REQUEST = 'UPDATE_ADMIN_REQUEST';
export const UPDATE_ADMIN_SUCCESS = 'UPDATE_ADMIN_SUCCESS';
export const UPDATE_ADMIN_ERROR = 'UPDATE_ADMIN_ERROR';
