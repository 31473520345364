import {all, fork, put, takeEvery} from 'redux-saga/effects';

import { GET_APPLICATIONS, ADD_APPLICATION, DELETE_APPLICATION, UPDATE_APPLICATION, GET_APPLICATIONS_ACTIVITY, GET_APPLICATIONS_EVENTS } from './actionTypes';
import {
  getApplicationsSuccess, getApplicationsError,
  addApplicationSuccess, addApplicationError,
  deleteApplicationSuccess, deleteApplicationError,
  updateApplicationSuccess, updateApplicationError,
  getApplicationsActivitySuccess, getApplicationsActivityError, getApplicationsEventsSuccess, getApplicationsEventsError,
} from './actions';
import apiClient from '../../gears/apiClient';

// Get applications
function* getApplications() {
  try {
    const response = yield apiClient.get('/current-app-configs');
    yield put(getApplicationsSuccess(response));
  } catch (error) {
    yield put(getApplicationsError(error));
  }
}

export function* watchGetApplications() {
  yield takeEvery(GET_APPLICATIONS, getApplications);
}

// Add applications
function* addApplication({payload}) {
  try {
    const response = yield apiClient.post('/new/application', payload);
    yield put(addApplicationSuccess(response));
  } catch (error) {
    yield put(addApplicationError(error));
  }
}

export function* watchAddApplications() {
  yield takeEvery(ADD_APPLICATION, addApplication);
}

// Delete application
function* deleteApplication({payload}) {
  try {
    yield apiClient.put('/inactive-app-configs', payload);
    yield put(deleteApplicationSuccess(payload));
  } catch (error) {
    yield put(deleteApplicationError(error));
  }
}

export function* watchDeleteApplications() {
  yield takeEvery(DELETE_APPLICATION, deleteApplication);
}

// Update applications
function* updateApplication({payload}) {
  try {
    yield apiClient.put('/update-app-configs', payload);
    yield put(updateApplicationSuccess(payload));
  } catch (error) {
    yield put(updateApplicationError(error));
  }
}

export function* watchUpdateApplications() {
  yield takeEvery(UPDATE_APPLICATION, updateApplication);
}

// Get applications activity
function* getApplicationsActivity({payload}) {
  try {
    const response = yield apiClient.get(`/app-activity/${payload}`);
    yield put(getApplicationsActivitySuccess(response));
  } catch (error) {
    yield put(getApplicationsActivityError(error));
  }
}

export function* watchGetApplicationsActivity() {
  yield takeEvery(GET_APPLICATIONS_ACTIVITY, getApplicationsActivity);
}

// Get applications activities
function* getApplicationsEvents({ payload: { data } }) {
  try {
    const response = yield apiClient.get('/app-activities', data);
    yield put(getApplicationsEventsSuccess(response));
  } catch (error) {
    yield put(getApplicationsEventsError(error));
  }
}

export function* watchGetDeviceActivities() {
  yield takeEvery(GET_APPLICATIONS_EVENTS, getApplicationsEvents);
}

function* applicationsSaga() {
  yield all([
    fork(watchGetApplications),
    fork(watchAddApplications),
    fork(watchDeleteApplications),
    fork(watchUpdateApplications),
    fork(watchGetApplicationsActivity),
    fork(watchGetDeviceActivities),
  ]);
}

export default applicationsSaga;
