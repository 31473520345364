import React from 'react';
import { useHistory } from "react-router-dom";
import styles from './indicator-card.module.scss';

const IndicatorCard = ({title, percent, value}) => {
	const history = useHistory();
	return (
		<div
			className={styles.card}
			onClick={() => history.push('/admin/issues?tab=application')}
		>
			<div className={styles.title}>
				<h4>{title}</h4>
				{percent !== undefined && <span className={styles.percent}>{percent}%</span>}
			</div>
			<p className={styles.value}>
				{value}
			</p>
		</div>
	);
};

export default IndicatorCard;
