import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import CompanyHeader from './company/CompanyHeader';

const PublicHeader = (props) => {
  const {companyLogo, customClass } = props;

  return (
    <>
      <CompanyHeader />
      <header className={`header center${customClass ? ` ${customClass}` : ''}`}>
        <div className="container">
          <img src={companyLogo} alt="Logo" />
        </div>
      </header>
    </>

  );
};

const mapStateToProps = (state) => {
  const {companyLogo} = state.Company;

  return {companyLogo};
};

export default withRouter(connect(
  mapStateToProps,
)(PublicHeader));
