import React, { useEffect, useState } from 'react'
import { Col, Row } from 'reactstrap'
import { logsTabsArr, renderTabs } from '../../helpers/tabs_helper';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import TestDetailsDrawer from '../../components/drawers/TestDetailsDrawer';

const Logs = () => {
  const [activeTab, setActiveTab] = useState('1');
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [testId, setTestId] = useState(null);
  const history = useHistory();

  useEffect(() => {
    if(testId) {
      setIsDrawerOpen(true)
    }
  }, [testId])

  const onDrawerClose = () => {
    setIsDrawerOpen(false);
    setTestId(null);
  }

  const onRowClick = (data) => {
    const testId = data.testId;
    const userId = data.user_id || data.userId;

    if(testId) {
      return setTestId(testId);
    }

    if(userId) {
      return history.push(`/admin/user-status/${userId}`);
    }
  }

  return (
    <div className="container-fluid content">
      <Row className="page-title align-items-center">
        <Col sm={6}>
          <div className="page-title-box">
            <h2>Logs</h2>
          </div>
        </Col>
      </Row>
      <div className="settings-customization recent-tests-card">
        {renderTabs(logsTabsArr(onRowClick), activeTab, setActiveTab)}
      </div>
      <TestDetailsDrawer isOpen={isDrawerOpen} testId={testId} onHandlerClose={onDrawerClose} />
    </div>
  )
}

export default Logs