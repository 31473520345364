import React, { useState } from 'react';
import { Tooltip } from 'reactstrap';
import { CircularProgress } from '@material-ui/core';

const Speedometer = ({
  maxValue,
  value,
  width,
  arrowColor,
  gradient,
  unitLabel = 'UNIT',
  label = 'N/A',
  tooltip,
  isLoading,
}) => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const degrees = ((value * 100) / maxValue) * 1.8;
  let transform = {
    transform: `translateY(-50%) rotate(${degrees}deg)`,
  };

  if (value > maxValue) {
    transform = {
      transform: 'translateY(-50%) rotate(180deg)',
    };
  }

  if (value < 0) {
    transform = {
      transform: 'translateY(-50%) rotate(0deg)',
    };
  }

  const titleStyle = {
    marginBottom: '8px',
    fontSize: '11px',
    fontWeight: 600,
    lineHeight: '16px',
    textTransform: 'uppercase',
    letterSpacing: '.5px',
  };

  const contentStyle = {
    fontSize: '12px',
    lineHeight: '16px',
  };

  return (
    <div className="speedometer-wrapper">
      <div className="title">
        {label}
        {tooltip && (
          <span className="title-icon">
            <i id={`tooltip_${label}`} className="mdi mdi-information" />
            <Tooltip
              placement="top"
              isOpen={isTooltipOpen}
              target={`tooltip_${label}`}
              toggle={() => setIsTooltipOpen((prevState) => !prevState)}
              style={{
                width: '100%',
                maxWidth: '224px',
                padding: '24px 16px',
                textAlign: 'left',
              }}
            >
              <h5 style={titleStyle}>What does this mean?</h5>
              <div style={contentStyle}>{tooltip}</div>
            </Tooltip>
          </span>
        )}
      </div>
      <div className="speedometer" style={{paddingTop: `${width / 2}px`}}>
        {isLoading && <div className="spinner-wrapper"><CircularProgress size={28} /></div>}
        <div
          className="gradient"
          style={{
            width: `${width}px`,
            height: `${width}px`,
            ...(value && {background: `linear-gradient(${gradient})`}),
          }}
        >
          <div className="gradient-inner" />
          <div className="gradient-half" />
          {value && (
            <div className="speedometer-arrow" style={transform}>
              <div className="speedometer-arrow-color" style={{backgroundColor: arrowColor}} />
            </div>
          )}
          <div className="speed">
            <div>{value || 'N/A'}</div>{unitLabel}
          </div>
        </div>
        <div className="speed-count">
          <div className="start">0</div>
          <div className="limit"><span>{maxValue}</span>+</div>
        </div>
      </div>
    </div>
  );
};

export default Speedometer;
