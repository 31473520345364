import React, { useCallback, useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import Pagination from '../pagination';
import AhoyTrack from '../../modules/AhoyTrack';
import NetworkIssuesTable from '../tables/NetworkIssuesTable';
import { buildPaginationData } from '../../utility/utils';
import { getIssuesByCategory } from './issuesHelper';
import IssuesTableFilter from '../tables-filters/IssuesTableFilter';

const IssueComponent = ({issueLabel, category, tableColumns}) => {
  AhoyTrack();
  const [ networkIssues, setNetworkIssues ] = useState({});
  const [ isLoading, setIsLoading ] = useState(false);
  const [ filter, setFilter ] = useState(null);
  const [ sortBy, setSortBy ] = useState('all');
  const [ paginationData, setPaginationData ] = useState(null);

  const fetchData = useCallback(async (page) => {
    let queryParams = {
      category,
    };

    if (page) {
      queryParams.page = page;
    }

    if (sortBy) {
      queryParams.status = sortBy;
    }

    if (filter) {
      queryParams = {...queryParams, ...filter};
    }

    setIsLoading(true);
    const data = await getIssuesByCategory(queryParams);
    setIsLoading(false);
    setNetworkIssues(data);
  }, [sortBy, filter]);

  const onFilterChange = (filter) => {
    setFilter(filter);
  };

  useEffect(() => {
    if (filter) {
      fetchData();
    }
  }, [filter]);

  useEffect(() => {
    setPaginationData(buildPaginationData(networkIssues));
  }, [networkIssues]);

  const onTableSortChange = (sortBy) => {
    if (sortBy) {
      setSortBy(sortBy);
    }
  };

  const navigateToPage = (page) => {
    fetchData(page);
  };

  useEffect(() => {
    if (typeof sortBy === 'string') {
      fetchData();
    }
  }, [sortBy]);

  const setData = (modifiedData) => {
    setNetworkIssues({
      ...networkIssues,
      issues: modifiedData,
    })
  }

  return (
    <div className="container-fluid content">
      <Row className="page-title align-items-center">
        <Col sm={6}>
          <div className="page-title-box">
            <h2>{issueLabel}</h2>
          </div>
        </Col>
      </Row>
      <Row className="align-items-center test-results-table-container">
        <Col xl={12}>
          <div className="justify-between  mb-4 d-md-flex">
            <IssuesTableFilter
              onFilterChange={onFilterChange}
              currentFilter={filter}
              tableColumns={tableColumns}
            />
          </div>
          <div className="table-responsive">
            <NetworkIssuesTable
              data={networkIssues?.issues}
              isLoading={isLoading}
              setData={setData}
              tableCategory={category}
              // onSortChange={onTableSortChange}
            />
          </div>
          {paginationData && (
            <Pagination paginationData={paginationData} navigateTo={navigateToPage} />
          )}
        </Col>
      </Row>
    </div>
  );
};

export default IssueComponent;
