import {RESET_PASSWORD, RESET_PASSWORD_ERROR, RESET_PASSWORD_SUCCESS} from './actionTypes';

const initialState = {
  forgetSuccessMsg: null,
  forgetError: null,
};

const resetPassword = (state = initialState, action) => {
  switch (action.type) {
    case RESET_PASSWORD:
      return {
        ...state,
        resetSuccessMsg: null,
        resetError: null,
      };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        resetSuccessMsg: action.payload,
      };
    case RESET_PASSWORD_ERROR:
      return {...state, resetError: action.payload};
    default:
      return {...state};
  }
};

export default resetPassword;
