import * as c from './actionTypes';

export const updateOnBoardingStep = (step, cb) => {
  return {
    type: c.UPDATE_ONBOARDING_STEP,
    payload: {
      step,
      cb
    },
  };
};

export const updateOnBoardingStepSuccess = (payload) => {
  return {
    type: c.UPDATE_ONBOARDING_STEP_SUCCESS,
    payload,
  };
};

export const updateOnBoardingStepError = (error) => {
  return {
    type: c.UPDATE_ONBOARDING_STEP_ERROR,
    payload: error,
  };
};
