import {
	FETCH_USER_ISSUES_LOGS,
	FETCH_USER_ISSUES_LOGS_ERROR,
	FETCH_USER_ISSUES_LOGS_SUCCESS,
	CHANGE_ISSUE_STATUS,
	CHANGE_ISSUE_STATUS_SUCCESS,
	CHANGE_ISSUE_STATUS_ERROR
} from "./actionTypes";

const initialState = {
	data: [],
	loading: false,
	error: null,
};

const UserIssuesLogs = (state = initialState, action) => {
	switch (action.type) {
		case FETCH_USER_ISSUES_LOGS:
			return {
				...state,
				loading: true,
			};
		case FETCH_USER_ISSUES_LOGS_SUCCESS:
			return {
				...state,
				data: action.payload,
				loading: false,
			};
		case FETCH_USER_ISSUES_LOGS_ERROR:
			return {
				...state,
				error: action.payload,
				loading: false,
			};
		case CHANGE_ISSUE_STATUS:
			return {
				...state,
				loading: true,
			};
		case CHANGE_ISSUE_STATUS_SUCCESS:
			return {
				...state,
				data: state.data?.map(issue =>
					issue?.id === action.payload.id
						? { ...issue, status: "Completed" }
						: issue
				),
				loading: false,
			};
		case CHANGE_ISSUE_STATUS_ERROR:
			return {
				...state,
				error: action.payload,
				loading: false,
			};
		default:
			return { ...state };
	}
};

export default UserIssuesLogs;
