import { takeEvery, fork, put, all } from 'redux-saga/effects';

import { FETCH_USER_RECENT_ACTIVITIES } from './actionTypes';
import { fetchUserRecentActivitySuccess, fetchUserRecentActivityError } from './actions';
import apiClient from "../../gears/apiClient";
function* fetchUserRecentActivities({ payload: { id } }) {
  try {
    const response = yield apiClient.get(`/user_recent_activities/${id}`);
    yield put(fetchUserRecentActivitySuccess(response));
  } catch (error) {
    yield put(fetchUserRecentActivityError(error));
  }
}

export function* watchFetchUserRecentActivities() {
  yield takeEvery(FETCH_USER_RECENT_ACTIVITIES, fetchUserRecentActivities);
}

function* userRecentActivitiesSaga() {
  yield all([
    fork(watchFetchUserRecentActivities),
  ]);
}

export default userRecentActivitiesSaga;
