import CarouselGif1 from '../../images/carousel/carousel_gif_1.gif';
import CarouselGif2 from '../../images/carousel/carousel_gif_2.gif';
import CarouselGif3 from '../../images/carousel/carousel_gif_3.gif';
import CarouselGif4 from '../../images/carousel/carousel_gif_4.gif';

export const sliderSettings = (setcurrentSlide) => {
  return {
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 6500,
    arrows: false,
    dots: true,
    pauseOnHover: false,
    centerMode: true,
    centerPadding: '0',
    infinite: false,
    afterChange: (index) => setcurrentSlide(index),
  };
};

export const sliderData = [
  {
    id: 1,
    header: '',
    sub_header: 'We are doing much more than checking your speed. HubbleIQs propriertary technology tests your connection to your applications, your WiFi etc.',
    image: CarouselGif1,
  },
  {
    id: 2,
    header: 'Have you downloaded our Browser Extension yet?',
    sub_header: 'Use the button below to download and install HubbleIQ+ extension to your device. The download link is unique to you, and you will be able to invite other users in the next step.',
    image: CarouselGif2,
  },
  {
    id: 3,
    header: 'Which apps can we monitor uptime for you?',
    sub_header: '',
    image: CarouselGif3,
  },
  {
    id: 4,
    header: 'Did you know we have an SDK?',
    sub_header: 'You can put HubbleIQ in your app to monitor end user experience!',
    image: CarouselGif4,
  },
];

export const testsTitleData = [
  {
    title: 'Checking download speeds...',
  },
  {
    title: 'Testing upload rates...',
  },
  {
    title: 'Examining connection stability...',
  },
  {
    title: 'Detecting packet loss...',
  },
  {
    title: 'Assessing Wi-Fi signal...',
  },
  {
    title: 'Evaluating ISP throughput...',
  },
  {
    title: 'Analyzing cloud performance...',
  },
]
