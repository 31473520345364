import React, { useEffect, useState } from 'react';
import {Col, Input, FormGroup, Label, Row} from 'reactstrap';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import {connect} from 'react-redux';
import * as PropTypes from 'prop-types';
import { getStatusMessage, updateStatusMessage } from '../../store/actions';
import IconRefresh from '../../images/icon-refresh.svg';
import Button from '../Button/Button';

const STATUS_TYPES = {
  best: 'Best',
  very_good: 'Very good',
  good: 'Good',
  decent_latency: 'Decent latency',
  decent_jitter: 'Decent jitter',
  decent_download: 'Decent download',
  decent_upload: 'Decent upload',
  low_download: 'low download',
  low_upload: 'Low upload',
  high_latency: 'High latency',
  high_jitter: 'High jitter',
  in_progress: 'In progress',
  isp_outage: 'Isp outage',
};

const ExtensionStatusForm = ({
  dispatchGetStatusMessage,
  dispatchUpdateStatusMessage,
  isStatusMessageLoading,
  currentStatusMessage,
  imageSrc,
  currentEXLogo,
}) => {
  const extensionSchema = Yup.object().shape({
    header: Yup.string().max(50, 'Maximum 50 characters').required('Field is required'),
    subheader: Yup.string().required('Field is required'),
  });

  const [ preview, setPreview ] = useState(true);
  const [ subheaderBtns, setSubheaderBtns ] = useState({});
  const [ hideBtn1, setHideBtn1 ] = useState(false);
  const [ hideBtn2, setHideBtn2 ] = useState(false);

  useEffect(() => {
    dispatchGetStatusMessage(Object.keys(STATUS_TYPES)[0]);
  }, []);

  const onChangeStatus = async (e) => {
    const status = e.target.value;
    await dispatchGetStatusMessage(status);
  };

  const onPreviewClick = () => {
    setPreview(!preview);
  };

  const statusColor = {
    best: 'good',
    very_good: 'good',
    good: 'good',
    decent_latency: 'decent',
    decent_jitter: 'decent',
    decent_download: 'decent',
    decent_upload: 'decent',
    low_download: 'bad',
    low_upload: 'bad',
    high_latency: 'bad',
    high_jitter: 'bad',
    in_progress: 'inprogress',
    isp_outage: 'decent',
  };

  useEffect(() => {
    if (currentStatusMessage) {
      const xmlString = currentStatusMessage?.subheader;
      const doc = new DOMParser().parseFromString(xmlString, 'text/html');
      const body = doc.getElementsByTagName('body');
      const subheader = body.item(0).getElementsByClassName('ext-subtitle').item(0)?.innerHTML;
      const btn1 = body.item(0).getElementsByClassName('action1').item(0);
      const btn2 = body.item(0).getElementsByClassName('action2').item(0);

      const newSubheaderbtns = {
        subheader,
      };

      if (btn1) {
        const type = btn1?.getAttribute('href') ? 'url' : 'shortcut';
        newSubheaderbtns.btn1 = {
          type,
          label: btn1?.innerHTML,
          attributeValue: type === 'url' ? btn1?.getAttribute('href') : btn1?.getAttribute('id'),
        };
      }

      if (btn2) {
        const type = btn2?.getAttribute('href') ? 'url' : 'shortcut';
        newSubheaderbtns.btn2 = {
          type,
          label: btn2?.innerHTML,
          attributeValue: type === 'url' ? btn2?.getAttribute('href') : btn2?.getAttribute('id'),
        };
      }

      setSubheaderBtns(newSubheaderbtns);
    }
  }, [currentStatusMessage]);

  const btn1Render = subheaderBtns?.btn1?.type === 'url'
    ? `<a class="action1" href="${subheaderBtns?.btn1?.attributeValue}" target="_blank" style="text-decoration: none; cursor: pointer; position: relative; width: 150px; height: 28px; margin-right: 15px; border: 2px solid white; background-color: transparent; border-radius: 28px; color: white; display: flex;align-items: center; justify-content: center;">${subheaderBtns?.btn1?.label}</a>`
    : `<button class="action1" id="${subheaderBtns?.btn1?.attributeValue}" style=" background: transparent; color: #FFFFFF; border: 2px solid white; width: 150px;height: 28px; border-radius: 30px; margin-right: 15px; padding: 3px 15px; cursor: pointer;">${subheaderBtns?.btn1?.label}</button>`;

  const btn2Render = subheaderBtns?.btn2?.type === 'url'
    ? `<a class="action2" href="${subheaderBtns?.btn2?.attributeValue}" target="_blank" style="text-decoration: none; cursor: pointer; position: relative; width: 150px; height: 28px; border: 2px solid white; background-color: transparent; border-radius: 28px; color: white; display: flex;align-items: center; justify-content: center;">${subheaderBtns?.btn2?.label}</a>`
    : `<button class="action2" id="${subheaderBtns?.btn2?.attributeValue}" style=" background: transparent; color: #FFFFFF; border: 2px solid white; width: 150px;height: 28px; border-radius: 30px; padding: 3px 15px; cursor: pointer;">${subheaderBtns?.btn2?.label}</button>`;

  const subheaderToShow = `<div class="ext-subtitle" style="margin-bottom: 20px;">${subheaderBtns?.subheader}</div>
  <div class="d-flex">
    ${subheaderBtns?.btn1?.type && subheaderBtns?.btn1?.attributeValue ? btn1Render : ''}
    ${subheaderBtns?.btn2?.type && subheaderBtns?.btn2?.attributeValue ? btn2Render : ''}
  </div>`;

  return (
    <div className="settings-tab-block">
      <div className="title">Test Results Section</div>
      <div className="subtitle">Customize the text for each status type</div>
      <Formik
        initialValues={{
          id: currentStatusMessage?.id || null,
          status_type: currentStatusMessage?.status_type || Object.keys(STATUS_TYPES)[0],
          header: currentStatusMessage?.header || '',
          subheader: subheaderBtns?.subheader || '',
          action1_url: subheaderBtns?.btn1?.attributeValue || '',
          action1_label: subheaderBtns?.btn1?.label || '',
          action2_url: subheaderBtns?.btn2?.attributeValue || '',
          action2_label: subheaderBtns?.btn2?.label || '',
          hidenSubheader: subheaderToShow || '',
        }}
        validationSchema={extensionSchema}
        onSubmit={(values) => dispatchUpdateStatusMessage({
          id: values?.id,
          status_type: values?.status_type,
          header: values?.header,
          subheader: values?.hidenSubheader,
        })}
        enableReinitialize
      >
        {({ handleChange, values, errors }) => (
          <Form className="form form-extension">
            <Row>
              <Col xl="6">
                <div className="settings-tab-item">
                  <Row>
                    <Col md={7}>
                      <FormGroup>
                        <Label for="extension-status">Status</Label>
                        <Input
                          id="extension-status"
                          name="status_type"
                          onChange={(e) => onChangeStatus(e)}
                          tag={Field}
                          as="select"
                        >
                          {
                            Object.entries(STATUS_TYPES).map(([value, label]) => <option key={`status-${value}`} value={value}>{label}</option>)
                          }
                        </Input>

                      </FormGroup>
                      <FormGroup>
                        <Label for="extension-headline">Headline Text</Label>
                        <Input
                          id="extension-headline"
                          className={`form-control ${errors.header ? 'is-invalid' : ''}`}
                          maxLength="50"
                          name="header"
                          onChange={(e) => handleChange(e)}
                          tag={Field}
                          disabled={isStatusMessageLoading}
                        />
                        <div className="character-count">
                          Character count: <span>{values.header?.length}</span>/<span>50</span>
                        </div>
                      </FormGroup>
                      <FormGroup>
                        <Label for="extension-subheadline">Sub-headline Text</Label>
                        <Field
                          id="extension-subheadline"
                          className={`form-control ${errors.subheader ? 'is-invalid' : ''}`}
                          name="subheader"
                          type="textarea"
                          onChange={(e) => {
                            setSubheaderBtns({
                              ...subheaderBtns,
                              subheader: e.target.value,
                            });
                          }}
                          as="textarea"
                          disabled={isStatusMessageLoading}
                        />
                      </FormGroup>

                      <FormGroup tag="fieldset">
                        <legend>Call to Action Button 1</legend>
                        <FormGroup className="form-group_radio">
                          <Label check>
                            URL
                            <Input
                              type="radio"
                              name="action1"
                              value="url"
                              checked={subheaderBtns?.btn1?.type === 'url'}
                              onChange={(e) => {
                                setSubheaderBtns({
                                  ...subheaderBtns,
                                  btn1: {
                                    ...subheaderBtns.btn1,
                                    attributeValue: '',
                                    type: e.target.value,
                                  },
                                });
                                setHideBtn1(false);
                              }}
                            />{' '}
                            <span />
                          </Label>
                          <Label check>
                            Shortcut
                            <Input
                              type="radio"
                              name="action1"
                              value="shortcut"
                              checked={subheaderBtns?.btn1?.type === 'shortcut'}
                              onChange={(e) => {
                                setSubheaderBtns({
                                  ...subheaderBtns,
                                  btn1: {
                                    ...subheaderBtns.btn1,
                                    attributeValue: '',
                                    type: e.target.value,
                                  },
                                });
                                setHideBtn1(false);
                              }}
                            />{' '}
                            <span />
                          </Label>
                          <Label check>
                            Hide
                            <Input
                              type="radio"
                              name="action1"
                              value="hide"
                              checked={subheaderBtns?.btn1?.type === 'hide'}
                              onChange={(e) => {
                                setSubheaderBtns({
                                  ...subheaderBtns,
                                  btn1: {
                                    ...subheaderBtns.btn1,
                                    attributeValue: '',
                                    type: e.target.value,
                                  },
                                });
                                setHideBtn1(true);
                              }}
                            />{' '}
                            <span />
                          </Label>
                        </FormGroup>
                        {!hideBtn1 && (
                          <>
                            <FormGroup>
                              <Label for="extension-link1-url">{subheaderBtns?.btn1?.type === 'url' ? 'URL' : 'Shortcut' }</Label>
                              {
                                subheaderBtns?.btn1?.type === 'url'
                                ? (
                                  <Field
                                    id="extension-link1-url"
                                    className={`form-control ${errors.action1_url ? 'is-invalid' : ''}`}
                                    name="action1_url"
                                    onChange={(e) => {
                                      setSubheaderBtns({
                                        ...subheaderBtns,
                                        btn1: {
                                          ...subheaderBtns.btn1,
                                          attributeValue: e.target.value,
                                        },
                                      });
                                    }}
                                    disabled={isStatusMessageLoading}
                                  />
                                )
                                : (
                                  <Input
                                    id="extension-link1-url"
                                    className={errors.action1_url ? 'is-invalid' : ''}
                                    name="action1_url"
                                    onChange={(e) => {
                                      setSubheaderBtns({
                                        ...subheaderBtns,
                                        btn1: {
                                          ...subheaderBtns.btn1,
                                          attributeValue: e.target.value,
                                        },
                                      });
                                    }}
                                    tag={Field}
                                    as="select"
                                    disabled={isStatusMessageLoading}
                                  >
                                    <option hidden value>--- Select an option ---</option>
                                    <option value="goToHistory">Results History</option>
                                    <option value="reportIssue">Share Results</option>
                                    <option value="helprequest">Request Help</option>
                                    <option value="retest">Retest</option>
                                  </Input>
                                )
                              }
                            </FormGroup>
                            <FormGroup>
                              <Label for="extension-link-label">Label</Label>
                              <Field
                                id="extension-link-label"
                                className={`form-control ${errors.action1_label ? 'is-invalid' : ''}`}
                                name="action1_label"
                                onChange={(e) => {
                                  setSubheaderBtns({
                                    ...subheaderBtns,
                                    btn1: {
                                      ...subheaderBtns.btn1,
                                      label: e.target.value,
                                    },
                                  });
                                }}
                                disabled={isStatusMessageLoading}
                              />
                            </FormGroup>
                          </>
                        )}
                      </FormGroup>

                      <FormGroup tag="fieldset">
                        <legend>Call to Action Button 2</legend>
                        <FormGroup className="form-group_radio">
                          <Label check>
                            URL
                            <Input
                              type="radio"
                              name="action2"
                              value="url"
                              checked={subheaderBtns?.btn2?.type === 'url'}
                              onChange={(e) => {
                                setSubheaderBtns({
                                  ...subheaderBtns,
                                  btn2: {
                                    ...subheaderBtns.btn2,
                                    attributeValue: '',
                                    type: e.target.value,
                                  },
                                });
                                setHideBtn2(false);
                              }}
                            />{' '}
                            <span />
                          </Label>
                          <Label check>
                            Shortcut
                            <Input
                              type="radio"
                              name="action2"
                              value="shortcut"
                              checked={subheaderBtns?.btn2?.type === 'shortcut'}
                              onChange={(e) => {
                                setSubheaderBtns({
                                  ...subheaderBtns,
                                  btn2: {
                                    ...subheaderBtns.btn2,
                                    attributeValue: '',
                                    type: e.target.value,
                                  },
                                });
                                setHideBtn2(false);
                              }}
                            />{' '}
                            <span />
                          </Label>
                          <Label check>
                            Hide
                            <Input
                              type="radio"
                              name="action2"
                              value="hide"
                              checked={subheaderBtns?.btn2?.type === 'hide'}
                              onChange={(e) => {
                                setSubheaderBtns({
                                  ...subheaderBtns,
                                  btn2: {
                                    ...subheaderBtns.btn2,
                                    attributeValue: '',
                                    type: e.target.value,
                                  },
                                });
                                setHideBtn2(true);
                              }}
                            />{' '}
                            <span />
                          </Label>
                        </FormGroup>
                        {!hideBtn2 && (
                          <>
                            <FormGroup>
                              <Label for="extension-link2-url">{subheaderBtns?.btn2?.type === 'url' ? 'URL' : 'Shortcut' }</Label>
                              {
                                subheaderBtns?.btn2?.type === 'url'
                                ? (
                                  <Field
                                    id="extension-link2-url"
                                    className={`form-control ${errors.action2_url ? 'is-invalid' : ''}`}
                                    name="action2_url"
                                    onChange={(e) => {
                                      setSubheaderBtns({
                                        ...subheaderBtns,
                                        btn2: {
                                          ...subheaderBtns.btn2,
                                          attributeValue: e.target.value,
                                        },
                                      });
                                    }}
                                    disabled={isStatusMessageLoading}
                                  />
                                )
                                : (
                                  <Input
                                    id="extension-link2-url"
                                    className={errors.action2_url ? 'is-invalid' : ''}
                                    name="action2_url"
                                    onChange={(e) => {
                                      setSubheaderBtns({
                                        ...subheaderBtns,
                                        btn2: {
                                          ...subheaderBtns.btn2,
                                          attributeValue: e.target.value,
                                        },
                                      });
                                    }}
                                    tag={Field}
                                    as="select"
                                    disabled={isStatusMessageLoading}
                                  >
                                    <option hidden value>--- Select an option ---</option>
                                    <option value="goToHistory">Results History</option>
                                    <option value="reportIssue">Share Results</option>
                                    <option value="helprequest">Request Help</option>
                                    <option value="retest">Retest</option>
                                  </Input>
                                )
                              }
                            </FormGroup>
                            <FormGroup>
                              <Label for="extension-button2-label">Label</Label>
                              <Field
                                id="extension-button2-label"
                                className={`form-control ${errors.action2_label ? 'is-invalid' : ''}`}
                                name="action2_label"
                                onChange={(e) => {
                                  setSubheaderBtns({
                                    ...subheaderBtns,
                                    btn2: {
                                      ...subheaderBtns.btn2,
                                      label: e.target.value,
                                    },
                                  });
                                }}
                                disabled={isStatusMessageLoading}
                              />
                            </FormGroup>
                          </>
                        )}
                      </FormGroup>

                      <div className="form-btn d-flex justify-content-between">
                        <Button generalType="default" type="submit" disabled={isStatusMessageLoading}>Save changes</Button>
                        <Button generalType="default" onClick={onPreviewClick}>Preview</Button>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col xl="6">
                {preview && (
                  <div className="extension-preview-wrapper">
                    <div className={`extension-preview ${statusColor[values?.status_type]}`}>
                      <div className="extension-preview-header">
                        <div className="extension-preview-header-top">
                          <div className="extension-preview-logo">
                            { imageSrc && <img src={imageSrc} alt="logo" className="img-responsive" /> }
                            { !imageSrc && currentEXLogo && <img src={currentEXLogo} alt="logo" className="img-responsive" /> }
                          </div>
                          <div className="extension-preview-update">
                            Updated just now
                            <div className="icon">
                              <img src={IconRefresh} alt="refresh icon" className="img-responsive" />
                            </div>
                          </div>
                        </div>
                        <div
                          className="extension-preview-title"
                          dangerouslySetInnerHTML={{__html: values?.header}}
                        />
                        <div
                          className="extension-preview-subtitle"
                          dangerouslySetInnerHTML={{__html: values?.hidenSubheader}}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </div>
  );
};

ExtensionStatusForm.defaultProps = {
};

ExtensionStatusForm.propTypes = {
  dispatchGetStatusMessage: PropTypes.func.isRequired,
  dispatchUpdateStatusMessage: PropTypes.func.isRequired,
  isStatusMessageLoading: PropTypes.bool.isRequired,
  currentStatusMessage: PropTypes.shape({
    id: PropTypes.number,
    status_type: PropTypes.string,
    header: PropTypes.string,
    subheader: PropTypes.string,
  }),
};

const mapStateToProps = (state) => {
  const {isStatusMessageLoading, currentStatusMessage = {}} = state.EXConfig;
  return {isStatusMessageLoading, currentStatusMessage};
};

const mapDispatchToProps = {
  dispatchGetStatusMessage: getStatusMessage,
  dispatchUpdateStatusMessage: updateStatusMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(ExtensionStatusForm);
