import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';

const MapViewFilterModalBlock = ({title, handleClearClick, children}) => {
  return (
    <div className="filter-block">
      <div className="filter-block-top">
        {title && <div className="filter-block-title">{title}</div>}
        <div className="filter-block-clear">
          {handleClearClick && <Button color="link" onClick={handleClearClick}>Clear</Button>}
        </div>
      </div>
      {children}
    </div>
  );
};

MapViewFilterModalBlock.propTypes = {
  title: PropTypes.string,
  handleClearClick: PropTypes.func,
  children: PropTypes.node,
};

export default MapViewFilterModalBlock;
