import React, { useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';
import queryString from 'query-string';
import RefreshButton from '../../components/refresh-button';
import Pagination from '../../components/pagination';
import { InviteModalConsumer } from '../../context/Context';
import { buildPaginationData } from '../../utility/utils';
import InvitedUsersTable from '../../components/tables/InvitedUsersTable/InvitedUsersTable';
import { toastr } from 'react-redux-toastr';
import apiClient from '../../gears/apiClient';
import TableFilters from '../../components/TableFilters';
import { invitedUsersTableFilters } from '../../components/tables/InvitedUsersTable/invited-users-helper';

const InvitedUsers = ({history, location}) => {  
  const [filter, setFilter] = useState({
    status: 'all'
  });
  const [sortBy, setSortBy] = useState(null);
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  
  let paginationData = null;
  if (data?.invites?.length > 0) {
    paginationData = buildPaginationData(data);
  }

  const fetchInvitedUsers = async (data) => {
    setIsLoading(true);
    try {
      const response = await apiClient.get('/invited-company-users', data);
      setData(response)
    } catch(e) {
      toastr.error(e?.message)
    }
    setIsLoading(false);
  }

  const fetchData = (page) => {
    let queryParams = {};

    if (page) {
      queryParams.page = page;
    }

    if (sortBy) {
      queryParams.sort_by = sortBy;
    }

    if (filter) {
      if(filter.status === 'all') {
        queryParams = {...queryParams};
      } else {
        queryParams = {...queryParams, ...filter};
      }
    }

    fetchInvitedUsers(queryParams)
  }

  useEffect(() => {
    const { search } = location;
    const {filter_by, search_by, keyword} = queryString.parse(search);

    if (filter_by) {
      setFilter(filter_by);
    } else if (search_by) {
      setFilter({search_by, keyword});
    }
  }, [location])

  const navigateToPage = (page) => {
    fetchData(page);
  }

  const onTableSortChange = (sortBy) => {
    if (sortBy) {
      setSortBy(sortBy);
    }
  }

  const onFilterChange = (filter) => {
    history.replace({search: ''});
    setFilter(filter);
  }

  useEffect(() => {
    fetchData()
  }, [filter, sortBy])

  return (
    <>
      <InviteModalConsumer>
        {(toggleModal) => {
          return (
            <div className="container-fluid content">
              <Row className="page-title align-items-center">
                <Col sm={6}>
                  <div className="page-title-box">
                    <h2>Invited Users</h2>
                  </div>
                </Col>
              </Row>
              <div className="page-block page-block_border">
                <Row className="align-items-center">
                  <Col xl={12}>
                    <div className="justify-between align-items-center mb-4 d-md-flex">
                      <div className="align-items-center d-md-flex">
                        <div className="d-flex align-items-center mr-5">
                          <span className="card-title mb-0">Recent Sessions</span>
                          <RefreshButton onRefresh={fetchData} />
                        </div>
                      </div>
                    </div>
                    <div className="justify-between  mb-4 d-md-flex">
                      <TableFilters
                        currentFilter={filter}
                        onFilterChange={onFilterChange}
                        searchByColumnOptions={invitedUsersTableFilters}
                        defaultSelect={invitedUsersTableFilters?.[0].value}
                      />
                    </div>
                    <div className="table-responsive">
                      <InvitedUsersTable
                        fetch={fetchData}
                        data={data?.invites}
                        isLoading={isLoading}
                        onSortChange={onTableSortChange}
                      />
                    </div>
                    {(data?.invites?.length > 0 && paginationData) && (
                      <Pagination paginationData={paginationData} navigateTo={navigateToPage} />
                    )}
                  </Col>
                </Row>
              </div>
            </div>
          );
        }}
      </InviteModalConsumer>
    </>
  );
  
}

export default InvitedUsers;
