export const initialFilters = {
  isLimit: true,
  providers: [],
  upload_lower_range: '',
  upload_upper_range: '',
  download_lower_range: '',
  download_upper_range: '',
  latency_lower_range: '',
  latency_upper_range: '',
  device_to_lan_min_latency: '',
  device_to_lan_max_latency: '',
  created_before: '',
  created_after: '',
};

export const checkLength = (obj, key) => {
  return obj[key]?.length > 0;
};

export const filterMapFilters = (filters) => {
  let params = {};
  const isProvidersFilter = checkLength(filters, 'providers');
  const isDownloadLowerValue = checkLength(filters, 'download_lower_range');
  const isDownloadUpeprValue = checkLength(filters, 'download_upper_range');
  const isUploadLowerValue = checkLength(filters, 'upload_lower_range');
  const isUploadUpeprValue = checkLength(filters, 'upload_upper_range');
  const isLatencyLowerValue = checkLength(filters, 'latency_lower_range');
  const isLatencyUpperValue = checkLength(filters, 'latency_upper_range');
  const isWiFiLowerValue = checkLength(filters, 'device_to_lan_min_latency');
  const isWiFiUpperValue = checkLength(filters, 'device_to_lan_max_latency');
  const isDateRangeValue = checkLength(filters, 'created_after');

  if (isProvidersFilter) {
    const string = filters.providers.join(', ');
    params = {...params, providers: string};
  }
  if (isDownloadLowerValue) { params = {...params, download_lower_range: filters.download_lower_range }; }
  if (isDownloadUpeprValue) { params = {...params, download_upper_range: filters.download_upper_range }; }
  if (isUploadLowerValue) { params = {...params, upload_lower_range: filters.upload_lower_range }; }
  if (isUploadUpeprValue) { params = {...params, upload_upper_range: filters.upload_upper_range }; }
  if (isLatencyLowerValue) { params = {...params, latency_lower_range: filters.latency_lower_range }; }
  if (isLatencyUpperValue) { params = {...params, latency_upper_range: filters.latency_upper_range }; }
  if (isWiFiLowerValue) { params = {...params, device_to_lan_min_latency: filters.device_to_lan_min_latency }; }
  if (isWiFiUpperValue) { params = {...params, device_to_lan_max_latency: filters.device_to_lan_max_latency }; }
  if (isDateRangeValue) {
    params = {
      ...params,
      created_after: filters.created_after,
      created_before: filters.created_before,
    };
  }
  return params;
};

export const clearFiltersGroup = (setFilters, groupName) => {
  switch (groupName) {
    case 'latency':
      setFilters((prevState) => ({
        ...prevState,
        latency_lower_range: '',
        latency_upper_range: '',
      }));
      break;
    case 'wifi':
      setFilters((prevState) => ({
        ...prevState,
        device_to_lan_min_latency: '',
        device_to_lan_max_latency: '',
      }));
      break;
    case 'download':
      setFilters((prevState) => ({
        ...prevState,
        download_lower_range: '',
        download_upper_range: '',
      }));
      break;
    case 'upload':
      setFilters((prevState) => ({
        ...prevState,
        upload_lower_range: '',
        upload_upper_range: '',
      }));
      break;
    case 'providers':
      setFilters((prevState) => ({...prevState, providers: []}));
      break;
    case 'date':
      setFilters((prevState) => ({
        ...prevState,
        created_after: '',
        created_before: '',
      }));
      break;
    default:
      setFilters(initialFilters);
  }
};
