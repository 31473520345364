import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Helmet} from 'react-helmet';
import Slider from 'react-slick';
import {withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import {browserName, osName} from 'react-device-detect';
import axios from 'axios';
import SpeedTestLib from '../../test-providers/librespeed/speedtest';
import MlabTestLib from '../../test-providers/mlab';
import PingTest from '../../test-providers/losstest/pingtest';
import PublicHeader from '../../components/public/PublicHeader';
import PublicFooter from '../../components/public/PublicFooter';
import spinnerGif from '../../images/Spinner.gif';
import AhoyTrack from '../../modules/AhoyTrack';
import apiClient from '../../gears/apiClient';

const DEFAULT_TEST_SERVER_ENDPOINT = '//ohio-test.hubbleiq.io';

const SliderSettings = {
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 7000,
  arrows: false,
  dots: true,
  pauseOnHover: false,
  centerMode: true,
  centerPadding: '0',
};

class Testing extends Component {
  constructor(props) {
    AhoyTrack();
    super(props);
    this.state = {
      isLoading: true,
      loadingCurrentText: '',
      loadingTexts: [
        'Testing download speed',
        'Testing upload speed',
        'Monitoring network stability',
        'Measuring signal quality',
        'Processing test results',
        'Almost finished... ',
      ],
      loadingTextIndex: 0,
      loadingTextTimeout: 7000,
      loadingTextInterval: null,
      webTestResult: {},
      isWindowFocused: true,
      guid: null,
    };
  }

  async componentDidMount() {
    const {history} = this.props;
    const isTestRunning = !!+localStorage.getItem('isTestRunning');
    // document.addEventListener('visibilitychange', this.onWindowsBlur);
    const currentUserGuid = localStorage.getItem('currentUserUUID');

    if (isTestRunning && isTestRunning === true) {
      history.push('/');
      return;
    }

    if (currentUserGuid) {
      this.setState({guid: currentUserGuid}, () => {
        this.startTesting();
      });
    } else {
      this.startTesting();
    }
  }

  componentWillUnmount() {
    // window.removeEventListener('blur', this.onWindowsBlur);
  }

  onWindowsBlur = () => {
    this.setState({isWindowFocused: false});
  }

  startTesting = async () => {
    const { isEndpointPresent } = this.props;
    localStorage.setItem('isTestRunning', 1);
    this.initLoadingText();
    await this.getApplications();
    if (isEndpointPresent) {
      this.librespeedTest();
    } else {
      try {
        const mlabTest = new MlabTestLib();
        if (mlabTest) {
          await mlabTest.runTest(async (data) => {
            const {data: clientIp} = await axios.get('https://api.ipify.org');
            const webTestResult = {
              ...data,
              clientIp,
              browser: browserName,
              os: osName,
            };

            this.setState({webTestResult}, () => {
              this.initLossTest();
            });
          });
        }
      } catch (e) {
        this.librespeedTest();
      }
    }
  }

  getApplications = async () => {
    const { guid } = this.state;
    try {
      const response = await apiClient.get('/current-app-configs');
      const promises = response.data.apps?.map(({ id, url }) => new Promise((resolve) => {
        const hasProtocol = /^http[s]?:\/\//.test(url);
        const modifiedUrl = !hasProtocol ? `http://${url}` : url;

        const appPayload = {
          app_id: id,
          reachable: false,
          average_page_load_time: null,
          latency_response: null,
        };

        fetch(modifiedUrl, { mode: 'no-cors' }).then(() => {
          const startTime = performance.now();
          return fetch(modifiedUrl, { mode: 'no-cors' }).then(() => {
            const endTime = performance.now();
            appPayload.reachable = true;
            appPayload.latency_response = Math.round(endTime - startTime);
            resolve(appPayload);
          }).catch(() => resolve(appPayload));
        }).catch(() => resolve(appPayload));
    }));

      const responseBulk = await Promise.allSettled(promises).then((res) => {
        if (res && res?.length >= 0) {
          return apiClient.post('/new/bulk-app-activity', {
            activities: res.map((app) => app.value),
            guid,
          });
        }
        return null;
      });
      if (!guid) {
        localStorage.setItem('currentUserUUID', responseBulk.data.guid);
        this.setState({
          guid: responseBulk.data.guid,
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  initLossTest = () => {
    console.log('init loss test')
    const {isWindowFocused} = this.state;

    if (!isWindowFocused) {
      return;
    }

    const configuration = {
      test: {
        count: 100,
        frequency: 65,
        delay: 25,
        size: 105,
      },
      socket: {
        host: this.getLossTestHost(),
      },
    };

    const pingtest = new PingTest(configuration);

    // done
    pingtest.addEventListener('done', (event) => {
      const {webTestResult} = this.state;
      if (event.detail.udp.lost !== undefined) {
        webTestResult.packet_loss = parseInt(((parseInt(event.detail.udp.lost, 10) / parseInt(event.detail.udp.count, 10)) * 100), 10);
      }

      this.setState({webTestResult, isLoading: false}, () => {
        this.redirectToTestResult();
      });
    });

    pingtest.run();
  }

  librespeedTest = () => {
    console.log('liberspeed test')
    const {testServerEndpoint} = this.props;
    const {isWindowFocused} = this.state;
    const testObject = new SpeedTestLib();

    if (!isWindowFocused) {
      return;
    }

    if (testObject) {
      if (!isWindowFocused) {
        testObject.abort();
        return;
      }

      testObject.setSelectedServer({
        name: 'custom_server',
        server: testServerEndpoint,
        dlURL: 'backend/garbage.php',
        ulURL: 'backend/empty.php',
        pingURL: 'backend/empty.php',
        getIpURL: 'backend/getIP.php',
      });
      testObject.onupdate = (data) => {
        const {isWindowFocused} = this.state;
        if (!isWindowFocused) {
          testObject.abort();
          return;
        }
        if (data.testState === 4) {
          const dlSpeed = data.dlStatus.length ? Number(data.dlStatus).toFixed(2) : null;
          const ulSpeed = data.ulStatus.length ? Number(data.ulStatus).toFixed(2) : null;
          const webTestResult = {
            download: dlSpeed,
            upload: ulSpeed,
            latency: Number(data.pingStatus).toFixed(),
            jitter: Number(data.jitterStatus).toFixed(),
            clientIp: data.clientIp,
            browser: browserName,
            os: osName,
          };

          this.setState({webTestResult}, () => {
            this.initLossTest();
          });
        }
      };
      testObject.start();
    }
  }

  initLoadingText = () => {
    const {loadingTextTimeout, loadingTextIndex, loadingTexts, isWindowFocused} = this.state;
    let {loadingTextInterval} = this.state;

    if (!isWindowFocused) {
      return;
    }

    if (!loadingTextInterval) {
      this.setState({
        loadingCurrentText: loadingTexts[loadingTextIndex],
      });
      loadingTextInterval = setInterval(() => {
        const nextIndex = loadingTextIndex + 1;
        if (nextIndex <= loadingTexts.length - 1) {
          this.setState({
            loadingCurrentText: loadingTexts[nextIndex],
            loadingTextIndex: nextIndex,
          });
        }
      }, loadingTextTimeout);
      clearInterval(loadingTextInterval)
      this.setState({
        loadingTextInterval,
      });
    }
  }

  redirectToTestResult = () => {
    const {history} = this.props;
    const {webTestResult, isWindowFocused, guid} = this.state;

    if (!isWindowFocused) {
      return;
    }

    localStorage.setItem('submitTestResult', 1);

    history.push({
      pathname: '/test-result',
      state: {...webTestResult, guid},
    });
  }

  restartTest = () => {
    this.setState({isWindowFocused: true, isLoading: true}, () => {
      this.startTesting();
    });
  }

  getLossTestHost = () => {
    const {testServerEndpoint} = this.props;
    let websocketProtocol = 'ws';
    let websocketPort = '10001';

    if (window.location.protocol === 'https:') {
      websocketProtocol = 'wss';
      websocketPort = '10000';
    }

    return `${websocketProtocol}:${testServerEndpoint}:${websocketPort}`;
  }

  render() {
    const {header_test, sub_header_test, toll_tips, welcome_header} = this.props;
    const {isLoading, loadingCurrentText, loadingTexts, isWindowFocused, loadingTextIndex} = this.state;
    return (
      <>
        <Helmet>
          <title>{welcome_header}</title>
          <link
            rel="stylesheet"
            type="text/css"
            // eslint-disable-next-line react/no-unknown-property
            charSet="UTF-8"
            href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
          />
        </Helmet>

        <PublicHeader />

        <div className="page-content page-testing">

          {
            !isWindowFocused && (
              <div className="testing-error-container">
                <div className="container">
                  <div className="row">
                    <div className="col-12 d-flex flex-column align-items-center">
                      <p className="heading center">Uh Oh!</p>
                      <p className="subheading center">
                        For the most accurate test results, please keep this window open and active,
                        and do not refresh this page while the test is in progress.
                      </p>

                      <button type="button" className="button button-dark button-md" onClick={this.restartTest}>Restart Test</button>
                    </div>
                  </div>
                </div>
              </div>
            )
          }

          {
            isWindowFocused && (
              <div>
                <div className="container">
                  <div className="row">
                    <div className="col-12 center">
                      <p className="heading">{header_test}</p>
                      <p className="subheading">{sub_header_test}</p>
                      <p className="spinner-img">
                        {isLoading && <img src={spinnerGif} alt="" />}
                      </p>
                      <p className={`page-testing__sub-gif ${loadingTextIndex < loadingTexts.length - 1 ? 'animate-flicker' : ''}`}>{loadingCurrentText}</p>
                    </div>
                  </div>
                </div>

                <div className="carousel">
                  <div className="container">
                    <div className="row">
                      <div className="col-12 col-sm-10 m-auto carousel__block">
                        <Slider {...SliderSettings}>
                          {
                            toll_tips.map(({img, header, sub_header}, index) => {
                              if (!img || !header || !sub_header) {
                                return '';
                              }

                              return (
                                <div className="carousel__item center" key={`testing-carusel-${index}`}>
                                  <img className="carousel__img" src={img} alt="" />
                                  <p className="carousel__heading mt-3">{header}</p>
                                  <p className="carousel__text">{sub_header}</p>
                                </div>
                              );
                            })
                          }
                        </Slider>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          }
        </div>
        <PublicFooter />
      </>
    );
  }
}

Testing.defaultProps = {
  welcome_header: '',
  header_test: '',
  sub_header_test: '',
  toll_tips: [
    {
      img: '',
      header: '',
      sub_header: '',
    },
  ],
};

Testing.propTypes = {
  companyName: PropTypes.string,
  welcome_header: PropTypes.string,
  header_test: PropTypes.string,
  sub_header_test: PropTypes.string,
  toll_tips: PropTypes.arrayOf(PropTypes.shape({
    img: PropTypes.string,
    header: PropTypes.string,
    sub_header: PropTypes.string,
  })),
  testServerEndpoint: PropTypes.string,
};

const mapStateToProps = (state) => {
  const {
    companyName,
    companyDetails: {
      step_1,
      step_2,
      test_server_endpoint,
      chrome_ex_key: companyKey,
    }
  } = state.Company;

  const stepData = {};
  stepData.welcome_header = step_1?.welcome_header;
  stepData.isEndpointPresent = Boolean(test_server_endpoint);
  stepData.testServerEndpoint = test_server_endpoint ? `//${test_server_endpoint.trim()}` : DEFAULT_TEST_SERVER_ENDPOINT;

  if (step_2) {
    stepData.header_test = step_2.header_test;
    stepData.sub_header_test = step_2.sub_header_test;
    stepData.toll_tips = [];

    for (let i = 1; i <= 3; i += 1) {
      stepData.toll_tips.push({
        img: step_2[`tool_tip_img_${i}`].url || '',
        header: step_2[`tool_tip_header_${i}`] || '',
        sub_header: step_2[`tool_tip_sub_header_${i}`] || '',
      });
    }
  }

  return {...stepData, companyName, companyKey};
};

const mapDispatchToProps = {};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Testing));
