import React, { useEffect, useState } from 'react';
import CustomScroll from 'react-custom-scroll';
import { Button, Input, InputGroup } from 'reactstrap';
import * as Yup from 'yup';
import AiResponse from '../aiResponse/AiResponse';
import ComponentLoader from '../ComponentLoader';
import TestFeature from '../testFeature/TestFeature';

const validationSchema = Yup.object().shape({
  email: Yup.string().email(),
});

const ExportPopup = ({
  close,
  onSubmit,
  data = null,
  isLoading = false,
  isMapView = false,
  onSavePolygon,
  noSave = null,
  isSummary = false,
  testIds,
  guids
}) => {
  const [isValid, setIsValid] = useState(false);
  const [emailValue, setEmailValue] = useState('');
  const [polygonName, setPolygonName] = useState('');
  const [wasSubmitted, setWasSubmitted] = useState(false);

  const handleEmailChange = (e) => {
    const { value } = e.target;
    validationSchema.isValid({ email: value }).then((valid) => {
      if (valid) {
        setEmailValue(value);
        setIsValid(true);
      } else {
        setIsValid(false);
      }
    });
  };

  const submitForm = () => {
    if (!wasSubmitted) {
      onSubmit(emailValue);
      setWasSubmitted(true);
    } else {
      close();
    }
  };

  const renderData = (dataToRender) => {
    const keys = Object.keys(dataToRender);
    return (
      keys.map((item) => {
        const isMedian = item.includes('median');
        const modifiedText = isMedian ? `Median ${item.replace('_median', '')}` : `Average ${item}`;
        return (
          <div className="popup-data-item" key={item}>
            <div className="popup-data-label">{modifiedText}</div>
            <div className="popup-data-value">{dataToRender[item]?.toFixed(2) ?? '0.00'}</div>
          </div>
        );
      })
    );
  };

  const aiPostData = {
    test_ids: testIds 
  }

  return (
    <CustomScroll heightRelativeToParent="100%">
      <div className={`popup-body ${isSummary ? 'popup-summary' : ''}`}>
        {isLoading && <ComponentLoader />}
        {data && (
          <>
            <div className="popup-summary-block">
              <div className="popup-data">
                <h4>Summary: </h4>
                {renderData(data)}
              </div>
              <hr />
              <AiResponse
                type="userActivityAverages"
                data={aiPostData}
              />
            </div>
          </>
        )}
        <div>
          <p className="popup-text">
            {!wasSubmitted && 'A .csv data export will be sent to the e-mail provided below:'}
            {wasSubmitted && 'An e-mail will be sent to you once your export is processed. If it does not arrive, be sure to check your SPAM or Junk folder.'}
          </p>
          {!wasSubmitted && (
            <InputGroup>
              <Input
                type="email"
                name="email"
                placeholder="E-mail address"
                onChange={handleEmailChange}
              />
            </InputGroup>
          )}
          <div className="popup-btns">
            <Button
              color="default"
              onClick={submitForm}
              disabled={!isValid}
            >
              {wasSubmitted ? 'Close' : 'Send now'}
            </Button>
          </div>
          {(isMapView && !noSave) && (
            <>
              <hr />
              <p className="popup-text">Save Polygon</p>
              <InputGroup>
                <Input
                  name="polygon_name"
                  placeholder="Enter polygon name"
                  value={polygonName}
                  onChange={(e) => setPolygonName(e.target.value)}
                />
              </InputGroup>
              <div className="popup-btns">
                <Button
                  color="default"
                  onClick={() => onSavePolygon(polygonName)}
                  disabled={polygonName.length < 1}
                >
                  Save Polygon
                </Button>
              </div>
              <TestFeature guids={guids} />
            </>
          )}
        </div>
      </div>
    </CustomScroll>
  );
};

export default ExportPopup;
