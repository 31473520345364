import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useHistory, useRouteMatch } from 'react-router-dom/cjs/react-router-dom';
import RefreshButton from '../../refresh-button';
import ActivitiesTableFilter from '../../tables-filters/ActivitiesTableFilter';
import UsersActivitiesTable from '../../tables/UsersActivitiesTable';
import { fetchUserStatus } from '../../../store/actions';
import Pagination from '../../pagination';
import { buildPaginationData } from '../../../utility/utils';

const UsersTable = ({onMarMarkerClick}) => {
  const history = useHistory();
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const {data, loading: isLoading} = useSelector((state) => state.UserStatus);
  const [sortBy, setSortBy] = useState(null);
  const [filter, setFilter] = useState(null);

  const fetchData = (page) => {
    // const {fetchUserStatus, history} = props;

    let queryParams = {};

    if (page) {
      queryParams.page = page;
    }

    if (sortBy) {
      queryParams.sort_by = sortBy;
    }

    if (filter) {
      queryParams = {...queryParams, ...filter};
    }

    dispatch(fetchUserStatus(history, match.params.id, queryParams));
  };

  useEffect(() => {
    fetchData();
  }, []);

  const onFilterChange = (filter) => {
    if (filter) {
      setFilter(filter, () => fetchData());
    }
  };

  const onTableSortChange = (sortBy) => {
    if (sortBy) {
      setSortBy(sortBy, () => fetchData());
    }
  };

  let paginationData = null;
  if (data?.user_activities?.length > 0) {
    paginationData = buildPaginationData(data);
  }

  const navigateToPage = (page) => {
    fetchData(page);
  };

  return (
    <>
      <div className="justify-between align-items-center mb-4 d-md-flex">
        <div className="align-items-center d-md-flex">
          <div className="d-flex align-items-center mr-5">
            <span className="card-title mb-0">Recent Sessions</span>
            <RefreshButton onRefresh={fetchData} />
          </div>
        </div>
      </div>
      <div className="justify-between mb-4 d-md-flex">
        <ActivitiesTableFilter
          onFilterChange={onFilterChange}
          userId={+match.params.id}
        />
      </div>
      <div className="table-responsive">
        <UsersActivitiesTable
          userId={+match.params.id}
          data={data?.user_activities}
          isLoading={isLoading}
          onSortChange={onTableSortChange}
          onMarMarkerClick={onMarMarkerClick}
        />
      </div>
      {(data?.user_activities?.length > 0 && paginationData) && (
        <Pagination
          paginationData={paginationData}
          navigateTo={navigateToPage}
        />
      )}
    </>
  );
};

UsersTable.propTypes = {
  onMarMarkerClick: PropTypes.func,
};

export default UsersTable;
