import { useEffect } from 'react';

const GoogleAnalytics = () => {
  const GA_ID = process.env.REACT_APP_GTM_ID;

  useEffect(() => {
    if (GA_ID) {
      const script = document.createElement('script');
      script.async = true;
      script.src = `https://www.googletagmanager.com/gtag/js?id=${GA_ID}`;
      document.head.appendChild(script);

      // 2. Initialize Google Analytics after the script loads
      script.onload = () => {
        window.dataLayer = window.dataLayer || [];
        function gtag() {
          window.dataLayer.push(arguments);
        }
        gtag('js', new Date());
        gtag('config', GA_ID);
      };

      return () => {
        const scriptTag = document.querySelector(`script[src="https://www.googletagmanager.com/gtag/js?id=${GA_ID}"]`);
        if (scriptTag) {
          scriptTag.remove();
        }
      };
    }
  }, [GA_ID]);

  return null;
};

export default GoogleAnalytics;