import { takeEvery, fork, put, all } from 'redux-saga/effects';
import { FETCH_ANALYTICS, FETCH_DASHBOARD_ANALYTICS, FETCH_DASHBOARD_QUERY } from './actionTypes';
import {
  fetchDashboardAnalyticsSuccess, fetchDashboardAnalyticsApiError,
  fetchDashboardQuerySuccess, fetchDashboardQueryError, fetchAnalyticsSuccess, fetchAnalyticsApiError, fetchAnalyticsError,
} from './actions';
import apiClient from '../../../gears/apiClient';

function* fetchDashboardAnalytics({ payload: { params } }) {
  try {
    const response = yield apiClient.get('/dashboard_analytics', params);
    yield put(fetchDashboardAnalyticsSuccess(response));
  } catch (error) {
    yield put(fetchDashboardAnalyticsApiError(error));
  }
}

export function* watchDashboardAnalyticsFetch() {
  yield takeEvery(FETCH_DASHBOARD_ANALYTICS, fetchDashboardAnalytics);
}

function* fetchDashboardQuery() {
  try {
    const response = yield apiClient.get('/overview_metrics');
    yield put(fetchDashboardQuerySuccess(response));
  } catch (error) {
    yield put(fetchDashboardQueryError(error));
  }
}

export function* watchFetchDashboardQuery() {
  yield takeEvery(FETCH_DASHBOARD_QUERY, fetchDashboardQuery);
}

function* fetchAnalytics({ payload }) {
  try {
    const response = yield apiClient.get(`/analytics?keyword=${payload}`);
    yield put(fetchAnalyticsSuccess(payload, response));
  } catch (error) {
    yield put(fetchAnalyticsError(payload, error));
  }
}

export function* watchAnalyticsFetch() {
  yield takeEvery(FETCH_ANALYTICS, fetchAnalytics);
}

function* dashboardAnalyticsSaga() {
  yield all([
    fork(watchDashboardAnalyticsFetch),
    fork(watchFetchDashboardQuery),
    fork(watchAnalyticsFetch),
  ]);
}

export default dashboardAnalyticsSaga;
