import React from 'react';
import { FormGroup, Label } from 'reactstrap';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import * as PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {inviteUser} from '../store/actions';
// import AhoyTrack from '../modules/AhoyTrack';
import Button from '../components/Button/Button';

const inviteSchema = Yup.object().shape({
  first_name: Yup.string().min(2, 'Too Short!').required('Required'),
  last_name: Yup.string().min(2, 'Too Short!').required('Required'),
  email: Yup.string().email('Invalid email').required('Required'),
});

// AhoyTrack('input');

const InviteForm = ({ inviteUser, close, history, changeActiveTab, sentToEmail, isLabelVisible = true }) => {
  return (
    <>
      <Formik
        initialValues={{
          first_name: '',
          last_name: '',
          email: '',
        }}
        validationSchema={inviteSchema}
        onSubmit={(values, {resetForm}) => {
          const updateUserCallback = () => {
            if (!changeActiveTab) {
              close();
            }
            resetForm();
          };

          if (changeActiveTab) {
            sentToEmail(values.email);
            changeActiveTab(3);
          }
          inviteUser(values, history, updateUserCallback);
        }}
        enableReinitialize
      >
        {({ errors, touched, handleChange, isValid, dirty }) => (
          <Form id="invite-form" className="form">
            <FormGroup>
              {isLabelVisible && <Label for="invite-firstName">First Name</Label>}
              <Field
                id="invite-firstName"
                className="form-control"
                type="text"
                name="first_name"
                placeholder="First name"
                onChange={(e) => handleChange(e)}
              />
              {errors.first_name && touched.first_name ? (
                <div className="error">{errors.first_name}</div>
              ) : null}
            </FormGroup>
            <FormGroup>
              {isLabelVisible && <Label for="invite-lastName">Last Name</Label>}
              <Field
                id="invite-lastName"
                className="form-control"
                type="text"
                name="last_name"
                placeholder="Last name"
                onChange={(e) => handleChange(e)}
              />
              {errors.last_name && touched.last_name ? (
                <div className="error">{errors.last_name}</div>
              ) : null}
            </FormGroup>
            <FormGroup>
              {isLabelVisible && <Label for="invite-email">Email</Label>}
              <Field
                id="invite-email"
                className="form-control"
                type="email"
                name="email"
                placeholder="E-mail address"
                onChange={(e) => handleChange(e)}
              />
              {errors.email && touched.email ? (
                <div className="error">{errors.email}</div>
              ) : null}
            </FormGroup>
            <div className="form-actions">
              <Button
                type="submit"
                generalType="default"
                disabled={!isValid || !dirty}
              >
                Send Invitation
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

const mapStateToProps = () => ({});

InviteForm.propTypes = {
  inviteUser: PropTypes.func,
  close: PropTypes.func,
};

export default withRouter(connect(mapStateToProps, {inviteUser})(InviteForm));
