import React from 'react';
import { Col, CustomInput, FormGroup, Label, Row } from 'reactstrap';

const UpdateExtensionLogo = ({ currentEXLogo, imageSrc, changeImage, browserTest }) => {
  return (
    <div className="settings-logo">
      <Row>
        <Col md={3} className="mb-3">
          <div className="settings-logo-title">Update logo</div>
          {
            browserTest
            ? <div className="settings-logo-text">This logo image will appear on your organization’s HubbleIQ browser test. This image will also be shown on this HubbleIQ admin portal.</div>
            : <div className="settings-logo-text">This image will appear on Chrome Extensions deployed by your organization.</div>
          }
        </Col>
        <Col sm={6} md={3}>
          <div className="settings-logo-item">
            <div className="title">File upload</div>
            {!browserTest && (
              <div className="settings-logo-text mb-3">White images & logos recommended</div>
            )}
            <div className="settings-logo-text">Files supported are JPG, PNG, GIF, up to 10MB file size.</div>
            <div className="settings-logo-text">Recommended dimensions are 275x55</div>
            <FormGroup className="form-file mt-3">
              <Label for="settings-logo" className="button button-default">
                <CustomInput
                  type="file"
                  name="chrome_ex_logo"
                  id="settings-logo"
                  onChange={changeImage}
                />
                Browse
              </Label>
            </FormGroup>
          </div>
        </Col>
        <Col sm={6}>
          <div className="settings-logo-item">
            <div className="title">Current logo</div>
            <div className="settings-logo-img">
              { imageSrc && <img src={imageSrc} alt="logo" className="img-responsive" /> }
              { !imageSrc && currentEXLogo && <img src={currentEXLogo} alt="logo" className="img-responsive" /> }
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default UpdateExtensionLogo;
