export const FETCH_RECENT_ACTIVITIES = 'FETCH_RECENT_ACTIVITIES';
export const FETCH_RECENT_ACTIVITIES_SUCCESS = 'FETCH_RECENT_ACTIVITIES_SUCCESS';
export const FETCH_RECENT_ACTIVITIES_API_ERROR = 'FETCH_RECENT_ACTIVITIES_API_ERROR';

export const FETCH_NETWORK_ANALYTICS = 'FETCH_NETWORK_ANALYTICS';
export const FETCH_NETWORK_ANALYTICS_SUCCESS = 'FETCH_NETWORK_ANALYTICS_SUCCESS';
export const FETCH_NETWORK_ANALYTICS_ERROR = 'FETCH_NETWORK_ANALYTICS_ERROR';

export const FETCH_USERS_SPEED = 'FETCH_USERS_SPEED';
export const FETCH_USERS_SPEED_SUCCESS = 'FETCH_USERS_SPEED_SUCCESS';
export const FETCH_USERS_SPEED_ERROR = 'FETCH_USERS_SPEED_ERROR';

export const CREATE_ACTIVITY_REQUEST = 'CREATE_ACTIVITY_REQUEST';
export const CREATE_ACTIVITY_SUCCESS = 'CREATE_ACTIVITY_SUCCESS';
export const CREATE_ACTIVITY_ERROR = 'CREATE_ACTIVITY_ERROR';

export const FETCH_USERS_TESTS = 'FETCH_USERS_TESTS';
export const FETCH_USERS_TESTS_SUCCESS = 'FETCH_USERS_TESTS_SUCCESS';
export const FETCH_USERS_TESTS_ERROR = 'FETCH_USERS_TESTS_ERROR';
