import React, { useEffect, useRef, useState } from 'react';
import apiClient from "../../../gears/apiClient";
import { Input } from "reactstrap";
import Loader from "../../Loader";
import FeaturedIssueCard from "../FeaturedIssueCard/FeaturedIssueCard";
import { ReactComponent as ChevronsIcons } from "../../../images/chevrons-left.svg";
import { toastr } from "react-redux-toastr";
import { getSelectOptions } from "./featured-issues-block.helper";
import styles from './featured-issues.module.scss';

const FeaturedIssuesBlock = ({ closeDrawer }) => {
	const [select, setSelect] = React.useState('all');
	const [prevSelect, setPrevSelect] = useState(select);
	const [page, setPage] = useState(1);
	const [issueData, setIssueData] = useState([])
	const lastElementRef = useRef(null);
	const observer = useRef();
	const [data, setData] = useState([]);
	const [totalIssues, setTotalIssues] = useState();
	const [isLoading, setIsLoading] = useState(false)
	
	const fetchData = async (page, selectValue) => {
		setIsLoading(true);
		try {
			const response = await apiClient.get('/featured-disruption-logs', {status: selectValue, page});
			setData(response?.issues);
			setTotalIssues(response?.totalItems);
		} catch (e) {
			toastr.error('Error', 'Cannotfetch my notification data');
		} finally {
			setIsLoading(false);
		}
	}
	useEffect(() => {
		if (isLoading || !data?.length) return;
		if (observer.current) observer.current.disconnect();
		observer.current = new IntersectionObserver(([entry]) => {
			if (entry.isIntersecting) {
				setPage((prevPageNumber) => prevPageNumber + 1);
			}
		});
		if (lastElementRef.current) observer.current.observe(lastElementRef.current);
		return () => observer.current.disconnect();
		
	}, [isLoading, lastElementRef]);
	
	useEffect(() => {
		if(prevSelect !== select) {
			setIssueData([]);
			setPage(1);
			setPrevSelect(select);
		} else {
			fetchData(page, select);
		}
	}, [select, page]);
	
	useEffect(() => {
		if(data?.length) {
			setIssueData((oldIssues) => [...oldIssues, ...data]);
		}
	}, [data]);
	
	return (
		<div className={styles.wrapper}>
			<button className={styles.headerBtn} onClick={closeDrawer}>
				<ChevronsIcons />
				<span>Featured Issues</span>
			</button>
			<div className={styles.control}>
			<span>{totalIssues || issueData?.length} Issues</span>
				<Input
					className="form-control"
					type="select"
					onChange={(e) => {
						setSelect(e.target.value)
					}}
					name="select"
					value={select}
				>
					{getSelectOptions().map((option) => <option key={option.label} value={option.value}>{option.label}</option>)}
				</Input>
			</div>
			{isLoading && <Loader />}
			<div className={styles.issues}>
				{!isLoading && data?.length === 0 && <span className={styles.noIssuesMsg}>There is no Issues!</span>}
				{issueData?.map((issue) =>
					<FeaturedIssueCard
						key={issue.id}
						data={issue}
					/>
				)}
				<div ref={lastElementRef} />
			</div>
		</div>
	);
};

export default FeaturedIssuesBlock;
