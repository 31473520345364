import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Dropdown, DropdownMenu, DropdownToggle, Spinner } from 'reactstrap';
import { toastr } from 'react-redux-toastr';
import CustomScroll from 'react-custom-scroll';
import apiClient from '../../gears/apiClient';

const MapBoxPolygons = ({setPolygonTemplate}) => {
  const [isPolygonsListLoading, setIsPolygonsListLoading] = useState(false);
  const [isPolygonsListOpen, setIsPolygonsListOpen] = useState(false);
  const [polygonsList, setPolygonsList] = useState([]);

  const chooseLayer = (polygonId) => {
    const currentPolygon = polygonsList.find((polygon) => polygon.properties.id === polygonId);
    setPolygonTemplate(currentPolygon);
    setIsPolygonsListOpen(false);
    setPolygonsList([]);
  };

  const deletePolygon = async (polygonId) => {
    setIsPolygonsListLoading(true);
    try {
      await apiClient.delete(`/delete-polygon/${polygonId}`);
      const copyPolygonsList = [...polygonsList];
      const modifiedPolygonsArr = copyPolygonsList.filter((polygon) => polygon.properties.id !== polygonId);
      setPolygonsList(modifiedPolygonsArr);
    } catch (e) {
      toastr.error(e);
    }
    setIsPolygonsListLoading(false);
  };

  const renderPolygonsList = () => {
    const arr = polygonsList.map((polygon) => (
      <div className="map-polygons-item" key={polygon.properties?.id}>
        <Button
          key={polygon.properties?.id}
          color="link"
          className="dropdown-item"
          onClick={() => chooseLayer(polygon.properties?.id)}
        >
          {polygon.properties?.name}
        </Button>
        <Button
          color="link"
          className="btn-remove"
          onClick={() => deletePolygon(polygon.properties?.id)}
        >
          <i className="mdi mdi-trash-can-outline" />
        </Button>
      </div>
    ));
    return arr;
  };

  const getPolygons = async () => {
    setIsPolygonsListLoading(true);
    try {
      const response = await apiClient.get('/my-polygons');
      setPolygonsList(response.features);
    } catch (e) {
      toastr.error(e);
    }
    setIsPolygonsListLoading(false);
  };

  const toggle = () => {
    setIsPolygonsListOpen((prevState) => !prevState);
    if (!isPolygonsListOpen) {
      getPolygons();
    } else {
      setPolygonsList([]);
    }
  };

  return (
    <Dropdown isOpen={isPolygonsListOpen} toggle={toggle} className="map-polygons" direction="left">
      <DropdownToggle className="btn waves-effect btn-polygons" tag="button">
        <span className="icon-wrapper"><i className="mdi mdi-format-list-bulleted-square" /></span>
      </DropdownToggle>
      <DropdownMenu>
        {isPolygonsListLoading && <div className="spinner-wrapper text-center"><Spinner className="primary" /></div>}
        <div className="dropdown-scroll">
          <CustomScroll heightRelativeToParent="100%">
            {polygonsList.length > 0 && renderPolygonsList()}
            {polygonsList.length <= 0 && <div className="text-center">No polygons</div>}
          </CustomScroll>
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};

MapBoxPolygons.propTypes = {
  setPolygonTemplate: PropTypes.func,
};

export default MapBoxPolygons;
