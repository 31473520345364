import {
  GET_APPLICATIONS, GET_APPLICATIONS_SUCCESS, GET_APPLICATIONS_ERROR,
  ADD_APPLICATION, ADD_APPLICATION_SUCCESS, ADD_APPLICATION_ERROR,
  DELETE_APPLICATION, DELETE_APPLICATION_SUCCESS, DELETE_APPLICATION_ERROR,
  UPDATE_APPLICATION, UPDATE_APPLICATION_SUCCESS, UPDATE_APPLICATION_ERROR,
  GET_APPLICATIONS_ACTIVITY, GET_APPLICATIONS_ACTIVITY_SUCCESS, GET_APPLICATIONS_ACTIVITY_ERROR, GET_APPLICATIONS_EVENTS, GET_APPLICATIONS_EVENTS_SUCCESS, GET_APPLICATIONS_EVENTS_ERROR,
} from './actionTypes';

const initialState = {
  error: null,
  loading: false,
  data: [],
  appsActivity: [],
  appsEvents: [],
};

const fetchApplications = (state = initialState, action) => {
  switch (action.type) {
    // Get applications
    case GET_APPLICATIONS:
      return {
        ...state,
        loading: true,
      };
    case GET_APPLICATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.apps,
      };
    case GET_APPLICATIONS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    // Add application
    case ADD_APPLICATION:
      return {
        ...state,
        loading: true,
      };
    case ADD_APPLICATION_SUCCESS:
      return {
        ...state,
        loading: false,
        data: [
          ...state.data,
          action.payload.app,
        ],
      };
    case ADD_APPLICATION_ERROR:
      return { ...state, error: action.payload, loading: false };
    // Delete application
    case DELETE_APPLICATION:
      return {
        ...state,
        loading: true,
      };
    case DELETE_APPLICATION_SUCCESS:
      return {
        ...state,
        loading: false,
        data: state.data.filter(({id}) => id !== action.id?.id),
      };
    case DELETE_APPLICATION_ERROR:
      return { ...state, error: action.payload, loading: false };
    // Update application
    case UPDATE_APPLICATION:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_APPLICATION_SUCCESS:
      return {
        ...state,
        loading: false,
        data: state.data.map((app) => {
          if (app.id === action.payload.id) {
            return action.payload;
          }
          return app;
        }),
      };
    case UPDATE_APPLICATION_ERROR:
      return { ...state, error: action.payload, loading: false };
    // Get applications activity
    case GET_APPLICATIONS_ACTIVITY:
      return {
        ...state,
        loading: true,
      };
    case GET_APPLICATIONS_ACTIVITY_SUCCESS:
      return {
        ...state,
        loading: false,
        appsActivity: action.payload,
      };
    case GET_APPLICATIONS_ACTIVITY_ERROR:
      return { ...state, error: action.payload, loading: false };
    case GET_APPLICATIONS_EVENTS:
      return {
        ...state,
        loading: true,
      };
    case GET_APPLICATIONS_EVENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        appsEvents: action.payload,
      };
    case GET_APPLICATIONS_EVENTS_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default fetchApplications;
