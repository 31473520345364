import React, { useEffect, useState } from 'react';
import apiClient from "../../../gears/apiClient";
import { useHistory } from 'react-router-dom';
import OverviewSectionTitle from "../OverviewSectionTitle/OverviewSectionTitle";
import IssuesResolvedStats from "../IssuesResolvedStats/IssuesResolvedStats";
import MetricCard from "../MetricCard/MetricCard";
import { useSelector } from "react-redux";
import { getMetricCardArray } from "./ai-self-service-metrics.helper";
import { toastr } from "react-redux-toastr";
import ComponentLoader from "../../ComponentLoader";
import styles from './ai-self-service-metrics.module.scss';

const AiSelfServiceMertics = () => {
	const [metricsData, setMetricsData] = useState({})
	const [isLoading, setIsLoading] = useState(false);
	const history = useHistory();
	const { active_user_result: { active_user: activeUser } } = useSelector((state) => state.DashboardAnalytics);
	
	const fetchMetrics = async () => {
		setIsLoading(true);
		try {
			const response = await apiClient.get('/self-service-metrics');
			if (response) {
				setMetricsData(response);
			}
		} catch (e) {
			toastr.error('Error', 'Cannot fetch self service metrics');
		} finally {
			setIsLoading(false);
		}
	}
	useEffect(() => {
		fetchMetrics();
	}, []);
	
	const metricCardArray = getMetricCardArray(metricsData, activeUser, history);
	
	return (
		<div className={styles.aiServiceMetrics}>
			<OverviewSectionTitle title="AI Self-Service Metrics" />
			<div className={styles.content}>
				{isLoading && <ComponentLoader customClass={styles.spinnerWrapper} />}
				{!isLoading && metricsData && (
					<>
						<div className={styles.chart}>
							<IssuesResolvedStats data={metricsData} />
						</div>
						<div className={styles.cards}>
							{metricCardArray.map((card) =>
								<MetricCard
									key={card.title}
									iconComponent={card.iconComponent}
									title={card.title}
									value={card.value}
									onClick={card?.onClick}
								/>)
							}
						</div>
					</>
				)}
			</div>
		</div>
	);
}

export default AiSelfServiceMertics;
