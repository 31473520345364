import React, {useState, useRef} from 'react';
import * as PropTypes from 'prop-types';
import {isEmpty} from 'lodash';
import SearchByColumn from './SearchByColumn';
import {mapDataToLegend} from '../../helpers/page_helper';
import Button from '../Button/Button';

const IssuesTableFilter = ({
  onFilterChange,
  currentFilter,
  tableColumns,
}) => {
  const [filter, setFilter] = useState({});
  const [showResetBtn, setShowResetBtn] = useState(false);
  const searchFilterRef = useRef();

  const onSearchChange = (value) => {
    const {searchBy} = value;
    if (searchBy) {
      if (searchBy === 'all') {
        setFilter({});
      } else {
        setFilter({...filter, ...value});
      }
    } else {
      const {searchBy, ...rest} = filter;
      setFilter({...rest});
    }
  };

  const handleSubmit = () => {
    const legend = {
      searchBy: 'status',
    };
    setShowResetBtn(!isEmpty(filter));
    if (Object.keys(filter).length === 0) {
      return onFilterChange(mapDataToLegend({}));
    }
    return onFilterChange(mapDataToLegend(legend, filter));
  };

  const resetFilter = () => {
    if (searchFilterRef) {
      searchFilterRef.current.reset();
      setFilter({});
      onFilterChange({status: 'all'});
      setShowResetBtn(false);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };

  return (
    <div className="d-flex align-items-center mb-3 mb-md-0 activities-filter">
      <SearchByColumn
        columnOptions={tableColumns}
        onChange={onSearchChange}
        ref={searchFilterRef}
        onKeyUp={handleKeyPress}
        defaultSelect={currentFilter?.status || 'all'}
        onlySelect
      />
      <div className="ml-3">
        <Button
          type="button"
          onClick={handleSubmit}
          generalType="default"
        >
          Filter
        </Button>
        {
          showResetBtn && (
            <Button
              className="ml-2"
              type="button"
              onClick={resetFilter}
              generalType="default-outlined"
            >
              <i className="mdi mdi-refresh icon" />
              Clear
            </Button>
          )
        }
      </div>
    </div>
  );
};

IssuesTableFilter.propTypes = {
  onFilterChange: PropTypes.func.isRequired,
  userId: PropTypes.number,
};

export default IssuesTableFilter;
