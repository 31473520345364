import React from 'react';
import ReactEcharts from 'echarts-for-react';
import 'echarts/lib/component/tooltip';
import PropTypes from 'prop-types';
import moment from 'moment';

const Last30DaysTestChart = ({ dataSet, title }) => {
  const option = {
    xAxis: {
      type: 'category',
      data: dataSet.map(({date}) => moment(date).format('MM/DD')),
    },
    yAxis: {
      type: 'value',
    },
    grid: {
      top: '10',
      bottom: '20',
      left: '50',
      right: '10',
    },
    series: [
      {
        type: 'line',
        data: dataSet.map(({activities_number}) => activities_number),
      },
    ],
    tooltip: {
      show: true,
      trigger: 'item',
    },
  };

  return (
    <div>
      <h4 className="card-title font-weight-normal">{title}</h4>
      <div className="mt-4">
        <ReactEcharts
          option={option}
          opts={{renderer: 'svg'}}
        />
      </div>
    </div>
  );
};

Last30DaysTestChart.defaultProps = {
  dataSet: [],
};

Last30DaysTestChart.propTypes = {
  dataSet: PropTypes.arrayOf(PropTypes.shape({
    activities_number: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    date: PropTypes.string,
  })),
};

export default Last30DaysTestChart;
