import { CREATE_ANSWERS_REQUEST, CREATE_ANSWERS_SUCCESS, CREATE_ANSWERS_ERROR } from './actionTypes';

export const createAnswers = (data) => {
  return {
    type: CREATE_ANSWERS_REQUEST,
    payload: { data },
  };
};

export const createAnswersSuccess = (payload) => {
  return {
    type: CREATE_ANSWERS_SUCCESS,
    payload,
  };
};

export const createAnswersSuccessError = (error) => {
  return {
    type: CREATE_ANSWERS_ERROR,
    payload: error,
  };
};
