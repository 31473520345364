import React from 'react';
import { Line } from 'react-chartjs-2';
import { useSelector } from 'react-redux';
import { Spinner } from 'reactstrap';

const UsersSpeedChart = () => {
  const {
    usersSpeed,
    usersSpeedIsLoading,
  } = useSelector((state) => state.RecentActivities);

  const options = {
    responsive: true,
    scales: {
      xAxes: [{
        scaleLabel: {
          display: true,
          labelString: 'Average Speed',
        },
      }],
      yAxes: [{
        scaleLabel: {
          display: true,
          labelString: 'Number of Users',
        },
      }],
    },
  };
  const labels = usersSpeed?.download?.map((item) => item.max_speed);
  const data = {
    labels,
    datasets: [
      {
        label: 'Download',
        fill: true,
        data: usersSpeed?.download?.map((item) => item.user_number),
        borderColor: 'rgb(79, 70, 229)',
        backgroundColor: 'rgba(79, 70, 229, 0.5)',
      },
      {
        label: 'Upload',
        fill: true,
        data: usersSpeed?.upload?.map((item) => item.user_number),
        borderColor: 'rgb(5, 190, 56)',
        backgroundColor: 'rgba(5, 190, 56, .5)',
      },
    ],
  };
  return (
    <div>
      {usersSpeedIsLoading && <div className="spinner-wrapper text-center"><Spinner color="primary" /></div>}
      {!usersSpeedIsLoading && <Line options={options} data={data} height={80} />}
    </div>
  );
};

export default UsersSpeedChart;
