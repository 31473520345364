import React from 'react';

const AthertonfiberHeader = () => {
  const [isOpen, setIsOpen] = React.useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="header center athertonfiber-header ">
      <div className="container">
        <nav className="navbar navbar-expand-xl navbar-light">
          <button aria-label="Toggle navigation" type="button" className="navbar-toggler" onClick={toggleMenu}>
            <span className="navbar-toggler-icon" />
          </button>
          <a className="navbar-brand-logo" href="https://www.athertonfiber.com/"><img
            src="https://www.athertonfiber.com/wp-content/uploads/2020/09/AthertonFiber-formlogo.png"
            width="172"
            height="40"
            alt=""
          />
          </a>
          <div className={`collapse navbar-collapse ${isOpen ? 'show' : ''}`}>
            <ul className="ml-auto navbar-nav">
              <li className="nav-item">
                <a
                  href="https://www.athertonfiber.com/fiber-for-home/"
                  className="nav-link"
                >Residential
                </a>
              </li>
              <li className="nav-item">
                <a
                  href="https://www.athertonfiber.com/fiber-for-business/"
                  className="nav-link"
                >Business
                </a>
              </li>
              <li className="nav-item">
                <a href="https://www.athertonfiber.com/getting-fiber/" className="nav-link">Getting
                  Fiber
                </a>
              </li>
              <li className="nav-item">
                <a href="https://www.athertonfiber.com/about-broadband/" className="nav-link">About
                  Broadband
                </a>
              </li>
              <li className="nav-item">
                <a href="https://www.athertonfiber.com/about-us/" className="nav-link">About Us</a>
              </li>
              <li className="nav-item">
                <a href="https://www.athertonfiber.com/support/" className="nav-link">Support</a>
              </li>
              <li className="nav-item">
                <a
                  href="https://www.athertonfiber.com/signup/"
                  className="styled-button nav-link"
                >Sign Up
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default AthertonfiberHeader;
