import { takeEvery, fork, put, all } from 'redux-saga/effects';

import { FETCH_USER_STATUS, FETCH_SCATTER_CHART, FETCH_USER_DEVICE, FETCH_USER_STATUS_ACTIVITY, FETCH_USER_DATA, REQUEST_REMOTE_TEST } from './actionTypes';
import {
  fetchUserStatusSuccess, fetchUserStatusApiError,
  fetchScatterChartSuccess, fetchScatterChartApiError,
  fetchUserDeviceSuccess, fetchUserDeviceError,
  fetchUserStatusActivitySuccess, fetchUserStatusActivityError, fetchUserDataSuccess, fetchUserDataError, requestRemoteTestSuccess, requestRemoteTestError,
} from './actions';
import apiClient from '../../../gears/apiClient';

function* fetchUserStatus({ payload: { id, data } }) {
  try {
    const response = yield apiClient.get(`/activities/${id}`, data);
    yield put(fetchUserStatusSuccess(response));
  } catch (error) {
    yield put(fetchUserStatusApiError(error));
  }
}

function* fetchUserStatusActivity({payload: { id }}) {
  try {
    const response = yield apiClient.get(`/user_recent_activities/${id}`);
    yield put(fetchUserStatusActivitySuccess(response));
  } catch (error) {
    yield put(fetchUserStatusActivityError(error));
  }
}

function* fetchScatterChart({ payload: { id, params } }) {
  try {
    const response = yield apiClient.get(`/activity_flow/${id}`, params);
    yield put(fetchScatterChartSuccess(response));
  } catch (error) {
    yield put(fetchScatterChartApiError(error));
  }
}

function* fetchUserDevice({ payload: { id } }) {
  try {
    const response = yield apiClient.get(`/get-last-device-activity/${id}`);
    yield put(fetchUserDeviceSuccess(response));
  } catch (error) {
    yield put(fetchUserDeviceError(error));
  }
}

function* fetchUserData({ payload: { id } }) {
  try {
    const response = yield apiClient.get(`/display-user-data/${id}`);
    yield put(fetchUserDataSuccess(response));
  } catch (error) {
    yield put(fetchUserDataError(error));
  }
}

function* requestRemoteTest({ payload }) {
  try {
    const response = yield apiClient.post(`/trigger-remote-test`, {
      guid: payload
    });
    yield put(requestRemoteTestSuccess(response));
  } catch (error) {
    yield put(requestRemoteTestError(error));
  }
}

export function* watchUserStatusFetch() {
  yield takeEvery(FETCH_USER_STATUS, fetchUserStatus);
}

export function* watchUserStatusActivity() {
  yield takeEvery(FETCH_USER_STATUS_ACTIVITY, fetchUserStatusActivity);
}

export function* watchScatterChartFetch() {
  yield takeEvery(FETCH_SCATTER_CHART, fetchScatterChart);
}

export function* watchUserDeviceFetch() {
  yield takeEvery(FETCH_USER_DEVICE, fetchUserDevice);
}

export function* watchUserDataFetch() {
  yield takeEvery(FETCH_USER_DATA, fetchUserData);
}

export function* watchRequestRemoteTest() {
  yield takeEvery(REQUEST_REMOTE_TEST, requestRemoteTest);
}

function* userStatusSaga() {
  yield all([
    fork(watchUserStatusFetch),
    fork(watchUserStatusActivity),
    fork(watchScatterChartFetch),
    fork(watchUserDeviceFetch),
    fork(watchUserDataFetch),
    fork(watchRequestRemoteTest),
  ]);
}

export default userStatusSaga;
