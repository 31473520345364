import { useEffect, useState } from 'react';
import {useStripe, useElements, PaymentElement} from '@stripe/react-stripe-js';
import { Button, FormGroup, Input, Label } from 'reactstrap';
import { toastr } from 'react-redux-toastr';
import apiClient from '../../gears/apiClient';
import { onNavigationButtonsClick } from '../onboarding/onBoardingHelper';
import { useDispatch, useSelector } from 'react-redux';
import { updateBillingEmail, updateCompanySubscription } from '../../store/actions';
import styles from './stripe.module.scss';

const StripeCheckoutForm = ({changeView}) => {
  const { data: profile } = useSelector(state => state.Profile);
  const [isProcessing, setIsProcessing] = useState(false);
  const [billingEmail, setBillingEmail] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(null);
  const [billingEmailError, setBillingEmailError] = useState(false);
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    let result;
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsProcessing(true);

    if(billingEmail && isEmailValid) {
      dispatch(updateBillingEmail({billing_email: billingEmail}))
    }

    elements.submit();

    result = await stripe.confirmSetup(
      {
        clientSecret: elements?._commonOptions.clientSecret.clientSecret,
        elements,
        redirect: 'if_required'
      }
    );

    if(result?.setupIntent) {
      try {
        await apiClient.post('/join-small-team-plan', {
          setupIntent: result.setupIntent?.id,
          ...(billingEmail && {billingEmail}),
        });
        toastr.success('Payment card saved');
        if(changeView) {
          changeView(false, true)
        } else {
          onNavigationButtonsClick('next', dispatch);
        }
        dispatch(updateCompanySubscription())
      } catch(e) {
        toastr.error( e?.error)
      }
    }

    if (result?.error) {
      toastr.error(result.error?.message);
    } else if(result?.paymentIntent && result?.paymentIntent.status === 'succeeded') {
      toastr.success(`Payment status: ${result.paymentIntent.status}`);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }

    setIsProcessing(false);
  };

  const renderButtons = () => {
    return changeView
      ? <>
          <Button
            disabled={isProcessing}
            className="button button-default"
          >
            {isProcessing ? 'Processing...' : 'Submit'}
          </Button>
          <Button
            onClick={() => changeView(false)}
            className="button button-elevated"
          >
            Cancel
          </Button>
      </>
      : <Button
          disabled={isProcessing}
          className="button button-default w-100"
        >
          Pay
        </Button>
  }

  const validateEmail = (email) => {
    return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  }

  const onBillingEmailChange = (email) => {
    setBillingEmail(email.toLowerCase());
    if(validateEmail(email)) {
      setIsEmailValid(true);
    } else {
      setIsEmailValid(false);
    }
  }

  useEffect(() => {
    if(billingEmail.length === 0 && billingEmailError) {
      setBillingEmailError(false)
    }
    if(billingEmail.length > 0 && isEmailValid) {
      setIsEmailValid(true)
      setBillingEmailError(false)
    }
    if(billingEmail.length > 0 && !isEmailValid) {
      setBillingEmailError(true)
    }
    
  }, [billingEmail])

  useEffect(() => {
    if(profile?.billing_email) {
      setBillingEmail(profile?.billing_email)
      setBillingEmailError(false)
      setIsEmailValid(true)
    }
  }, [profile?.billing_email])

  return (
    <form onSubmit={handleSubmit} className={styles.stripeForm}>
      <div className={styles.stripeFormElements}>
        <FormGroup>
          <Label for="onboarding-billing-email">Email</Label>
          <Input
            id="onboarding-billing-email"
            className={`${billingEmailError ? 'is-invalid' : ''}`}
            name="billing-email"
            value={billingEmail}
            onChange={(e) => onBillingEmailChange(e.target.value)}
          />
        </FormGroup>
        <PaymentElement />
      </div>
      <div className={styles.stripeFormActions}>
        {renderButtons()}
      </div>
    </form>
  )
};

export default StripeCheckoutForm;