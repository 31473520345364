import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import AthertonfiberFooter from './athertonfiber-support/Footer';
import NfoFooter from './nfo-support/Footer';

const ComapnyFooter = ({companyName}) => {
  if (companyName === 'nfo-support') {
    return (<NfoFooter />);
  } if (companyName === 'athertonfiber-support') {
    return (<AthertonfiberFooter />);
  }
  return '';
};

ComapnyFooter.defaultProps = {
  companyName: null,
};

ComapnyFooter.propTypes = {
  companyName: PropTypes.string,
};

const mapStateToProps = (state) => {
  const {companyName} = state.Company;

  return {companyName};
};

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ComapnyFooter);
