import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { toastr } from 'react-redux-toastr';
import { useSelector } from 'react-redux';
import TestDetailsDrawer from '../drawers/TestDetailsDrawer';
import Popup from '../popup/Popup';
import MapBox from './MapBox';
import ExportPopup from '../exportPopup/ExportPopup';
import { createPolygon } from './mapBoxHelper';
import { filterMapFilters } from '../mapViewFilterModal/mapViewFilterHelper';
import apiClient from '../../gears/apiClient';

const MapBoxContainer = ({filters, isUsersTestsData}) => {
  const {
    data: activitiesData,
  } = useSelector((state) => state.RecentActivities);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [testId, setTestId] = useState(null);
  const [isExportPopupOpen, setIsExportPopupOpen] = useState(false);
  const [testIds, setTestIds] = useState([]);
  const [usersGuids, setUsersGuids] = useState([]);
  const [mapMarkers, setMapMarkers] = useState([]);
  const [polygonCoordinates, setPolygonCoordinates] = useState([]);
  const [isPolygonTemplate, setIsPolygonTemplate] = useState(false);
  const [averagesDataisLoading, setAveragesDataIsLoading] = useState(false);
  const [averagesData, setAveragesData] = useState({});
  const isTestIds = testIds.length > 0;

  useEffect(() => {
    setMapMarkers(activitiesData?.features);
}, [activitiesData]);

  const onMapMarkerClick = (id) => {
    setTestId(id);
    setIsDrawerOpen(true);
  };

  const onDrawerClose = () => {
    setTestId(null);
    setIsDrawerOpen(false);
  };

  const exportPopupCloseHandler = () => {
    setIsExportPopupOpen(false);
    if (testIds.length > 0) {
      setTestIds([]);
      setUsersGuids([]);
      setPolygonCoordinates([]);
      setIsPolygonTemplate(false);
    }
  };

  const calculateAverages = async (testIds) => {
    setAveragesDataIsLoading(true);
    const body = {
      test_ids: [...testIds],
    };
    try {
      const response = await apiClient.post('/calculate_activities_averages', body);
      setAveragesData(response);
    } catch (e) {
      toastr.error('System', e.error);
    }
    setAveragesDataIsLoading(false);
  };

  const openExportPopupWithData = ({testIds, coordinates, noSave = null, guids}) => {
    calculateAverages(testIds);
    setTestIds(testIds);
    setUsersGuids(guids);
    setPolygonCoordinates(coordinates);
    setIsExportPopupOpen(true);
    if (noSave) setIsPolygonTemplate(true);
  };

  const exportData = async (email) => {
    let params = {email, ...filterMapFilters(filters)};
    if (isTestIds) {
      params = {email, test_ids: [...testIds]};
    }

    const request = () => {
      if (isTestIds) {
        return apiClient.post('/export_recent_activities', params);
      }
      if (isUsersTestsData) {
        return apiClient.get('/export_user_map_points', params);
      }
      return apiClient.get('/export_recent_activities', params);
    };
    try {
      request();
    } catch (e) {
      toastr.error('System', e.message);
    }
  };

  const createNewPolygonTemplate = (polygonName) => {
    const {coordinates} = polygonCoordinates.geometry;
    const copyCoordinates = [...coordinates[0]];
    const modifyCoordinates = copyCoordinates.map((coordinate) => ({
      longitude: coordinate[0],
      latitude: coordinate[1],
    }));
    createPolygon(polygonName, modifyCoordinates);
  };

  return (
    <div>
      <MapBox
        markers={mapMarkers}
        isMapView
        openExportModal={openExportPopupWithData}
        onMapMarkerClick={onMapMarkerClick}
      />
      <TestDetailsDrawer
        isOpen={isDrawerOpen}
        onHandlerClose={onDrawerClose}
        testId={testId}
      />
      {isExportPopupOpen && (
        <Popup
          customClass="export scroll"
          close={exportPopupCloseHandler}
          isSummary={true}
        >
          <ExportPopup
            onSubmit={exportData}
            close={exportPopupCloseHandler}
            isLoading={averagesDataisLoading}
            data={averagesData}
            isMapView
            onSavePolygon={createNewPolygonTemplate}
            noSave={isPolygonTemplate}
            isSummary
            testIds={testIds}
            guids={usersGuids}
          />
        </Popup>
      )}
    </div>
  );
};

MapBoxContainer.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  filters: PropTypes.object,
  isUsersTestsData: PropTypes.bool,
};

export default MapBoxContainer;
