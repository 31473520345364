import React, { useEffect } from 'react';
import { useTable, useSortBy } from 'react-table';
import Loader from './Loader';

function ReactTable({
  columns,
  data = [],
  onSortChange,
  loading,
  isSticky = false,
  onRowClick,
  ...rest
}) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setHiddenColumns,
    state: { sortBy },
  } = useTable(
    {
      columns,
      data,
      ...rest,
    },
    useSortBy,
  );
  
  useEffect(() => {
    if (sortBy) {
      onSortChange({ sortBy });
    }
  }, [sortBy, onSortChange]);
  
  useEffect(() => {
    setHiddenColumns(
      columns.filter(({ isVisible }) => isVisible === false).map((column) => column.accessor),
    );
  }, [setHiddenColumns, columns]);
  
  return (
    <table className={`table table-hover table-centered table-nowrap mb-0 ${rest?.className}`} {...getTableProps()}>
      <thead className={isSticky ? 'sticky-table' : ''}>
      {headerGroups.map((headerGroup) => (
        <tr {...headerGroup.getHeaderGroupProps()}>
          {headerGroup.headers.map((column) => (
            // Add the sorting props to control sorting. For this example
            // we can add them into the header props
            <th {...column.getHeaderProps(column.getSortByToggleProps())}>
              {column.render('Header')}
              {/* Add a sort direction indicator */}
              <span className="icon">
                  {column.isSorted && column.isSortedDesc && (<i className="mdi mdi-triangle mdi-rotate-180 down" />)}
                {column.isSorted && !column.isSortedDesc && (<i className="mdi mdi-triangle up" />)}
                </span>
            </th>
          ))}
        </tr>
      ))}
      </thead>
      <tbody {...getTableBodyProps()}>
      {loading && (
        <tr>
          <td colSpan="10000" style={{ position: 'relative', paddingTop: '80px' }}>
            <Loader />
          </td>
        </tr>
      )}
      
      {!loading && rows.length <= 0 && (
        <tr>
          <td colSpan="10000" className="p-4 text-center">No results found</td>
        </tr>
      )}
      
      {rows.map((row) => {
        prepareRow(row);
        return (
          <tr
            {...row.getRowProps({
              onClick: onRowClick ? () => onRowClick(row.original) : undefined,
              style: { cursor: onRowClick ? 'pointer' : 'default' },
            })}
          >
            {row.cells.map((cell) => {
              return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
            })}
          </tr>
        );
      })}
      </tbody>
    </table>
  );
}

export default ReactTable;
