import { FETCH_TEST_RESULTS, FETCH_TEST_RESULTS_SUCCESS, FETCH_TEST_RESULTS_API_ERROR } from './actionTypes';

export const fetchTestResults = (history, data) => {
  return {
    type: FETCH_TEST_RESULTS,
    payload: { data, history },
  };
};

export const fetchTestResultsSuccess = (payload) => {
  return {
    type: FETCH_TEST_RESULTS_SUCCESS,
    payload,
  };
};

export const fetchTestResultsApiError = (error) => {
  return {
    type: FETCH_TEST_RESULTS_API_ERROR,
    payload: error,
  };
};
