import React, { useEffect, useState } from 'react';
import OverviewSectionTitle from "../OverviewSectionTitle/OverviewSectionTitle";
import IndicatorCard from "../IndicatorCard/IndicatorCard";
import apiClient from "../../../gears/apiClient";
import { getAppPerformanceArray } from "./app-performance.helper";
import { toastr } from "react-redux-toastr";
import ComponentLoader from "../../ComponentLoader";
import styles from './app-performance-indicators.module.scss';

const AppPerformanceIndicators = () => {
	const [appPerformanceData, setAppPerformanceData] = useState({})
	const [isLoading, setIsLoading] = useState(false);
	const fetchAppPerformance = async () => {
		setIsLoading(true);
		try {
			const response = await apiClient.get('/application-metrics');
			if (response) {
				setAppPerformanceData(response);
			}
		} catch (e) {
			toastr.error('Error', 'Cannot fetch application performance indicators');
		} finally {
			setIsLoading(false);
		}
	}
	useEffect(() => {
		fetchAppPerformance();
	}, []);

	const appPerformanceDataArray = getAppPerformanceArray(appPerformanceData);
	return (
		<>
			<OverviewSectionTitle title='Application Performance Indicators' />
			<div className={styles.content}>
				{isLoading && <ComponentLoader customClass={styles.spinnerWrapper} />}
				{!isLoading && appPerformanceDataArray?.map((card) => <IndicatorCard key={card.title} title={card.title} percent={card.percent} value={card.value} />)}
			</div>
		</>
	);
};

export default AppPerformanceIndicators;
