import React from 'react';
import { Col, Row } from 'reactstrap';
import DashboardStatsCard from './cards/StatsCard';

const BluetoothStatus = ({title, value, isLoading}) => {
  const renderStatus = (value) => {
    const statusByValue = value ? 'Enabled' : 'Disabled';
    return <span className="text-custom">{statusByValue}</span>;
  };

  return (
    <Row>
      <Col xs="12" xl="3" className="mb-4">
        <DashboardStatsCard
          title={(
            <span>
              {title}
            </span>
          )}
          text={renderStatus(value)}
          loading={isLoading}
        />
      </Col>
    </Row>
  );
};

export default BluetoothStatus;
