import {
  ADD_BLUETOOTH_DEVICE, ADD_BLUETOOTH_DEVICE_ERROR, ADD_BLUETOOTH_DEVICE_SUCCESS,
  DELETE_BLUETOOTH_DEVICE, DELETE_BLUETOOTH_DEVICE_ERROR, DELETE_BLUETOOTH_DEVICE_SUCCESS,
  GET_BLUETOOTH_DEVICES, GET_BLUETOOTH_DEVICES_ERROR, GET_BLUETOOTH_DEVICES_SUCCESS,
  UPDATE_BLUETOOTH_DEVICE, UPDATE_BLUETOOTH_DEVICE_ERROR, UPDATE_BLUETOOTH_DEVICE_SUCCESS,
} from './actionTypes';

export const getBluetoothDevices = (payload) => {
  return {
    type: GET_BLUETOOTH_DEVICES,
    payload,
  };
};

export const getBluetoothDevicesSuccess = (payload) => {
  return {
    type: GET_BLUETOOTH_DEVICES_SUCCESS,
    payload,
  };
};

export const getBluetoothDevicesError = (error) => {
  return {
    type: GET_BLUETOOTH_DEVICES_ERROR,
    error,
  };
};

export const addBluetoothDevice = (payload) => {
  return {
    type: ADD_BLUETOOTH_DEVICE,
    payload,
  };
};

export const addBluetoothDeviceSuccess = (payload) => {
  return {
    type: ADD_BLUETOOTH_DEVICE_SUCCESS,
    payload,
  };
};

export const addBluetoothDeviceError = (error) => {
  return {
    type: ADD_BLUETOOTH_DEVICE_ERROR,
    error,
  };
};

export const deleteBluetoothDevice = (payload) => {
  return {
    type: DELETE_BLUETOOTH_DEVICE,
    payload,
  };
};

export const deleteBluetoothDeviceSuccess = (id) => {
  return {
    type: DELETE_BLUETOOTH_DEVICE_SUCCESS,
    id,
  };
};

export const deleteBluetoothDeviceError = (error) => {
  return {
    type: DELETE_BLUETOOTH_DEVICE_ERROR,
    error,
  };
};

export const updateBluetoothDevice = (payload) => {
  return {
    type: UPDATE_BLUETOOTH_DEVICE,
    payload,
  };
};

export const updateBluetoothDeviceSuccess = (payload) => {
  return {
    type: UPDATE_BLUETOOTH_DEVICE_SUCCESS,
    payload,
  };
};

export const updateBluetoothDeviceError = (error) => {
  return {
    type: UPDATE_BLUETOOTH_DEVICE_ERROR,
    error,
  };
};
