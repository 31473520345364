import React from 'react';
import styles from './overview-section-title.module.scss';

const OverviewSectionTitle = ({title}) => {
	return (
		<h3 className={styles.overviewSectionTitle}>
			{title}
		</h3>
	);
};

export default OverviewSectionTitle;
