import React from 'react';
import { Button } from 'reactstrap';
import { useSelector } from 'react-redux';
import { renderPlanDescription } from './SubscriptionsHelpers';
import styles from './subscription.module.scss';

const SubscriptionPlan = ({
  planData,
  onPlanChange,
  isStripeVisible = false,
  isDowngrade,
}) => {
	const isNumber = (value) => typeof value === 'number';
  const { isCustomPlan } = useSelector(state => state.Company);
	const isEnterprise = !isNumber(planData.price) && !isNumber(planData.max_events);
	const isActive = planData.is_current;
  const isFreePlan = planData.price === 0
  const isButtonVisible = !isEnterprise && !isStripeVisible && !isDowngrade && !isFreePlan && !isCustomPlan

	return (
    <div className={styles.subscriptionValues}>
      <div className={`${styles.card} ${isActive ? styles.cardActive : ''}`}>
        <h4 className={styles.cardTitle}>{planData.name}</h4>
        <p className={styles.cardText}>{planData.description}</p>
        <p className={styles.cardPrice}>
          {(!isFreePlan && !isEnterprise)
            ? <><span className={styles.textBold}>${planData.price}</span>/mo</>
            : ''
          }
        </p>
        <div className={styles.cardBottomPart}>
        <div className={styles.cardButtonWrapper}>
          {isButtonVisible && (
            <Button
              className={styles.cardButton}
              disabled={isActive || !planData.price}
              onClick={() => onPlanChange(planData.id)}
            >
              {isActive ? 'Current Plan' : 'Upgrade'}
            </Button>
          )}
          {isActive && isFreePlan && (
            <div className={`btn btn-secondary ${styles.cardButton} disabled`}>Current Plan</div>
          )}
          {(isEnterprise || isFreePlan) && (
            <a
              className={`btn btn-secondary ${styles.cardButton}`}
              href="https://www.hubbleiq.com/contact"
              target="_blank"
              rel="noreferrer"
            >
              Contact Us
            </a>
          )}
        </div>
        <p className={styles.cardTextBold}>
          {isNumber(planData.max_events) && planData.id !== 12
           ? <>{planData.max_events} Events/Month</>
           : ''
          }
        </p>
        <p className={`${styles.cardText} ${styles.cardTextBottom}`}>
          {renderPlanDescription(planData.id)}
        </p>
        </div>
      </div>
    </div>
	)
};

export default SubscriptionPlan;
