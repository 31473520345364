import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { List } from 'react-content-loader'
import { toastr } from 'react-redux-toastr'
import apiClient from '../../gears/apiClient'
import './AiResponse.scss';
import { useSelector } from 'react-redux'

const AiResponse = ({title = null, type, data}) => {
  const { ai_features_enabled: isAiFeaturesEnabled } = useSelector((state) => state.Company.companyDetails)
  const [aiResponse, setAiResponse] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const getAIData = async () => {
    setIsLoading(true)
    const payload = {
      type,
      data,
    };
    try {
      const response = await apiClient.post('/generate_ai_response', payload);
      setAiResponse(response.ai_response);
    } catch (e) {
      toastr.error('System', e.error);
    }
    setIsLoading(false)
  };

  useEffect(() => {
    if (isAiFeaturesEnabled) {
      getAIData()
    }
  }, [])
  
  return (
    <div className="ai-response">
      {title && <h3 className="ai-response-title">{`AI-Generated ${title}`}</h3>}
      {isLoading && <List height={110} />}
      {!isLoading && (
        <div className="ai-response-data" dangerouslySetInnerHTML={{ __html: aiResponse }} />
      )}
    </div>
  )
}

AiResponse.propTypes = {
  title: PropTypes.string,
  type: PropTypes.string,
  data: PropTypes.object,
}

export default AiResponse
