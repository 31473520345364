import React from 'react';
import PropTypes from 'prop-types';
import { Col, FormGroup, Label, Row, Input } from 'reactstrap';

const MapViewFilterRange = ({filters, setFilters, data}) => {
  const updateState = (name, value) => {
    setFilters((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const filterInputGroup = ({
    title,
    name,
    placeholder,
  }) => {
    return (
      <Col key={name}>
        <FormGroup>
          <Label for={name}>{title}</Label>
          <Input
            id={name}
            type="number"
            name={name}
            min="0"
            inputMode="numeric"
            pattern="[0-9]*"
            value={filters?.[name]}
            onChange={(e) => {
              updateState(e.target.name, e.target.value);
            }}
            placeholder={placeholder}
          />
        </FormGroup>
      </Col>
    );
  };

  return (
    <Row>
      {data.map((item) => filterInputGroup(item))}
    </Row>
  );
};

MapViewFilterRange.propTypes = {
  filters: PropTypes.shape({
    latency_upper_range: PropTypes.string,
    latency_lower_range: PropTypes.string,
  }),
  setFilters: PropTypes.func,
};

export default MapViewFilterRange;
