import React, {Component} from 'react';
import {Alert, Card, Col, Row} from 'reactstrap';

// Redux
import {connect} from 'react-redux';
import {Link, withRouter} from 'react-router-dom';

// availity-reactstrap-validation
import {AvField, AvForm} from 'availity-reactstrap-validation';

// action
import {userForgetPassword} from '../../store/actions';
// import AhoyTrack from '../../modules/AhoyTrack';

class ForgetPasswordPage extends Component {
  constructor(props) {
    // AhoyTrack();
    super(props);
    this.state = {};

    // handleValidSubmit
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
  }

  // handleValidSubmit
  handleValidSubmit(event, values) {
    const {userForgetPassword} = this.props;
    userForgetPassword(values);
  }

  render() {
    const {companyLogo, forgetError, forgetSuccessMsg} = this.props;
    return (
      <>
        <div className="home-btn d-none d-sm-block">
          <Link to="/" className="text-dark">
            <i className="fas fa-home h2" />
          </Link>
        </div>
        <div className="account-pages forgot-password">
          <div className="blue-header" />
          <div className="form-container">
            <Card className="login-form">
              <div className="logo-container">
                <div className="logo-img">
                  <img src={companyLogo} alt="logo" />
                </div>
              </div>
              <div className="login-form-content">
                <div className="auth-header">
                  <span className="login-header">Reset Your Password</span>
                  <span className="login-subheader">Enter the email you use for your HubbleIQ account.</span>
                </div>

                {forgetError ? (
                  <Alert color="danger" style={{marginTop: '13px'}}>
                    {forgetError}
                  </Alert>
                ) : null}
                {forgetSuccessMsg ? (
                  <Alert color="success" style={{marginTop: '13px'}}>
                    {forgetSuccessMsg}
                  </Alert>
                ) : null}

                {
                  !forgetSuccessMsg && (
                  <AvForm className="form-horizontal mt-4" onValidSubmit={this.handleValidSubmit}>
                    <div className="form-group">
                      <AvField
                        name="email"
                        label="Email"
                        className="form-control"
                        placeholder="Enter your email"
                        type="email"
                        required
                      />
                    </div>
                    <Row className="form-group">
                      <Col lg={12} className="reset-bt-container">
                        <button className="btn auth-button" type="submit">
                          Reset Your Password
                        </button>
                      </Col>
                      <Col lg={12} className="forgot-password-container">
                        <div className="col-12 mt-4 forgot-password mb-3">
                          <Link to="/admin/login">
                            Go Back to Log in
                          </Link>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                  )
                }
                <div className="hubbleiq-copy">
                  <p className="mb-0">©{new Date().getFullYear()} HubbleIQ INC. All Right Reserved.</p>
                </div>
              </div>
            </Card>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const {companyLogo} = state.Company;
  const {forgetError, forgetSuccessMsg} = state.ForgetPassword;
  return {forgetError, forgetSuccessMsg, companyLogo};
};

export default withRouter(connect(mapStateToProps, {userForgetPassword})(ForgetPasswordPage));
