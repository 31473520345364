import React from 'react';
import PropTypes from 'prop-types';

const ServiceExperience = ({ experience }) => {
  const value = experience.status.available ? 100 : 25;
  const color = experience.status.available ? 'good' : 'bad';

  return (
    <div className="result d-flex flex-row align-items-center">
      <div className={`capStatus ${color}`}>&nbsp;</div>
      <span className="result-name">{experience.service}</span>
    </div>
  );
};

ServiceExperience.propTypes = {
  experience: PropTypes.object,
};

export default ServiceExperience;
