import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import ArrowUp from '../../../images/icon-arrow-up.svg';
import NetworkStability from '../../../images/icon-network-stability.svg';
import Latency from '../../../images/icon_gauge.svg';
import PacketLoss from '../../../images/icon_packet_loss.svg';
import apiClient from "../../../gears/apiClient";
import { toastr } from "react-redux-toastr";
import Loader from "../../Loader";

const InternetPerformance = ({
  displayTitle = true,
  download,
  upload,
  jitter,
  latency,
  packetLoss,
  statusDescription,
}) => {
  const initialData = {
    decent_download_threshold: null,
    decent_jitter_threshold: null,
    decent_latency_threshold: null,
    decent_upload_threshold: null,
    good_download_threshold: null,
    good_jitter_threshold: null,
    good_latency_threshold: null,
    good_upload_threshold: null,
  };
  const [colorsData, setColorsData] = useState(initialData);
  const [isLoading, setIsLoading] = useState(false);
  
  const fetchColors = async () => {
    setIsLoading(true);
    try {
      const response = await apiClient.get('/settings/network-thresholds');
      setColorsData(response);
    } catch (e) {
      toastr.error(e?.error);
    }
    setIsLoading(false);
  };
  
  useEffect(() => {
    fetchColors();
  }, []);
  
  const getHighlightedClass = (option, value) => {
    const {
      [`decent_${option}_threshold`]: decentThreshold,
      [`good_${option}_threshold`]: goodThreshold,
    } = colorsData;
    
    if (option === 'upload' || option === 'download') {
      if (value < decentThreshold) {
        return 'bad';
      }
      if (value >= decentThreshold && value < goodThreshold) {
        return 'medium';
      }
      return 'good';
    } else if (option === 'packetLoss') {
      if (value >= 0 && value <= 2.5) {
        return 'good';
      }
      if (value > 2.5 && value <= 5) {
        return 'medium';
      }
      if (value > 5) {
        return 'bad';
      }
    } else {
      if (value > decentThreshold) {
        return 'bad';
      }
      if (value <= decentThreshold && value > goodThreshold) {
        return 'medium';
      }
      return 'good';
    }
  }

  const itemsArr = [
    {
      title: 'Upload',
      icon: ArrowUp,
      value: <>{upload}<span>mbps</span></>,
    },
    {
      title: 'Download',
      icon: ArrowUp,
      value: <>{download}<span>mbps</span></>,
    },
    {
      title: 'Jitter',
      icon: NetworkStability,
      value: <>{jitter}<span>ms</span></>,
    },
    {
      title: 'Latency',
      icon: Latency,
      value: <>{latency}<span>ms</span></>,
    },
    {
      title: 'Packet Loss',
      icon: PacketLoss,
      value: <>{packetLoss}<span>%</span></>,
    },
  ];

  const renderItem = () => {
    const itemsToOptions = {
      'Upload': 'upload',
      'Download': 'download',
      'Jitter': 'jitter',
      'Latency': 'latency',
      'Packet Loss': 'packetLoss',
    };
    const values = {
      upload,
      download,
      jitter,
      latency,
      packetLoss: packetLoss,
    };
    return itemsArr.map((item) => {
      const option = itemsToOptions[item.title];
      const isDownload = option === 'download';
      const value = values[option];
      const status = getHighlightedClass(option, value);
      return (
        <Col xs="12" sm="6" md="6" lg="6" xl="6" key={item.title}>
          <div className="item">
            <div className={`item-icon${isDownload ? ' rotate-180' : ''}`}>
              <img src={item.icon} alt={`icon-${item.title}`}/>
            </div>
            <div className="item-info">
              <div className="item-title">{item.title}</div>
              <div className={`item-value ${status}`}>{item.value}</div>
            </div>
          </div>
        </Col>
      );
    });
  };

  return (
    <div className="internet-performance">
      {displayTitle && (<div className="internet-performance-label">Internet Performance</div>)}
      <Row>
        {isLoading ? <Loader /> : renderItem()}
      </Row>
    </div>
  );
};

InternetPerformance.propTypes = {
  download: PropTypes.number,
  upload: PropTypes.number,
  jitter: PropTypes.number,
  latency: PropTypes.number,
  packetLoss: PropTypes.number,
  statusDescription: PropTypes.string,
};

export default InternetPerformance;
