export const userDeviceTableColumns = {
	MONITORED_AT: {
		Header: 'Monitored At',
		accessor: 'monitored_at',
	},
	AVG_CPU: {
		Header: 'CPU Utilization value',
		accessor: 'avg_cpu_utilization',
	},
	AVG_MEMORY: {
		Header: 'Average Memory Utilization value',
		accessor: 'avg_memory_utilization',
	},
	AVG_BATTERY: {
		Header: 'Average Battery Health',
		accessor: 'avg_battery_health',
	},
	OS: {
		Header: 'Operation System',
		accessor: 'operating_system',
	},
}
