import { HELP_REQUEST, HELP_REQUEST_SUCCESS, HELP_REQUEST_API_ERROR } from './actionTypes';

export const helpRequest = (history, data) => {
  return {
    type: HELP_REQUEST,
    payload: { data, history },
  };
};

export const helpRequestSuccess = (payload) => {
  return {
    type: HELP_REQUEST_SUCCESS,
    payload,
  };
};

export const helpRequestApiError = (error) => {
  return {
    type: HELP_REQUEST_API_ERROR,
    payload: error,
  };
};
