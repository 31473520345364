import React, { useMemo, useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';
const ProfileMenu = ({ user, companyLogo }) => {
  const [menu, setMenu] = useState(false);
  const [userData, setUserData] = useState({});
  
  useMemo(() => {
    if(user) {
      setUserData(user);
    }
  }, [user]);
  const toggle = () => {
    setMenu((prevState) => !prevState);
  };
  
  const avatar = useMemo(() => {
    if (userData && userData.first_name && userData.last_name) {
      return `${userData.first_name.charAt(0).toUpperCase()}${userData.last_name.charAt(0).toUpperCase()}`;
    }
    return null;
  }, [userData]);
  
  const renderAvatar = avatar ?? <i className="mdi mdi-account-outline font-size-16 align-middle" />;
  
  const renderLogo = useMemo(() => {
    return companyLogo ? <img src={companyLogo} className="img-responsive" alt="logo" /> : 'Customer logo';
  }, [companyLogo]);
  
  return (
    <>
      <Dropdown isOpen={menu} toggle={toggle} className="d-inline-block header-profile">
        <DropdownToggle
          className="btn header-item waves-effect btn-popup btn-profile mr-0"
          id="page-header-user-dropdown"
          tag="button"
        >
          <span className={`logo${companyLogo ? ' active' : ''}`}>{renderLogo}</span>
          <span className="avatar">{renderAvatar}</span>
        </DropdownToggle>
        <DropdownMenu right>
          <Link to="/admin/profile" className="dropdown-item logout">
            <i className="mdi mdi-account font-size-17 align-middle mr-1" />
            <span>Profile settings</span>
          </Link>
          <Link to="/admin/logout" className="dropdown-item logout">
            <i className="mdi mdi-logout font-size-17 align-middle mr-1" />
            <span>Logout</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </>
  );
};

export default withRouter(ProfileMenu);
