import axios from 'axios';
import { toastr } from 'react-redux-toastr';

class BluetoothHelper {
  fetchBluetoothDevices = (token) => {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/bluetooth_devices', {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err.response.data.errors);
          toastr.error(err.response.data.error);
        });
    });
  };

  addBluetoothDevice = (token, data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          '/api/create_bluetooth_device',
          data,
          {headers: {Authorization: `Bearer ${token}`}},
        )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err.response.data.error);
          toastr.error(err.response.data.error);
        });
    });
  };

  deleteBluetoothDevice = (token, data) => {
    return new Promise((resolve, reject) => {
      axios
        .delete(
          '/api/delete_bluetooth_device',
          data,
          {headers: {Authorization: `Bearer ${token}`}},
        )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err.response.data.error);
          toastr.error(err.response.data.error);
        });
    });
  };

  updateBluetoothDevice = (token, data) => {
    return new Promise((resolve, reject) => {
      axios
        .put(
          '/api/update_bluetooth_device',
          data,
          {headers: {Authorization: `Bearer ${token}`}},
        )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err.response.data.error);
          toastr.error(err.response.data.error);
        });
    });
  };

  /**
   * Handle the error
   * @param {*} error
   */
  handleError = (error) => {
    // var errorCode = error.code;
    return error.message;
  };
}

export default BluetoothHelper;
