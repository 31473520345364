import React from 'react';

const iconVideo = <svg xmlns="http://www.w3.org/2000/svg" width="20" height="14" fill="none"><path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="m13 5 4.6-2.3a1 1 0 0 1 1.4 1v6.7a1 1 0 0 1-1.4.9L13 9V5ZM11 1H3a2 2 0 0 0-2 2v8c0 1.1.9 2 2 2h8a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2Z" /></svg>;
const iconMusic = <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none"><path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M4 17a3 3 0 1 0 0-6 3 3 0 0 0 0 6ZM14 17a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" /><path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M7 14V1h10v13M7 5h10" /></svg>;
const iconScreenSharing = <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" fill="none"><path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M19 9v3a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h9M5 17h10M7 13v4M13 13v4M15 1h4v4M14 6l5-5" /></svg>;
const iconFileSharing = <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" fill="none"><path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M10 1v4a1 1 0 0 0 1 1h4"/><path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M12 17h7m0 0-3-3m3 3-3 3m-8.5-1H3a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h7l5 5v5l-7.5 8Z" /></svg>;

export const renderCapabilities = (testData) => {
  const capabilities = [
    {
      label: 'HD Streaming Video',
      value: testData?.hd_streaming,
      icon: iconVideo,
    },
    {
      label: 'Streaming Video',
      value: testData?.streaming,
      icon: iconVideo,
    },
    {
      label: 'Internet Voice',
      value: testData?.internet_voice,
      icon: iconMusic,
    },
    {
      label: 'Screen Sharing',
      value: testData?.screen_sharing,
      icon: iconScreenSharing,
    },
    {
      label: 'File Sharing',
      value: testData?.file_sharing,
      icon: iconFileSharing,
    },
  ];

  return capabilities;
};
