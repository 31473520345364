import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';

const Trial = ({trialExpiration, trialClose, mainContact, toggleModal}) => {
  const oneDay = 24 * 60 * 60 * 1000;
  const expirationDay = new Date(trialExpiration);
  const currentDate = new Date();
  const diffDays = Math.round(Math.abs((expirationDay - currentDate) / oneDay));
  const renderDaysText = (value) => {
    if (value > 1) {
      return `${value} DAYS LEFT`;
    }
    if (value === 1) {
      return `${value} DAY LEFT`;
    }
    return 'YOUR FREE TRIAL PERIOD HAS EXPIRED';
  };

  return (
    <div className="trial">
      {expirationDay > currentDate
        ? <><span className="trial-days">{renderDaysText(diffDays)}</span> {(diffDays >= 1) && 'IN YOUR FREE TRIAL'} -</>
        : <><span className="trial-days">YOUR FREE TRIAL PERIOD HAS EXPIRED</span> -</>}
      {
        mainContact
        ? <Link to="/admin/profile" className="trial-link">SUBSCRIBE NOW!</Link>
        : <Button color="link" className="trial-link btn-link_custom" onClick={() => toggleModal('contact')}>SUBSCRIBE NOW!</Button>
      }
      <span className="mobile-hide">
        or
        <a className="trial-link" href="mailto:contact@hubbleiq.com">CONTACT US</a>
        {(diffDays < 1) && 'TO KEEP YOUR ACCESS TO THE AGENT PORTAL'}
      </span>
      <Button color="link btn-close" onClick={trialClose}>
        <i className="mdi mdi-close font-size-20" />
      </Button>
    </div>
  );
};

export default Trial;
