import React, { useState, useEffect } from 'react';
import {Col, Input, FormGroup, Label, Row} from 'reactstrap';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { toastr } from 'react-redux-toastr';
import Button from '../Button/Button';

const BrowserTestHelpForm = ({data, isLoading, updateBrowserSettings}) => {
  const extensionSchema = Yup.object().shape({
    articles: Yup.array().of(
      Yup.object().shape({
        header: Yup.string().when('hide', {
          is: false,
          then: Yup.string().required(),
        }),
        sub_header: Yup.string().when('hide', {
          is: false,
          then: Yup.string().required(),
        }),
        article_url: Yup.string().when('hide', {
          is: false,
          then: Yup.string().required(),
        }),
      }),
    ),
  });

  const [articlesArr, setArticlesArr] = useState([]);

  useEffect(() => {
    setArticlesArr(data?.articles.map((item) => {
      const isHide = (item.article_url && item.header && item.sub_header).length > 0;
      return {...item, hide: !isHide};
    }));
  }, [data]);

  const addToArticles = () => {
    if (articlesArr.length < 4) {
      setArticlesArr([
        ...articlesArr,
        {
          header: '',
          sub_header: '',
          order: articlesArr?.length + 1,
          image: {
            url: '',
          },
        },
      ]);
    }
    const copyArr = [...articlesArr];
    const firstHidden = copyArr.find((item) => item.hide === true);
    firstHidden.hide = false;
    setArticlesArr(copyArr);
  };

  const changeImage = (e, f, i) => {
    const file = e.target.files[0];
    const fileSize = (file.size / (1024 * 1024)).toFixed(2);
    const validMimeTypes = [
      'image/jpeg', 'image/png', 'image/gif',
    ];

    if (!validMimeTypes.includes(file.type)) {
      toastr.error('Supported files are JPG, PNG, GIF');
      return;
    }

    if (fileSize > 10) {
      toastr.error('File size must be up to 10MB');
      return;
    }

    f(`articles.${i}.file`, file);
    f(`articles.${i}.file_name`, file.name);
    f(`articles.${i}.image`, URL.createObjectURL(file));
  };

  const removeCarouselItem = (index, f) => {
    const copyArr = [...articlesArr];
    const currentObject = copyArr[index];
    Object.keys(currentObject).forEach((item) => {
      if (item === 'image') {
        currentObject[item].url = null;
      } else if (item === 'order') {
        currentObject[item] = currentObject.order;
      } else {
        currentObject[item] = null;
      }
    });
    f(`articles.${index}.header`, '');
    f(`articles.${index}.sub_header`, '');
    f(`articles.${index}.image`, '');
    f(`articles.${index}.file_name`, '');
    f(`articles.${index}.article_url`, '');
    f(`articles.${index}.order`, currentObject.order);
    currentObject.hide = true;
    setArticlesArr(copyArr);
  };

  return (
    <div className="settings-tab-block">
      <div className="title">Help Content Section</div>
      <div className="subtitle">Help articles in this section appear once testing has completed. You can include up to 4 articles to guide the user to self-help and self-service.</div>
      <Formik
        initialValues={{
          articles: articlesArr?.map((item) => ({
            header: item?.header || '',
            sub_header: item?.sub_header || '',
            image: item?.image.url || '',
            file_name: '',
            article_url: item?.article_url || '',
            order: item.order,
            hide: item.hide,
          })) || [],
        }}
        validationSchema={extensionSchema}
        onSubmit={(values) => {
          const articlesPair = values.articles?.map((item, i) => {
            return {
              ...(item?.file && {[`article_img_${i + 1}`]: item?.file}),
              [`article_header_${i + 1}`]: item?.header,
              [`article_sub_header_${i + 1}`]: item?.sub_header,
              [`article_link_${i + 1}`]: item?.article_url,
              [`article_${i + 1}_order`]: item?.order,
            };
          });
          updateBrowserSettings({
            ...(Object.assign({}, ...articlesPair)),
          });
        }}
        enableReinitialize
      >
        {({ handleChange, values, errors, setFieldValue, isValid, dirty }) => (
          <Form className="form form-helpdesk">
            <div className="settings-tab-item">
              <Row>
                <Col xs="12">
                  <FormGroup>
                    <div className="form-section no-line">
                      <div className="form-blocks">
                        <Row>
                          {articlesArr?.map((item, i) => (
                            !item.hide && (
                              <Col xl="3" md="6" key={i}>
                                <div className="form-block">
                                  <Button
                                    type="button"
                                    generalType="default"
                                    className="btn-remove"
                                    size="small"
                                    onClick={() => removeCarouselItem(i, setFieldValue)}
                                  >
                                    Remove
                                  </Button>
                                  <div className="form-block-title">Article {item?.order}</div>
                                  <FormGroup>
                                    <Label>File upload</Label>
                                    <Input
                                      id={`articles${i}_file_name`}
                                      name={`articles.${i}.file_name`}
                                      type="file"
                                      hidden
                                      onChange={(event) => changeImage(event, setFieldValue, i)}
                                    />
                                    <FormGroup>
                                      <Field
                                        className="form-control"
                                        value={values?.articles?.[i]?.file_name}
                                        readOnly
                                      />
                                    </FormGroup>
                                    <Label for={`articles${i}_file_name`} className="btn btn-default">Browse</Label>
                                  </FormGroup>
                                  <FormGroup>
                                    <Label>Current image</Label>
                                    <div className="form-block-preview">
                                      <img src={values?.articles?.[i]?.image} alt="uploaded file" />
                                    </div>
                                  </FormGroup>
                                  <FormGroup>
                                    <Label for={`articles${i}_header`}>headline Text</Label>
                                    <Field
                                      id={`articles${i}_header`}
                                      className={`form-control ${errors.articles?.[i]?.header ? 'is-invalid' : ''}`}
                                      maxLength="50"
                                      name={`articles.${i}.header`}
                                      onChange={(e) => handleChange(e)}
                                      disabled={isLoading}
                                    />
                                    <div className="character-count">
                                      Character count: <span>{values.articles?.[i]?.header?.length || 0}</span>/<span>50</span>
                                    </div>
                                  </FormGroup>
                                  <FormGroup>
                                    <Label for={`articles${i}_sub_header`}>Sub-headline text</Label>
                                    <Field
                                      id={`articles${i}_sub_header`}
                                      className={`form-control ${errors.articles?.[i]?.sub_header ? 'is-invalid' : ''}`}
                                      maxLength="120"
                                      name={`articles.${i}.sub_header`}
                                      type="textarea"
                                      onChange={(e) => handleChange(e)}
                                      as="textarea"
                                      disabled={isLoading}
                                    />
                                    <div className="character-count">
                                      Character count: <span>{values.articles?.[i]?.sub_header?.length || 0}</span>/<span>120</span>
                                    </div>
                                  </FormGroup>
                                  <FormGroup>
                                    <Label for={`articles${i}_article_url`}>Article URL</Label>
                                    <Field
                                      id={`articles${i}_article_url`}
                                      className={`form-control ${errors.articles?.[i]?.article_url ? 'is-invalid' : ''}`}
                                      name={`articles.${i}.article_url`}
                                      onChange={handleChange}
                                      disabled={isLoading}
                                    />
                                  </FormGroup>
                                </div>
                              </Col>
                            )
                          ))}
                          {(articlesArr.length < 4 || articlesArr?.filter((item) => item.hide === true).length > 0) && (
                            <Col xl="3" md="6">
                              <div className="form-col-add">
                                <Button
                                  generalType="default"
                                  type="button"
                                  onClick={addToArticles}
                                >
                                  + Add
                                </Button>
                              </div>
                            </Col>
                          )}
                        </Row>
                      </div>
                    </div>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="3">
                  <div className="form-btn d-flex justify-content-end">
                    <Button
                      type="submit"
                      generalType="default"
                      disabled={
                        (!isValid || !dirty)
                        && !articlesArr?.filter((item) => item.hide === true).length > 0
                        && !(data.articles.length < articlesArr.length)
                      }
                    >
                      Save changes
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default BrowserTestHelpForm;
