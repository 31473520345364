import React, {useEffect} from 'react';

const ComponentStyleWrapper = ({ children, disableDashboardStyle }) => {
  // useEffect(() => {
  //   if (disableDashboardStyle) {
  //     document.styleSheets[0].disabled = true;
  //     document.styleSheets[1].disabled = false;
  //   } else {
  //     document.styleSheets[0].disabled = false;
  //     document.styleSheets[1].disabled = true;
  //   }
  // });

  return <>{children}</>;
};

export default ComponentStyleWrapper;
