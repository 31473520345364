import React from 'react'
import averages from './SummaryAverages.module.css'

const SummaryAveragesBlock = ({
  icon,
  label,
  value,
}) => {
  const isValue = typeof value === 'number'
  const roundedNumbers = (number) => {
    return number && number > 1000 ? Math.round(number) : number;
  }
  return (
    <div className={averages.block}>
      <div className={averages.label}>
        <div className={averages.icon}>
          <img src={icon} alt="icon" />
        </div>
        <div className={averages.name}>{label}</div>
      </div>
      {isValue &&  (
        <div
          className={`${averages.valueBlock}`}
        >
          <p className={`${averages.valueBlockTitle}`}>Avg</p>
          <div className={averages.value}>{roundedNumbers(value)} <span>ms</span></div>
        </div>
      )}
    </div>
  )
}

export default SummaryAveragesBlock
