import React, { useState } from 'react';
import InviteForm from '../forms/InviteForm';
import InviteCsvForm from '../forms/InviteCsvForm';
import { renderTabs } from '../helpers/tabs_helper';
import DeployGoogleAdminForm from './DeployGoogleAdminForm';

const InviteModalBody = ({close}) => {
  const [activeTab, setActiveTab] = useState('1');

  const arr = [
    {
      id: '1',
      label: 'Google Admin',
      component: <DeployGoogleAdminForm close={close} />,
    },
    {
      id: '2',
      label: 'Email Invite',
      component: <InviteForm close={close} isLabelVisible={false} />,
    },
    {
      id: '3',
      label: 'Batch Import',
      component: <InviteCsvForm close={close} />,
    },
  ];

  return (
    <div>
      <div className="modal-text">
        Please fill in the form below and we will e-mail a unique invitation link to the HubbleIQ Chrome Extension.
        Once installed, the users test results will appear in your dashboard.
      </div>
      {renderTabs(arr, activeTab, setActiveTab, true, true)}
    </div>
  );
};

export default InviteModalBody;
