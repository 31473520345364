import React, { Component } from 'react';
import ReactEcharts from 'echarts-for-react';
import { connect } from 'react-redux';

import { fetchScatterChart } from '../store/actions';

class Scatter extends Component {
  componentDidMount() {
    const { sessionId, fetchScatterChart } = this.props;
    fetchScatterChart(sessionId);
  }

  onChartClick = (e) => {
    const { onMarkerClick } = this.props;
    onMarkerClick(e.data?.[2]);
  }

  onEvents = {
    'click': this.onChartClick,
  }

  getOption = () => {
    const good = [];
    const decent = [];
    const bad = [];
    const dates = [];
    const { chart } = this.props;

    for (let i = 0; i < chart?.activities?.length; i += 1) {
      const { test_result, session_date, test_id } = chart?.activities[i];

      const date = session_date.split(' ')[0];
      const time = session_date.split(' ')[1];
      const isPm = session_date.split(' ')[2] === 'PM';
      const hour = +time.split(':')[0];
      const hourDecimals = Math.floor((+time.split(':')[1] / 60) * 100);
      const is12 = hour < 12;
      const pmTime = isPm && is12 ? hour + 12 : hour;
      const amTime = is12 ? hour : 0;
      const modifiedTime = isPm ? pmTime : amTime;
      const transformedTime = +`${modifiedTime}.${hourDecimals}`;

      dates.push(date);

      if (test_result === 'good') {
        good.push([date, transformedTime, test_id]);
      } else if (test_result === 'decent') {
        decent.push([date, transformedTime, test_id]);
      } else if (test_result === 'bad') {
        bad.push([date, transformedTime, test_id]);
      }
    }

    const uniqueDates = dates.filter((value, index, array) => array.indexOf(value) === index);

    return {
      // backgroundColor: '#EBEBEC',
      tooltip: {
        trigger: 'axis',
        show: false,
      },
      toolbox: {
        show: false,
      },
      grid: {
        zlevel: 0,
        x: 60,
        x2: 0,
        y: 30,
        y2: 30,
        borderWidth: 0,
        backgroundColor: 'rgba(0,0,0,0)',
        borderColor: 'rgba(0,0,0,0)',
      },
      xAxis: {
        type: 'category',
        data: uniqueDates,
        axisLine: {
          show: false,
          lineStyle: {
            color: '#74788d',
          },
        },
        axisTick: false,
        splitLine: {
          show: true,
       },
      },
      yAxis: {
        data: ['Morning', 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 'Afternoon', 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
        type: 'category',
        axisLine: {
          show: false,
          lineStyle: {
            color: '#74788d',
          },
        },
        axisTick: false,
        splitLine: {
          show: true,
       },
      },
      series: [
        {
          symbolSize: 10,
          data: good,
          type: 'scatter',
          id: this.data?.[2],
        },
        {
          symbolSize: 10,
          data: decent,
          type: 'scatter',
          id: this.data?.[2],
        },
        {
          symbolSize: 10,
          data: bad,
          type: 'scatter',
          id: this.data?.[2],
        },
      ],
      color: ['#00C785', '#FEBA00', '#E00707'],
    };
  };

  render() {
    return <ReactEcharts style={{ height: '360px' }} option={this.getOption()} onEvents={this.onEvents} />;
  }
}

const mapStateToProps = (state) => {
  const { chart } = state.UserStatus;
  return { chart };
};

export default connect(mapStateToProps, {
  fetchScatterChart,
})(Scatter);
