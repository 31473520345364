export const capitalizeFirstLetter = (string) => {
  return string?.charAt(0).toUpperCase() + string?.slice(1);
};

export const toLocalTime = (dateTime) => {
  if (!dateTime) {
    return '';
  }
  let dateTimeTransformer = dateTime.replace('PM', '').replace('AM', '').trim();
  dateTimeTransformer += ':00';
  const d = new Date();
  const n = d.getTimezoneOffset();
  const msec = Date.parse(dateTimeTransformer + n);
  const dateClientSide = new Date(msec);
  return dateClientSide.toLocaleString().replace(':00 ', ' ').replace(',', '');
};

export const mapTestResultToColor = {
  good: 'success',
  decent: 'warning',
  bad: 'danger',
};

export const mapTestResultStatus = {
  good: 'Good',
  decent: 'Decent',
  bad: 'Poor',
};

export const mapStatusDescriptionToLabel = {
  decent_download: 'Moderate download',
  decent_upload: 'Moderate upload',
  decent_jitter: 'Moderate jitter',
  decent_latency: ' Moderate latency',
  decent_packetloss: 'Moderate packet loss',
  low_download: 'Poor download',
  low_upload: 'Poor upload',
  high_jitter: 'High jitter',
  high_latency: 'High latency',
  high_packetloss: 'High packet loss',
};

export const defaultChartsColors = ['#5584BD', '#DEDFFA', '#6D69CF', '#FF5844', '#FEBA00', '#9C9CB5', '#CCCCCC', '#CF2E20', '#D18B44'];

export const getRandomColor = () => {
  const letters = '0123456789ABCDEF'.split('');
  let color = '#';
  for (let i = 0; i < 6; i += 1) {
    color += letters[Math.floor(Math.random() * 16)];
  }

  return color;
};

export const mapDataToLegend = (legend, data) => {
  return Object.keys(legend).reduce((acc, key) => ({
    ...acc,
    ...(legend && data && legend[key] && {
      [legend[key]]: data[key],
    }),
  }), {});
};

export const serializeQuery = (obj = {}, prefix = '') => {
  const str = [];
  Object.keys(obj).forEach((p) => {
    const k = prefix ? `${prefix}[${p}]` : p;
    const v = obj[p];
    str.push((v !== null && typeof v === 'object')
      ? this.serializeQuery(v, k)
      : `${encodeURIComponent(k)}=${encodeURIComponent(v)}`);
  });
  return str.filter((s) => s.length).join('&');
}

export const setCookieForDomain = (key, value) => {
  window.Cookies.set(key, value, {
    domain: getDomainFromHostName(),
    sameSite: 'Strict',
  });
}

export const getDomainFromHostName = () => {
  const { hostname } = window.location;
  return hostname.substring(hostname.lastIndexOf(".", hostname.lastIndexOf(".") - 1) + 1);
}

export const extensionLink = 'https://chromewebstore.google.com/detail/wifi-speed-monitor-for-ch/cbfnbdgmjknfjeidohbhmpdliofaopne?ct_token='
