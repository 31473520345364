import React from 'react';
import PropTypes from 'prop-types';
import {CircularProgress} from '@material-ui/core';
import {Link} from 'react-router-dom';

const DashboardStatsCard = ({className, title, text, linkTo, loading, urlTo}) => {
  const getContent = () => {
    const content = (
      <>
        <h5 className="card-title text-center">{title}</h5>
        <p className="card-text text-center">{text}</p>
      </>
    );

    if (loading) {
      return (
        <div className="d-flex justify-content-center">
          <CircularProgress size={28} />
        </div>
      );
    }

    if (linkTo) {
      return (
        <Link to={linkTo}>
          {content}
        </Link>
      );
    }

    if (urlTo) {
      return (
        <a href={urlTo} target="_blank" rel="noreferrer">
          {content}
        </a>
      );
    }

    return content;
  };

  return (
    <div className={`card dashboard-stats-card ${className}`}>
      <div className="card-body">
        { getContent() }
      </div>
    </div>
  );
};

DashboardStatsCard.defaultProps = {
  className: 'bg-gray',
  loading: false,
};

DashboardStatsCard.propTypes = {
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.number]),
  linkTo: PropTypes.string,
  loading: PropTypes.bool,
};

export default DashboardStatsCard;
