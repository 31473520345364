import * as c from './actionTypes';

export const getDeviceActivities = (history, data) => {
  return {
    type: c.GET_DEVICE_ACTIVITIES,
    payload: {
      data, history,
    },
  };
};

export const getDeviceActivitiesSuccess = (payload) => {
  return {
    type: c.GET_DEVICE_ACTIVITIES_SUCCESS,
    payload,
  };
};

export const getDeviceActivitiesApiError = (error) => {
  return {
    type: c.GET_DEVICE_ACTIVITIES_ERROR,
    payload: error,
  };
};

export const getUserDeviceActivities = (history, data) => {
  return {
    type: c.GET_USER_DEVICE_ACTIVITIES,
    payload: {
      data, history,
    },
  };
};

export const getUserDeviceActivitiesSuccess = (payload) => {
  return {
    type: c.GET_USER_DEVICE_ACTIVITIES_SUCCESS,
    payload,
  };
};

export const getUserDeviceActivitiesApiError = (error) => {
  return {
    type: c.GET_USER_DEVICE_ACTIVITIES_ERROR,
    payload: error,
  };
};
