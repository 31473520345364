import React from 'react';
import { Drawer } from '@material-ui/core';
import IssueHeader from "./IssueHeader/IssueHeader";
import styles from './inssue-details.module.scss'
import IssueDescription from "./IssueDescription/IssueDescription";
import IssueEventLogs from "./IssueEventLogs/IssueEventLogs";
import AIChat from "../../AIChat/AIChat";

const IssueDetailsDrawer = ({
  isOpen,
  onHandlerClose,
  issueId,
  data = [],
  callBack = null,
}) => {
	return (
		<Drawer
			anchor="right"
			open={isOpen}
			onClose={onHandlerClose}
			className="drawer"
		>
			<div className={styles.issueDetailsDrawer}>
					<IssueHeader
						closeDrawer={onHandlerClose}
						issueId={issueId}
						arrData={data}
            callBack={callBack}
					/>
					<IssueDescription issueId={issueId} />
					<AIChat issueId={issueId} arrData={data} />
					<IssueEventLogs issueId={issueId} />
			</div>
		</Drawer>
	);
};

export default IssueDetailsDrawer;
