import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import ReactTable from '../ReactTable';
import {logsDeviceTableColumns} from "../../helpers/tabs_helper";

const DeviceEventsTable = ({
  data,
  isLoading,
  onSortChange,
  disableSortBy = false,
  onRowClick
}) => {
  const cpuStatus = (value) => {
    if (value >= 0 && value <= 31) {
      return <span className="result-status result-success">Average</span>;
    }
    if (value > 31) {
      return <span className="result-status result-danger">High</span>;
    }
    return <span className="result-status">Not Available</span>;
  };

  const memoryStatus = (value) => {
    if (value >= 0 && value <= 18) {
      return <span className="result-status result-success">Average</span>;
    }
    if (value > 18) {
      return <span className="result-status result-danger">High</span>;
    }
    return <span className="result-status">Not Available</span>;
  };

  const batteryStatus = (value) => {
    switch (value) {
      case 0:
        return <span className="result-status result-danger">Poor</span>;
      case 1:
        return <span className="result-status result-success">Good</span>;
      default:
        return <span className="result-status">Plugged In</span>;
    }
  };

  const columns = [
      {
        Header: logsDeviceTableColumns.CREATED_AT.header,
        accessor: logsDeviceTableColumns.CREATED_AT.accessor,
        Cell: ({row: {original}}) => (
          <Link to={`/admin/user-status/${original?.user_id}?activetab=device`}>{original.created_at}</Link>
        ),
      },
      {
        Header: logsDeviceTableColumns.NAME.header,
        accessor: logsDeviceTableColumns.NAME.accessor,
      },
      {
        Header: logsDeviceTableColumns.GUID.header,
        accessor: logsDeviceTableColumns.GUID.accessor,
      },
      {
        Header: logsDeviceTableColumns.CPU.header,
        accessor: logsDeviceTableColumns.CPU.accessor,
        Cell: ({row: {original}}) => (
          cpuStatus(original.cpu_utilization)
        ),
      },
      {
        Header: logsDeviceTableColumns.MEMORY.header,
        accessor: logsDeviceTableColumns.MEMORY.accessor,
        Cell: ({row: {original}}) => (
          memoryStatus(original.memory_utilization)
        ),
      },
      {
        Header: logsDeviceTableColumns.BATTERY.header,
        accessor: logsDeviceTableColumns.BATTERY.accessor,
        Cell: ({row: {original}}) => (
          batteryStatus(original.battery_health)
        ),
      },
      {
        Header: logsDeviceTableColumns.OS.header,
        accessor: logsDeviceTableColumns.OS.accessor,
      },
    ];

  const onSort = useCallback(({sortBy}) => {
    if (onSortChange) {
      if (sortBy.length > 0) {
        const [{id, desc}] = sortBy;
        onSortChange(`${[id]} ${desc ? 'desc' : 'asc'}`);
      } else {
        onSortChange();
      }
    }
  }, []);

  return (
    <>
      <ReactTable
        columns={columns}
        data={data}
        onSortChange={onSort}
        loading={isLoading}
        manualSortBy
        disableMultiSort
        disableSortBy={disableSortBy}
        onRowClick={onRowClick}
      />
    </>

  );
};

DeviceEventsTable.defaultProps = {
  data: [],
  isLoading: false,
};

DeviceEventsTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.any),
  onSortChange: PropTypes.func,
  isLoading: PropTypes.bool,
};

const mapStateToProps = (state) => {
  const { error, loading } = state.Device;
  return { error, loading };
};

export default withRouter(connect(mapStateToProps, null)(DeviceEventsTable));
