import React, {useCallback, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import ReactTable from '../../ReactTable';
import {toastr} from "react-redux-toastr";
import {fetchAdmins} from "../../../store/administrators/actions";
import UpdateAdministrator from "../../DashboardSettings/tabs/Administrators/UpdateAdministrator";
import * as PropTypes from "prop-types";
import AdminsTableColumns from "./AdminsTableColumns";

const AdminsTable = ({
    onSortChange,
    disableSortBy = false,
  }) => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [selectedUser, setSelectedUser] = useState();
	const [modalContent, setModalContent] = useState();
	
	const toggleModal = () => setIsModalOpen(!isModalOpen);
	
	const onModalOpen = (value, content) => {
		setSelectedUser(data.find(({id}) => id === value));
		setModalContent(content);
		toggleModal();
	};
	
	const dispatch = useDispatch();
	const isLoading = useSelector(state => state.Admins.loading);
	const data = useSelector(state => state.Admins.data?.users);
	
	const onSort = useCallback(({sortBy}) => {
		if (onSortChange) {
			if (sortBy.length > 0) {
				const [{id, desc}] = sortBy;
				onSortChange(`${[id]} ${desc ? 'desc' : 'asc'}`);
			} else {
				onSortChange();
			}
		}
	}, []);
	
	const onSuccessSubmit = () => {
		dispatch(fetchAdmins());
		setSelectedUser(null);
		toggleModal();
		toastr.success('System', 'Success administrator update');
	};
	
	const isEditMode = modalContent === 'edit'
	const modalHeaderTitle = isEditMode ? 'Edit administrator' : `Are you sure you want to remove administrator ${selectedUser?.email}?`
	
	return (
		<>
			<ReactTable
				columns={AdminsTableColumns({ onModalOpen })}
				data={data}
				onSortChange={onSort}
				loading={isLoading}
				manualSortBy
				disableMultiSort
				disableSortBy={disableSortBy}
			/>
			{selectedUser && (
				<UpdateAdministrator
					onSuccessSubmit={onSuccessSubmit}
					isEditMode={isEditMode}
					modalHeaderTitle={modalHeaderTitle}
					toggleModal={toggleModal}
					selectedUser={selectedUser}
					isModalOpen={isModalOpen}
				/>
			)}
		</>
	);
};

AdminsTable.propTypes = {
	onSortChange: PropTypes.func,
	disableSortBy: PropTypes.bool,
};

export default AdminsTable;
