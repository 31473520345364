import React, {useMemo, useCallback, useState} from 'react';
import PropTypes from 'prop-types';
import {Link, withRouter} from 'react-router-dom';
import { Button } from '@material-ui/core';
import ReactTable from '../ReactTable';
import {mapStatusDescriptionToLabel, mapTestResultStatus, mapTestResultToColor} from '../../helpers/page_helper';
import TestDetailsDrawer from '../drawers/TestDetailsDrawer';

const UsersActivitiesTable = ({
  data,
  isLoading,
  onSortChange,
  disableSortBy = false,
  userId,
}) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [testId, setTestId] = useState(null);

  const onDrawerClose = () => {
    setIsDrawerOpen(false);
    setTestId(null);
  };

  const onDrawerOpen = (id) => {
    setTestId(id);
    setIsDrawerOpen(true);
  };
  const columns = useMemo(
    () => [
      {
        Header: <span className="pl-25">Username/Type</span>,
        accessor: 'email',
        Cell: ({row: {original}}) => (
          <div className="wrapper">
            {original.help_desk_request ?
              <span className="icon"><i className="mdi mdi-face-agent font-size-17 align-middle" /></span> :
              null}
            <Link to={`/admin/user-status/${original.user_id}`}>{original.email}</Link>
          </div>
        ),
        isVisible: !(userId),
      },
      {
        Header: 'Session Date',
        accessor: 'session_date',
        Cell: ({row: {original}}) => (
          <Button
            variant="text"
            size="small"
            onClick={() => onDrawerOpen(original.test_id)}
          >
            {original.session_date}
          </Button>
        ),
      },
      {
        Header: 'Upload',
        accessor: 'upload',
        Cell: ({value}) => `${value} mbps`,
      },
      {
        Header: 'Download',
        accessor: 'download',
        Cell: ({value}) => `${value} mbps`,
      },
      {
        Header: 'Service Provider',
        accessor: 'service_provider',
        Cell: ({value}) => (
          value && value.length > 20 ? `${value.substring(0, 20)} ...` : value
        ),
      },
      {
        Header: 'Status',
        accessor: 'test_result',
        Cell: ({value}) => (
          <span className={`result-status result-${mapTestResultToColor[value]}`}>{mapTestResultStatus[value]}</span>
        ),
      },
      {
        Header: 'Issue',
        accessor: 'status_description',
        Cell: ({row: {original}}) => (
          <span className={`result-status result-${mapTestResultToColor[original.test_result]}`}>
            {mapStatusDescriptionToLabel[original.status_description]}
          </span>
        ),
      },
      {
        Header: 'Test Type',
        accessor: 'test_type',
      },
      {
        Header: 'UID/GUID',
        accessor: 'guid',
        Cell: ({row: {original}}) => (
          <Link to={`/admin/user-status/${original.user_id}`}>{original.guid}</Link>
        ),
      },
      {
        Header: 'IP ADDRESS',
        accessor: 'ip_address',
      },
    ], [],
  );

    const onSort = useCallback(({ sortBy }) => {
      if (onSortChange) {
        if (sortBy.length > 0) {
          const [{id, desc}] = sortBy;
          onSortChange(`${[id]} ${desc ? 'desc' : 'asc'}`);
        } else {
          onSortChange();
        }
      }
  }, []);

  return (
    <>
      <ReactTable
        columns={columns}
        data={data}
        onSortChange={onSort}
        loading={isLoading}
        manualSortBy
        disableMultiSort
        disableSortBy={disableSortBy}
      />
      <TestDetailsDrawer
        isOpen={isDrawerOpen}
        onHandlerClose={onDrawerClose}
        testId={testId}
      />
    </>
  );
};

UsersActivitiesTable.defaultProps = {
  data: [],
  isLoading: false,
};

UsersActivitiesTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  onSortChange: PropTypes.func,
  isLoading: PropTypes.bool,
  userId: PropTypes.number,
};

export default withRouter(UsersActivitiesTable);
