import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap'
import { useHistory, Link } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import ComponentLoader from '../ComponentLoader'
import { fetchNotifications, markNotificationsAsRead } from '../../store/actions';
import { toastr } from 'react-redux-toastr';
import webSockeService from '../WebsocketService';
import { renderNotification, subscribeAdminEventArr } from './announcement-helper'
import announcements from './announcements.module.scss'

const Announcements = ({isOpen, setIsOpen}) => {
  const { data, isLoading, error } = useSelector(state => state.Notifications);
  const { isActivePlan, ownerSetupStep } = useSelector((state) => state.Company);
  const { data: profileData } = useSelector(state => state.Profile);
  const [noLoading, setNoLoading] = useState(false);
  const [isUnreaded, setIsUnreaded] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const isButtonDisabled = !isActivePlan && ownerSetupStep > 5;

  const fetchMyNotifications = () => {
    dispatch(fetchNotifications({
      limit: 5
    }))
  }

  const callBackFunction = () => {
    if(isOpen) {
      if(!noLoading) {
        setNoLoading(true);
      }
      fetchMyNotifications();
    }
    if(!isUnreaded) {
      setIsUnreaded(true);
    }
  }

  useEffect(() => {
    if(isOpen) {
      fetchMyNotifications();
    }
  }, [isOpen]);

  useEffect(() => {
    if(data?.length > 0) {
      setIsUnreaded(data?.find(notification => notification.wasRead === false))
    }
  }, [data])

  useEffect(() => {
    if(error) {
      toastr.error(error);
    }
  }, [error])

  const onMarkAsReadClick = (notificationId = null, url = '') => {
    if(notificationId) {
      dispatch(markNotificationsAsRead({ids: [notificationId]}))
      if(url) {
        history.push(url)
      }
    } else {
      const copyData = [...data];
      const notWasReadNotifications = copyData.filter(notification => notification.wasRead === false);
      const notificationsIds = notWasReadNotifications.map(notification => notification.id);
      dispatch(markNotificationsAsRead({ids: notificationsIds}))
    }
  }

  useEffect(() => {
    if(profileData?.guid) {
      subscribeAdminEventArr.map(eventName => (
        webSockeService.subscribeAdminEvent(
          profileData?.guid,
          eventName,
          () => {
            callBackFunction()
          }
        )
      ))
    }
  }, [profileData])

  const dropdownToggle = () => {
    if(noLoading) {
      setNoLoading(false);
    }
    setIsOpen()
  }

  return (
    <Dropdown
      isOpen={isOpen}
      toggle={dropdownToggle}
      className={`d-inline-block ${announcements.dropdown}`}
    >
      <DropdownToggle
        className={`btn header-item waves-effect btn-popup btn-profile mr-0 ${announcements.dropdownToggle}`}
        tag="button"
        disabled={isButtonDisabled}
      >
        <span className={announcements.icon}>
          <i className="mdi mdi-bell-outline" />
          {isUnreaded && <span className={announcements.count} />}
        </span>
      </DropdownToggle>
      <DropdownMenu right className={announcements.dropdownMenu}>
        <div className={announcements.dropdownHeader}>
          <div className={announcements.dropdownHeaderTitle}>Notifications</div>
          <div className={announcements.dropdownHeaderActions}>
            <Button
              color="link"
              onClick={() => onMarkAsReadClick()}
            >
              Mark all as Read
            </Button>
          </div>
        </div>
        <div className={announcements.dropdownBody}>
          {(isLoading && !noLoading) && <div className="text-center"><ComponentLoader /></div>}
          {!isLoading && renderNotification(data, onMarkAsReadClick)}
        </div>
        {/* Hide for now */}
        {/* <div className={announcements.dropdownFooter}>
          <Link to="/">See all notifications</Link>
        </div> */}
      </DropdownMenu>
    </Dropdown>
  )
}

Announcements.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func
}

export default Announcements