import { FETCH_USER_ISSUES_DETAILS, FETCH_USER_ISSUES_DETAILS_SUCCESS, FETCH_USER_ISSUES_DETAILS_ERROR } from "./actionTypes";

const initialState = {
	data: {},
	loading: false,
	error: null,
};

const UserIssuesDetails = (state = initialState, action) => {
	switch (action.type) {
		case FETCH_USER_ISSUES_DETAILS:
			return {
				...state,
				loading: true,
			};
		case FETCH_USER_ISSUES_DETAILS_SUCCESS:
			return {
				...state,
				data: action.payload,
				loading: false,
			};
		case FETCH_USER_ISSUES_DETAILS_ERROR:
			return {
				...state,
				error: action.payload,
				loading: false,
			};
		default:
			return { ...state };
	}
};

export default UserIssuesDetails;
