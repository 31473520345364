import React, {Component} from 'react';
import {Alert, Card, Col, Row} from 'reactstrap';

// Redux
import {connect} from 'react-redux';
import {Link, withRouter} from 'react-router-dom';

// availity-reactstrap-validation
import {AvField, AvForm} from 'availity-reactstrap-validation';

// action
import {userResetPassword} from '../../store/actions';
// import AhoyTrack from '../../modules/AhoyTrack';

class ResetPasswordPage extends Component {
  constructor(props) {
    // AhoyTrack();
    super(props);
    this.state = {};
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
  }

  async handleValidSubmit(event, values) {
    const {userResetPassword, match: {params: {token}}, history} = this.props;
    if (token) {
      userResetPassword({
        token,
        password: values.password,
        history,
      });
    }
  }

  render() {
    const {companyLogo, resetError, resetSuccessMsg} = this.props;
    return (
      <>
        <div className="home-btn d-none d-sm-block">
          <Link to="/" className="text-dark">
            <i className="fas fa-home h2" />
          </Link>
        </div>
        <div className="account-pages reset-password">
          <div className="blue-header" />
          <div className="form-container">
            <Card className="login-form">
              <div className="logo-container">
                <div className="logo-img">
                  <img src={companyLogo} alt="logo" />
                </div>
              </div>
              <div className="login-form-content">
                <div className="auth-header">
                  <span className="login-header">Enter Your New Password</span>
                </div>
                <AvForm className="form-horizontal mt-4" onValidSubmit={this.handleValidSubmit}>
                  {resetError ? (
                    <Alert color="danger" style={{marginTop: '13px'}}>
                      {resetError}
                    </Alert>
                  ) : null}
                  {resetSuccessMsg ? (
                    <Alert color="success" style={{marginTop: '13px'}}>
                      {resetSuccessMsg}
                    </Alert>
                  ) : null}

                  <div className="form-group">
                    <AvField
                      name="password"
                      label=""
                      className="form-control"
                      placeholder="New Password"
                      type="password"
                      validate={{
                        required: {value: true},
                        minLength: {value: 8, errorMessage: 'Password must be at least 8 characters'},
                      }}
                    />
                  </div>
                  <Row className="form-group">
                    <Col lg={12} className="reset-bt-container">
                      <button className="btn auth-button" type="submit">
                        Continue
                      </button>
                    </Col>
                  </Row>
                </AvForm>
                <div className="hubbleiq-copy mt-2">
                  <p className="mb-0">©{new Date().getFullYear()} HubbleIQ INC. All Right Reserved.</p>
                </div>
              </div>
            </Card>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const {companyLogo} = state.Company;
  const {resetError, resetSuccessMsg} = state.ResetPassword;
  return {resetError, resetSuccessMsg, companyLogo};
};

export default withRouter(connect(mapStateToProps, {userResetPassword})(ResetPasswordPage));
