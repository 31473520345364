import React from 'react';
import PropTypes from 'prop-types';

const style = {
  width: '16px',
  height: '16px',
  padding: '6px',
  borderRadius: '100%',
  cursor: 'pointer',
  // transform: 'translate(-50%, -50%)',
};

const styleInner = {
  width: '100%',
  height: '100%',
  backgroundColor: '#4F46E5',
  borderRadius: '100%',
  boxShadow: 'none',
};

const toggle = (status) => {
  switch (status) {
    case 'good':
      return '#0CC778';
    case 'poor':
      return '#E13A26';
    case 'bad':
      return '#E13A26';
    case 'decent':
      return '#E1B107';
    default:
      return '#4F46E5';
  }
};

const GoogleMapMarker = ({testId, status, content, userId}) => {
  const colorByStatus = (statusText) => {
    const colorCode = toggle(statusText);
    const modifiedinnerStyle = {...styleInner};
    modifiedinnerStyle.backgroundColor = colorCode;
    modifiedinnerStyle.boxShadow = `0 0 5px 5px ${colorCode}`;
    return modifiedinnerStyle;
  };

  return (
    <div id={testId ? `test_${testId}` : `user_${userId}`} style={style}>
      {content}
      <div style={colorByStatus(status)} />
    </div>
  );
};

GoogleMapMarker.propTypes = {
  testId: PropTypes.number,
  userId: PropTypes.number,
  content: PropTypes.node,
};

export default GoogleMapMarker;
