import {takeEvery, put, all, fork} from 'redux-saga/effects';
import {toastr} from 'react-redux-toastr';
import {
  GET_EX_LOGO_REQUEST,
  UPDATE_EX_LOGO_REQUEST,
  GET_STATUS_MESSAGE_REQUEST,
  UPDATE_STATUS_MESSAGE_REQUEST,
  GET_HELPDESK_MESSAGE,
  UPDATE_HELPDESK_MESSAGE,
} from './actionTypes';
import {
  getEXLogoSuccess,
  getEXLogoError,
  updateEXLogoSuccess,
  updateEXLogoError,
  getStatusMessageSuccess,
  getStatusMessageError,
  updateStatusMessageSuccess,
  updateStatusMessageError,
  getHelpdeskMessageSuccess,
  getHelpdeskMessageError,
  updateHelpdeskMessageSuccess,
  updateHelpdeskMessageError,
} from './actions';
import { updateLastRestartTime } from '../actions';
import apiClient from '../../gears/apiClient';

function* getEXLogo() {
  try {
    const response = yield apiClient.get('/settings/get_current_chrome_ex_logo');
    yield put(getEXLogoSuccess(response));
  } catch (error) {
    yield put(getEXLogoError(error));
    toastr.error('System', error);
  }
}

function* updateEXLogo({payload}) {
  try {
    yield apiClient.post('/settings/new_chrome_ex_logo', payload);
    yield put(updateEXLogoSuccess());
    toastr.success('System', 'Success logo update');
  } catch (error) {
    yield put(updateEXLogoError(error));
    toastr.error('System', error);
  }
}

function* getStatusMessage({payload}) {
  try {
    const response = yield apiClient.get(`/fetch_status_message/${payload}`);
    yield put(getStatusMessageSuccess(response));
  } catch (error) {
    yield put(getStatusMessageError(error));
    toastr.error('System', error);
  }
}

function* updateStatusMessage({payload: {id, ...data}}) {
  try {
    const response = yield apiClient.put(`/update_message/${id}`, data);
    yield put(updateStatusMessageSuccess(response));
    toastr.success('System', 'Updated with success');
  } catch (error) {
    yield put(updateStatusMessageError(error));
    toastr.error('System', error);
  }
}

function* getHelpdeskMessage() {
  try {
    const response = yield apiClient.get('/settings/chrome_help_request');
    yield put(getHelpdeskMessageSuccess(response));
  } catch (error) {
    yield put(getHelpdeskMessageError(error));
    toastr.error('System', error);
  }
}

function* updateHelpdeskMessage({payload: {...data}}) {
  try {
    const response = yield apiClient.post('/settings/update_chrome_help_request', {...data});
    yield put(updateHelpdeskMessageSuccess(response));
    yield put(updateLastRestartTime(data.last_restart_time_threshold));
    toastr.success('System', 'Updated with success');
  } catch (error) {
    yield put(updateHelpdeskMessageError(error));
    toastr.error('System', error);
  }
}

export function* getEXLogoWatcher() {
  yield takeEvery(GET_EX_LOGO_REQUEST, getEXLogo);
}

export function* updateEXLogoWatcher() {
  yield takeEvery(UPDATE_EX_LOGO_REQUEST, updateEXLogo);
}

export function* getStatusMessageWatcher() {
  yield takeEvery(GET_STATUS_MESSAGE_REQUEST, getStatusMessage);
}

export function* updateStatusMessageWatcher() {
  yield takeEvery(UPDATE_STATUS_MESSAGE_REQUEST, updateStatusMessage);
}

export function* getHelpdeskMessageWatcher() {
  yield takeEvery(GET_HELPDESK_MESSAGE, getHelpdeskMessage);
}

export function* updateHelpdeskMessageWatcher() {
  yield takeEvery(UPDATE_HELPDESK_MESSAGE, updateHelpdeskMessage);
}

function* XTConfigSaga() {
  yield all([
    fork(getEXLogoWatcher),
    fork(updateEXLogoWatcher),
    fork(getStatusMessageWatcher),
    fork(updateStatusMessageWatcher),
    fork(getHelpdeskMessageWatcher),
    fork(updateHelpdeskMessageWatcher),
  ]);
}

export default XTConfigSaga;
