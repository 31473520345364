import React from 'react';

const Button = ({
  children,
  onClick,
  className = '',
  generalType = 'primary',
  disabled,
  size = 'medium',
  type = 'button',
  ...props
}) => {
  const onClickHandler = () => {
    if (onClick !== undefined) {
      return onClick();
    }
    return null;
  };
  return (
    <button
      className={`button button-${generalType} button-size-${size}${
        disabled ? ' disabled' : ''
      } ${className}`}
      onClick={onClickHandler}
      {...props}
      // eslint-disable-next-line react/button-has-type
      type={type}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export default Button;
