import { Button, Input } from "reactstrap";
import ExtensionConfigurationFormItem from "./ExtensionConfigurationFormItem";

const pluralText = (value, text) => (value !== 1 ? `${text}s` : text);

export const extensionConfigurationFormItemsTop = (formik) => [
  {
    label: "Automatically test new networks",
    name: "chrome_new_connection_auto_test",
    value: formik.values.chrome_new_connection_auto_test,
    action: formik.handleChange,
    description: "Automatically run a test when a new internet connection is detected",
  },
  {
    label: "Recurring testing",
    name: "chrome_daily_auto_test",
    value: formik.values.chrome_daily_auto_test,
    action: formik.handleChange,
    description: "Triger a network test if the most recent results are over",
    children: <>
      <div className="inline-input">
        <Input
          type="number"
          id="test_frequency_hours"
          name="test_frequency_hours"
          value={formik.values.test_frequency_hours}
          className={`${formik.errors.test_frequency_hours ? 'is-invalid' : ''}`}
          onChange={(e) => formik.handleChange(e)}
          min="1"
          disabled={!formik.values.chrome_daily_auto_test}
        />
      </div>
      {pluralText(formik.values.test_frequency_hours, 'hour')}
    </>,
  },
];

export const extensionConfigurationFormItemsBottom = (formik, setIsConfirmModalOpen) => [
  {
    label: "Device Restart Notification",
    name: "extension_notification",
    value: formik.values.extension_notification,
    action: formik.handleChange,
    description: "Notify user weekly if their device hasn&#39;t been restarted in over",
    children: <>
      <div className="inline-input">
        <Input
          type="number"
          id="last_restart_time_threshold"
          name="last_restart_time_threshold"
          value={formik.values.last_restart_time_threshold}
          className={`form-control ${formik.errors.last_restart_time_threshold ? 'is-invalid' : ''}`}
          onChange={(e) => formik.handleChange(e)}
          min="0"
          max="99"
          disabled={!formik.values.extension_notification}
        />
      </div>
      {formik.values.last_restart_time_threshold !== 1 ? 'days' : 'day'}
    </>,
  },
  {
    label: "Storing User Email",
    name: "store_email",
    value: formik.values.store_email,
    action: formik.handleChange,
    setIsConfirmModalOpen: setIsConfirmModalOpen,
    description: "Only available for Extension V3",
  },
  {
    label: "Enable Packet Loss Testing",
    name: "packet_loss_testing",
    value: formik.values.packet_loss_testing,
    action: formik.handleChange,
    description: "Toggle to enable or disable packet loss testing.",
  },
  {
    label: "Enable Hop-to-Hop Testing",
    name: "hop_to_hop_testing",
    value: formik.values.hop_to_hop_testing,
    action: formik.handleChange,
    description: "Toggle to enable or disable hop-to-hop testing.",
  },
];

export const issuesSettingsFormItems = (arr, action, onEditButtonClick) => (
  arr.map(issue => ({
    id: issue.id,
    label: issue.title,
    name: issue.type,
    value: issue.recordIssue,
    action: action,
    description: issue.description,
    children: <div className="children-wrapper">
      <Button
        onClick={() => onEditButtonClick(issue.id)}
        disabled={!issue.recordIssue}
        className="button button-default button-edit"
      >
        <i className="mdi mdi-pencil" />
      </Button>
    </div>
  }))
);

export const renderExtensionConfigurationFormItems = (arr) => arr.map(item => (
  <ExtensionConfigurationFormItem
    key={item.id || item.name}
    id={item.id}
    label={item.label}
    name={item.name}
    value={item.value}
    action={item.action}
    description={item.description}
    setIsConfirmModalOpen={item.setIsConfirmModalOpen || null}
  >
    {item.children && item.children}
  </ExtensionConfigurationFormItem>
));