import React from 'react';
import { Link } from 'react-router-dom';
import { finishLinksArr } from './onBoardingHelper';

const Finish = ({ closeOnboardingModal, hide }) => {
  const linksArr = finishLinksArr(closeOnboardingModal);

  return (
    <>
      <div className="title">That’s it!</div>
      <div className="subtitle">
        You have completed the Setup. The deployed and installed extensions will begin to update the agent portal within 20 minutes. Use the shortcuts below to explore on your own, and remember, we are here to help!
      </div>
      <div className="finish">
        {linksArr.map(link => (
          <Link
            to={link.to}
            className={link.className}
            onClick={link.action}
          >
            {link.label}
          </Link>  
        ))}
      </div>

    </>
  );
};

  export default Finish;
