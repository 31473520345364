import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import {connect} from 'react-redux';
import AuthHelper from '../helpers/auth_helper';
import ComponentStyleWrapper from '../components/ComponentStyleWrapper';
import { COOKIE_NAME_TOKEN } from "../gears/constants";

const webTestRouteCheck = [
  '/',
  '/testing',
  '/test-result',
  '/start-test',
];

const AppRoute = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  companyName,
  isWebTestEnabled,
  welcomeHeader,
  welcomeSubheader,
  welcomeImg,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) => {
      const authHelper = new AuthHelper();
      const isAuthenticated = authHelper.getAuthenticatedUser();
      const isTokenExpired = authHelper.isTokenExpired();
      const modifiedTitle = props.location.pathname.replace('/admin/', '')?.replace('-', ' ');
      const modifiedTitleCapitalize = modifiedTitle.toLowerCase().split(' ').map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
      const title = `${modifiedTitleCapitalize} - ${companyName}`
      
      if (isTokenExpired) {
        window.Cookies.remove(COOKIE_NAME_TOKEN);
      }
      
      if ((isAuthProtected && !isAuthenticated)) {
        return <Redirect to={{ pathname: '/admin/login', state: { from: props.location } }} />;
      }

      if (!isWebTestEnabled && webTestRouteCheck.includes(rest.path)) {
        if (isAuthenticated) {
          return <Redirect to={{ pathname: '/admin/broadband-insights', state: { from: props.location } }} />;
        }
        
        return <Redirect to={{ pathname: '/admin/login', state: { from: props.location } }} />;
      }

      return (
        <Layout>
          <Helmet>
            <title>{title}</title>
            <meta name="description" content={welcomeSubheader} />
            <meta property="og:title" content={`${welcomeHeader} - ${companyName}`} />
            <meta property="og:description" content={welcomeSubheader} />
            <meta property="og:type" content="website" />
            <meta property="og:image" content={welcomeImg} />
            <meta name="twitter:card" content="summary" />
            <meta name="twitter:title" content={`${welcomeHeader} - ${companyName}`} />
            <meta name="twitter:image:src" content={welcomeImg} />
          </Helmet>
          <ComponentStyleWrapper disableDashboardStyle={webTestRouteCheck.includes(rest.path)}>
            <Component {...props} />
          </ComponentStyleWrapper>
        </Layout>
      );
    }}
  />
);

const mapStateToProps = (state) => {
  const {
    companyName,
    isWebTestEnabled,
    companyDetails: { step_1 }
  } = state.Company;
  const welcomeHeader = step_1?.welcome_header;
  const welcomeSubheader = step_1?.welcome_subheader;
  const welcomeImg = step_1?.welcome_img;
 return {
  companyName,
  isWebTestEnabled,
  welcomeHeader,
  welcomeSubheader,
  welcomeImg
 };
};

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AppRoute);
