import {
	FETCH_ISSUE_EVENT_LOGS,
	FETCH_ISSUE_EVENT_LOGS_SUCCESS,
	FETCH_ISSUE_EVENT_LOGS_ERROR,
} from "./actionTypes";

export const fetchIssueEventLogs = (issueId) => ({
	type: FETCH_ISSUE_EVENT_LOGS,
	payload: { issueId },
});

export const fetchIssueEventLogsSuccess = (payload) => ({
	type: FETCH_ISSUE_EVENT_LOGS_SUCCESS,
	payload,
});

export const fetchIssueEventLogsError = (error) => ({
	type: FETCH_ISSUE_EVENT_LOGS_ERROR,
	payload: error,
});
