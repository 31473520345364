import React from 'react'
import results from '../components/resultsCard/ResultsCard.module.css'
import { COOKIE_NAME_COMPANY_NAME } from '../gears/constants'

const renderLabel = (label) => {
  switch (label) {
    case 'address':
      return ''
    case 'latency':
      return 'Average latency'
    default:
      return `${label}:`
  }
}

const renderValue = (label, val) => {
  if (label === 'address') {
    return ''
  }
  return val
}

export const renderCardItems = (data, source) => {
  const objToArr = Object.entries(data)
  const content = objToArr.map(item => {
    const label = renderLabel(item[0])
    const value = renderValue(item[0], item[1], source)
    const isNumber = typeof value === 'number'
    const units = isNumber && ' ms'
    return (
      <div className={results.card_item} key={label}>
        {`${label} ${value}`}
        {isNumber && <span>{units}</span>}
      </div>
    )
  })
  return content
}

export const getDomainNameWithCompanyName = (urlCompanyName = false) => {
  const cookiesCompanyName = window.Cookies.get(COOKIE_NAME_COMPANY_NAME);
  const companyName = urlCompanyName || cookiesCompanyName;
  const {protocol, host} = window.location;
  const currentCompanyName = companyName ? `${companyName.toLowerCase()}.` : '';
  const modifiedUrl = `${protocol}//${currentCompanyName}${host}`;
  return modifiedUrl;
}

export const buildPaginationData = (data) => {
  return {
    isLast: data?.next_page === null || data?.nextPage === null,
    nextPage: data?.next_page || data?.nextPage,
    prevPage: data?.prev_page || data?.prevPage,
    currentPage: data?.current_page || data?.currentPage,
  }
}
