import React, { useState } from 'react';
import { FormGroup, Label } from 'reactstrap';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import * as PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import CSVFileValidator from 'csv-file-validator';
import Dropzone from 'react-dropzone';
import {inviteUsersList} from '../store/actions';
import Button from '../components/Button/Button';

const SUPPORTED_FORMATS = 'text/csv';

const inviteCsvSchema = Yup.object().shape({
  file: Yup.mixed().required().test('fileType', 'Only CSV files excepted', (value) => SUPPORTED_FORMATS.includes(value?.type)),
});

const InviteCsvForm = ({ inviteUsersList, close, history, changeActiveTab, sentToEmail }) => {
  const [ fileError, setFileError ] = useState('');
  const config = {
    headers: [
      {
        name: 'First Name',
        required: true,
      },
      {
        name: 'Last Name',
        required: true,
      },
      {
        name: 'Email',
        required: true,
      },
    ],
    headerError: (headerName) => setFileError(`${headerName} is not valid`),
    isHeaderNameOptional: false,
  };

  return (
    <>
      <Formik
        initialValues={{ file: null }}
        validationSchema={inviteCsvSchema}
        onSubmit={({file}, { resetForm }) => {
          const updateUserCallback = () => {
            if (!changeActiveTab) {
              close();
            }
            resetForm();
          };

           CSVFileValidator(file, config)
            .then((csvData) => {
              if (csvData.inValidMessages.length === 0 && file.size < 1048576) {
                inviteUsersList(file, history, updateUserCallback);
                if (changeActiveTab) {
                  sentToEmail('file');
                  changeActiveTab(3);
                }
              } else if (csvData.inValidMessages.length === 0 && file.size >= 1048576) {
                setFileError('Your file exceeds 1 Mb limit');
              } else {
                setFileError('Incompatible file structure');
              }
            })
            .catch((err) => {
              setFileError(err);
            });
          }}
        enableReinitialize
      >
        {({ errors, touched, isValid, dirty, setFieldValue }) => (
          <Form id="invite-csv-form">
            <FormGroup>
              <Label for="invite-csv-file">Uplod CSV file</Label>
              <div className="description">
                {!changeActiveTab && (
                  <>Please download the ‘import template’ and upload once it is populated.</>
                )}
                <div><a href="/files/hubbleiq_user_import_template.csv" download>User Import Template Download</a></div>
              </div>
              <Dropzone
                id="invite-csv-file"
                onDrop={(file) => {
                  setFieldValue('file', file[0]);
                  setFileError(null);
                }}
                maxFiles={1}
                maxSize={3145728}
                accept={['.csv']}
                name="file"
              >
                {({getRootProps, getInputProps, acceptedFiles}) => (
                  <div className="dropzone">
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <div className="dropzone--select">
                        <div className="dropzone-icon"><i className="mdi mdi-cloud-upload" /></div>
                        <div className="dropzone-text">
                          <span className="dropzone-text-link">Click to upload list</span>
                          or drag and drop
                        </div>
                        <div className="dropzone-accept">CSV (max. 3MB)</div>
                      </div>
                    </div>
                    {acceptedFiles.length > 0 && (
                    <div className="dropzone-files">
                      {acceptedFiles.map((file) => (
                        <div key={file.path}>
                          {file.path} - {file.size} bytes
                        </div>
                      ))}
                    </div>
                  )}
                  </div>
                )}
              </Dropzone>
              {errors.file && touched.file ? (
                <div className="error">{errors.file}</div>
              ) : null}
            </FormGroup>
            {fileError && (
              <div className="error">{fileError}</div>
            )}
            <div className="actions">
              <Button
                type="submit"
                generalType="default"
                disabled={!isValid || !dirty}
              >
                Send Invitations
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

const mapStateToProps = () => ({});

InviteCsvForm.propTypes = {
  inviteUsersList: PropTypes.func,
  close: PropTypes.func,
};

export default withRouter(connect(mapStateToProps, {inviteUsersList})(InviteCsvForm));
