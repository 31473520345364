import React from 'react'
import IconDevice from '../../images/summaryHops/icon-device.svg'
import IconWifi from '../../images/summaryHops/icon-wifi.svg'
import IconWan from '../../images/summaryHops/icon-wan.svg'
import IconCloud from '../../images/summaryHops/icon-cloud.svg'
import SummaryAveragesBlock from './SummaryAveragesBlock'
import style from './SummaryAverages.module.css'

const SummaryAverages = ({hops}) => {
  return (
    <div className={style.averages}>
        {hops?.device_lan !== 0 && hops?.device_lan !== null &&
          <SummaryAveragesBlock
          icon={IconDevice}
          label="Endpoint Device"
          value={hops?.device_lan}
        />}
      {hops?.lan_wan !== 0 && hops?.lan_wan !== null &&
        <SummaryAveragesBlock
          icon={IconWifi}
          label="LAN Gateway"
          value={hops?.lan_wan}
        />}
      {hops?.wan_cloud !== 0 && hops?.wan_cloud !== null &&
        <SummaryAveragesBlock
          icon={IconWan}
          label="ISP WAN"
          value={hops?.wan_cloud}
        />}
        <SummaryAveragesBlock
          icon={IconCloud}
          label="Web App"
        />
    </div>
  )
}

export default SummaryAverages
