export const enterprisePlan = {
  description: 'For large enterprises and B2C deployments',
  id: 'enterprise',
  name: 'Custom Pricing',
}

export const renderPlanDescription = (planId) => {
  switch (planId) {
    case 12:
      return <span><b>$2.50 per additional user</b> – Billed at the end of the month.<br/> <b>7-Day Free Trial</b> – No charge until after your free trial ends.<br/> <b>Cancel Anytime</b> – You won’t be charged until day 7</span>
    case 9:
      return 'Access to full feature, API, SDK, Chrome Extension, and more!'
    default:
      return 'Have a larger team or specific needs? We offer custom solutions tailored to your requirements. Let’s chat and build the perfect plan for you.'
  }
};