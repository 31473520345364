import React from 'react';
import styles from "./install-extension-btn.module.scss";
import { useSelector } from "react-redux";
import useInstallExtensionLink from "../../hooks/useInstallExtensionLink";

function InstallExtensionBtn({type = 'white', text}) {
	const { companyDetails: { chrome_ex_key } } = useSelector((state) => state.Company);
	const { data: { first_name, last_name, email } } = useSelector((state) => state.Profile);
	const installExtensionLink = useInstallExtensionLink();
	const renderBtnHandler = (type) => {
		if (type === 'white') {
			return (
				<a
					href={installExtensionLink}
					target="_blank"
					rel="noreferrer"
					className={`${type === 'white' ? styles.installBtn : styles.installBtnViolet}`}
				>
					{text}
				</a>
			)
		} else {
			return (
				<a
					href={installExtensionLink}
					target="_blank"
					rel="noreferrer"
					className="button button-default"
				>
					{text}
				</a>
			)
		}
	}
	return (
		<>
			{renderBtnHandler(type)}
		</>
	);
}

export default InstallExtensionBtn;
