import React, {useCallback, useState} from 'react';
import PropTypes from 'prop-types';
import {Link, withRouter} from 'react-router-dom';
import {FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import {connect} from 'react-redux';
import {toastr} from 'react-redux-toastr';
import moment from 'moment';
import ReactTable from '../../ReactTable';
import UserForm from '../../forms/UserForm';
import { changeUserStatus } from '../../../store/actions';
import Button from '../../Button/Button';
import { capitalizeFirstLetter } from '../../../helpers/page_helper';
import { actionButtonsArray } from "./users-table.helper";

const UsersTable = ({
  fetch,
  data,
  isLoading,
  onSortChange,
  disableSortBy = false,
  dispatchChangeUserStatus,
  guids,
  setGuids
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState();
  const [modalContent, setModalContent] = useState();
  
  const toggleGuids = (guid) => {
    const isGuidSelected = guids.includes(guid);
    if(isGuidSelected) {
      const copyGuids = [...guids];
      const modifiedGuids = copyGuids.filter(item => item !== guid);
      setGuids(modifiedGuids);
    } else {
      setGuids([
        ...guids,
        guid
      ])
    }
  }
  
  const toggleModal = () => setIsModalOpen(!isModalOpen);
  
  const onModalOpen = (value, content) => {
    setSelectedUser(data.find(({id}) => id === value));
    setModalContent(content);
    toggleModal();
  };
  
  const columns = [
    {
      accessor: 'guids',
      Cell: ({row: {original}}) => (
        original.guid && (
          <FormGroup className="form-group_checkbox">
            <Label check>
              <Input
                type="checkbox"
                name="test"
                checked={guids.includes(original.guid)}
                onChange={() => toggleGuids(original.guid)}
              />{' '}
              <span />
            </Label>
          </FormGroup>
        )
      ),
    },
    {
      Header: 'Guid',
      accessor: 'guid',
      Cell: ({row: {original}}) => (
        <Link to={`/admin/user-status/${original.id}`}>{original.guid}</Link>
      ),
    },
    {
      Header: 'First name',
      accessor: 'first_name',
    },
    {
      Header: 'Last name',
      accessor: 'last_name',
    },
    {
      Header: 'Email',
      accessor: 'email',
    },
    {
      Header: 'Latest Activity',
      accessor: 'last_time_run_test',
    },
    {
      Header: 'Last restart',
      accessor: 'last_restart_time',
      Cell: ({row: {original}}) => (
        original.last_restart_time ? moment(original.last_restart_time).format('MM/DD/YYYY hh:mm A') : 'N/A'
      ),
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: ({ row: { original } }) => {
        if (original.active === false) {
          return 'Deactivated';
        } else {
          return original?.status ? capitalizeFirstLetter(original.status) : '';
        }
      },
    },
    {
      Header: 'Notes',
      accessor: 'notes',
      Cell: ({value}) => (
        value && value.length > 20 ? `${value.substring(0, 20)} ...` : value
      ),
    },
    {
      Header: 'Actions',
      accessor: 'id',
      Cell: ({ row: { original }, value }) => (
        <div className="actions">
          {actionButtonsArray(original, value, onModalOpen).map((item, index) =>
            <Button
              generalType={item.generalType}
              type={item.type}
              onClick={item.onClick}
              title={item.title}
              className={item.className}
              key={index}
            >
              <i className={item.icon} />
            </Button>
          )}
        </div>
      ),
    },
  ];

  const handleDeactivate = (status, userId, guid) => {
    dispatchChangeUserStatus(status, userId, guid);
    toggleModal();
  };
  
  const onSuccessSubmit = () => {
    fetch();
    setSelectedUser(null);
    toggleModal();
    toastr.success('System', 'Success user update');
  };
  
  const onSort = useCallback(({sortBy}) => {
    if (onSortChange) {
      if (sortBy.length > 0) {
        const [{id, desc}] = sortBy;
        onSortChange(`${[id]} ${desc ? 'desc' : 'asc'}`);
      } else {
        onSortChange();
      }
    }
  }, []);
  
  const actionsHandler =  {
    deactivate: {
      title: `Are you sure you want to ${selectedUser?.active ? 'deactivate' : 'reactivate'} user ${selectedUser?.guid}?`,
      buttons: [
        {
          generalType: 'default',
          onClick: toggleModal,
          label: 'No, Cancel'
        },
        {
          generalType: 'default',
          form: 'user-form',
          onClick: () => handleDeactivate(!selectedUser?.active, selectedUser?.id, selectedUser?.guid),
          label: `Yes, ${selectedUser?.active ? 'Deactivate' : 'Reactivate'}`,
        }
      ],
    },
    edit: {
      title: 'Edit User',
      buttons: [
        {
          generalType: 'default',
          onClick: toggleModal,
          label: 'Cancel',
        },
        {
          generalType: 'default',
          form: 'user-form',
          label: 'Submit',
        }
      ],
    },
  };

  return (
    <>
      <ReactTable
        columns={columns}
        data={data}
        onSortChange={onSort}
        loading={isLoading}
        manualSortBy
        disableMultiSort
        disableSortBy={disableSortBy}
      />
      
      {selectedUser && (
        <Modal isOpen={isModalOpen} toggle={toggleModal} className="custom">
          <ModalHeader toggle={toggleModal}>{actionsHandler[modalContent]?.title || ''}</ModalHeader>
          
          {modalContent === 'edit' && (
            <ModalBody>
              <UserForm
                user={selectedUser}
                onSuccessSubmit={onSuccessSubmit}
              />
            </ModalBody>
          )}
          
          <ModalFooter>
            {actionsHandler[modalContent]?.buttons.map((button, i) => (
              <Button
                key={i}
                generalType={button.generalType}
                onClick={button.onClick}
                form={button.form || null}
              >
                {button.label}
              </Button>
            ))}
          </ModalFooter>
        
        </Modal>
      )}
    </>
  );
};

UsersTable.defaultProps = {
  data: [],
  isLoading: false,
};

UsersTable.propTypes = {
  fetch: PropTypes.func,
  data: PropTypes.arrayOf(PropTypes.any),
  onSortChange: PropTypes.func,
  isLoading: PropTypes.bool,
};

const mapStateToProps = (state) => {
  const { error, loading, data } = state.Users;
  return { error, loading, data: data?.users };
};

const mapDispatchToProps = {
  dispatchChangeUserStatus: changeUserStatus,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UsersTable));
