//https://github.com/feross/simple-peer
import SimplePeer from 'simple-peer';
class RTCClient extends EventTarget {
    constructor(configuration = {}) {
        //Handle any setup in parent
        super();

        //Store the configuration
        this._configuration = configuration;

        //State
        this._state = null;
    }

    start() {
        //Scope this
        const self = this;

        //Update state
        self._state = 'uninitialized';

        //Create RTC connection
        this._connection = new SimplePeer(this._configuration || {});

        //Bind signal handler
        this._connection.on('signal', data => {
            //Update state
            self._state = 'signaling';

            //Fire event handler
            self.dispatchEvent(new CustomEvent('signal', {detail: {signal: data}}));
        });

        //Bind connect handler
        this._connection.on('connect', () => {
            //Update state
            self._state = 'connected';

            //Fire event handler
            self.dispatchEvent(new Event('connect'));
        });

        //Bind data handler
        this._connection.on('data', data => {
            //Fire event handler
            self.dispatchEvent(new CustomEvent('data', {detail: {data: JSON.parse(data)}}));
        });

        //Bind stream handler
        this._connection.on('stream', stream => {
            //Fire event handler
            self.dispatchEvent(new CustomEvent('stream', {detail: {stream: stream}}));
        });

        //Bind track handler
        this._connection.on('track', (track, stream) => {
            //Fire event handler
            self.dispatchEvent(new CustomEvent('track', {detail: {track: track, stream: stream}}));
        });

        //Bind close handler
        this._connection.on('close', () => {
            //Update state
            self._state = 'closed';

            //Fire event handler
            self.dispatchEvent(new Event('close'));
        });

        //Bind error handler
        this._connection.on('error', error => {
            //Update state
            self._state = 'error';

            //Fire event handler
            self.dispatchEvent(new CustomEvent('error', {detail: {error: error}}));
        });
    }

    signal(data) {
        //Sent signal
        this._connection.signal(data);

        //Chain
        return this;
    }

    send(data) {
        //Check to make sure connection is open and ready to send
        if (this._state !== 'connected') {
            throw new Error('not connected');
        }

        //Attempt to send the message
        try {
            //Sent message
            this._connection.send(data);
        } catch (error) {
            console.error(error);
        }

        //Chain
        return this;
    }

    close(message = null) {
        //Check to make sure connection is open and ready to send
        if (this._state !== 'connected') { return; }

        //Shutdown
        this._connection.destroy(message);

        //Chain
        return this;
    }

    supported() { return SimplePeer.WEBRTC_SUPPORT;}
}

export default RTCClient;