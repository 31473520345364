import { takeEvery, fork, put, all } from 'redux-saga/effects';

// Login Redux States
import { CREATE_ANSWERS_REQUEST } from './actionTypes';
import { createAnswersSuccess, createAnswersSuccessError } from './actions';

import apiClient from '../../gears/apiClient';

function* createAnswers({ payload: {data} }) {
  try {
    const response = yield apiClient.post('/new_answers', data);
    yield put(createAnswersSuccess(response));
  } catch (error) {
    yield put(createAnswersSuccessError(error));
  }
}

export function* watchCreateAnswers() {
  yield takeEvery(CREATE_ANSWERS_REQUEST, createAnswers);
}

function* answersSaga() {
  yield all([fork(watchCreateAnswers)]);
}

export default answersSaga;
