import { takeEvery, fork, put, all } from 'redux-saga/effects';

import { FETCH_USER_ACTIVITY_ANALYTICS } from './actionTypes';
import { fetchUserActivityAnalyticsSuccess, fetchUserActivityAnalyticsApiError } from './actions';
import apiClient from '../../../gears/apiClient';

function* fetchUserActivityAnalytics({ payload: { id } }) {
  try {
    const response = yield apiClient.get(`/user-activity-analytics/${id}`);
    yield put(fetchUserActivityAnalyticsSuccess(response));
  } catch (error) {
    yield put(fetchUserActivityAnalyticsApiError(error));
  }
}

export function* watchUserActivityAnalyticsFetch() {
  yield takeEvery(FETCH_USER_ACTIVITY_ANALYTICS, fetchUserActivityAnalytics);
}

function* userActivityAnalyticsSaga() {
  yield all([fork(watchUserActivityAnalyticsFetch)]);
}

export default userActivityAnalyticsSaga;
