import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {Field, Form, reduxForm} from 'redux-form';
import {Input} from 'reactstrap';
import {helpRequest} from '../../store/helpRequest/actions';
import {Link} from "react-router-dom";

const required = (value) => (value || typeof value === 'number' ? undefined : 'Required');

const renderField = ({input, label, type, meta: {touched, error}, cols, rows}) => (
  <>
    <Input {...input} placeholder={label} type={type} cols={cols} rows={rows} invalid={!!(touched && error)} />
  </>
);

const PublicFeedbackForm = (props) => {
  const {
    handleSubmit,
    valid,
    activityLoading,
    activityId,
    helpRequest: helpRequestDispatch,
    helpRequestSuccessSubmit,
    helpRequestRedirectUrl,
    helpRequestError,
    submitBtnLabel,
    customFormHeader,
  } = props;

  useEffect(
    () => {
      if (helpRequestSuccessSubmit && helpRequestRedirectUrl) {
        window.location.href = helpRequestRedirectUrl;
      }
    },
    [helpRequestSuccessSubmit],
  );

  const onSubmit = (values) => {
    helpRequestDispatch(values, activityId);
  };

  if (!activityId) {
    return '';
  }

  return (
    <>
      {helpRequestError && <div className="row help-form__info submitted">{helpRequestError}</div>}

      {(helpRequestSuccessSubmit && !helpRequestRedirectUrl) && (
        <div className="row help-form__info submitted">
          We have received your service request. Our support agent will contact you as soon as possible.

          <Link to="/testing" className="button button-dark button-md mt-4">Retest</Link>
        </div>
      )}

      {
        !helpRequestSuccessSubmit && (
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div className="row help-form__info">
            <div className="col-12">
              <p className="subheading">{customFormHeader}</p>
              <Field
                name="description"
                component={renderField}
                type="textarea"
                cols="30"
                rows="6"
              />
            </div>
            <div className="col-12 col-md-6">
              <p className="subheading">Name:</p>
              <Field name="name" component={renderField} type="text" validate={required} invalid />
            </div>
            <div className="col-12 col-md-6">
              <p className="subheading">Email:</p>
              <Field name="email" component={renderField} type="email" validate={required} />
            </div>
            <div className="col-12 center">
              <button className="button button-submit" type="submit" disabled={!valid || activityLoading}>{submitBtnLabel}
              </button>
            </div>
          </div>
        </Form>
      )
}

    </>
  );
};

const mapStateToProps = (state) => {
  const {data, loading} = state.RecentActivities;
  const {step_3} = state.Company.companyDetails;
  const {successSubmit: helpRequestSuccessSubmit, error: helpRequestError} = state.HelpRequest;

  return {
    activityId: data?.activity_id || null,
    activityLoading: loading,
    helpRequestSuccessSubmit,
    helpRequestRedirectUrl: step_3?.custom_redirect_url,
    helpRequestError,
    submitBtnLabel: step_3?.custom_submit_btn_label || 'REQUEST HELP',
    customFormHeader: step_3?.custom_form_header || 'Describe what issues you are experiencing:',
  };
};

const mapDispatchToProps = {
  helpRequest,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(reduxForm({
  form: 'public-feedback-form',
  // onSubmit
})(PublicFeedbackForm));
