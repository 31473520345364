import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import ReactTable from '../ReactTable';

const LogsReportedTable = ({
  data,
  isLoading,
  onSortChange,
  disableSortBy = false,
  tableColumns = [],
  onRowClick
}) => {

  const onSort = useCallback(({ sortBy }) => {
    if (onSortChange) {
      if (sortBy.length > 0) {
        const [{id, desc}] = sortBy;
        onSortChange(`${[id]} ${desc ? 'desc' : 'asc'}`);
      } else {
        onSortChange();
      }
    }
  }, []);

  return (
    <>
      <ReactTable
        columns={tableColumns}
        data={data}
        onSortChange={onSort}
        loading={isLoading}
        manualSortBy
        disableMultiSort
        disableSortBy={disableSortBy}
        onRowClick={onRowClick}
      />
    </>
  );
};

LogsReportedTable.defaultProps = {
  data: [],
  isLoading: false,
};

LogsReportedTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.array,
  onSortChange: PropTypes.func,
  isLoading: PropTypes.bool,
};

export default LogsReportedTable;
