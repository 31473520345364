import React from 'react';
import { Col, Row } from 'reactstrap';
import Stripe from '../stripe';
import OnboardingPricingShapes from '../../images/onboarding-pricing-shapes.svg';

const OnboardingPricing = () => {
  return (
    <div className="onboarding-pricing">
      <Row>
        <Col md="6">
          <div className="onboarding-pricing-icon">
            <img src={OnboardingPricingShapes} alt="shapes" />
          </div>
          <p className="onboarding-pricing-text">
            Get full access to all AI-driven features, monitor network performance, and onboard unlimited users. 
          </p>
          <p className="onboarding-pricing-text">
            No commitment, and you can cancel anytime if it’s not the right fit for you!
          </p>
          <hr/>
          <p className="onboarding-pricing-text onboarding-pricing-text_color">
            After your 7 day trial, you will be auto-enrolled into our <b>basic</b> plan. For plans and pricing details, visit our <a href="/">Pricing Page</a>. 
          </p>
          <p className="onboarding-pricing-text onboarding-pricing-text_color">
            Not ready to sign up? <br/> <a href="/">Contact Sales</a> for more information.
          </p>
        </Col>
        <Col xs="12" md="6" className="onboarding-pricing-stripe">
          <Stripe />
        </Col>
      </Row>
    </div>
  )
}

export default OnboardingPricing