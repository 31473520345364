import React, {useEffect, useState} from 'react';
import {Col, Row} from "reactstrap";
import RefreshButton from "../../../refresh-button";
import UsersTableFilter from "../../../tables-filters/UserssTableFilter";
import AdminsTable from "../../../tables/AdminsTable/AdminsTable";
import Pagination from "../../../pagination";
import {useDispatch, useSelector} from "react-redux";
import queryString from "query-string";
import {fetchAdmins} from "../../../../store/administrators/actions";
import {filterListOptions} from "./helper";
import PropTypes from "prop-types";
import { buildPaginationData } from '../../../../utility/utils';

function Administrators({
	location,
}) {
	const dispatch = useDispatch();
	const { data, loading } = useSelector(state => state.Admins);
	const [filterParams, setFilterParams] = useState({});
	const [sortBy, setSortBy] = useState(null);
	const [paginationData, setPaginationData] = useState(null);
	
	useEffect(() => {
		const { search } = location;
		const { filter_by, search_by, keyword } = queryString.parse(search);
		
		if (filter_by) {
			setFilterParams({ filter_by });
		} else if (search_by) {
			setFilterParams({ search_by, keyword });
		} else {
			fetchData();
		}
	}, [location]);
	
	const navigateToPage = (page) => {
		fetchData(page);
	};
	
	const onTableSortChange = (sortBy) => {
		if (sortBy) {
			setSortBy(sortBy);
		}
	};
	
	const onFilterChange = (filter) => {
		setFilterParams(filter);
	};
	
	useEffect(() => {
		if (sortBy || filterParams) {
			fetchData();
		}
	}, [sortBy, filterParams]);
	
	const fetchData = (page = 1) => {
		let queryParams = {
			page,
			...filterParams
		};
		if (sortBy) {
			queryParams.sort_by = sortBy;
		}
		dispatch(fetchAdmins(queryParams));
	};
	
	useEffect(() => {
		if (data?.users?.length > 0) {
			setPaginationData(buildPaginationData(data));
		}
	}, [data?.current_page, data?.next_page]);
	
	return (
		<Row className="align-items-center">
			<Col xl={12}>
				<div className="justify-between align-items-center mb-4 d-md-flex">
					<div className="align-items-center d-md-flex">
						<div className="d-flex align-items-center mr-5">
							<span className="card-title mb-0">Recent Sessions</span>
							<RefreshButton onRefresh={() => fetchData()}/>
						</div>
					</div>
				</div>
				<div className="justify-between  mb-4 d-md-flex">
					<UsersTableFilter filterListCustom={filterListOptions} onFilterChange={onFilterChange} currentFilter={filterParams}/>
				</div>
				<div className="table-responsive">
					<AdminsTable
						onSortChange={onTableSortChange}
					/>
				</div>
				{(data?.users?.length > 0 && paginationData) && <Pagination paginationData={paginationData} navigateTo={navigateToPage}/>}
			</Col>
		</Row>
	);
}

Administrators.propTypes = {
	location: PropTypes.object,
}

export default Administrators;
