import { UPDATE_USER_REQUEST, UPDATE_USER_SUCCESS, UPDATE_USER_ERROR } from './actionTypes';

const initialState = {
  error: null,
  loading: false,
};

const fetchUsers = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_USER_REQUEST:
      return {
        ...state,
        history: action.history,
        loading: true,
      };
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      };
    case UPDATE_USER_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default fetchUsers;
