import React, {useState} from 'react';
import UserDetailsTabs from "../../UserDetailsTabs";
import {TabContent, TabPane} from "reactstrap";
import {
	appActivitiesTableColumns,
	deviceActivitiesTableColumns,
	getIssueEventLogsTableTabs,
	networkIssuesTableColumns,
	userActivitiesTableColumns,
} from "./issues-event-logs-columns.helper";
import ReactTable from "../../ReactTable";
import {issuesEventLogsFakeData} from "./issues-event-logs.const";

function IssuesEventLogsTable({data}) {
	const [activeTab, setActiveTab] = useState('0')
	const toggleTabs = (tab) => {
		setActiveTab(tab);
	}

	return (
		<div className="content">
			<div className="user-details-tabs" style={{padding: '0 15px'}}>
				<UserDetailsTabs activeTab={activeTab} toggleTabs={toggleTabs} tabs={getIssueEventLogsTableTabs}/>
			</div>
			<TabContent activeTab={activeTab} style={{padding: '15px', overflowX: 'auto'}}>
				<TabPane tabId="0" className="tab_network">
					<ReactTable
						columns={networkIssuesTableColumns}
						data={data?.networkIssues}
						onSortChange={() => {}}
						loading={false}
						manualSortBy
						disableMultiSort
						disableSortBy={true}
					/>
				</TabPane>
				<TabPane tabId="1" className="tab_device">
					<ReactTable
						columns={deviceActivitiesTableColumns}
						data={data?.deviceActivities}
						onSortChange={() => {}}
						loading={false}
						manualSortBy
						disableMultiSort
						disableSortBy={true}
					/>
				</TabPane>
				<TabPane tabId="2" className="tab_applications">
					<ReactTable
						columns={appActivitiesTableColumns}
						data={data?.appActivities}
						onSortChange={() => {}}
						loading={false}
						manualSortBy
						disableMultiSort
						disableSortBy={true}
					/>
				</TabPane>
				<TabPane tabId="3" className="user_activity">
					<ReactTable
						columns={userActivitiesTableColumns}
						data={data?.userActivities}
						onSortChange={() => {}}
						loading={false}
						manualSortBy
						disableMultiSort
						disableSortBy={true}
					/>
				</TabPane>
			</TabContent>
		
		</div>
	);
}

export default IssuesEventLogsTable;
