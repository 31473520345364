import React from 'react';
import Button from "../../Button/Button";

const AdminsTableColumns = ({ onModalOpen }) => {
	const columns = [
		{
			Header: 'First name',
			accessor: 'first_name',
		},
		{
			Header: 'Last name',
			accessor: 'last_name',
		},
		{
			Header: 'Email',
			accessor: 'email',
		},
		{
			Header: 'Actions',
			accessor: 'id',
			Cell: ({value}) => (
				<div className="actions">
					<Button
						generalType="default"
						type="button"
						onClick={() => onModalOpen(value, 'edit')}
						title="Edit"
					>
						<i className="mdi mdi-pencil" />
					</Button>
					<Button
						generalType="default"
						className="ml-2"
						type="button"
						onClick={() => onModalOpen(value)}
						title="Delete"
					>
						<i className="mdi mdi-delete" />
					</Button>
				</div>
			),
		},
	];
	
	return columns;
};

export default AdminsTableColumns;
