import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { toastr } from 'react-redux-toastr'
import apiClient from '../../gears/apiClient'
import { Button } from 'reactstrap'

const TestFeature = ({guids = [], callback = () => null, date = null}) => {
  const [isLoading, setIsLoading] = useState(false);
  const isGuids = guids.length !== 0;

  const triggerRemoteTest = async () => {
    setIsLoading(true);
    const successText = guids.length > 1 ? 'selected users' : 'current user';
    try {
      await apiClient.post('/trigger-multiple-remote-test', {
        guids: [...guids],
        ...(date ? {date: date} : ''),
      });
      toastr.success(`The test was launched for the ${successText}`);
      callback();
    } catch(error) {
      toastr.error(error?.message);
    }
    setIsLoading(false);
  }

  return (
    <div className="test-feature">
      <Button
        onClick={triggerRemoteTest}
        className="button button-default"
        disabled={isLoading || !isGuids}
      >
        Test Now
      </Button>
    </div>
  )
}

TestFeature.propTypes = {
  guids: PropTypes.arrayOf(PropTypes.string),
  callback: PropTypes.func
}

export default TestFeature