import React, { useEffect, useState, useRef } from 'react';
import { Button, Spinner } from 'reactstrap';
import { useSelector } from 'react-redux';
import { renderIssueStatus } from '../drawers/IssueDetailsDrawer/issueDetailsHelper';
import aiChatAvatar from '../../images/ai-chat-avatar.svg';
import ComponentLoader from '../ComponentLoader';
import { AI_USER, fetchAiChatMsgHistory, fetchAiChatMsgSend, HUMAN_USER, renderMessages, userRole } from './aiChat-helper';
import styles from './ai-chat.module.scss';

const AIChat = ({ issueId, arrData = [] }) => {
	const [inputMsg, setInputMsg] = useState('');
	const [messages, setMessages] = useState([]);
	const chatWindowRef = useRef(null);
	const { data: userData } = useSelector((state) => state.UserIssuesDetails);
	const { data: userIssueLogsData = [] } = useSelector((state) => state.UserIssuesLogs);
	const [data, setData] = useState([]);
	const [isLoading, setIsLoading] = useState(true)
	
	const issueStatus = () => {
		const dataToFilter = arrData ? arrData : userIssueLogsData;
		return renderIssueStatus(dataToFilter, issueId)
	}
	
	useEffect(() => {
		if (issueId) {
			fetchAiChatMsgHistory(issueId, '', setIsLoading, setData);
		}
	}, [issueId]);

  useEffect(() => {
		if (data.length > 0 ) {
      const isUser = data.find(message => message.role === HUMAN_USER);
      const duration = isUser ? 0 : 1000
			let counter = 0;

      if(isUser) {
        setMessages(
          data?.map((msg) => {
            return({
              text: msg.content,
              user: userRole(msg.role),
            })
          })
        )
      } else {
				setIsLoading(true);
        const interval = setInterval(() => {
          if (counter >= data.length) {
            clearInterval(interval);
          } else {
						if(isLoading) {
							setIsLoading(false);
						}
            setMessages(prevState => ([
              ...prevState,
              {
                text: data[counter].content,
                user: userRole(data[counter].role),
              }
            ]))
            counter++;
          }
        }, duration);
        return () => clearInterval(interval);
      }
		}
  }, [data]);
	
	useEffect(() => {
		if (chatWindowRef.current) {
			chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
		}
	}, [messages]);
	
	const handleSend = async () => {
		if (inputMsg.trim() !== '') {
      setMessages(prevState => ([
        ...prevState,
        {
          text: inputMsg,
          user: HUMAN_USER
        },
				{
          text: <div className={styles.aiLoader} />,
          user: AI_USER
        },
      ]))
			try {
				await fetchAiChatMsgSend(issueId, inputMsg, setData);
        setInputMsg('');
			} catch (error) {
				console.error('Error sending message:', error);
			}
		}
	};
	
	const handleKeyPress = (e) => {
		if (e.key === 'Enter' && userData?.userActive) {
			handleSend();
		}
	};
	
	return (
		<div className={styles.chatWrapper}>
			<div className={styles.chatTitle}>
				<img src={aiChatAvatar} className={styles.avatar} alt="AI Chat Avatar" />
				<p>HubbleIQ AI</p>
			</div>
			{isLoading && <ComponentLoader customClass="text-center" />}
			{!isLoading && (
				<>
					{messages.length >= 12 && (<div className={styles.warrningMsg}>You reach the limit of messages</div>)}
					<div className={styles.chatContainer} aria-disabled={issueStatus === 'Completed'}>
						{messages.length > 0 && (
							<div ref={chatWindowRef} className={styles.chatWindow}>
								{renderMessages(messages)}
							</div>
						)}
						<div className={styles.chatInput}>
							<input
								type="text"
								value={inputMsg}
								onChange={(e) => setInputMsg(e.target.value)}
								onKeyDown={handleKeyPress}
								placeholder="Your message..."
							/>
							<Button
								onClick={handleSend}
								disabled={(inputMsg.length === 0 || isLoading) || messages?.length >= 12 || !userData?.userActive}
								className="button button-default"
							>
								{isLoading
									? <span><Spinner size="sm" color="primary"/> Sending... </span>
									: 'Send Question'
								}
							</Button>
						</div>
					</div>
				</>
			)}
		</div>
	);
};

export default AIChat;
