import React from 'react';
import { Button } from 'reactstrap';

const Popup = (props) => {
  const { customClass = '', close, children, isSummary } = props;

  return (
    <div className={`popup ${customClass}`}>
      <div className={`popup-content ${isSummary ? 'summary-content': ''}`}>
        <Button color="close" onClick={() => close()} />
        {children}
      </div>
    </div>
  );
};

export default Popup;
