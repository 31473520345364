import MonitoringImage from '../../images/onboarding-img-monitoring.png';
import TroubleshootingImage from '../../images/onboarding-img-troubleshooting.png';
import ApplicationsImage from '../../images/onboarding-img-applications.png';
import ResolvingImage from '../../images/onboarding-img-resolving.jpg';
import OnboardingCommon from './OnboardingCommon';
import OnboardingPricing from './OnboardingPricing';
import Welcome from './Welcome';
import Finish from './Finish';
import { toastr } from 'react-redux-toastr';
import apiClient from '../../gears/apiClient';
import { updateSetupStep } from '../../store/actions';


export const onBoardingSteps = (closeOnboardingModal) => {
  return [
    {
      label: 'Monitoring',
      component: <OnboardingCommon
        title="Ensure Seamless Connectivity for Your Remote Workforce"
        subtitle="HubbleIQ helps you monitor remote internet connections in real-time, ensuring a strong, stable connection for work-related tasks like video calls, cloud applications, and more."
        imgPath={MonitoringImage}
      />,
    },
    {
      label: 'Troubleshooting',
      component: <OnboardingCommon
        title="Pinpoint Weak Links Instantly with AI"
        subtitle="HubbleIQ uses advanced AI to analyze connection quality, speed, and reliability, giving you real-time insights into any connectivity issues. Whether they’re at home or working on the go, stay ahead of disruptions."
        imgPath={TroubleshootingImage}
      />,
    },
    {
      label: 'Applications',
      component: <OnboardingCommon
        title="Monitor Remote Application Uptime and Performance"
        subtitle="Track the uptime and health of applications used by your remote team, pinpoint the cause of disruptions instantly, and take action before they impact productivity."
        imgPath={ApplicationsImage}
      />,
    },
    {
      label: 'Self-service',
      component: <OnboardingCommon
        title="Help Users Resolve their own Tech Issues"
        subtitle="By providing intuitive, real-time insights into network performance and device health, users can tackle problems before they escalate—minimizing downtime and reducing the need for IT support."
        imgPath={ResolvingImage}
      />,
    },
    {
      label: 'Pricing',
      component: <OnboardingCommon
        title={<>Try HubbleIQ Free for 7 Days <br/> Unlimited Access, Cancel Anytime</>}
        content={<OnboardingPricing />}
      />
    },
    {
      label: 'Welcome',
      component: <Welcome />,
    },
    {
      label: 'Finish',
      component: <Finish closeOnboardingModal={closeOnboardingModal} />,
    }
  ];
};

export const updateInitialSetupState = async (buttonValue) => {
  try {
    return await apiClient.post('/update-initial-setup-step', {
      navigate: buttonValue
    });
  } catch(e) {
    toastr.error(e)
  }
}

export const onNavigationButtonsClick = async (
  stepValue,
  callback
) => {
  const stepData = await updateInitialSetupState(stepValue);
  callback(updateSetupStep(stepData?.current_step));
}

export const finishLinksArr = (closeOnboardingModal) => [
  {
    label: 'View Dashboard',
    to: '/admin/overview',
    className: 'button button-default',
    action: closeOnboardingModal,
  },
  {
    label: 'View Test Results',
    to: '/admin/tests',
    className: 'button button-default',
    action: closeOnboardingModal,
  },
  {
    label: 'Manage users',
    to: '/admin/users',
    className: 'button button-default',
    action: closeOnboardingModal,
  },
  {
    label: 'Customize Extension',
    to: '/admin/extension-settings',
    className: 'button button-default',
    action: closeOnboardingModal,
  },
]