import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Helmet} from 'react-helmet';
import {withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import {Element} from 'react-scroll';
import PublicHeader from '../../components/public/PublicHeader';
import PublicFooter from '../../components/public/PublicFooter';
import FeedbackForm from '../../components/public/FeedbackForm';
import TestResultBlock from '../../components/public/TestResult';
import SurveyForm from '../../components/public/SurveyForm';
import AhoyTrack from '../../modules/AhoyTrack';

class TestResult extends Component {
  constructor(props) {
    AhoyTrack();
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const {location: {state: webTestResult}, history} = this.props;
    localStorage.setItem('isTestRunning', 0);

    if (!webTestResult) {
      history.push('/testing');
    }
  }

  render() {
    const {welcome_header, help_header, help_sub_header, articles, location: {state: webTestResult}} = this.props;
    const sortedArticles = articles.sort((a, b) => ((a.article_order > b.article_order) ? 1 : -1));

    return (
      <>
        <Helmet>
          <title>{welcome_header}</title>
        </Helmet>

        <PublicHeader customClass="result" />

        <div className="page-result">

          {webTestResult && <TestResultBlock webTestResult={{...webTestResult}} />}
          <div className="articles">
            <div className="container">
              <div className="row">
                {
                  sortedArticles.map(({article_header, article_sub_header, article_link, img}, index) => {
                    if (!article_header || !article_sub_header || !article_link || !img) {
                      return '';
                    }

                    return (
                      <a
                        href={article_link}
                        className="col-sm-6 col-12 articles__item"
                        key={`test-result-articles-${index}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img className="articles__img" src={img} alt="" />
                        <p className="articles__heading">{article_header}</p>
                        <p className="articles__text">{article_sub_header}</p>
                      </a>
                    );
                  })
                }
              </div>
            </div>
          </div>
          <Element name="help-section">
            <div id="help-section" className="help-form center">
              <div className="container" style={{maxWidth: '900px'}}>
                <div className="row">
                  <p className="col-12 heading">{help_header}</p>
                  <p className="col-12 subheading">{help_sub_header}</p>
                  <SurveyForm />
                </div>

                <FeedbackForm />
              </div>
            </div>
          </Element>
        </div>
        <PublicFooter />
      </>
    );
  }
}

TestResult.defaultProps = {
  welcome_header: '',
  help_header: '',
  help_sub_header: '',
  articles: [
    {
      article_header: '',
      article_sub_header: '',
      article_link: '',
      img: '',
      article_order: 0,
    },
  ],
};

TestResult.propTypes = {
  welcome_header: PropTypes.string,
  help_header: PropTypes.string,
  help_sub_header: PropTypes.string,
  articles: PropTypes.arrayOf(PropTypes.shape({
    article_header: PropTypes.string,
    article_sub_header: PropTypes.string,
    article_link: PropTypes.string,
    img: PropTypes.string,
    article_order: PropTypes.number,
  })),
};

const mapStateToProps = (state) => {
  const {step_1, step_3} = state.Company.companyDetails;
  const stepData = {};
  stepData.welcome_header = step_1?.welcome_header;

  if (step_3) {
    stepData.help_header = step_3.help_header;
    stepData.help_sub_header = step_3.help_sub_header;
    stepData.articles = [];

    for (let i = 1; i <= 4; i += 1) {
      stepData.articles.push({
        article_header: step_3[`article_${i}`].article_header || '',
        article_sub_header: step_3[`article_${i}`].article_sub_header || '',
        article_link: step_3[`article_${i}`].article_link || '',
        article_order: step_3[`article_${i}`].article_order || 0,
        img: step_3[`article_${i}`].article_img.url || '',
      });
    }
  }

  return {...stepData};
};

const mapDispatchToProps = {};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TestResult));
