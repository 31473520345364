import React from 'react';
// import { Col, Row } from 'reactstrap';

const DevicePerformanceBlock = ({title, subtitle, children}) => {
  // const renderCol = arr.map(item => {
  //   return (
  //     <Col xs="12" sm="6" md="3" className={item.customClass}>
  //       <div className="device-info-item">
  //         <div className="device-info-name">{item.name}</div>
  //         <div className="device-info-value">{item.value}</div>
  //       </div>
  //     </Col>
  //   )
  // })
  return (
    <div className="tab-block">
      <div className="tab-block-title">{title}</div>
      <div className="tab-block-subtitle">{subtitle}</div>
      {children}
      {/* <div className="device-info">
        <Row>
        </Row>
      </div> */}
    </div>
  );
};

export default DevicePerformanceBlock;
