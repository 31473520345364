import React, {useState} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormGroup, Input, Label} from 'reactstrap';
import ExportPopup from './exportPopup/ExportPopup';
import Popup from './popup/Popup';
import Button from './Button/Button';
import apiClient from '../gears/apiClient';

const rangeOptions = ['7d', '15d', '30d'];

const TableDateExport = ({className, userId}) => {
  const [range, setRange] = useState('7d');
  const [type] = useState('csv');
  const [ popup, setPopup ] = useState(false);

  const handleRangeChange = (e) => {
    setRange(e.target.value);
  };

  const isPopupOpen = () => {
    setPopup(!popup);
    // eslint-disable-next-line no-unused-expressions
    popup ? document.body.style.overflow = '' : document.body.style.overflow = 'hidden';
  };

  // eslint-disable-next-line consistent-return
  const exportData = async (email) => {
    const qParams = {type, range, email};

    if (userId) {
      qParams.user_id = userId;
    }
    await apiClient.get('/activity-export', qParams);
  };

  return (
    <>
      <div className={classNames(`table-data-export ${className}`)}>
        <div className="range-options">
          <div className="font-size-18 font-weight-bold title">Range:</div>
          {
            rangeOptions.map((rangeOption, index) => (
              <FormGroup check inline key={index}>
                <Label
                  check
                  className={classNames({
                  checked: range === rangeOption,
                })}
                >
                  <Input
                    className="d-none"
                    type="radio"
                    name="range"
                    value={rangeOption}
                    checked={range === rangeOption}
                    onChange={handleRangeChange}
                  />
                  {rangeOption}
                </Label>
              </FormGroup>
            ))
          }
        </div>
        <Button
          generalType="default"
          onClick={isPopupOpen}
        >
          Export
        </Button>
      </div>
      {popup && (
        <Popup
          customClass="export"
          close={isPopupOpen}
        >
          <ExportPopup
            onSubmit={exportData}
            close={isPopupOpen}
          />
        </Popup>
      )}
    </>
  );
};

TableDateExport.defaultProps = {
  className: '',
};

TableDateExport.propTypes = {
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  userId: PropTypes.number,
};

export default TableDateExport;
