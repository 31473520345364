import React, { useState } from 'react';
import { Button, Form, Input } from 'reactstrap';
// import AhoyTrack from '../modules/AhoyTrack';

const ApplicationAddCard = ({ addApp, inputsData, btnText, isApp = true}) => {
  const [card, setCard] = useState({});
  const [isValidUrl, setIsValidUrl] = useState(null);

  const onInputchange = (e) => {
    if (e.target.type === 'url') {
      const isUrlValid = (e.target.value).match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g);
      const isIpValid = (e.target.value).match(/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/);
      if (isUrlValid) {
        setIsValidUrl(isUrlValid);
      } else {
        setIsValidUrl(isIpValid);
      }
    } else {
      setIsValidUrl(true);
    }
    setCard({
      ...card,
      [e.target.name]: e.target.value,
    });
  };
  const onAddApp = async () => {
    // AhoyTrack('button');
    addApp({...card });
    setCard({
      [inputsData[0].input_name]: '',
      [inputsData[1].input_name]: '',
    });
  };

  const isDisabled = isApp
    ? !(card?.[inputsData?.[1].input_name] && card?.[inputsData?.[0].input_name] && isValidUrl)
    : !(card.name && isValidUrl);

  return (
    <>
      <div className="application-cards-col">
        <Form>
          <div className="application-cards-item card card_custom">
            <div className="card-info">
              <div className="card-name">
                <Input
                  name={inputsData?.[0].input_name}
                  id="applicationName"
                  placeholder={inputsData?.[0].placeholder}
                  type={inputsData?.[0].type}
                  onChange={onInputchange}
                  value={card?.[inputsData?.[0].input_name] || ''}
                />
              </div>
              <div className="card-url">
                <Input
                  name={inputsData?.[1].input_name}
                  id="applicationUrl"
                  placeholder={inputsData?.[1].placeholder}
                  type={inputsData?.[1].type}
                  onChange={onInputchange}
                  value={card?.[inputsData?.[1].input_name] || ''}
                />
              </div>
            </div>
            <div className="card-value">
              <div className="card-add">
                <Button
                  outline
                  type="button"
                  color="primary"
                  onClick={onAddApp}
                  disabled={isDisabled}
                >
                  {btnText}
                </Button>
              </div>
            </div>
            <div className="card-actions" />
          </div>
        </Form>
      </div>
    </>
  );
};

export default ApplicationAddCard;
