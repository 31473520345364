import { toastr } from "react-redux-toastr";
import { Button } from "reactstrap";
import moment from 'moment';
import apiClient from "../../gears/apiClient"
import { ACCESSOR, HEADERS } from "../tables/Issues/Issues-columns.helper";

export const getIssuesByCategory = async (data) => {
  try {
    const response = await apiClient.get('/disruption-logs', data);
    return response;  
  } catch(error) {
    toastr.error(error?.message);
    return null;
  }
}

export const networkIssuesSearchByColumnOptions = (type = '') =>  {
  const columns = [
    {
      value: 'all',
      label: 'All',
      type: 'single_select',
    },
    {
      value: 'active',
      label: 'Active',
      type: 'single_select',
    },
    {
      value: 'new',
      label: 'New',
      type: 'single_select',
    },
  ];

  switch(type) {
    case 'reported':
      columns.push({
        value: 'completed',
        label: 'Completed',
        type: 'single_select'
      });
      break;
    default:
      break;
  }
  return columns;
};

const reportedIssuesTableColumns = [
  {
    Header: HEADERS.GUID,
    accessor: ACCESSOR.GUID,
  },
  {
    Header: HEADERS.TIMESTAMP,
    accessor: ACCESSOR.CREATED_AT,
  },
  {
    Header: HEADERS.DESCRIPTION,
    accessor: ACCESSOR.DESCRIPTION,
  },
  {
    Header: HEADERS.STATUS,
    accessor: ACCESSOR.STATUS,
  },
];

export const issuesTableColumns = (onDrawerOpen, type = '') => {
  const columns = [
    {
      Header: HEADERS.EVENT_TYPE,
      accessor: ACCESSOR.TYPE,
      Cell: ({ row: { original }}) => {
        if (original.type.toLowerCase() === 'high packet loss') {
          return (
            <Button color="link" className="p-0" onClick={() => onDrawerOpen(original.test_id)}>
              <span style={{textTransform: 'capitalize', textDecoration: 'underline'}}>{original.issue_type}</span>
            </Button>
          );
        }
        return <span style={{textTransform: 'capitalize'}}>{original.type}</span>;
      },
    },
    {
      Header: HEADERS.SEVERITY,
      accessor: ACCESSOR.SEVERITY,
    },
    {
      Header: HEADERS.STATUS,
      accessor: ACCESSOR.STATUS,
    },
    {
      Header: HEADERS.START_TIME,
      accessor: ACCESSOR.START,
      Cell: ({ row: { original }}) => (
        original.start|| 'N/A'
      ),
    },
    {
      Header: HEADERS.END_TIME,
      accessor: ACCESSOR.END,
      Cell: ({ row: { original }}) => (
        original.end || 'N/A'
      ),
    },
    {
      Header: HEADERS.DURATION,
      accessor: ACCESSOR.DURATION,
    },
    {
      Header: HEADERS.ISP,
      accessor: ACCESSOR.ISP,
      Cell: ({ row: { original }}) => (
        original?.location?.isp || 'N/A'
      ),
    },
    {
      Header: HEADERS.GUID,
      accessor: ACCESSOR.GUID,
    },
  ];

  switch(type) {
    case 'reported':
      return reportedIssuesTableColumns;
    case 'helpdeskRequest':
      return reportedIssuesTableColumns.filter(column => column.accessor !== 'status');
    default:
      break
  }
  return columns;
};