import { io } from "socket.io-client";

class WebSockeService {
  static instance;
  _socket = io(process.env.REACT_APP_WS_URL || 'https://ws.hubbleiq.com', {
    path: "/api/ws/",
    transports: ['websocket', 'polling'],
  });

  constructor() {
    if (!WebSockeService.instance) {
      this.init();
      WebSockeService.instance = this;
    }
    return WebSockeService.instance;
  }

  reconnect() {
    setTimeout(() => {
      this._socket.connect();
    }, 2000);
  }

  init() {
    if (!this._socket) {
      return;
    }
    this._socket.on("connect_error", () => {
      this.reconnect();
      this._socket.io.opts.transports = ['polling', 'websocket'];
    });
    this._socket.on('connect', () => {
      console.log('Connected');
      this._socket.emit('events', { test: 'test' });
    });

    this._socket.on('disconnect', function () {
      console.log('Disconnected');
    });

    this._socket.on('error', (error) => {
      // Sentry.captureException(error);
      this.reconnect();
    });
  }

  subscribeCompanyEvent(companyName, eventName, cb) {
    if (companyName) {
      this._socket.on(`company::${companyName}::${eventName}`, cb);
    } else {
      console.log('No company name provided');
    }
  }

  subscribeAdminEvent(guid, eventName, cb) {
    if (guid) {
      this._socket.on(`admin::${guid}::${eventName}`, cb);
    } else {
      console.log('No guid provided');
    }
  }

}

const webSockeService = new WebSockeService();

export default webSockeService;
