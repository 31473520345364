import React from 'react';
import PropTypes from 'prop-types';

const UserQuestions = ({qa}) => {
  if (qa.length <= 0) {
    return '';
  }

  return (
    <div>
      <span className="dashboard-label">User Inputs</span>
      <div className="row user-questions-box">
        <div className="col  d-flex flex-row flex-wrap">
          {
            qa.map(({question, answer}, index) => (
              <div className="d-flex flex-column question-block justify-between" key={`qa-block-${index}`}>
                <p className="question-text">{question}</p>
                <p className="question-answer">{answer}</p>
              </div>
            ))
          }
        </div>
      </div>
    </div>
  );
};

UserQuestions.propTypes = {
  qa: PropTypes.arrayOf(PropTypes.shape({
    question: PropTypes.string,
    answer: PropTypes.string,
  })),
};

export default UserQuestions;
