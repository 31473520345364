import { all, fork, put, takeEvery } from 'redux-saga/effects';
import apiClient from "../../gears/apiClient";
import { fetchNotificationsError, fetchNotificationsSuccess, markNotificationsAsReadError, markNotificationsAsReadSuccess } from "./actions";
import { FETCH_NOTIFICATIONS, MARK_NOTIFICATIONS_AS_READ } from "./actionTypes";

function* fetchNotifications({ payload: { data } }) {
  try {
    const response = yield apiClient.get('/my-notifications', data);
    yield put(fetchNotificationsSuccess(response.notifications));
  } catch (error) {
    yield put(fetchNotificationsError(error));
  }
}

function* markNotificationsAsRead({ payload: { data } }) {
  try {
    yield apiClient.post('/mark-notifications-read', data);
    yield put(markNotificationsAsReadSuccess(data));
  } catch (error) {
    yield put(markNotificationsAsReadError(error));
  }
}

export function* watchNotificationsFetch() {
  yield takeEvery(FETCH_NOTIFICATIONS, fetchNotifications);
}

export function* watchMarkNotificationsAsRead() {
  yield takeEvery(MARK_NOTIFICATIONS_AS_READ, markNotificationsAsRead);
}

function* NotificationsSaga() {
  yield all([
    fork(watchNotificationsFetch),
    fork(watchMarkNotificationsAsRead),
  ]);
}

export default NotificationsSaga;