import {
  FETCH_COMPANY_DETAILS, FETCH_COMPANY_DETAILS_SUCCESS, FETCH_COMPANY_DETAILS_API_ERROR,
  FETCH_COMPANY_LOGO, FETCH_COMPANY_LOGO_SUCCESS, FETCH_COMPANY_LOGO_API_ERROR,
  FETCH_USER_IP, FETCH_USER_IP_SUCCESS, FETCH_USER_IP_ERROR,
  FETCH_COMPANY_DETAILS_WEB_TEST_STATUS,
  UPDATE_COMPANY_LOGO, UPDATE_COMPANY_LOGO_SUCCESS, UPDATE_COMPANY_LOGO_ERROR,
  GET_LOGO, GET_LOGO_SUCCESS, GET_LOGO_ERROR,
  CANCEL_COMPANY_SUBSCRIPTION,
  CANCEL_COMPANY_SUBSCRIPTION_SUCCESS,
  CANCEL_COMPANY_SUBSCRIPTION_ERROR,
  UPDATE_COMPANY_SUBSCRIPTION,
} from './actionTypes';

const initialState = {
  error: null,
  companyDetailsLoading: true,
  loading: false,
  companyDetails: {},
  companyLogo: null,
  companyName: null,
  isWebTestEnabled: true,
  currentLogo: null,
  trial: null,
  trial_expiration: null,
};

const fetchCompanyData = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_COMPANY_DETAILS:
      return {
        ...state,
        data: action.data,
        history: action.history,
        companyDetailsLoading: true,
      };
    case FETCH_COMPANY_DETAILS_SUCCESS:
      return {
        ...state,
        companyDetails: action.payload,
        companyDetailsLoading: false,
      };
    case FETCH_COMPANY_DETAILS_API_ERROR:
      return { ...state, error: action.payload, companyDetailsLoading: false };
    case FETCH_COMPANY_DETAILS_WEB_TEST_STATUS:
      return { ...state, isWebTestEnabled: action.payload, companyDetailsLoading: false };
    case FETCH_COMPANY_LOGO:
      return {
        ...state,
        data: action.data,
        history: action.history,
        loading: true,
      };
    case FETCH_COMPANY_LOGO_SUCCESS:
      return {
        ...state,
        companyLogo: action.payload.company_logo,
        companyName: action.payload.name,
        trial: action.payload.trial,
        trial_expiration: action.payload.trial_expiration,
        loading: false,
        isFreePlan: action.payload.is_free_plan,
        isActivePlan: action.payload.is_active_plan,
        isCustomPlan: action.payload.is_custom_plan,
        ownerSetupStep: action.payload.owner_setup_step
      };
    case FETCH_COMPANY_LOGO_API_ERROR:
      return { ...state, error: action.payload, loading: false };
    // Logo new url
    case GET_LOGO:
      return {
        ...state,
      };
    case GET_LOGO_SUCCESS:
      return {
        ...state,
        companyLogo: action.payload.logo,
      };
    case GET_LOGO_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    // End Logo
    case UPDATE_COMPANY_LOGO:
      return {
        ...state,
        data: action.data,
        history: action.history,
        loading: true,
      };
    case UPDATE_COMPANY_LOGO_SUCCESS:
      return {
        ...state,
        companyLogo: action.payload.logo,
        loading: false,
      };
    case UPDATE_COMPANY_LOGO_ERROR:
      return { ...state, error: action.payload, loading: false };
    case FETCH_USER_IP:
      return {
        ...state,
        loading: true,
      };
    case FETCH_USER_IP_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case FETCH_USER_IP_ERROR:
      return { ...state, error: action.payload, loading: false };
    case CANCEL_COMPANY_SUBSCRIPTION:
      return {
        ...state,
        cancelSubscriptionLoading: true,
        cancelSubscriptionError: false,
      };
    case CANCEL_COMPANY_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        cancelSubscriptionLoading: false,
        cancelSubscriptionError: false,
        isFreePlan: true,
        isActivePlan: false,
      };
    case CANCEL_COMPANY_SUBSCRIPTION_ERROR:
      return {
        ...state,
        cancelSubscriptionLoading: false,
        cancelSubscriptionError: true,
      };
    case UPDATE_COMPANY_SUBSCRIPTION:
    return {
      ...state,
      error: false,
      loading: false,
      isFreePlan: false,
      isActivePlan: true,
    };
    default:
      return state;
  }
};

export default fetchCompanyData;
