import { takeEvery, fork, put, all } from 'redux-saga/effects';

// Login Redux States
import { HELP_REQUEST } from './actionTypes';
import { helpRequestSuccess, helpRequestApiError } from './actions';

import apiClient from '../../gears/apiClient';

function* helpRequest({ payload: {data} }) {
  try {
    const response = yield apiClient.post('new_help_request', data);
    yield put(helpRequestSuccess(response));
  } catch (error) {
    yield put(helpRequestApiError(error));
  }
}

export function* watchHelpRequest() {
  yield takeEvery(HELP_REQUEST, helpRequest);
}

function* helpRequestSaga() {
  yield all([fork(watchHelpRequest)]);
}

export default helpRequestSaga;
