import { ReactComponent as ClockIcon } from "../../../images/metric-card-icons/clock.svg";
import { ReactComponent as UserIcon } from "../../../images/metric-card-icons/user.svg";
import { ReactComponent as AlertTriangleIcon } from "../../../images/metric-card-icons/alert-triangle.svg";
import { ReactComponent as UserExclamationIcon } from "../../../images/metric-card-icons/user-exclamation.svg";

export const getMetricCardArray = (data, activeUser, history) => [
	{
		iconComponent: UserIcon,
		title: 'Active Users',
		value: activeUser,
		onClick: () => history.push("/admin/users"),
	},
	{
		iconComponent: ClockIcon,
		title: 'Hours Saved',
		value: data?.hoursSaved,
	},
	{
		iconComponent: AlertTriangleIcon,
		title: 'Total Issues',
		value: data?.issueCount,
		onClick: () => history.push("/admin/issues?tab=network"),
	},
	{
		iconComponent: UserExclamationIcon,
		title: 'User Reported Issues',
		value: data?.usersWithIssues,
		onClick: () => history.push("/admin/issues?tab=reported"),
	}
];
