import {
	FETCH_AI_CHAT_HISTORY,
	FETCH_AI_CHAT_HISTORY_SUCCESS,
	FETCH_AI_CHAT_HISTORY_ERROR,
	FETCH_AI_CHAT_SEND_MSG,
	FETCH_AI_CHAT_SEND_MSG_SUCCESS,
	FETCH_AI_CHAT_SEND_MSG_ERROR,
} from "./actionTypes";

export const fetchAIChatHistory = (id, msg) => ({
	type: FETCH_AI_CHAT_HISTORY,
	payload: { id, msg },
});

export const fetchAIChatHistorySuccess = (payload) => ({
	type: FETCH_AI_CHAT_HISTORY_SUCCESS,
	payload,
});

export const fetchAIChatHistoryError = (error) => ({
	type: FETCH_AI_CHAT_HISTORY_ERROR,
	payload: error,
});

export const fetchAIChatSendMsg = (id, msg) => ({
	type: FETCH_AI_CHAT_SEND_MSG,
	payload: { id, msg },
});

export const fetchAIChatSendMsgSuccess = (payload) => ({
	type: FETCH_AI_CHAT_SEND_MSG_SUCCESS,
	payload,
});

export const fetchAIChatSendMsgError = (error) => ({
	type: FETCH_AI_CHAT_SEND_MSG_ERROR,
	payload: error,
});
