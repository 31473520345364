import {
	FETCH_AI_CHAT_HISTORY,
	FETCH_AI_CHAT_HISTORY_SUCCESS,
	FETCH_AI_CHAT_HISTORY_ERROR,
	FETCH_AI_CHAT_SEND_MSG,
	FETCH_AI_CHAT_SEND_MSG_SUCCESS,
	FETCH_AI_CHAT_SEND_MSG_ERROR,
} from "./actionTypes";

const initialState = {
	data: {},
	isLoading: false,
	sendMsgLoading: false,
	sendMsgError: null,
	error: null,
};

const IssuesAIChat = (state = initialState, action) => {
	switch (action.type) {
		case FETCH_AI_CHAT_HISTORY:
			return {
				...state,
				isLoading: true,
			};
		case FETCH_AI_CHAT_HISTORY_SUCCESS:
			return {
				...state,
				data: action.payload,
				isLoading: false,
			};
		case FETCH_AI_CHAT_HISTORY_ERROR:
			return {
				...state,
				error: action.payload,
				isLoading: false,
			};
		case FETCH_AI_CHAT_SEND_MSG:
			return {
				...state,
				sendMsgLoading: true,
			};
		case FETCH_AI_CHAT_SEND_MSG_SUCCESS:
			return {
				...state,
				data:  [...state.data, ...action.payload],
				sendMsgLoading: false,
			};
		case FETCH_AI_CHAT_SEND_MSG_ERROR:
			return {
				...state,
				sendMsgError: action.payload,
				sendMsgLoading: false,
			};
		default:
			return { ...state };
	}
};

export default IssuesAIChat;
