import React from 'react';
import {Doughnut} from 'react-chartjs-2';
import styles from './doughnut-chart.module.scss';

function DoughnutChart({ values }) {
	const { issueCount, selfResolvedIssues } = values;
	const data = {
		labels: ['Issues Resolved by Self-Service', 'All Issues'],
		datasets: [{
			data: [selfResolvedIssues, issueCount],
			backgroundColor: ['#4F46E5','#F0EFFF'],
			hoverBackgroundColor: ['#4F46E5', '#F0EFFF'],
			borderColor: 'transparent',
		}]
	};
	
	const options = {
		circumference: Math.PI,
		rotation : Math.PI,
		cutoutPercentage : 55,
		responsive: true,
		legend: {
			display: false,
		},
		tooltips: {
			enabled: true,
		}
	};
	
	return (
		<div className={styles.chartWrapper}>
			<div className={styles.labels}>
				<div className={styles.label}>
					<div className={styles.round} style={{backgroundColor: data.datasets[0].backgroundColor[1]}} />
					All Issues
				</div>
				<div className={styles.label}>
					<div className={styles.round} style={{backgroundColor: data.datasets[0].backgroundColor[0]}} />
					Issues Resolved by Self-Service
				</div>
			</div>
			<div className={styles.chart}>
				<Doughnut data={data} options={options} />
			</div>
		</div>
	);
}

export default DoughnutChart;
