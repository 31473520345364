import React from 'react';

const ToggleInviteModal = React.createContext();

const InviteModalProvider = ToggleInviteModal.Provider;
const InviteModalConsumer = ToggleInviteModal.Consumer;

export {
  InviteModalProvider,
  InviteModalConsumer,
};
