import React from 'react';
import { Spinner } from 'reactstrap';

const ComponentLoader = ({customClass = ''}) => {
  return (
    <div className={`spinner-wrapper ${customClass}`}><Spinner color="primary" /></div>
  );
};

export default ComponentLoader;
