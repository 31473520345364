// import ahoy from 'ahoy.js'

const env = process.env.NODE_ENV

const AhoyTrack = (tag) => {
	// if (env != 'pre_production' || env != 'production') {
	// 	if (tag == null) {
	// 		ahoy.trackView();
	// 	} else {
	// 		ahoy.trackChanges(tag);
	// 		ahoy.trackClicks(tag);
	// 	}
	// }
}

export default AhoyTrack
