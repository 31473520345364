import { FETCH_TEST_DETAILS, FETCH_TEST_DETAILS_SUCCESS, FETCH_TEST_DETAILS_API_ERROR, FETCH_TEST_DETAILS_ACTIVITY, FETCH_TEST_DETAILS_ACTIVITY_SUCCESS, FETCH_TEST_DETAILS_ACTIVITY_ERROR, FETCH_TEST_DETAILS_AVERAGES, FETCH_TEST_DETAILS_AVERAGES_SUCCESS, FETCH_TEST_DETAILS_AVERAGES_ERROR } from './actionTypes';

export const fetchTestDetails = (id, history) => ({
  type: FETCH_TEST_DETAILS,
  payload: { id, history },
});

export const fetchTestDetailsSuccess = (payload) => ({
  type: FETCH_TEST_DETAILS_SUCCESS,
  payload,
});

export const fetchTestDetailsApiError = (error) => ({
  type: FETCH_TEST_DETAILS_API_ERROR,
  payload: error,
});

export const fetchTestDetailsActivity = (id) => ({
  type: FETCH_TEST_DETAILS_ACTIVITY,
  payload: { id },
});

export const fetchTestDetailsActivitySuccess = (payload) => ({
  type: FETCH_TEST_DETAILS_ACTIVITY_SUCCESS,
  payload,
});

export const fetchTestDetailsActivityError = (error) => ({
  type: FETCH_TEST_DETAILS_ACTIVITY_ERROR,
  payload: error,
});

export const fetchTestDetailsAverages = (id) => ({
  type: FETCH_TEST_DETAILS_AVERAGES,
  payload: { id },
});

export const fetchTestDetailsAveragesSuccess = (payload) => ({
  type: FETCH_TEST_DETAILS_AVERAGES_SUCCESS,
  payload,
});

export const fetchTestDetailsAveragesError = (error) => ({
  type: FETCH_TEST_DETAILS_AVERAGES_ERROR,
  payload: error,
});
