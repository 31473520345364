import React, {useState} from 'react';
import ProgressBar from "@ramonak/react-progress-bar";
import {Tooltip} from "reactstrap";
import {List} from "react-content-loader";
import styles from './subscription.module.scss';

const SubscriptionsProgressBar = ({ data, loading }) => {
	const [isToolTipOpen, setIsTooltipOpen] = useState(false);
	const lessThanFifteen = data?.used_percentage < 15;

	return (
		<div className={styles.subscriptionValues}>
			<h4 className={styles.title}>Event statistics</h4>
			<div className={styles.valuesWrapper}>
				<div className={styles.progressBarWrapper}>
					{!loading ?
						<>
							<p>Max Events: <span>{data?.max_events}</span></p>
							<div id="progressBar">
								<ProgressBar
									completed={data?.used_percentage}
									bgColor="#4F46E5"
									animateOnRender={true}
									className={styles.progressBar}
									barContainerClassName={lessThanFifteen ? styles.progressBarContainer : ''}
									labelClassName={lessThanFifteen ? styles.progressBarLabel : ''}
								/>
								<span className="card-tooltip">
				          <Tooltip
					          placement="top"
					          isOpen={isToolTipOpen}
					          target="progressBar"
					          toggle={() => setIsTooltipOpen((prevState) => !prevState)}>
				            {`Available Events: ${data?.available_events}`}
				          </Tooltip>
				        </span>
							</div>
						</> :
						<List height={45} viewBox="0 -10 200 45" />
					}
				</div>
			</div>
		</div>
	);
};

export default SubscriptionsProgressBar;
