import { FETCH_USER_ACTIVITY_ANALYTICS, FETCH_USER_ACTIVITY_ANALYTICS_SUCCESS, FETCH_USER_ACTIVITY_ANALYTICS_API_ERROR } from './actionTypes';

export const fetchUserActivityAnalytics = (id) => {
  return {
    type: FETCH_USER_ACTIVITY_ANALYTICS,
    payload: { id },
  };
};

export const fetchUserActivityAnalyticsSuccess = (payload) => {
  return {
    type: FETCH_USER_ACTIVITY_ANALYTICS_SUCCESS,
    payload,
  };
};

export const fetchUserActivityAnalyticsApiError = (error) => {
  return {
    type: FETCH_USER_ACTIVITY_ANALYTICS_API_ERROR,
    payload: error,
  };
};
