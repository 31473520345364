import React from 'react';
import { Drawer } from "@material-ui/core";
import FeaturedIssuesBlock from "../../overview/FeaturedIssues/FeaturedIssuesBlock";

const FeaturesIssuesDrawer = ({isOpen, onHandlerClose}) => {
	return (
		<Drawer
			anchor="right"
			open={isOpen}
			onClose={onHandlerClose}
		>
			<FeaturedIssuesBlock closeDrawer={onHandlerClose} />
		</Drawer>
	);
};

export default FeaturesIssuesDrawer;
