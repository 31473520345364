import { HEADERS } from "../tables/Issues/Issues-columns.helper";

export const logsReportedTableColumns = [
  {
    Header: HEADERS.GUID,
    accessor: 'guid',
  },
  {
    Header: HEADERS.TIMESTAMP,
    accessor: 'createdAt',
  },
  {
    Header: HEADERS.DESCRIPTION,
    accessor: 'description',
  }
];
