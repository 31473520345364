import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import ApplicationAddCard from '../../components/ApplicationAddCard';
import ApplicationEditCard from '../../components/ApplicationEditCard';
import { getApplications, addApplication, deleteApplication, updateApplication } from '../../store/application/actions';
import AhoyTrack from '../../modules/AhoyTrack';

const ApplicationsSettings = ({
  data,
  dispatchGetApplications,
  dispatchAddApplication,
  dispatchDeleteApplication,
  dispatchUpdateApplication,
}) => {
  // const [ status, setStatus ] = useState(null);
  const [applications, setApplications] = useState(data);

  AhoyTrack();
  AhoyTrack('button');

  useEffect(() => {
    dispatchGetApplications();
}, []);

  useEffect(() => {
      setApplications(data);
  }, [data]);

  const addApp = (obj) => {
    dispatchAddApplication({
      name: obj.name,
      url: obj.url,
    });
  };

  const deleteApp = (id) => {
    dispatchDeleteApplication({id});
    const newApplications = [...applications].filter((app) => app.id !== id);
    setApplications(newApplications);
  };

  const saveApp = (data) => {
    dispatchUpdateApplication(data);
  };

  const fetchAppStatus = async (url) => {
    return fetch(url, {mode: 'no-cors'})
    .then(() => {
      return 'good';
      // setStatus('good');
    })
    .catch(() => {
      return 'poor';
      // setStatus('poor');
    });
  };

  const inputsData = [
    {
      name: '',
      input_name: 'name',
      placeholder: 'Application Name',
      type: 'text',
    },
    {
      name: '',
      input_name: 'url',
      placeholder: 'Input URL or IP Address',
      type: 'url',
    },
  ];

  return (
    <div className="container-fluid content app-settings">
      <Row className="align-items-center page-title">
        <Col sm={6}>
          <div className="page-title-box">
            <h2 className="app-settings-title">Application Settings</h2>
          </div>
        </Col>
      </Row>
      <div className="app-settings-content page-block">
        <div className="page-block-title">
          <h4>Application Performance & Availability Configuration</h4>
        </div>
        <div className="page-block-subtitle subtitle app-settings-content-text">
          By configuring the list of applications below, HubbleIQ will test the reachability and individual performance of your applications
          from each individual user.This will help you pinpoint widespread or user centric outages and poor performance.
        </div>
        <div className="page-block">
          <div className="page-block-title app-settings-content-critical-title">Business critical applications</div>
          <div className="application-cards">
            <div className="application-cards-list">
              {applications.length > 0 && (
              applications?.slice(0, 6)?.map((app) => (
                <ApplicationEditCard
                  app={app}
                  deleteApp={deleteApp}
                  key={app.id}
                  fetchAppStatus={fetchAppStatus}
                  addApp={addApp}
                  saveApp={saveApp}
                />
              ))
            )}
              {applications?.length <= 6 && (
              <ApplicationAddCard
                addApp={addApp}
                fetchAppStatus={fetchAppStatus}
                inputsData={inputsData}
                btnText="Add Application"
              />
            )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { data } = state.Applications;
  return { data };
};

const mapDispatchToProps = {
  dispatchGetApplications: getApplications,
  dispatchAddApplication: addApplication,
  dispatchDeleteApplication: deleteApplication,
  dispatchUpdateApplication: updateApplication,
};

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationsSettings);
