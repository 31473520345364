import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import AthertonfiberHeader from './athertonfiber-support/Header';
import NfoHeader from './nfo-support/Header';

const CompanyHeader = ({companyName}) => {
  if (companyName === 'nfo-support') {
    return (<NfoHeader />);
  } if (companyName === 'athertonfiber-support') {
    return (<AthertonfiberHeader />);
  }
  return '';
};

CompanyHeader.defaultProps = {
  companyName: null,
};

CompanyHeader.propTypes = {
  companyName: PropTypes.string,
};

const mapStateToProps = (state) => {
  const {companyName} = state.Company;

  return {companyName};
};

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CompanyHeader);
