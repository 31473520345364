import React, { useEffect, useState } from 'react';
import { Step, StepLabel, Stepper } from '@material-ui/core';
import { Button, Spinner } from 'reactstrap';
import { onBoardingSteps, onNavigationButtonsClick } from './onBoardingHelper';
import { useDispatch, useSelector } from 'react-redux';

const Onboarding = ({ closeOnboardingModal, loading }) => {
  const [ isNextButtonVisible, setIsNextButtonVisible ] = useState(true);
  const [ localStep, setLocalStep ] = useState(null);
  const { data } = useSelector((state) => state.Profile);
  const currentStep = data?.initial_setup_step;
  const steps = onBoardingSteps(closeOnboardingModal);
  const dispatch = useDispatch();

  const onNavigationButtonsHandler = async (stepValue) => (
    onNavigationButtonsClick(
      stepValue,
      dispatch
    )
  )

  useEffect(() => {
    if(currentStep) {
      setLocalStep(currentStep)
      setIsNextButtonVisible(currentStep !== 5)
    }
  }, [currentStep])

  const isBackButtonVisible = localStep !== 1 && localStep < 6

  return (
    <div className="onboarding">
      <div className="onboarding-stepper">
        {
        loading
          ? <Spinner />
          : (
            <Stepper activeStep={localStep - 1} alternativeLabel>
              {steps.map(({label}) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          )
        }
      </div>
      <div className="onboarding-content">
        <div className="onboarding-step">
          {steps[localStep - 1]?.component}
        </div>
      </div>
      <div className="onboarding-actions">
        {isBackButtonVisible && (
          <Button
            color="link btn-back"
            onClick={() => onNavigationButtonsHandler('previous')}
          >
            <i className="mdi mdi-arrow-left font-size-20" />
            Back
          </Button>
        )}
        {(localStep !== steps.length && isNextButtonVisible) && (
          <Button color="link btn-next" onClick={() => onNavigationButtonsHandler('next')}>
            Next
            <i className="mdi mdi-arrow-right font-size-20" />
          </Button>
        )}
        {localStep === steps.length && (
          <Button color="link btn-next" onClick={closeOnboardingModal}>
            Finish
          </Button>
        )}
      </div>
    </div>
  );
};

export default Onboarding;
