import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { CircularProgress } from '@material-ui/core';
import ApplicationAddCard from '../../components/ApplicationAddCard';
import ApplicationEditCard from '../../components/ApplicationEditCard';
import { addBluetoothDevice, deleteBluetoothDevice, getBluetoothDevices, updateBluetoothDevice } from '../../store/actions';
import AhoyTrack from '../../modules/AhoyTrack';

const BluetoothSettings = ({
  data,
  isLoading,
  dispatchGetBluetoothDevices,
  dispatchAddBluetoothDevice,
  dispatchDeleteBluetoothDevice,
  dispatchUpdateBluetoothDevice,
}) => {
  const [bluetoothDevices, setBluetoothDevices] = useState(data);

  AhoyTrack();
  AhoyTrack('button');

  useEffect(() => {
    dispatchGetBluetoothDevices();
}, []);

  useEffect(() => {
    setBluetoothDevices(data);
  }, [data]);

  const addBluetoothDevice = (obj) => {
    dispatchAddBluetoothDevice({
      name: obj.name,
      uid: obj.uid,
      status: false,
    });
  };

  const deleteBluetoothDevice = (id) => {
    dispatchDeleteBluetoothDevice({id});
    const newBluetoothDevices = [...bluetoothDevices].filter((app) => app.id !== id);
    setBluetoothDevices(newBluetoothDevices);
  };

  const updateBluetoothDevice = (data) => {
    dispatchUpdateBluetoothDevice(data);
  };

  const inputsData = [
    {
      name: '',
      input_name: 'name',
      placeholder: 'Application Name',
      type: 'text',
    },
    {
      name: '',
      input_name: 'uid',
      placeholder: 'Input UID',
      type: 'text',
    },
  ];

  return (
    <div className="container-fluid content app-settings">
      <Row className="align-items-center page-title">
        <Col sm={6}>
          <div className="page-title-box">
            <h2 className="app-settings-title">Bluetooth SDK</h2>
          </div>
        </Col>
      </Row>
      <div className="app-settings-content page-block">
        <div className="page-block-title">
          <h4>Bluetooth Device Configuration</h4>
        </div>
        <div className="page-block-subtitle subtitle app-settings-content-text">
          Please update the list below to specify the Bluetooth devices to be detected and tracked for monitoring connectivity quality using the HubbleIQ SDK. The changes will be automatically updated in your application.
        </div>
        <div className="page-block">
          <div className="page-block-title app-settings-content-critical-title">Bluetooth Devices</div>
          <div className="application-cards">
            <div className="application-cards-list">
              {isLoading && (
                <div className="d-flex justify-content-center mb-4">
                  <CircularProgress size={28} />
                </div>
              )}
              {bluetoothDevices.length > 0 && (
                bluetoothDevices?.map((app) => (
                  <ApplicationEditCard
                    app={app}
                    deleteApp={deleteBluetoothDevice}
                    key={app.id}
                    addApp={addBluetoothDevice}
                    saveApp={updateBluetoothDevice}
                    isApp={false}
                  />
                ))
            )}
              <ApplicationAddCard
                addApp={addBluetoothDevice}
                inputsData={inputsData}
                btnText="Add Device"
                isApp={false}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { data, isLoading } = state.BluetoothDevices;
  return { data, isLoading };
};

const mapDispatchToProps = {
  dispatchGetBluetoothDevices: getBluetoothDevices,
  dispatchAddBluetoothDevice: addBluetoothDevice,
  dispatchDeleteBluetoothDevice: deleteBluetoothDevice,
  dispatchUpdateBluetoothDevice: updateBluetoothDevice,
};

export default connect(mapStateToProps, mapDispatchToProps)(BluetoothSettings);
