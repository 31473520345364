import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Tooltip } from '@material-ui/core';

import {mapTestResultStatus, mapStatusDescriptionToLabel} from '../../../helpers/page_helper';

const ExperienceSummary = ({ status, statusDescription, pocketLoss }) => {
  const classes = classnames({
    'status-elem': true,
    [status?.toLowerCase()]: status,
  });

  if (!status) {
    return '';
  }

  const renderWifiLan = () => {
    if (pocketLoss === null || pocketLoss === undefined) {
      return '';
    }

    const isGood = pocketLoss < 6;

    return (
      <div className="experience-summary-block">
        <div className="wifi-status">
          <div className="label">WiFi / LAN</div>
          <Tooltip
            arrow
            title={isGood ? '' : 'High packet loss may be caused by poor WiFi signal strength or WiFi congestion'}
          >
            <div className={`wifi-status-icon${isGood ? ' good' : ''}`}>
              <i className={`mdi${isGood ? ' mdi-wifi' : ' mdi-wifi-strength-4-alert'}`} />
            </div>
          </Tooltip>
        </div>
      </div>
    );
  };

  return (
    <div className="experience-summary">
      <div className="experience-summary-label">Experience Summary</div>
      <div className="experience-summary-block">
        <div className="label">Your Connection Status</div>
        <div className={classes}>{mapTestResultStatus[status]}</div>
      </div>

      {
        ['bad', 'decent'].includes(status) && (
          <div className="experience-summary-block">
            <div className="label">Description</div>
            <div className={`status-label ${status}`}>
              {mapStatusDescriptionToLabel[statusDescription]}
            </div>
          </div>
        )
      }

      { renderWifiLan() }
    </div>
  );
};

ExperienceSummary.propTypes = {
  status: PropTypes.string,
  statusDescription: PropTypes.string,
  pocketLoss: PropTypes.number,
};

export default ExperienceSummary;
