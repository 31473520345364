export const actionButtonsArray = (original, value, onModalOpen) => [
	{
		generalType: "default",
		type: "button",
		onClick: () => onModalOpen(value, 'deactivate'),
		title: `${original?.active ? 'Deactivate' : 'Reactivate'}`,
		className: "",
		icon: "mdi mdi-account-off"
	},
	{
		generalType: "default",
		type: "button",
		onClick: () => onModalOpen(value, 'edit'),
		className: "ml-2",
		title: "Edit",
		icon: "mdi mdi-pencil"
	},
];
