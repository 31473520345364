export const getIssueEventLogsTableTabs = [
	{
		title: 'Network',
		disabled: false,
		warning: false,
	},
	{
		title: 'Device',
		disabled: false,
		warning: false,
	},
	{
		title: 'Applications',
		disabled: false,
		warning: false,
	},
	{
		title: 'User Activity',
		disabled: false,
		warning: false,
	},
];

const appActivitiesHeaders = {
	NAME: 'Name',
	URL: 'Url',
	REACHABLE: 'Reachable',
	LATENCY_RESPONSE: 'Latency Response',
	AVERAGE_PAGE_LOAD_TIME: 'Average Page Load Time',
	CREATED_AT: 'Created at',
};

const userActivitiesHeaders = {
	TEST_RESULT: 'Test Result',
	IP: 'IP',
	SERVICE_PROVIDER: 'Service Provider',
	DOWNLOAD: 'Download',
	UPLOAD: 'Upload',
	STATUS_DESCRIPTION: 'Status',
	CREATED_AT: 'Created at',
};

const networkIssuesHeaders = {
	TYPE: 'Type',
	START: 'Start',
	END: 'End',
	DURATION: 'Duration',
	SERVICE_PROVIDER: 'Service Provider',
	CREATED_AT: 'Created at',
};

const deviceActivitiesHeaders = {
	CPU_UTILIZATION: 'Cpu Utilization',
	MEMORY_UTILIZATION: 'Memory Utilization',
	BATTERY_HEALTH: 'Battery Health',
	CREATED_AT: 'Created at',
};

export const deviceActivitiesTableColumns = [
	{
		Header: deviceActivitiesHeaders.CPU_UTILIZATION,
		accessor: 'cpuUtilization',
	},
	{
		Header: deviceActivitiesHeaders.MEMORY_UTILIZATION,
		accessor: 'memoryUtilization',
	},
	{
		Header: deviceActivitiesHeaders.BATTERY_HEALTH,
		accessor: 'batteryHealth',
	},
	{
		Header: deviceActivitiesHeaders.CREATED_AT,
		accessor: 'createdAt',
	},
];

export const networkIssuesTableColumns = [
	{
		Header: networkIssuesHeaders.TYPE,
		accessor: 'type',
	},
	{
		Header: networkIssuesHeaders.START,
		accessor: 'start',
	},
	{
		Header: networkIssuesHeaders.END,
		accessor: 'end',
	},
	{
		Header: networkIssuesHeaders.DURATION,
		accessor: 'duration',
	},
	{
		Header: networkIssuesHeaders.SERVICE_PROVIDER,
		accessor: 'serviceProvider',
	},
	{
		Header: networkIssuesHeaders.CREATED_AT,
		accessor: 'createdAt',
	},
];

export const appActivitiesTableColumns = [
	{
		Header: appActivitiesHeaders.NAME,
		accessor: 'name',
	},
	{
		Header: appActivitiesHeaders.URL,
		accessor: 'url',
	},
	{
		Header: appActivitiesHeaders.REACHABLE,
		accessor: 'reachable',
	},
	{
		Header: appActivitiesHeaders.LATENCY_RESPONSE,
		accessor: 'latencyResponse',
	},
	{
		Header: appActivitiesHeaders.AVERAGE_PAGE_LOAD_TIME,
		accessor: 'averagePageLoadTime',
	},
	{
		Header: appActivitiesHeaders.CREATED_AT,
		accessor: 'createdAt',
	},
];

export const userActivitiesTableColumns = [
	{
		Header: userActivitiesHeaders.TEST_RESULT,
		accessor: 'testResult',
	},
	{
		Header: userActivitiesHeaders.IP,
		accessor: 'ip',
	},
	{
		Header: userActivitiesHeaders.SERVICE_PROVIDER,
		accessor: 'serviceProvider',
	},
	{
		Header: userActivitiesHeaders.DOWNLOAD,
		accessor: 'download',
	},
	{
		Header: userActivitiesHeaders.UPLOAD,
		accessor: 'upload',
	},
	{
		Header: userActivitiesHeaders.STATUS_DESCRIPTION,
		accessor: 'statusDescription',
	},
	{
		Header: userActivitiesHeaders.CREATED_AT,
		accessor: 'createdAt',
	},
];
