export const getAppPerformanceArray = (data) => [
	{
		title: 'Availability',
		value: `${data?.availabilityPercentage?.toFixed(2)}%`,
	},
	{
		title: 'Downtime',
		value: data?.totalDowntime,
		percent: data?.downtimePercentage,
	},
	{
		title: 'Longest downtime',
		value: data?.longestDowntime,
	},
	{
		title: 'Incidents',
		value: data?.totalIncidents,
	}
]
