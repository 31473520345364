import { FETCH_USER_ISSUES_DETAILS, FETCH_USER_ISSUES_DETAILS_SUCCESS, FETCH_USER_ISSUES_DETAILS_ERROR } from "./actionTypes";

export const fetchUserIssuesDetails = (issueId) => ({
	type: FETCH_USER_ISSUES_DETAILS,
	payload: { issueId },
});

export const fetchUserIssuesDetailsSuccess = (payload) => ({
	type: FETCH_USER_ISSUES_DETAILS_SUCCESS,
	payload,
});

export const fetchUserIssuesDetailsError = (error) => ({
	type: FETCH_USER_ISSUES_DETAILS_ERROR,
	payload: error,
});
