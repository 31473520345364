export const usersTableFilters = [
  {
    value: 'email',
    label: 'Email',
  },
  {
    value: 'first_name',
    label: 'First name',
  },
  {
    value: 'last_name',
    label: 'Last name',
  },
  {
    value: 'guid',
    label: 'Guid',
  },
  {
    value: 'last_restart_time',
    label: 'Last Restart Time',
    type: 'select',
    options: [
      {
        value: 'more_than_threshold',
        label: 'More Than Threshold',
      },
      {
        value: 'less_than_threshold',
        label: 'Less Than Threshold',
      },
      {
        value: 'no_restart',
        label: 'No restart',
      },
    ],
  },
  {
    value: 'status',
    label: 'Status',
    type: 'select',
    options: [
      {
        value: 'active',
        label: 'Active',
      },
      {
        value: 'inactive',
        label: 'Inactive',
      },
    ],
  },
]