import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import ProvidersList from './ProvidersList';
import { clearFiltersGroup } from './mapViewFilterHelper';
import MapViewFilterModalBlock from './MapViewFilterModalBlock';
import LatencyFilters from './LatencyFilters';
import MapViewDatePicker from './MapViewDatePicker';
import MapViewDownloadFilter from './MapViewDownloadFilter';
import MapViewUploadFilter from './MapViewUploadFilter';
import WiFiQualityFilter from "./WiFiQualityFilter";

const MapViewFilterModal = ({show, toggleModal, filters, setFilters, onFilterApply}) => {
  return (
    <Modal isOpen={show} toggle={toggleModal} size="lg" className="custom">
      <ModalHeader>Filters</ModalHeader>
      <ModalBody>
        <div className="map-view-filter filter">
          <MapViewFilterModalBlock
            handleClearClick={() => clearFiltersGroup(setFilters, 'date')}
          >
            <MapViewDatePicker filters={filters} setFilters={setFilters} />
          </MapViewFilterModalBlock>
          <MapViewFilterModalBlock
            title="Filter by Provider"
            handleClearClick={() => clearFiltersGroup(setFilters, 'providers')}
          >
            <ProvidersList filters={filters} setFilters={setFilters} />
          </MapViewFilterModalBlock>
          <MapViewFilterModalBlock
            title="Filter by Download"
            handleClearClick={() => clearFiltersGroup(setFilters, 'download')}
          >
            <MapViewDownloadFilter filters={filters} setFilters={setFilters} />
          </MapViewFilterModalBlock>
          <MapViewFilterModalBlock
            title="Filter by Upload"
            handleClearClick={() => clearFiltersGroup(setFilters, 'upload')}
          >
            <MapViewUploadFilter filters={filters} setFilters={setFilters} />
          </MapViewFilterModalBlock>
          <MapViewFilterModalBlock
            title="Filter by Latency"
            handleClearClick={() => clearFiltersGroup(setFilters, 'latency')}
          >
            <LatencyFilters filters={filters} setFilters={setFilters} />
          </MapViewFilterModalBlock>
          <MapViewFilterModalBlock
            title="Wifi Quality"
            handleClearClick={() => clearFiltersGroup(setFilters, 'wifi')}
          >
            <WiFiQualityFilter filters={filters} setFilters={setFilters} />
          </MapViewFilterModalBlock>
        </div>
      </ModalBody>
      <ModalFooter>
        <div style={{display: 'flex', gap: 10}}>
          <Button
            className="button button-default-outlined"
            onClick={() => clearFiltersGroup(setFilters)}
          >
            Clear All
          </Button>
          <Button
            className="button button-default"
            onClick={onFilterApply}
          >
            Apply Filters
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

MapViewFilterModal.propTypes = {
  show: PropTypes.bool,
  toggleModal: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  filters: PropTypes.object,
  setFilters: PropTypes.func,
  onFilterApply: PropTypes.func,
};

export default MapViewFilterModal;
