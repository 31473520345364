import React, {useEffect, useState} from 'react';
import {toastr} from "react-redux-toastr";
import {useDispatch, useSelector} from "react-redux";
import { Button, Col, Row } from 'reactstrap';
import Stripe from '../stripe';
import SubscriptionsPlans from './SubscriptionsPlans';
import SubscriptionsProgressBar from "./SubscriptionProgressBar";
import apiClient from "../../gears/apiClient";
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom';
import { cancelCompanySubscription, logoutUser } from '../../store/actions';
import subscription from './subscription.module.scss';

function SubscriptionsInfo() {
	const {
    companyDetails: { company_key },
    cancelSubscriptionLoading,
    isCustomPlan,
    isActivePlan
  } = useSelector((state) => state.Company);
	const [subscriptionsData, setSubscriptionsData] = useState({});
	const [isLoading, setIsLoading] = useState(true);
	const [isStripeVisible, setIsStripeVisible] = useState(false);
  const [selectedPlanId, setSelectedPlanId] = useState(null);
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

	const getSubscriptionPlanData = async () => {
		try {
			const response = await apiClient.get('/current_subscription_plan', {company_key});
			setSubscriptionsData(response);
		} catch (e) {
			toastr.error('Subscription plan data', e);
		}
		setIsLoading(false);
	}
	
	useEffect(() => {
		if (company_key) {
			getSubscriptionPlanData();
		}
	}, [company_key]);

  const onCancelClick = (val, isUpdated = false) => {
    if(isUpdated) {
      getSubscriptionPlanData()
    }
    setIsStripeVisible(val);
    setSelectedPlanId(null);
  }

  const title = isStripeVisible ? 'Selected plan' : 'Choose the plan that fits your need.';

  useEffect(() => {
    const url = location?.search;
    const paymentStatus = new URLSearchParams(url).get('redirect_status');
    if(paymentStatus) {
      toastr.success(`Payment status: ${paymentStatus}`)
      history.replace({
        search: '',
      })
    }
  }, [])

  const onCancelSubscriptionClick = () => {
    dispatch(cancelCompanySubscription())
    dispatch(logoutUser(history))
  }
	
	return (
		<div className="subscriptions">
			<div className="mb-4">
        <SubscriptionsProgressBar data={subscriptionsData} loading={isLoading} />
      </div>
      <div>
        <div className={subscription.top}>
          <h3 className={subscription.plansTitle}>{title}</h3>
          {!isCustomPlan && (
            <Button
                className="button button-default"
                onClick={onCancelSubscriptionClick}
                disabled={!isActivePlan || cancelSubscriptionLoading}
              >
              Cancel Subscription
            </Button>
          )}
        </div>
        <Row>
          <Col xs="12" sm="12" md="6" xl={isStripeVisible ? 6 : 12}>
            <SubscriptionsPlans
              isStripeVisible={isStripeVisible}
              onPlanChange={setIsStripeVisible}
              setSelectedPlanId={setSelectedPlanId}
            />
          </Col>
          {isStripeVisible && (
            <Col>
              <Stripe changeView={onCancelClick} />
            </Col>
          )}
        </Row>
      </div>
		</div>
	);
}

export default SubscriptionsInfo;
