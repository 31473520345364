import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { logoutUser } from '../../store/actions';

const Logout = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    
    useEffect(() => {
        dispatch(logoutUser(history));
    }, [dispatch, history]);
    
    return null;
};

export default Logout;
