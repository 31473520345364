import React from 'react'
import { Col, Row } from 'reactstrap'
import ResultsCard from '../resultsCard/ResultsCard'
import LatencyCard from '../latencyCard/LatencyCard'

const HopsDetailCards = ({hops}) => {
  const deviceLanAddress = hops?.deviceLan?.address?.length > 0 ? hops?.deviceLan?.address : 'N/A'
  return (
    <Row>
      <Col xs="12" md="4" className="mb-4">
        <ResultsCard
          title="Device < > lan"
          subtitle={`IP: ${deviceLanAddress}`}
          content={
            <LatencyCard
              data={hops?.deviceLan}
              source="deviceLan"
            />
          }
        />
      </Col>
      <Col xs="12" md="4" className="mb-4">
        <ResultsCard
          title="Lan < > ISP WAN"
          subtitle
          content={
            <LatencyCard
              data={hops?.lanWan}
              source="lanWan"
            />
          }
        />
      </Col>
      <Col xs="12" md="4" className="mb-4">
        <ResultsCard
          title="ISP < > Web Apps"
          subtitle
          content={
            <LatencyCard
              data={hops?.wanCloud}
              source="wanCloud"
            />
          }
        />
      </Col>
    </Row>
  )
}

export default HopsDetailCards