import React, {useState, useRef} from 'react';
import * as PropTypes from 'prop-types';
import {isEmpty} from 'lodash';
import SearchByColumn from './SearchByColumn';
import {mapDataToLegend} from '../../helpers/page_helper';
import Button from '../Button/Button';

const NetworkEventsTableFilter = ({onFilterChange, userId, currentFilter}) => {
  const [filter, setFilter] = useState({});
  const [showResetBtn, setShowResetBtn] = useState(false);
  const searchFilterRef = useRef();
  const SearchByColumnOptions = [
    {
      value: 'all',
      label: 'All',
      type: 'single_select',
    },
    {
      value: 'instability',
      label: 'Instability',
      type: 'single_select',
    },
    {
      value: 'ispOutage',
      label: 'Outage',
      type: 'single_select',
    },
  ];

  if (userId) {
    SearchByColumnOptions.splice(0, 1);
  }

  const onSearchChange = (value) => {
    const {searchBy} = value;
    if (searchBy) {
      if (searchBy === 'all') {
        setFilter({});
      } else {
        setFilter({...filter, ...value});
      }
    } else {
      const {searchBy, ...rest} = filter;
      setFilter({...rest});
    }
  };

  const handleSubmit = () => {
    const legend = {
      searchBy: 'search_by',
    };
    setShowResetBtn(!isEmpty(filter));
    if (Object.keys(filter).length === 0) {
      return onFilterChange(mapDataToLegend({}));
    }
    return onFilterChange(mapDataToLegend(legend, filter));
  };

  const resetFilter = () => {
    if (searchFilterRef) {
      searchFilterRef.current.reset();
      setFilter({});
      onFilterChange({});
      setShowResetBtn(false);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };

  return (
    <div className="d-flex align-items-center mb-3 mb-md-0 activities-filter">
      <SearchByColumn
        columnOptions={SearchByColumnOptions}
        onChange={onSearchChange}
        ref={searchFilterRef}
        onKeyUp={handleKeyPress}
        defaultSelect={currentFilter?.search_by || 'all'}
        onlySelect
      />
      <div className="ml-3">
        <Button
          type="button"
          onClick={handleSubmit}
          generalType="default"
        >
          Filter
        </Button>
        {
          showResetBtn && (
            <Button
              className="ml-2"
              type="button"
              onClick={resetFilter}
              generalType="default-outlined"
            >
              <i className="mdi mdi-refresh icon" />
              Clear
            </Button>
          )
        }
      </div>
    </div>
  );
};

NetworkEventsTableFilter.propTypes = {
  onFilterChange: PropTypes.func.isRequired,
  userId: PropTypes.number,
};

export default NetworkEventsTableFilter;
