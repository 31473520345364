import React from 'react';
import { Card, CardBody, Row, Col, Tooltip } from 'reactstrap';
import Scale from './Scale';
import Speedometer from './Speedometer';

const ChartBlocks = ({toggle, tooltipOpen }) => {
  return (
    <div className="chartblocks">
      <div className="chartblocks-top">
        <Row>
          <Col md="6" xl="4" className="offset-md-3 offset-xl-4">
            <Card className="recent-tests-card">
              <span className="card-tooltip">
                <i id="ois" className="mdi mdi-help-circle-outline text-primary font-size-20" />
                <Tooltip placement="left" isOpen={tooltipOpen} target="ois" toggle={() => toggle(1)}>
                  Organizational IQ Score
                </Tooltip>
              </span>
              <CardBody>
                <div className="title">Organizational IQ Score</div>
                <Speedometer maxValue={100} value={75} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
      <div className="chartblocks-row">
        <Row>
          <Col md="6" xl="4">
            <Card className="recent-tests-card">
              <CardBody>
                <div className="scale-title">Network Experience Score</div>
                <Scale maxValue={100} value={95} />
              </CardBody>
            </Card>
          </Col>
          <Col md="6" xl="4">
            <Card className="recent-tests-card">
              <CardBody>
                <div className="scale-title">Device Experience Score</div>
                <Scale maxValue={100} value={56} />
              </CardBody>
            </Card>
          </Col>
          <Col md="6" xl="4">
            <Card className="recent-tests-card">
              <CardBody>
                <div className="scale-title">Application Experience Score</div>
                <Scale maxValue={100} value={75} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ChartBlocks;
