import React from 'react';
import styles from './metric-card.module.scss';

const MetricCard = ({
  iconComponent: Icon,
  title,
	value,
	onClick
}) => {
	return (
		<div className={`${styles.card} ${onClick ? styles.clickable : ''}`} onClick={onClick && onClick}>
			<div className={styles.icon}>
				<Icon />
			</div>
			<h4 className={styles.title}>{title}</h4>
			<p className={styles.value}>{value}</p>
		</div>
	);
};

export default MetricCard;
