import { all } from 'redux-saga/effects';

// public
import AccountSaga from './auth/register/saga';
import AuthSaga from './auth/login/saga';
import ForgetSaga from './auth/forgetpwd/saga';
import ResetPassword from './auth/resetpwd/saga';
import LayoutSaga from './layout/saga';

// private
import CompanySaga from './company/saga';
import UsersSaga from './users/saga';
import UserUpdateSaga from './users/update/saga';

import TestResultsSaga from './testresults/sessions/saga';
import UserStatusSaga from './testresults/userstatus/saga';
import TestDetailsSaga from './testresults/testdetails/saga';
import UserActivityAnalyticsSaga from './testresults/user-activity-analytics/saga';

import DashboardAnalyticsSaga from './dashboard/analytics/saga';
import RecentActivitiesSaga from './dashboard/activities/saga';

import HelpRequestSaga from './helpRequest/saga';
import AnswersSaga from './answers/saga';

import ProfileSaga from './profile/saga';

import EXConfigSaga from './ex-config/saga';

import FetchApplications from './application/saga';
import BluetoothDevicesSaga from './bluetooth/saga';
import GetDeviceActivities from './device-activities/saga';
import AdminsSaga from "./administrators/saga";
import OnBoarding from "./on-boarding/saga";
import UserRecentActivities from "./user-recent-activities/saga";
import userIssuesLogsSaga from "./user-issue-logs/saga";
import issuesEventLogsSaga from "./user-issue-events-logs/saga";
import userIssuesDetailsSaga from "./user-issue-details/saga";
import issuesAIChatSaga from "./issues-ai-chat/saga";
import IssueLogsSaga from "./issue-logs-list/saga";
import NotificationsSaga from './notifications/saga';

export default function* rootSaga() {
  yield all([
    // public
    AdminsSaga(),
    AccountSaga(),
    AuthSaga(),
    ForgetSaga(),
    ResetPassword(),
    LayoutSaga(),
    CompanySaga(),
    HelpRequestSaga(),
    // private
    TestResultsSaga(),
    UserStatusSaga(),
    UserUpdateSaga(),
    TestDetailsSaga(),
    DashboardAnalyticsSaga(),
    RecentActivitiesSaga(),
    UsersSaga(),
    AnswersSaga(),
    UserActivityAnalyticsSaga(),
    ProfileSaga(),
    EXConfigSaga(),
    FetchApplications(),
    GetDeviceActivities(),
    BluetoothDevicesSaga(),
    OnBoarding(),
    UserRecentActivities(),
    userIssuesLogsSaga(),
    issuesEventLogsSaga(),
    userIssuesDetailsSaga(),
    issuesAIChatSaga(),
    IssueLogsSaga(),
    NotificationsSaga(),
  ]);
}
