import React from 'react';
import PropTypes from 'prop-types';
import MapViewFilterRange from './MapViewFilterRange';

const WiFiQualityFilter = ({filters, setFilters}) => {
	const data = [
		{
			title: 'From',
			name: 'device_to_lan_min_latency',
			placeholder: 'Value in ms',
		},
		{
			title: 'To',
			name: 'device_to_lan_max_latency',
			placeholder: 'Value in ms',
		},
	];
	
	return (
		<MapViewFilterRange filters={filters} setFilters={setFilters} data={data} />
	);
};

WiFiQualityFilter.propTypes = {
	filters: PropTypes.shape({
		device_to_lan_min_latency: PropTypes.string,
		device_to_lan_max_latency: PropTypes.string,
	}),
	setFilters: PropTypes.func,
};

export default WiFiQualityFilter;
