import {
  ADD_BLUETOOTH_DEVICE, ADD_BLUETOOTH_DEVICE_ERROR, ADD_BLUETOOTH_DEVICE_SUCCESS,
  DELETE_BLUETOOTH_DEVICE, DELETE_BLUETOOTH_DEVICE_ERROR, DELETE_BLUETOOTH_DEVICE_SUCCESS,
  GET_BLUETOOTH_DEVICES, GET_BLUETOOTH_DEVICES_ERROR, GET_BLUETOOTH_DEVICES_SUCCESS,
  UPDATE_BLUETOOTH_DEVICE, UPDATE_BLUETOOTH_DEVICE_ERROR, UPDATE_BLUETOOTH_DEVICE_SUCCESS,
} from './actionTypes';

const initialState = {
  data: [],
  isisLoading: false,
  error: null,
};

const fetchBluetooth = (state = initialState, action) => {
  switch (action.type) {
    case GET_BLUETOOTH_DEVICES:
      return {
        ...state,
        isLoading: true,
      };
    case GET_BLUETOOTH_DEVICES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload.bluetooth_devices,
      };
    case GET_BLUETOOTH_DEVICES_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case ADD_BLUETOOTH_DEVICE:
      return {
        ...state,
        isLoading: true,
      };
    case ADD_BLUETOOTH_DEVICE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: [
          ...state.data,
          action.payload.bluetooth_device,
        ],
      };
    case ADD_BLUETOOTH_DEVICE_ERROR:
      return { ...state, error: action.payload, isLoading: false };
    case DELETE_BLUETOOTH_DEVICE:
      return {
        ...state,
        isLoading: true,
      };
    case DELETE_BLUETOOTH_DEVICE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: state.data.filter(({id}) => id !== action.id),
      };
    case DELETE_BLUETOOTH_DEVICE_ERROR:
      return { ...state, error: action.error, isLoading: false };
    case UPDATE_BLUETOOTH_DEVICE:
      return {
        ...state,
        isLoading: true,
      };
    case UPDATE_BLUETOOTH_DEVICE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: state.data.map((app) => {
          if (app.id === action.payload.id) {
            return action.payload;
          }
          return app;
        }),
      };
    case UPDATE_BLUETOOTH_DEVICE_ERROR:
      return { ...state, error: action.payload, isLoading: false };
    default:
      return state;
  }
};

export default fetchBluetooth;
