import React from 'react';
import {useDispatch} from 'react-redux';
import {Field, Form, Formik} from 'formik';
import * as Yup from 'yup';
import {FormFeedback, FormGroup, Input, Label} from 'reactstrap';
import * as PropTypes from 'prop-types';
import {updateUser} from '../../store/users/update/actions';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

const validationSchema = Yup.object().shape({
  first_name: Yup.string(),
  last_name: Yup.string(),
  email: Yup.string().matches(
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    'Invalid email format'
  ),
  notes: Yup.string(),
});

const UserForm = ({
  user,
  onSuccessSubmit,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const isUserEmail = user?.email

  return (
    <Formik
      initialValues={{
        first_name: user?.first_name || '',
        last_name: user?.last_name || '',
        email: user?.email || '',
        notes: user?.notes || '',
      }}
      validationSchema={validationSchema}
      onSubmit={async (values) => {
        const updateUserCallback = () => {
          onSuccessSubmit();
        };

        dispatch(updateUser(user.id, values, updateUserCallback, history));
      }}
      enableReinitialize
    >
      {({errors, touched, handleSubmit}) => (
        <Form id="user-form" onSubmit={handleSubmit}>
          <FormGroup>
            <Label for="user-edit-first_name">First name</Label>
            <Input
              id="user-edit-first_name"
              type="text"
              name="first_name"
              invalid={touched.first_name && !!errors.first_name}
              tag={Field}
            />
            <FormFeedback>{errors.first_name}</FormFeedback>
          </FormGroup>

          <FormGroup>
            <Label for="user-edit-last_name">Last name</Label>
            <Input
              id="user-edit-last_name"
              type="text"
              name="last_name"
              invalid={touched.last_name && !!errors.last_name}
              tag={Field}
            />
            <FormFeedback>{errors.last_name}</FormFeedback>
          </FormGroup>

          {!isUserEmail && (
            <FormGroup>
              <Label for="user-edit-email">Email</Label>
              <Input
                id="user-edit-email"
                type="text"
                name="email"
                invalid={touched.email && !!errors.email}
                tag={Field}
              />
              <FormFeedback>{errors.email}</FormFeedback>
            </FormGroup>
          )}

          <FormGroup>
            <Label for="user-edit-notes">Notes</Label>
            <Field
              id="user-edit-notes"
              type="textarea"
              name="notes"
              className={`form-control ${errors.notes ? 'is-invalid' : ''}`}
              as="textarea"
              maxLength="1000"
            />
            <FormFeedback>{errors.notes}</FormFeedback>
          </FormGroup>
        </Form>
      )}
    </Formik>
  );
};

UserForm.propTypes = {
  onSuccessSubmit: PropTypes.func,
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
    email: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    notes: PropTypes.string,
  }),
};

export default UserForm;
