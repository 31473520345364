import React from 'react';
import classnames from 'classnames';

const StreamingCapabilities = ({title, data}) => {
  return (
    <div className="streaming-capabilities">
      {title && (
        <div className="streaming-capabilities-label">{title}</div>
      )}
      <div className="streaming-categories">
        <div className="results">
          {data?.map((item, index) => (
            <div key={item.label + index} className="result d-flex flex-row align-items-center">
              <div
                className={classnames({
                  icon: true,
                  capStatus: true,
                  [item.value]: item.value,
                })}
              >
                {item?.icon}
              </div>
              <span className="result-name">{item.label}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default StreamingCapabilities;
