import React from 'react';
import ReactTable from '../ReactTable';
import { userDeviceTableColumns } from "../../helpers/user_tabs_helper";

const UserDeviceTable = ({data, isLoading, onSortChange, disableSortBy = false}) => {
	const cpuStatus = (value) => {
		if (value >= 0 && value <= 31) {
			return <span className="result-status result-success">Average</span>;
		}
		if (value > 31) {
			return <span className="result-status result-danger">High</span>;
		}
		return <span className="result-status">Not Available</span>;
	};
	
	const memoryStatus = (value) => {
		if (value >= 0 && value <= 18) {
			return <span className="result-status result-success">Average</span>;
		}
		if (value > 18) {
			return <span className="result-status result-danger">High</span>;
		}
		return <span className="result-status">Not Available</span>;
	};
	
	const batteryStatus = (value) => {
		switch (value) {
			case 0:
				return <span className="result-status result-danger">Poor</span>;
			case 1:
				return <span className="result-status result-success">Good</span>;
			default:
				return <span className="result-status">Plugged In</span>;
		}
	};
	
	const columns = [
		{
			Header: userDeviceTableColumns.MONITORED_AT.Header,
			accessor: userDeviceTableColumns.MONITORED_AT.accessor,
		},
		{
			Header: userDeviceTableColumns.AVG_CPU.Header,
			accessor: userDeviceTableColumns.AVG_CPU.accessor,
			Cell: ({row: {original}}) => (
				cpuStatus(original.avg_cpu_utilization)
			),
		},
		{
			Header: userDeviceTableColumns.AVG_MEMORY.Header,
			accessor: userDeviceTableColumns.AVG_MEMORY.accessor,
			Cell: ({row: {original}}) => (
				memoryStatus(original.avg_memory_utilization)
			),
		},
		{
			Header: userDeviceTableColumns.AVG_BATTERY.Header,
			accessor: userDeviceTableColumns.AVG_BATTERY.accessor,
			Cell: ({row: {original}}) => (
				original.avg_battery_health || <span>N/A</span>
			),
		},
		{
			Header: userDeviceTableColumns.OS.Header,
			accessor: userDeviceTableColumns.OS.accessor,
			Cell: ({row: {original}}) => (
				original.operating_system || <span>N/A</span>
			),
		},
	];
	
	return (
		<>
			<ReactTable
				columns={columns}
				data={data}
				onSortChange={() => {}}
				loading={isLoading}
				manualSortBy
				disableMultiSort
				disableSortBy={disableSortBy}
			/>
		</>
	
	);
};

export default UserDeviceTable;
