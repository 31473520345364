import {all, fork, put, takeEvery} from 'redux-saga/effects';

import {CREATE_ACTIVITY_REQUEST, FETCH_NETWORK_ANALYTICS, FETCH_RECENT_ACTIVITIES, FETCH_USERS_SPEED, FETCH_USERS_TESTS} from './actionTypes';
import {
  createActivityError,
  createActivitySuccess,
  fetchNetworkAnalyticsError,
  fetchNetworkAnalyticsSuccess,
  fetchRecentActivitiesApiError,
  fetchRecentActivitiesSuccess,
  fetchUsersSpeedError,
  fetchUsersSpeedSuccess,
  fetchUsersTestsError,
  fetchUsersTestsSuccess,
} from './actions';
import apiClient from '../../../gears/apiClient';

function* fetchRecentActivities({payload: {params}}) {
  try {
    const response = yield apiClient.get('/recent_activities', params);
    yield put(fetchRecentActivitiesSuccess(response));
  } catch (error) {
    yield put(fetchRecentActivitiesApiError(error));
  }
}

export function* watchRecentActivitiesFetch() {
  yield takeEvery(FETCH_RECENT_ACTIVITIES, fetchRecentActivities);
}

function* fetchNetworkAnalytics({ payload }) {
  try {
    const response = yield apiClient.get('/network_analytics', payload);
    yield put(fetchNetworkAnalyticsSuccess(response));
  } catch (error) {
    yield put(fetchNetworkAnalyticsError(error));
  }
}

export function* watchFetchNetworkNalytics() {
  yield takeEvery(FETCH_NETWORK_ANALYTICS, fetchNetworkAnalytics);
}

function* fetchUsersSpeeed({ payload }) {
  try {
    const response = yield apiClient.get('/recent_user_speed_distributions', payload);
    yield put(fetchUsersSpeedSuccess(response));
  } catch (error) {
    yield put(fetchUsersSpeedError(error));
  }
}

export function* watchFetchUsersSpeed() {
  yield takeEvery(FETCH_USERS_SPEED, fetchUsersSpeeed);
}

function* createActivity({payload: {data}}) {
  try {
    const response = yield apiClient.post('/new_activity', data);
    yield put(createActivitySuccess(response));
  } catch (error) {
    yield put(createActivityError(error));
  }
}

export function* watchCreateActivity() {
  yield takeEvery(CREATE_ACTIVITY_REQUEST, createActivity);
}

function* fetchUsersTests({payload: {params}}) {
  try {
    const response = yield apiClient.get('/user_map_points', params);
    yield put(fetchUsersTestsSuccess(response));
  } catch (error) {
    yield put(fetchUsersTestsError(error));
  }
}

export function* watchFetchUsersTests() {
  yield takeEvery(FETCH_USERS_TESTS, fetchUsersTests);
}

function* recentActivitiesSaga() {
  yield all([
    fork(watchRecentActivitiesFetch),
    fork(watchCreateActivity),
    fork(watchFetchNetworkNalytics),
    fork(watchFetchUsersSpeed),
    fork(watchFetchUsersTests),
  ]);
}

export default recentActivitiesSaga;
