import {RESET_PASSWORD, RESET_PASSWORD_ERROR, RESET_PASSWORD_SUCCESS} from './actionTypes';

export const userResetPassword = (payload) => {
  return {
    type: RESET_PASSWORD,
    payload,
  };
};

export const userResetPasswordSuccess = (message) => {
  return {
    type: RESET_PASSWORD_SUCCESS,
    payload: message,
  };
};

export const userResetPasswordError = (message) => {
  return {
    type: RESET_PASSWORD_ERROR,
    payload: message,
  };
};
