import React from 'react';
import {connect} from 'react-redux';
import {Field, Form, Formik} from 'formik';
import * as Yup from 'yup';
import {Button, FormFeedback, FormGroup, Input, Label} from 'reactstrap';
import * as PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {updateMyProfile} from '../../store/actions';

const validationSchema = Yup.object().shape({
  first_name: Yup.string(),
  last_name: Yup.string(),
  email: Yup.string().email('Invalid email').required('Email is required'),
});

const ProfileForm = ({ profile, updateMyProfile, history }) => {
  return (
    <Formik
      initialValues={{
        // id: profile?.id || '',
        first_name: profile?.first_name || '',
        last_name: profile?.last_name || '',
        email: profile?.email || '',
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        updateMyProfile(values, history, 'toastr');
      }}
      enableReinitialize
    >
      {({ errors, touched, handleSubmit, isValid }) => (
        <Form id="profile-form" className="form" onSubmit={handleSubmit}>
          <div className="form-title">User Information</div>
          <div className="form-text">
            You can update your user information below.If you update your Email, the new Email will be used to login to this agent dashboard.
          </div>
          <FormGroup>
            <Label for="profile-firstName">First name</Label>
            <Input
              id="profile-firstName"
              type="text"
              name="first_name"
              invalid={touched.first_name && !!errors.first_name}
              tag={Field}
            />
            <FormFeedback>{errors.first_name}</FormFeedback>
          </FormGroup>

          <FormGroup>
            <Label for="profile-lastName">Last name</Label>
            <Input
              id="profile-lastName"
              type="text"
              name="last_name"
              invalid={touched.last_name && !!errors.last_name}
              tag={Field}
            />
            <FormFeedback>{errors.last_name}</FormFeedback>
          </FormGroup>

          <FormGroup>
            <Label for="profile-email">Email</Label>
            <Input
              id="profile-email"
              type="text"
              name="email"
              invalid={touched.email && !!errors.email}
              tag={Field}
            />
            <FormFeedback>{errors.email}</FormFeedback>
          </FormGroup>

          <FormGroup>
            <Button
              type="submit"
              color="primary"
              disabled={!isValid}
            >Submit
            </Button>
          </FormGroup>
        </Form>
      )}
    </Formik>
  );
};

ProfileForm.propTypes = {
  updateMyProfile: PropTypes.func.isRequired,
  profile: PropTypes.shape({
    id: PropTypes.number,
    email: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = { updateMyProfile };

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProfileForm),
);
