import React, { useState } from 'react';
import styles from './sdk-credentials.module.scss';

function SdkCredentialRow({ title, value, noCopy }) {
	const [copied, setCopied] = useState(false);
	
	const copyToClipboard = () => {
		navigator.clipboard.writeText(value)
			.then(() => {
				setCopied(true);
				const resetCopied = () => setCopied(false);
				setTimeout(resetCopied, 2000);
			})
			.catch((error) => {
				console.error('Failed to copy:', error);
			});
	};
	
	return (
		<div className={styles.row}>
			<p className={styles.title}>{title}:</p>
			{value && <p className={styles.value}>{value}</p>}
			{!noCopy && value && (
				<button
					type="button"
					className={styles['copy-button']}
					onClick={copyToClipboard}
				>
					{copied ? 'Copied' : 'Copy'}
				</button>
			)}
		</div>
	);
}

export default SdkCredentialRow;
