import { useEffect, useState } from 'react';
import apiClient from "../gears/apiClient";
import {extensionLink, installExtensionLink} from "../helpers/page_helper";

const useInstallExtensionLink = (shouldFetch = true) => {
	const [installExtensionLink, setInstallExtensionLink] = useState('');
	
	useEffect(() => {
		if (!shouldFetch) return;
		
		const fetchInstallExtensionLink = async () => {
			try {
				const response = await apiClient.get('/invite-company-user');
				setInstallExtensionLink(`${process.env.REACT_APP_INSTALL_EXTENSION_LINK || extensionLink}${response?.invite_token}`);
			} catch (error) {
				console.error('Error fetching install extension link:', error);
			}
		};
		
		fetchInstallExtensionLink();
		
	}, [shouldFetch]);
	
	return installExtensionLink;
};

export default useInstallExtensionLink;
