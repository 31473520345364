import React from 'react';

const Scale = ({ maxValue, value, gradient, valueColor }) => {
  let transform = `${(value * 100) / maxValue}%`;

  if (value >= maxValue) {
    transform = `calc(${100}% - 4px)`;
  }

  if (value === 0 || value < 0) {
    transform = '4px';
  }

  return (
    <div className="scale">
      <div className="scale-bar-wrapper">
        <div className="scale-bar-value" style={{left: `${transform}`, backgroundColor: valueColor}} />
        <div
          className="scale-bar"
          style={{...(value && {background: `linear-gradient(${gradient})`})}}
        />
      </div>
      <div className="scale-data">
        <div className="scale-data-label">Percentile</div>
        <div className="scale-data-value">{value || 'N/A'}</div>
      </div>
    </div>
  );
};

export default Scale;
