import axios from 'axios';

const redirectToUrl = (value) => {
  if (value?.data?.org.toLowerCase().includes('paxio')) {
    window.location.replace('http://paxiosupport.hubbleiq.net');
  }
};

class FetchResultsHelper {
  fetchUserIp = () => {
    return new Promise((resolve, reject) => {
      axios
        .get('https://ipapi.co/json', {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        })
        .then((res) => {
          redirectToUrl(res);
          resolve(res);
        })
        .catch((err) => {
          reject(err.response.data.errors);
        });
    });
  };

  /**
   * Handle the error
   * @param {*} error
   */
  handleError = (error) => {
    // var errorCode = error.code;
    return error.message;
  };
}

export default FetchResultsHelper;
