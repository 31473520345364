import { takeEvery, put, all, fork } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';
import { UPDATE_USER_REQUEST } from './actionTypes';
import { updateUserSuccess, updateUserError } from './actions';
import apiClient from '../../../gears/apiClient';

function* updateUser({id, payload, callback}) {
  try {
    yield apiClient.put(`/user/${id}`, payload);
    yield put(updateUserSuccess());

    if (callback) {
      callback();
    }
  } catch (error) {
    yield put(updateUserError(error));
    toastr.error('System', error.errors);
  }
}

export function* updateUserWatcher() {
  yield takeEvery(UPDATE_USER_REQUEST, updateUser);
}

function* UserUpdateSaga() {
  yield all([fork(updateUserWatcher)]);
}

export default UserUpdateSaga;
