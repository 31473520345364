import {
  FETCH_USER_STATUS,
  FETCH_USER_STATUS_SUCCESS,
  FETCH_USER_STATUS_API_ERROR,
  FETCH_SCATTER_CHART,
  FETCH_SCATTER_CHART_SUCCESS,
  FETCH_SCATTER_CHART_API_ERROR,
  FETCH_USER_DEVICE,
  FETCH_USER_DEVICE_SUCCESS,
  FETCH_USER_DEVICE_ERROR,
  FETCH_USER_STATUS_ACTIVITY,
  FETCH_USER_STATUS_ACTIVITY_SUCCESS,
  FETCH_USER_STATUS_ACTIVITY_ERROR,
  FETCH_USER_DATA,
  FETCH_USER_DATA_SUCCESS,
  FETCH_USER_DATA_ERROR,
  REQUEST_REMOTE_TEST,
  REQUEST_REMOTE_TEST_SUCCESS,
  REQUEST_REMOTE_TEST_ERROR,
} from './actionTypes';

const initialState = {
  error: null,
  loading: false,
  userStatusActivityData: {},
  userStatusActivityLoading: false,
  requestRemoteResultText: 'Test Now',
  requestRemoteResultIsSuccess: null,
  requestRemoteTestLoading: false,
  requestRemoteTestError: null,
};

const fetchUserStatus = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_USER_STATUS:
      return {
        ...state,
        data: action.payload,
        history: action.history,
        loading: true,
      };
    case FETCH_USER_STATUS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      };
    case FETCH_USER_STATUS_API_ERROR:
      return { ...state, error: action.payload, loading: false };
    case FETCH_USER_STATUS_ACTIVITY:
      return {
        ...state,
        userStatusActivityData: action.payload,
        userStatusActivityLoading: true,
      };
    case FETCH_USER_STATUS_ACTIVITY_SUCCESS:
      return {
        ...state,
        userStatusActivityData: action.payload,
        userStatusActivityLoading: false,
      };
    case FETCH_USER_STATUS_ACTIVITY_ERROR:
      return { ...state, error: action.payload, userStatusActivityLoading: false };
    case FETCH_SCATTER_CHART:
      return {
        ...state,
        chart: action.payload,
        chartLoading: true,
      };
    case FETCH_SCATTER_CHART_SUCCESS:
      return {
        ...state,
        chart: action.payload,
        chartLoading: false,
      };
    case FETCH_SCATTER_CHART_API_ERROR:
      return { ...state, chartError: action.payload, chartLoading: false };
    case FETCH_USER_DEVICE:
      return {
        ...state,
        device: action.payload,
        deviceLoading: true,
      };
    case FETCH_USER_DEVICE_SUCCESS:
      return {
        ...state,
        device: action.payload,
        deviceLoading: false,
      };
    case FETCH_USER_DEVICE_ERROR:
      return { ...state, deviceError: action.payload, deviceLoading: false };
    case FETCH_USER_DATA:
      return {
        ...state,
        userData: action.payload,
        userDataisLoading: true,
      };
    case FETCH_USER_DATA_SUCCESS:
      return {
        ...state,
        userData: action.payload,
        userDataisLoading: false,
      };
    case FETCH_USER_DATA_ERROR:
      return {
        ...state,
        userDataError: action.payload,
        userDataisLoading: false,
      };
    case REQUEST_REMOTE_TEST:
      return {
        ...state,
        requestRemoteTestLoading: true,
        requestRemoteTestError: null,
      };
    case REQUEST_REMOTE_TEST_SUCCESS:
      return {
        ...state,
        requestRemoteResultText: 'Test Requested',
        requestRemoteResultIsSuccess: true,
        requestRemoteTestLoading: false,
        requestRemoteTestError: null,
      };
    case REQUEST_REMOTE_TEST_ERROR:
      return {
        ...state,
        requestRemoteResultText: 'Check Network Health Error',
        requestRemoteResultIsSuccess: false,
        requestRemoteTestLoading: false,
        requestRemoteTestError: action.payload,
      };
    default:
      return state;
  }
};

export default fetchUserStatus;
