import React, { useEffect, useState } from 'react';
import { Button, Col, Row, Spinner } from 'reactstrap';
import { toastr } from 'react-redux-toastr';
import ColorSchemeSlider from './ColorSchemeSlider';
import apiClient from '../../gears/apiClient';

const initialData = {
  decent_download_threshold: null,
  decent_jitter_threshold: null,
  decent_latency_threshold: null,
  decent_upload_threshold: null,
  good_download_threshold: null,
  good_jitter_threshold: null,
  good_latency_threshold: null,
  good_upload_threshold: null,
};

const minDistance = 1;

const ColorScheme = () => {
  const [colorsData, setColorsData] = useState(initialData);
  const [isLoading, setIsLoading] = useState(false);

  const fetchColors = async () => {
    setIsLoading(true);
    try {
      const response = await apiClient.get('/settings/network-thresholds');
      setColorsData(response);
    } catch (e) {
      toastr.error(e?.error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchColors();
  }, []);

  const updateColorsSettings = async () => {
    setIsLoading(true);
    try {
      await apiClient.put(
        '/settings/network-thresholds',
        colorsData,
      );
    } catch (e) {
      toastr.error(e);
    }
    setIsLoading(false);
  };

  return (
    <div className="color-scheme">
      {isLoading && <div className="spinner-wrapper"><Spinner color="pimary" /></div>}
      <Row>
        <Col xs="12" sm="5">
          <div className="color-scheme-block">
            <ColorSchemeSlider
              colorLeft="#FF5844"
              colorMiddle="#FEBA00"
              colorRight="#5EE101"
              title="Download"
              minDistance={minDistance}
              maxValue={100}
              unit="mbps"
              keys={[
                'decent_download_threshold',
                'good_download_threshold',
              ]}
              colorsData={colorsData}
              setColorsData={setColorsData}
            />
          </div>
        </Col>
        <Col xs="12" sm="5" className="offset-sm-1">
          <div className="color-scheme-block">
            <ColorSchemeSlider
              colorLeft="#FF5844"
              colorMiddle="#FEBA00"
              colorRight="#5EE101"
              title="Upload"
              minDistance={minDistance}
              maxValue={100}
              unit="mbps"
              keys={[
                'decent_upload_threshold',
                'good_upload_threshold',
              ]}
              colorsData={colorsData}
              setColorsData={setColorsData}
            />
          </div>
        </Col>
        <Col xs="12" sm="5">
          <div className="color-scheme-block">
            <ColorSchemeSlider
              colorLeft="#5EE101"
              colorMiddle="#FEBA00"
              colorRight="#FF5844"
              title="Latency"
              minDistance={minDistance}
              maxValue={1000}
              keys={[
                'decent_latency_threshold',
                'good_latency_threshold',
              ]}
              colorsData={colorsData}
              unit="ms"
              setColorsData={setColorsData}
            />
          </div>
        </Col>
        <Col xs="12" sm="5" className="offset-sm-1">
          <div className="color-scheme-block">
            <ColorSchemeSlider
              colorLeft="#5EE101"
              colorMiddle="#FEBA00"
              colorRight="#FF5844"
              title="Jitter"
              minDistance={minDistance}
              maxValue={1000}
              unit="ms"
              keys={[
                'decent_jitter_threshold',
                'good_jitter_threshold',
              ]}
              colorsData={colorsData}
              setColorsData={setColorsData}
            />
          </div>
        </Col>
      </Row>
      <div className="color-scheme-actions">
        <Button
          type="button"
          color="primary"
          onClick={updateColorsSettings}
          disabled={isLoading}
        >
          Submit
        </Button>
      </div>
    </div>
  );
};

ColorScheme.propTypes = {};

export default ColorScheme;
