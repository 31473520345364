import React, {useCallback, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import ReactTable from '../ReactTable';
import { issuesTableColumns } from '../issues/issuesHelper';
import IssueDetailsDrawer from '../drawers/IssueDetailsDrawer/IssueDetailsDrawer';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom';

const NetworkIssuesTable = ({
  data,
  isLoading,
  onSortChange,
  disableSortBy = false,
  setData,
  tableCategory = '',
}) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [issueDetailId, setIssueDetailId] = useState(null);
  const location = useLocation();
  const history = useHistory();

  const onDrawerClose = () => {
    setIsDrawerOpen(false);
    setIssueDetailId(null);
    if(location.search) {
      history.replace({
        search: '',
      })
    }
  };

  const onDrawerOpen = (id) => {
    setIssueDetailId(id);
    setIsDrawerOpen(true);
  };

    const onSort = useCallback(({ sortBy }) => {
      if (onSortChange) {
        if (sortBy.length > 0) {
          const [{id, desc}] = sortBy;
          onSortChange(`${[id]} ${desc ? 'desc' : 'asc'}`);
        } else {
          onSortChange();
        }
      }
  }, []);

  const handleRowClick = (rowData) => {
		onDrawerOpen(rowData.id);
	};

  const onMarkCompleteClick = () => {
    const copyData = [...data];
    const currentIssue = copyData.find(issue => issue.id === issueDetailId);
    currentIssue.status = 'Completed';
    setData(copyData);
  }

  useEffect(() => {
    if(location.search) {
      const url = location.search;
      const searchParamsUrl = new URLSearchParams(url);
      const id = searchParamsUrl.get('id');
      if(id) {
        onDrawerOpen(id);
      }
    }
  }, [location.search]);

  return (
    <>
      <ReactTable
        columns={issuesTableColumns(onDrawerOpen, tableCategory)}
        data={data}
        onSortChange={onSort}
        loading={isLoading}
        manualSortBy
        disableMultiSort
        disableSortBy={disableSortBy}
        onRowClick={handleRowClick}
      />
      <IssueDetailsDrawer
				isOpen={isDrawerOpen}
				onHandlerClose={onDrawerClose}
				issueId={issueDetailId}
        data={data}
        callBack={onMarkCompleteClick}
			/>
    </>
  );
};

NetworkIssuesTable.defaultProps = {
  data: [],
  isLoading: false,
};

NetworkIssuesTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.array,
  onSortChange: PropTypes.func,
  isLoading: PropTypes.bool,
};

export default withRouter(NetworkIssuesTable);
