import React from 'react';
import { FormGroup, Label } from 'reactstrap';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import * as PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {requestTest} from '../store/actions';
import Button from '../components/Button/Button';

const requestTestSchema = Yup.object().shape({
  first_name: Yup.string().min(2, 'Too Short!').required('Required'),
  last_name: Yup.string().min(2, 'Too Short!').required('Required'),
  email: Yup.string().email('Invalid email').required('Required'),
});

const RequestTestForm = ({requestTest, close, history, isLabelVisible = true}) => {
  return (
    <>
      <div className="modal-text">
        Please fill in the form below and we will e-mail a link to run an on-demand browser test.
        Once run, the test results will appear in your dashboard.
      </div>
      <Formik
        initialValues={{
          first_name: '',
          last_name: '',
          email: '',
        }}
        validationSchema={requestTestSchema}
        onSubmit={(values, {resetForm}) => {
          const updateUserCallback = () => {
            close();
            resetForm();
          };

          requestTest(values, history, updateUserCallback);
        }}
        enableReinitialize
      >
        {({ errors, touched, handleChange, isValid, dirty }) => (
          <Form id="test-form">
            <FormGroup>
              {isLabelVisible && <Label for="test-firstName">First name</Label>}
              <Field
                id="test-firstName"
                className="form-control"
                type="text"
                name="first_name"
                placeholder="First name"
                onChange={(e) => handleChange(e)}
              />
              {errors.first_name && touched.first_name ? (
                <div className="error">{errors.first_name}</div>
              ) : null}
            </FormGroup>
            <FormGroup>
              {isLabelVisible && <Label for="test-lastName">Last name</Label>}
              <Field
                id="test-lastName"
                className="form-control"
                type="text"
                name="last_name"
                placeholder="Last name"
                onChange={(e) => handleChange(e)}
              />
              {errors.last_name && touched.last_name ? (
                <div className="error">{errors.last_name}</div>
              ) : null}
            </FormGroup>
            <FormGroup>
              {isLabelVisible && <Label for="test-email">Email</Label>}
              <Field
                id="test-email"
                className="form-control"
                type="email"
                name="email"
                placeholder="E-mail address"
                onChange={(e) => handleChange(e)}
              />
              {errors.email && touched.email ? (
                <div className="error">{errors.email}</div>
              ) : null}
            </FormGroup>
            <div>
              <Button
                type="submit"
                generalType="default"
                disabled={!isValid || !dirty}
              >
                Request Test
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

const mapStateToProps = () => ({});

RequestTestForm.propTypes = {
  requestTest: PropTypes.func,
  close: PropTypes.func,
};

export default withRouter(connect(mapStateToProps, {requestTest})(RequestTestForm));
