import React from 'react';
import { useSelector } from 'react-redux';
import { Col, Row, Spinner } from 'reactstrap';

const MapViewCards = () => {
  const {
    networkAnalytics,
    networkdAnalyticsIsLoading,
  } = useSelector((state) => state.RecentActivities);

  const renderCard = () => {
    const cardLabel = {
      activities_number: 'Tests Run',
      active_user_number: 'Unique Users',
      distinct_ip_address_number: 'Unique Networks',
    };

    const cardArr = Object.keys(networkAnalytics).map((item) => {
      return (
        <Col xs="12" sm="6" md="4" className="mb-3" key={item}>
          <div className="map-view-card card">
            <div className="card-value">
              <h3>
                {networkAnalytics?.[item]}
              </h3>
            </div>
            <div className="card-label">{cardLabel?.[item]}</div>
          </div>
        </Col>
      );
    });

    return cardArr;
  };

  return (
    <div className="map-view-cards">
      {networkdAnalyticsIsLoading && <div className="spinner-wrapper text-center"><Spinner color="primary" /></div>}
      {!networkdAnalyticsIsLoading && (
        <Row>
          {renderCard()}
        </Row>
      )}
    </div>
  );
};

export default MapViewCards;
