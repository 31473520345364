import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

// Front
import {reducer as toastrReducer} from 'react-redux-toastr';
import Layout from './layout/reducer';

// Authentication
import Login from './auth/login/reducer';
import Account from './auth/register/reducer';
import ForgetPassword from './auth/forgetpwd/reducer';
import ResetPassword from './auth/resetpwd/reducer';

import Company from './company/reducer';
import TestResults from './testresults/sessions/reducer';
import UserStatus from './testresults/userstatus/reducer';
import TestDetails from './testresults/testdetails/reducer';
import UserActivityAnalytics from './testresults/user-activity-analytics/reducer';

import DashboardAnalytics from './dashboard/analytics/reducer';
import RecentActivities from './dashboard/activities/reducer';

import HelpRequest from './helpRequest/reducer';
import Answers from './answers/reducer';

import Admins from './administrators/reducer';
import Users from './users/reducer';
import UserUpdate from './users/update/reducer';
import Profile from './profile/reducer';
import EXConfig from './ex-config/reducer';
import Applications from './application/reducer';
import BluetoothDevices from './bluetooth/reducer';
import Device from './device-activities/reducer';
import OnBoarding from './on-boarding/reducer';
import UserRecentActivities from './user-recent-activities/reducer';
import UserIssuesLogs from "./user-issue-logs/reducer";
import IssuesEventLogs from "./user-issue-events-logs/reducer";
import UserIssuesDetails from "./user-issue-details/reducer";
import IssuesAIChat from "./issues-ai-chat/reducer";
import IssueLogsList from "./issue-logs-list/reducer";
import Notifications from "./notifications/reducer";

const rootReducer = combineReducers({
  form: formReducer,
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  ResetPassword,
  Company,
  HelpRequest,
  UserIssuesLogs,
  UserIssuesDetails,
  IssuesEventLogs,
  IssuesAIChat,
  // private
  TestResults,
  UserStatus,
  TestDetails,
  DashboardAnalytics,
  RecentActivities,
  Admins,
  Users,
  UserUpdate,
  Answers,
  UserActivityAnalytics,
  Profile,
  EXConfig,
  Applications,
  BluetoothDevices,
  Device,
  OnBoarding,
  UserRecentActivities,
  IssueLogsList,
  Notifications,
  toastr: toastrReducer,
});

export default rootReducer;
