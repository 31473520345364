import {
  GET_ISSUE_LOGS,
  GET_ISSUE_LOGS_SUCCESS,
  GET_ISSUE_LOGS_ERROR,
  GET_REPORED_LOGS,
  GET_REPORED_LOGS_SUCCESS,
  GET_REPORED_LOGS_ERROR
} from "./actionTypes";

export const getIssueLogs = (params) => {
  return {
    type: GET_ISSUE_LOGS,
    payload: {
      params
    },
  };
};

export const getIssueLogsSuccess = (payload) => {
  return {
    type: GET_ISSUE_LOGS_SUCCESS,
    payload,
  };
};

export const getIssueLogsError = (error) => {
  return {
    type: GET_ISSUE_LOGS_ERROR,
    payload: error,
  };
};

export const getReportedLogs = (params) => {
  return {
    type: GET_REPORED_LOGS,
    payload: {
      params
    },
  };
};

export const getReportedLogsSuccess = (payload) => {
  return {
    type: GET_REPORED_LOGS_SUCCESS,
    payload,
  };
};

export const getReportedLogsError = (error) => {
  return {
    type: GET_REPORED_LOGS_ERROR,
    payload: error,
  };
};
