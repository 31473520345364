import { FETCH_NOTIFICATIONS, FETCH_NOTIFICATIONS_ERROR, FETCH_NOTIFICATIONS_SUCCESS, MARK_NOTIFICATIONS_AS_READ, MARK_NOTIFICATIONS_AS_READ_ERROR, MARK_NOTIFICATIONS_AS_READ_SUCCESS } from './actionTypes';

const initialState = {
  data: [],
  isLoading: false,
  error: null,
  markAsReadisLoading: false,
  markAsReadError: null,
};

const fetchNotificationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_NOTIFICATIONS:
      return {
        ...state,
        data: action.data,
        isLoading: true,
      };
    case FETCH_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    case FETCH_NOTIFICATIONS_ERROR:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    case MARK_NOTIFICATIONS_AS_READ:
      return {
        ...state,
        markAsReadisLoading: true,
      };
    case MARK_NOTIFICATIONS_AS_READ_SUCCESS:
      const copyData = [...state.data];
      action.payload.ids.map(id => {
        const currentNotification = copyData.find(item => item.id === id)
        currentNotification.wasRead = true;
        return currentNotification
      });
      return {
        ...state,
        data: copyData,
        markAsReadisLoading: false,
      };
    case MARK_NOTIFICATIONS_AS_READ_ERROR:
      return {
        ...state,
        markAsReadError: action.payload,
        markAsReadisLoading: false,
      };
    default:
      return state;
  };
};

export default fetchNotificationsReducer;
