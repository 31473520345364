import React from 'react';
import {CircularProgress} from '@material-ui/core';

const PageLoader = () => {
  return (
    <div className="page-loader">
      <CircularProgress />
    </div>
  );
};
export default PageLoader;
