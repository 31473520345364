import React from 'react';
import styles from './extra-content.module.scss';

const ExtraContent = ({ data }) => {
	const { status, ip, duration, start, end, description, location } = data || {}
	
	const contentData = [
		{ title: 'Status:', value: status, condition: status },
		{ title: 'Description:', value: description, condition: description },
		{ title: 'Location:', value: `${location?.country} - ${location?.city}`, condition: location?.country || location?.city },
		{ title: 'IP:', value: ip, condition: ip },
		{ title: 'Duration:', value: duration, condition: duration },
		{ title: 'Start date:', value: start, condition: start },
		{ title: 'End date:', value: end, condition: end },
	];
	return (
		<div className={styles.content}>
			{
				(contentData
					.filter((item) => item.condition)
					.map((item, index) => (
						<div key={index}>
							<p className={styles.title}>{item.title}</p>
							<span className={styles.value}>{item.value}</span>
						</div>
					))
				)
			}
		</div>
	);
};

export default ExtraContent;
