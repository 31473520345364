const capitalizeFirstLetter = (string) => {
	return string.charAt(0).toUpperCase() + string.slice(1);
};

const formatEndTime = (value) => new Date(value).toLocaleString();

export const HEADERS = {
	SEVERITY: 'Severity',
	TYPE: 'Type',
	STATUS: 'Status',
	START_TIME: 'Start Time',
	END_TIME: 'End Time',
	DURATION: 'Duration',
	IP_ADDRESS: 'IP Address',
	EVENT_TYPE: 'Event Type',
	ISP: 'ISP',
	GUID: 'Guid',
	TIMESTAMP: 'Timestamp',
	DESCRIPTION: 'Description',
};

export const ACCESSOR = {
	GUID: 'guid',
	SEVERITY: 'severity',
	TYPE: 'type',
	STATUS: 'status',
	START: 'start',
	END: 'end',
	DURATION: 'duration',
	IP: 'ip',
	CREATED_AT: 'createdAt',
	ISP: 'isp',
	DESCRIPTION: 'description',
};

export const getColumns = (onDrawerOpen) => [
	{
		Header: HEADERS.SEVERITY,
		accessor: ACCESSOR.SEVERITY,
		Cell: ({ value }) => capitalizeFirstLetter(value),
	},
	{
		Header: HEADERS.TYPE,
		accessor: ACCESSOR.TYPE,
	},
	{
		Header: HEADERS.STATUS,
		accessor: ACCESSOR.STATUS,
	},
	{
		Header: HEADERS.START_TIME,
		accessor: ACCESSOR.START,
	},
	{
		Header: HEADERS.END_TIME,
		accessor: ACCESSOR.END,
		Cell: ({ value }) => formatEndTime(value),
	},
	{
		Header: HEADERS.DURATION,
		accessor: ACCESSOR.DURATION,
	},
	{
		Header: HEADERS.IP_ADDRESS,
		accessor: ACCESSOR.IP,
	},
];
