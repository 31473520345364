import { COOKIE_NAME_TOKEN } from './constants';

class ApiClient {
  store = null;

  token = null;

  headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json;charset=utf-8',
  };

  formDataHeaders = {
    Accept: 'application/json',
  }

  apiBaseUrl = '';

  constructor(apiBaseUrl = '') {
    this.apiBaseUrl = apiBaseUrl;
  }

  /**
     * @param {*} args
     */
  static create(apiBaseUrl) {
    const client = new this(apiBaseUrl);
    return client;
  }

  setStore(store) {
    this.store = store;
  }

  setJwtToken(newToken) {
    this.token = newToken;
    return this;
  }

  /**
     * Make a request to API
     * @param {string} method
     * @param {string} url
     * @param {object} options
     * @param {*} data
     */
  async request(method, url, data, options = {}) {
    const isFormData = data instanceof FormData;
    let headers = this.getHeaders(isFormData);
    if (typeof options.headers !== 'undefined') {
      headers = {
        ...headers,
        ...options.headers,
      };
      // eslint-disable-next-line no-param-reassign
      delete options.headers;
    }
    // @ref https://www.npmjs.com/package/axios
    const params = {
      withCredentials: true,
      method,
      headers,
      data: null,
      [this.dataSendType(method)]: this.serialize(data),
      parameters: null,
      ...options,
    };
    return fetch(`${this.apiBaseUrl}${url}`, params)
      .then((response) => {
        if (response.ok) {
          if (options.raw) {
            return response;
          }
          return response.json();
        }
        return response.json().then(Promise.reject.bind(Promise));
      });
  }

  post(url, data = {}, options = {}) {
    return this.request('post', url, data, options);
  }

  get(url, data = {}, options = {}) {
    let qString = '';
    if (data && Object.keys(data).length) {
      qString += `?${this.serializeQuery(data)}`;
    }
    return this.request('get', `${url}${qString}`, data, options);
  }

  put(url, data = {}, options = {}) {
    return this.request('put', url, data, options);
  }

  patch(url, data = {}, options = {}) {
    return this.request('patch', url, data, options);
  }

  delete(url, data = {}, options = {}) {
    return this.request('delete', url, data, options);
  }

  serializeQuery(obj = {}, prefix = '') {
    const str = [];
    Object.keys(obj).forEach((p) => {
      const k = prefix ? `${prefix}[${p}]` : p;
      const v = obj[p];
      str.push((v !== null && typeof v === 'object')
        ? this.serializeQuery(v, k)
        : `${encodeURIComponent(k)}=${encodeURIComponent(v)}`);
    });
    return str.filter((s) => s.length).join('&');
  }

  // eslint-disable-next-line class-methods-use-this
  serialize(data) {
    const isFormData = data instanceof FormData;
    return isFormData ? data : JSON.stringify(data);
  }

  /**
   * @param {string} method
    * @returns {string}
  */

  // eslint-disable-next-line class-methods-use-this
  dataSendType(method) {
    return method.toUpperCase() === 'GET' ? 'params' : 'body';
  }

  /**
    * Build request headers
  */
  getHeaders(isFormData) {
    const cookiesToken = window.Cookies.get(COOKIE_NAME_TOKEN);
    const headers = {
      ...(isFormData ? {...this.formDataHeaders} : {...this.headers}),
    };
    if (this.token) {
      headers.Authorization = `Bearer ${this.token}`;
    } else if (cookiesToken) {
      headers.Authorization = `Bearer ${cookiesToken}`;
    }

    return headers;
  }
}

const apiUrl = process.env.REACT_APP_API_URL;
const apiClient = ApiClient.create(apiUrl || '/api-v2');

export default apiClient;
