import React from 'react';
import SdkCredentials from "../../components/SDKCredentials/SDKCredentials";
import { Col, Row } from "reactstrap";
function SDKCredentials() {
	return (
		<div className="container-fluid content settings extension">
			<Row className="page-title align-items-center">
				<Col sm={6}>
					<div className="page-title-box">
						<h2>SDK Credentials</h2>
					</div>
				</Col>
			</Row>
			<div className="settings-customization recent-tests-card page-block page-block_border">
				<SdkCredentials/>
			</div>
		</div>
	);
}

export default SDKCredentials;
