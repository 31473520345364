import * as type from './actionTypes';

const initialState = {
  currentEXLogo: null,
  errorLogoUpdate: null,
  loadingLogoUpdate: null,

  isStatusMessageLoading: true,
  currentStatusMessage: null,

  helpdeskMessage: null,
  isHelpdeskMessageLoading: true,
};

export default (state = initialState, action) => {
  switch (action.type) {
    // Logo
    case type.GET_EX_LOGO_REQUEST:
      return {
        ...state,
      };
    case type.GET_EX_LOGO_SUCCESS:
      return {
        ...state,
        currentEXLogo: action.payload.logo,
      };
    case type.GET_EX_LOGO_ERROR:
      return {
        ...state,
        error: action.payload,
      };

    case type.UPDATE_EX_LOGO_REQUEST:
      return {
        ...state,
        loadingLogoUpdate: true,
      };
    case type.UPDATE_EX_LOGO_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loadingLogoUpdate: false,
      };
    case type.UPDATE_EX_LOGO_ERROR:
      return {
        ...state,
        errorLogoUpdate: action.payload,
        loadingLogoUpdate: false,
      };

    // Status Message
    case type.GET_STATUS_MESSAGE_REQUEST:
      return {
        ...state,
        isStatusMessageLoading: true,
      };
    case type.GET_STATUS_MESSAGE_SUCCESS:
      return {
        ...state,
        currentStatusMessage: action.payload,
        isStatusMessageLoading: false,
      };
    case type.GET_STATUS_MESSAGE_ERROR:
      return {
        ...state,
        error: action.payload,
        isStatusMessageLoading: false,
      };

    case type.UPDATE_STATUS_MESSAGE_REQUEST:
      return {
        ...state,
        isStatusMessageLoading: true,
      };
    case type.UPDATE_STATUS_MESSAGE_SUCCESS:
      return {
        ...state,
        isStatusMessageLoading: false,
      };
    case type.UPDATE_STATUS_MESSAGE_ERROR:
      return {
        ...state,
        error: action.payload,
        isStatusMessageLoading: false,
      };

      // Helpdesk Message
    case type.GET_HELPDESK_MESSAGE:
      return {
        ...state,
        isHelpdeskMessageLoading: true,
      };
    case type.GET_HELPDESK_MESSAGE_SUCCESS:
      return {
        ...state,
        helpdeskMessage: action.payload,
        isHelpdeskMessageLoading: false,
      };
    case type.GET_HELPDESK_MESSAGE_ERROR:
      return {
        ...state,
        error: action.payload,
        isHelpdeskMessageLoading: false,
      };

    // Update Helpdesk Message
    case type.UPDATE_HELPDESK_MESSAGE:
      return {
        ...state,
        isHelpdeskMessageLoading: true,
      };
    case type.UPDATE_HELPDESK_MESSAGE_SUCCESS:
      return {
        ...state,
        isHelpdeskMessageLoading: false,
      };
    case type.UPDATE_HELPDESK_MESSAGE_ERROR:
      return {
        ...state,
        error: action.payload,
        isHelpdeskMessageLoading: false,
      };

    default:
      return state;
  }
};
