import React from 'react';
import {Col, Input, FormGroup, Label, Row} from 'reactstrap';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { toastr } from 'react-redux-toastr';
import Button from '../Button/Button';

const BrowserTestWelcomeForm = ({data, isLoading, updateBrowserSettings}) => {
  const welcomeSchema = Yup.object().shape({
    welcome_header: Yup.string().max(50, 'Maximum 50 characters').required('Field is required'),
    welcome_subheader: Yup.string().max(80, 'Maximum 80 characters').required('Field is required'),
    welcome_img: Yup.string(),
    btn_text_test_trigger: Yup.string().required('Field is required'),
  });

  const changeImage = (e, f) => {
    const file = e.target.files[0];
    const fileSize = (file.size / (1024 * 1024)).toFixed(2);
    const validMimeTypes = [
      'image/jpeg', 'image/png', 'image/gif',
    ];

    if (!validMimeTypes.includes(file.type)) {
      toastr.error('Supported files are JPG, PNG, GIF');
      return;
    }

    if (fileSize > 10) {
      toastr.error('File size must be up to 10MB');
      return;
    }

    f('img', file);
    f('file', file.name);
    f('welcome_img', URL.createObjectURL(file));
  };

  return (
    <div className="settings-tab-block">
      <div className="title">Welcome</div>
      <Formik
        initialValues={{
          welcome_header: data?.welcome_header || '',
          welcome_subheader: data?.welcome_subheader || '',
          file: '',
          welcome_img: data?.welcome_img?.url || '',
          btn_text_test_trigger: data?.btn_text_test_trigger || '',
        }}
        validationSchema={welcomeSchema}
        onSubmit={(values) => {
          updateBrowserSettings({
            welcome_header: values.welcome_header,
            welcome_subheader: values.welcome_subheader,
            ...(values.img && {welcome_img: values.img}),
            btn_text_test_trigger: values.btn_text_test_trigger,
          });
        }}
        enableReinitialize
      >
        {({ handleChange, values, errors, setFieldValue, isValid, dirty }) => (
          <Form className="form form-helpdesk">
            <Row>
              <Col xl="6">
                <div className="settings-tab-item">
                  <Row>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="welcome_header">Headline Text</Label>
                        <Input
                          id="welcome_header"
                          className={`form-control ${errors.welcome_header ? 'is-invalid' : ''}`}
                          maxLength="50"
                          name="welcome_header"
                          onChange={handleChange}
                          tag={Field}
                          disabled={isLoading}
                        />
                        <div className="character-count">
                          Character count: <span>{values.welcome_header?.length}</span>/<span>50</span>
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="welcome_subheader">Sub-headline Text</Label>
                        <Field
                          id="welcome_subheader"
                          className={`form-control ${errors.welcome_subheader ? 'is-invalid' : ''}`}
                          maxLength="200"
                          name="welcome_subheader"
                          type="textarea"
                          onChange={handleChange}
                          as="textarea"
                          disabled={isLoading}
                        />
                        <div className="character-count">
                          Character count: <span>{values.welcome_subheader?.length}</span>/<span>200</span>
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <FormGroup>
                        <Label>File upload</Label>
                        <Input
                          id="welcome-file"
                          name="file"
                          type="file"
                          hidden
                          onChange={(event) => changeImage(event, setFieldValue)}
                        />
                        <FormGroup>
                          <Field
                            className="form-control"
                            value={values?.file}
                            readOnly
                          />
                        </FormGroup>
                        <div className="d-flex">
                          <Label for="welcome-file" className="button button-default button-size-small mb-0" style={{maxHeight: '35px'}}>Browse</Label>
                          <div className="d-flex align-items-center justify-content-end flex-grow-1 pl-2">Image dimesions: 950x650px, png, jpg, gif</div>
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label>Current image</Label>
                        <div className="form-block-preview">
                          {values?.welcome_img
                            ? <img src={values?.welcome_img} alt="uploaded file" />
                            : (
                              data?.welcome_img?.url && <img src={data?.welcome_img?.url} alt="uploaded file" />
                            )}
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <Label for="welcome-button-text">
                          Start Test Button Text
                        </Label>
                        <Input
                          id="welcome-button-text"
                          className={`form-control ${errors.custom_redirect_url ? 'is-invalid' : ''}`}
                          name="btn_text_test_trigger"
                          onChange={handleChange}
                          tag={Field}
                          disabled={isLoading}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6">
                      <div className="form-btn d-flex justify-content-between">
                        <Button type="submit" generalType="default" disabled={!isValid || !dirty}>Save changes</Button>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default BrowserTestWelcomeForm;
