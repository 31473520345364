import {
	FETCH_ISSUE_EVENT_LOGS,
	FETCH_ISSUE_EVENT_LOGS_SUCCESS,
	FETCH_ISSUE_EVENT_LOGS_ERROR,
} from "./actionTypes";

const initialState = {
	data: {},
	loading: false,
	error: null,
};

const IssuesEventLogs = (state = initialState, action) => {
	switch (action.type) {
		case FETCH_ISSUE_EVENT_LOGS:
			return {
				...state,
				loading: true,
			};
		case FETCH_ISSUE_EVENT_LOGS_SUCCESS:
			return {
				...state,
				data: action.payload,
				loading: false,
			};
		case FETCH_ISSUE_EVENT_LOGS_ERROR:
			return {
				...state,
				error: action.payload,
				loading: false,
			};
		default:
			return { ...state };
	}
};

export default IssuesEventLogs;
