import { takeEvery, fork, put, all } from 'redux-saga/effects';

// Login Redux States
import { FORGET_PASSWORD } from './actionTypes';
import { userForgetPasswordSuccess, userForgetPasswordError } from './actions';

import apiClient from '../../../gears/apiClient';

// If user is send successfully send mail link then dispatch redux action's are directly from here.
function* forgetPassword({ payload }) {
  try {
    yield apiClient.post('/password/forgot', payload);
    yield put(userForgetPasswordSuccess(`Thank you. Please check your e-mail for password reset instructions.
      If you did not receive anything, be sure to also check your SPAM folder!`));
  } catch (error) {
    yield put(userForgetPasswordError(error));
  }
}

export function* watchUserPasswordForget() {
  yield takeEvery(FORGET_PASSWORD, forgetPassword);
}

function* forgetPasswordSaga() {
  yield all([fork(watchUserPasswordForget)]);
}

export default forgetPasswordSaga;
