import React from 'react';
import {Row, Col} from 'reactstrap';
import SubscriptionsInfo from "../../components/Subscriptions/Subscriptions";

const Subscriptions = () => {
	
	return (
		<div className="container-fluid content settings extension">
			<Row className="page-title align-items-center">
				<Col sm={6}>
					<div className="page-title-box">
						<h2>Subscriptions</h2>
					</div>
				</Col>
			</Row>
			<div className="page-block page-block_border">
				<SubscriptionsInfo />
			</div>
		</div>
	);
};

export default Subscriptions;
