import { takeEvery, fork, put, all } from 'redux-saga/effects';

import { FETCH_ISSUE_EVENT_LOGS } from './actionTypes';
import { fetchIssueEventLogsSuccess, fetchIssueEventLogsError } from './actions';
import apiClient from "../../gears/apiClient";
function* fetchIssuesEventLogs({ payload: { issueId } }) {
	try {
		const response = yield apiClient.get(`/disruption-log-activities/${issueId}`);
		yield put(fetchIssueEventLogsSuccess(response));
	} catch (error) {
		yield put(fetchIssueEventLogsError(error));
	}
}

export function* watchFetchIssuesEventLogs() {
	yield takeEvery(FETCH_ISSUE_EVENT_LOGS, fetchIssuesEventLogs);
}

function* issuesEventLogsSaga() {
	yield all([
		fork(watchFetchIssuesEventLogs),
	]);
}

export default issuesEventLogsSaga;
