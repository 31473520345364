import React, { useEffect, useState } from 'react';
import { Col, Row, TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import { toastr } from 'react-redux-toastr';
import { connect } from 'react-redux';
import BrowserTestWelcomeForm from '../../components/forms/BrowserTestWelcomeForm';
import BrowserTestTestingForm from '../../components/forms/BrowserTestTestingForm';
import BrowserTestResultsForm from '../../components/forms/BrowserTestResultsForm';
import BrowserTestHelpForm from '../../components/forms/BrowserTestHelpForm';
import BrowserTestSurveyForm from '../../components/forms/BrowserTestSurveyForm';
import Loader from '../../components/Loader';
import UpdateExtensionLogo from '../../components/UpdateExtensionLogo';
import { getLogo, updateCompanyLogo } from '../../store/actions';
import AhoyTrack from '../../modules/AhoyTrack';
import apiClient from '../../gears/apiClient';

const BrowserTestSettings = ({companyLogo, dispatchUpdateCompanyLogo}) => {
  AhoyTrack();
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [activeTab, setActiveTab] = useState('1');
  const [ imageSrc, setImageSrc ] = useState(null);

  const fetchBrowserSettings = async () => {
    try {
      const res = await apiClient.get('/settings/browser-settings');
      setData(res);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  const updateBrowserSettings = async (values) => {
    setIsLoading(true);
    const formData = new FormData();
    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of Object.entries(values)) {
      formData.append(key, value);
    }
    try {
      await apiClient.post('/settings/update-browser-asset', formData);
      await fetchBrowserSettings();
      toastr.success('System', 'Updated succesfully');
    } catch (e) {
      toastr.error('System', 'Failed to update');
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchBrowserSettings();
  }, []);

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  if (isLoading) {
    return <Loader />;
  }

  const changeImage = (e) => {
    const file = e.target.files[0];
    const fileSize = (file.size / (1024 * 1024)).toFixed(2);
    const validMimeTypes = [
      'image/jpeg', 'image/png', 'image/gif',
    ];

    if (!validMimeTypes.includes(file.type)) {
      toastr.error('Supported files are JPG, PNG, GIF');
      return;
    }

    if (fileSize > 10) {
      toastr.error('File size must be up to 10MB');
      return;
    }

    setImageSrc(URL.createObjectURL(file));
    const formData = new FormData();
    formData.append('logo', file);
    dispatchUpdateCompanyLogo(formData);
  };

  return (
    <div className="container-fluid content settings extension">
      <Row className="page-title align-items-center">
        <Col sm={6}>
          <div className="page-title-box">
            <h2>Browser Test Settings</h2>
          </div>
        </Col>
      </Row>
      <UpdateExtensionLogo
        currentEXLogo={companyLogo}
        imageSrc={imageSrc}
        changeImage={changeImage}
        browserTest
      />
      <div className="settings-customization recent-tests-card">
        <Nav tabs>
          <NavItem>
            <NavLink
              className={activeTab === '1' ? 'active' : null}
              onClick={() => { toggle('1'); }}
            >
              Welcome
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={activeTab === '2' ? 'active' : null}
              onClick={() => { toggle('2'); }}
            >
              Testing
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={activeTab === '3' ? 'active' : null}
              onClick={() => { toggle('3'); }}
            >
              Results
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={activeTab === '4' ? 'active' : null}
              onClick={() => { toggle('4'); }}
            >
              Help Content
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={activeTab === '5' ? 'active' : null}
              onClick={() => { toggle('5'); }}
            >
              Survey
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab} className="page-block page-block_border">
          <TabPane tabId="1">
            <BrowserTestWelcomeForm data={data} isLoading={isLoading} updateBrowserSettings={updateBrowserSettings} />
          </TabPane>
          <TabPane tabId="2">
            <BrowserTestTestingForm data={data} isLoading={isLoading} updateBrowserSettings={updateBrowserSettings} />
          </TabPane>
          <TabPane tabId="3">
            <BrowserTestResultsForm />
          </TabPane>
          <TabPane tabId="4">
            <BrowserTestHelpForm data={data} isLoading={isLoading} updateBrowserSettings={updateBrowserSettings} />
          </TabPane>
          <TabPane tabId="5">
            <BrowserTestSurveyForm data={data} isLoading={isLoading} updateBrowserSettings={updateBrowserSettings} setIsLoading={setIsLoading} />
          </TabPane>
        </TabContent>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { companyLogo } = state.Company;
  return { companyLogo };
};

const mapDispatchToProps = {
  dispatchUpdateCompanyLogo: updateCompanyLogo,
  dispatchGetLogo: getLogo,
};

export default connect(mapStateToProps, mapDispatchToProps)(BrowserTestSettings);
