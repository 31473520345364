export const FETCH_USER_STATUS = 'FETCH_USER_STATUS';
export const FETCH_USER_STATUS_SUCCESS = 'FETCH_USER_STATUS_SUCCESS';
export const FETCH_USER_STATUS_API_ERROR = 'FETCH_USER_STATUS_API_ERROR';

export const FETCH_USER_DATA = 'FETCH_USER_DATA';
export const FETCH_USER_DATA_SUCCESS = 'FETCH_USER_DATA_SUCCESS';
export const FETCH_USER_DATA_ERROR = 'FETCH_USER_DATA_ERROR';

export const FETCH_USER_STATUS_ACTIVITY = 'FETCH_USER_STATUS_ACTIVITY';
export const FETCH_USER_STATUS_ACTIVITY_SUCCESS = 'FETCH_USER_STATUS_ACTIVITY_SUCCESS';
export const FETCH_USER_STATUS_ACTIVITY_ERROR = 'FETCH_USER_STATUS_ACTIVITY_ERROR';

export const FETCH_SCATTER_CHART = 'FETCH_SCATTER_CHART';
export const FETCH_SCATTER_CHART_SUCCESS = 'FETCH_SCATTER_CHART_SUCCESS';
export const FETCH_SCATTER_CHART_API_ERROR = 'FETCH_SCATTER_CHART_API_ERROR';

export const FETCH_USER_DEVICE = 'FETCH_USER_DEVICE';
export const FETCH_USER_DEVICE_SUCCESS = 'FETCH_USER_DEVICE_SUCCESS';
export const FETCH_USER_DEVICE_ERROR = 'FETCH_USER_DEVICE_ERROR';

export const REQUEST_REMOTE_TEST = 'REQUEST_REMOTE_TEST';
export const REQUEST_REMOTE_TEST_SUCCESS = 'REQUEST_REMOTE_TEST_SUCCESS';
export const REQUEST_REMOTE_TEST_ERROR = 'REQUEST_REMOTE_TEST_ERROR';
