import { takeEvery, fork, put, all } from 'redux-saga/effects';

import { FETCH_TEST_DETAILS, FETCH_TEST_DETAILS_ACTIVITY, FETCH_TEST_DETAILS_AVERAGES } from './actionTypes';
import {
  fetchTestDetailsSuccess, fetchTestDetailsApiError,
  fetchTestDetailsActivitySuccess, fetchTestDetailsActivityError,
  fetchTestDetailsAveragesSuccess, fetchTestDetailsAveragesError,
} from './actions';
import apiClient from '../../../gears/apiClient';

function* fetchTestDetails({ payload: { id } }) {
  try {
    const response = yield apiClient.get(`/activity/${id}`);
    yield put(fetchTestDetailsSuccess(response));
  } catch (error) {
    yield put(fetchTestDetailsApiError(error));
  }
}

export function* watchTestDetailsFetch() {
  yield takeEvery(FETCH_TEST_DETAILS, fetchTestDetails);
}

function* fetchTestDetailsActivity({ payload: { id } }) {
  try {
    const response = yield apiClient.get(`/user_activity/${id}`);
    yield put(fetchTestDetailsActivitySuccess(response));
  } catch (error) {
    yield put(fetchTestDetailsActivityError(error));
  }
}

export function* watchTestDetailsActivity() {
  yield takeEvery(FETCH_TEST_DETAILS_ACTIVITY, fetchTestDetailsActivity);
}

function* fetchTestDetailsAverages({ payload: { id } }) {
  try {
    const response = yield apiClient.get(`/last-week-averages/${id}`);
    yield put(fetchTestDetailsAveragesSuccess(response));
  } catch (error) {
    yield put(fetchTestDetailsAveragesError(error));
  }
}

export function* watchFetchTestDetailsAverages() {
  yield takeEvery(FETCH_TEST_DETAILS_AVERAGES, fetchTestDetailsAverages);
}

function* testDetailsSaga() {
  yield all([
    fork(watchTestDetailsFetch),
    fork(watchTestDetailsActivity),
    fork(watchFetchTestDetailsAverages),
  ]);
}

export default testDetailsSaga;
