import React from 'react';
import { Nav, NavItem, NavLink } from 'reactstrap';

const Tabs = ({ activeTab, toggleTabs, tabs }) => {

  return (
    <>
      <Nav tabs>
        {tabs.map((tab, i) => (
          <NavItem key={i} disabled={tab.disabled}>
            <NavLink
              className={`${!tab.disabled ? '' : 'disabled'} ${activeTab === `${i}` ? 'active' : ''}`}
              onClick={() => toggleTabs(`${i}`)}
            >
              {tab.title}
              {/* <span className="count" /> */}
              {tab.warning && <span className="warning">!</span>}
            </NavLink>
          </NavItem>
        ))}
      </Nav>
    </>
  );
};

export default Tabs;
