import React, { useEffect, useState } from 'react';
import {Col, Input, FormGroup, Label, Row} from 'reactstrap';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { toastr } from 'react-redux-toastr';
import * as PropTypes from 'prop-types';
import Button from '../Button/Button';
import apiClient from '../../gears/apiClient';

const STATUS_TYPES = {
  best: 'Best',
  very_good: 'Very good',
  good: 'Good',
  decent_latency: 'Decent latency',
  decent_jitter: 'Decent jitter',
  decent_download: 'Decent download',
  decent_upload: 'Decent upload',
  low_download: 'low download',
  low_upload: 'Low upload',
  high_latency: 'High latency',
  high_jitter: 'High jitter',
  in_progress: 'In progress',
  isp_outage: 'Isp outage',
};

const BrowserTestResultsForm = () => {
  const extensionSchema = Yup.object().shape({
    header: Yup.string().max(50, 'Maximum 50 characters').required('Field is required'),
    subheader: Yup.string().required('Field is required'),
  });

  const [ subheaderBtns, setSubheaderBtns ] = useState({});
  const [currentStatusMessage, setCurrentStatusMessage] = useState({});
  const [isStatusMessageLoading, setIsStatusMessageLoading] = useState(false);

  const getStatusMessage = async (status) => {
    setIsStatusMessageLoading(true);
    try {
      const res = await apiClient.get(`/fetch_status_message/${status}?platform=browser`);
      setCurrentStatusMessage(res.data);
      setIsStatusMessageLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  const updateStatusMessage = async (data) => {
    setIsStatusMessageLoading(true);
    try {
      await apiClient.put(`/update_message/${data.id}`, data);
      toastr.success('System', 'Status updated');
      setIsStatusMessageLoading(false);
    } catch (e) {
      toastr.error('System', e.message);
    }
  };

  useEffect(() => {
    getStatusMessage(Object.keys(STATUS_TYPES)[0]);
  }, []);

  const onChangeStatus = async (e) => {
    const status = e.target.value;
    await getStatusMessage(status);
  };

  return (
    <div className="settings-tab-block">
      <div className="title">Test Results Section</div>
      <div className="subtitle">Customize the text for each status type</div>
      <Formik
        initialValues={{
          id: currentStatusMessage?.id || null,
          status_type: currentStatusMessage?.status_type || Object.keys(STATUS_TYPES)[0],
          header: currentStatusMessage?.header || '',
          subheader: currentStatusMessage?.subheader || '',
        }}
        validationSchema={extensionSchema}
        onSubmit={(values) => (
          updateStatusMessage({
            id: values?.id,
            status_type: values?.status_type,
            header: values?.header,
            subheader: values?.subheader,
          })
        )}
        enableReinitialize
      >
        {({ handleChange, values, errors, isValid, dirty }) => (
          <Form className="form form-extension">
            <Row>
              <Col xl="6">
                <div className="settings-tab-item">
                  <Row>
                    <Col md={7}>
                      <FormGroup>
                        <Label for="extension-status">Status</Label>
                        <Input
                          id="extension-status"
                          name="status_type"
                          onChange={(e) => onChangeStatus(e)}
                          tag={Field}
                          as="select"
                        >
                          {
                            Object.entries(STATUS_TYPES).map(([value, label]) => <option key={`status-${value}`} value={value}>{label}</option>)
                          }
                        </Input>

                      </FormGroup>
                      <FormGroup>
                        <Label for="extension-headline">Headline Text</Label>
                        <Input
                          id="extension-headline"
                          className={`form-control ${errors.header ? 'is-invalid' : ''}`}
                          maxLength="50"
                          name="header"
                          onChange={(e) => handleChange(e)}
                          tag={Field}
                          disabled={isStatusMessageLoading}
                        />
                        <div className="character-count">
                          Character count: <span>{values.header?.length}</span>/<span>50</span>
                        </div>
                      </FormGroup>
                      <FormGroup>
                        <Label for="extension-subheadline">Sub-headline Text</Label>
                        <Field
                          id="extension-subheadline"
                          className={`form-control ${errors.subheader ? 'is-invalid' : ''}`}
                          name="subheader"
                          type="textarea"
                          onChange={(e) => {
                            setSubheaderBtns({
                              ...subheaderBtns,
                              subheader: e.target.value,
                            });
                            handleChange(e);
                          }}
                          as="textarea"
                          disabled={isStatusMessageLoading}
                        />
                      </FormGroup>

                      <div className="form-btn d-flex justify-content-between">
                        <Button generalType="default" type="submit" disabled={(!isValid || !dirty) || isStatusMessageLoading}>Save changes</Button>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </div>
  );
};

BrowserTestResultsForm.propTypes = {
  currentStatusMessage: PropTypes.shape({
    id: PropTypes.number,
    status_type: PropTypes.string,
    header: PropTypes.string,
    subheader: PropTypes.string,
  }),
};

export default BrowserTestResultsForm;
