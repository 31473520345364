import React from 'react';

const Welcome = () => {
  return (
    <>
      <div className="title">Let’s get started!</div>
      <div className="subtitle">
        Welcome to HubbleIQ. This Setup guide will have you up and running, and deployed to your users in no time.
      </div>
      <div className="video">
        <iframe
          title="welcome video"
          src="https://player.vimeo.com/video/616936699?h=1d5b021f19&title=0&byline=0&portrait=0"
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
        />
      </div>
    </>
  );
};

  export default Welcome;
