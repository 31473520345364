import React from 'react'
import PropTypes from 'prop-types'

const OnboardingCommon = ({title, subtitle, imgPath, content = null}) => {
  return (
    <>
      <div className="title">{title}</div>
      <div className="subtitle">{subtitle}</div>
      <div className="content">
        {imgPath && (
          <div className="content-img">
            <img src={imgPath} alt="onboarding" className="img-responsive" />
          </div>
        )}
        {content && content}
      </div>
    </>
  )
}

OnboardingCommon.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  subtitle: PropTypes.string,
  imgPath: PropTypes.string
}

export default OnboardingCommon