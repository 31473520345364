import React, { useEffect } from 'react';
import { Row, CardBody, Spinner, Col } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import SessionInformation from '../../components/test-results/session-information';
import ExperienceSummary from '../../components/test-results/experience-summary';
import InternetPerformance from '../../components/test-results/internet-performance';
import ExpectedExperience from '../../components/test-results/expected-experience';
import StreamingCapabilities from '../../components/test-results/streaming-capabilities';
import { fetchTestDetails, fetchTestDetailsActivity } from '../../store/actions';
import UserInputs from '../../components/test-results/user-inputs';
import AhoyTrack from '../../modules/AhoyTrack';
import MapBox from '../../components/MapBox/MapBox';
import { renderCapabilities } from './testDetailsHelper';
import AiResponse from '../../components/aiResponse/AiResponse';
import Hops from '../../components/hops/Hops';
import UserOnlineStatus from "../../components/UserOnlineStatus/UserOnlineStatus";

const TestDetails = ({testId}) => {
  AhoyTrack();
  const {
    data: testData,
    loading,
    testDetailsActivityData,
    testDetailsActivityLoading,
  } = useSelector((state) => state.TestDetails);
  const { ai_features_enabled: isAiFeaturesEnabled } = useSelector((state) => state.Company.companyDetails)
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    if (testId) {
      dispatch(fetchTestDetails(testId, history));
      dispatch(fetchTestDetailsActivity(testId));
    }
  }, [testId]);

  const aiPostData = {
    test_id: testId
  }
  const isHopsData = testData?.hops && Object.keys(testData?.hops).length > 0
  return (
    <div className="user-test">
      {loading && <div className="spinner-wrapper"><Spinner color="primary" /></div>}
      <div className="user-test-block user-test-block_border block user-test-flex">
        <UserOnlineStatus userId={testData?.user_id} />
        <div className="drawer-title"><h2>{testData?.name || ''}</h2></div>
      </div>

      {isAiFeaturesEnabled && (
        <div className="user-test-block user-test-block_border">
          <AiResponse
            title="network experience summary"
            type="userActivitySlide"
            data={aiPostData}
          />
        </div>
      )}

      {isHopsData && (
        <div className="user-test-block">
          <Hops hops={testData?.hops} />
        </div>
      )}

      <div className="info-section info-section_custom d-flex flex-column user-test-block block">
        <SessionInformation details={testData} />
      </div>

      <div className="user-test-block block">
        <Row>
          <Col xs="12" sm="4">
            <div className="user-test-block block mb-4">
              <ExperienceSummary
                status={testData?.test_result}
                statusDescription={testData?.status_description}
                pocketLoss={testData?.packet_loss}
              />
            </div>
          </Col>
          <Col xs="12" sm="4">
            <div className="user-test-block block">
              {testData && (
                <StreamingCapabilities
                  title="Capabilities"
                  data={renderCapabilities(testData)}
                />
              )}
              {testData && testData?.experience && <ExpectedExperience experience={testData?.experience} />}
            </div>
          </Col>
        </Row>
      </div>

      <div className="user-test-block block">
        <InternetPerformance
          download={testData?.download}
          upload={testData?.upload}
          jitter={testData?.jitter}
          latency={testData?.latency}
          packetLoss={testData?.packet_loss}
          statusDescription={testData?.status_description}
        />
      </div>

      <div className="user-test-block block">
        <UserInputs survery={testData?.survery} qa={testData?.qa} />
      </div>

      <div className="user-test-block block">
        <CardBody className="p-0 recent-tests-card">
          <h4 className="card-title">Location</h4>
          <div id="usa" className="recent-tests-card  vector-map-height position-relative">
            {testDetailsActivityLoading && <div className="spinner-wrapper text-center"><Spinner color="primary" /></div>}
            {!testDetailsActivityLoading && (
              <MapBox
                markers={testDetailsActivityData?.features}
                displayOnBoarding={false}
                noClick
              />
            )}
          </div>
        </CardBody>
      </div>
    </div>
  );
};

export default TestDetails;
