import React, { useCallback, useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import Pagination from '../pagination';
import { buildPaginationData } from '../../utility/utils';
import { logsReportedTableColumns } from './logsReportedHelper';
import LogsReportedTable from '../tables/LogsReportedTable';
import TableFilters from '../TableFilters';
import {useDispatch, useSelector} from "react-redux";
import {getReportedLogs} from "../../store/issue-logs-list/actions";

const LogsReported = ({
  issueLabel,
  tableColumns,
  onRowClick
}) => {
  const [ filter, setFilter ] = useState(null);
  const [ sortBy, setSortBy ] = useState('');
  const [ paginationData, setPaginationData ] = useState(null);
  const dispatch = useDispatch();
  const {data, loading: isLoading} = useSelector((state) => state.IssueLogsList);
  const fetchData = useCallback(async (page) => {
    let queryParams = {};

    if (page) {
      queryParams.page = page;
    }

    if (sortBy) {
      queryParams.status = sortBy;
    }

    if (filter) {
      queryParams = {...queryParams, ...filter};
    }

    dispatch(getReportedLogs(queryParams));
  }, [sortBy, filter]);

  const onFilterChange = (filter) => {
    setFilter(filter);
  };

  useEffect(() => {
    if (filter) {
      fetchData();
    }
  }, [filter]);

  useEffect(() => {
    if (data?.requests?.length > 0) {
      setPaginationData(buildPaginationData(data));
    }
  }, [data]);

  const onTableSortChange = (sortBy) => {
    if (sortBy) {
      setSortBy(sortBy);
    }
  };

  const navigateToPage = (page) => {
    fetchData(page);
  };

  useEffect(() => {
    if (typeof sortBy === 'string') {
      fetchData();
    }
  }, [sortBy]);

  return (
    <div className="container-fluid content">
      <Row className="page-title align-items-center">
        <Col sm={6}>
          <div className="page-title-box">
            <h2>{issueLabel}</h2>
          </div>
        </Col>
      </Row>
      <Row className="align-items-center test-results-table-container">
        <Col xl={12}>
          <div className="justify-between  mb-4 d-md-flex">
            <TableFilters
              onFilterChange={onFilterChange}
              currentFilter={filter}
              searchByColumnOptions={tableColumns}
              defaultSelect='guid'
            />
          </div>
          <div className="table-responsive">
            <LogsReportedTable
              data={data?.requests}
              isLoading={isLoading}
              tableColumns={logsReportedTableColumns}
              onRowClick={onRowClick}
              // onSortChange={onTableSortChange}
            />
          </div>
          {(data?.requests?.length > 0 && paginationData) && (
            <Pagination paginationData={paginationData} navigateTo={navigateToPage} />
          )}
        </Col>
      </Row>
    </div>
  );
};

export default LogsReported;
