import {
  CREATE_ANSWERS_REQUEST,
  CREATE_ANSWERS_SUCCESS,
  CREATE_ANSWERS_ERROR,
 } from './actionTypes';

const initialState = {
  error: null,
  loading: false,
};

const answers = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_ANSWERS_REQUEST:
      return {
        ...state,
        data: action.data,
        loading: true,
      };
    case CREATE_ANSWERS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      };
    case CREATE_ANSWERS_ERROR:
      return { ...state, error: action.payload, loading: false };
    default:
      return { ...state };
  }
};

export default answers;
