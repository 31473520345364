import * as c from './actionTypes';

export const fetchDashboardAnalytics = (history, params = {}) => {
  return {
    type: c.FETCH_DASHBOARD_ANALYTICS,
    payload: { history, params },
  };
};

export const fetchDashboardAnalyticsSuccess = (payload) => {
  return {
    type: c.FETCH_DASHBOARD_ANALYTICS_SUCCESS,
    payload,
  };
};

export const fetchDashboardAnalyticsApiError = (error) => {
  return {
    type: c.FETCH_DASHBOARD_ANALYTICS_API_ERROR,
    payload: error,
  };
};

export const fetchDashboardQuery = () => {
  return {
    type: c.FETCH_DASHBOARD_QUERY,
  };
};

export const fetchDashboardQuerySuccess = (payload) => {
  return {
    type: c.FETCH_DASHBOARD_QUERY_SUCCESS,
    payload,
  };
};

export const fetchDashboardQueryError = (error) => {
  return {
    type: c.FETCH_DASHBOARD_QUERY_ERROR,
    error,
  };
};

export const fetchAnalytics = (payload) => {
  return {
    type: c.FETCH_ANALYTICS,
    payload,
  };
};

export const fetchAnalyticsSuccess = (key, payload) => {
  return {
    type: c.FETCH_ANALYTICS_SUCCESS,
    key,
    payload,
  };
};

export const fetchAnalyticsError = (key, error) => {
  return {
    type: c.FETCH_ANALYTICS_ERROR,
    key,
    payload: error,
  };
};
