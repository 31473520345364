import React, {useState} from 'react';
import {Row, Col, Nav, NavItem, NavLink, TabContent, TabPane} from 'reactstrap';
import Administrators from "../../components/DashboardSettings/tabs/Administrators/Administrators";
const DashboardSettings = ({
   history,
   location
 }) => {
	
	const [activeTab, setActiveTab] = useState('1');
	const toggle = (tab) => {
		if (activeTab !== tab) setActiveTab(tab);
	};
	
	return (
		<div className="container-fluid content settings extension">
			<Row className="page-title align-items-center">
				<Col sm={6}>
					<div className="page-title-box">
						<h2>Dashboard Settings</h2>
					</div>
				</Col>
			</Row>
			<div className="settings-customization recent-tests-card">
				<Nav tabs>
					<NavItem>
						<NavLink
							className={activeTab === '1' ? 'active' : null}
							onClick={() => {
								toggle('1');
							}}
						>
							Administrators
						</NavLink>
					</NavItem>
				</Nav>
				<TabContent activeTab={activeTab} className="page-block page-block_border">
					<TabPane tabId="1">
						<Administrators history={history} location={location} />
					</TabPane>
				</TabContent>
			</div>
		</div>
	);
};

export default DashboardSettings;
