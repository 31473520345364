import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import HubbleLogo from '../../images/HubbleIQ_logo.png';
import Gbutton from '../../images/G-button.png';
import CompanyFooter from './company/CompanyFooter';

const PublicFooter = ({chromeExtLink}) => {
  return (
    <>
      <footer className="footer center">
        <div className="hubble_logo_container">
          <a href="https://www.hubbleiq.com/" target="_blank" rel="noreferrer"><img src={HubbleLogo} alt="Logo" /></a>
        </div>
        <p>
          {
            chromeExtLink && 
            <a href={chromeExtLink}
              className="button button-light button-chrome"
              target="_blank"
              rel="noreferrer">
                Add to Chrome
                <span>
                  <img
                    src={Gbutton}
                    alt="google icon"
                    className="img-fluid"
                  />
                </span>
              </a>
          }

        </p>
        <p className="footer__copyright">© {new Date().getFullYear()} HubbleIQ Inc. All Rights Reserved.</p>
      </footer>
      <CompanyFooter />
    </>
  );
};

PublicFooter.defaultProps = {
  chromeExtLink: null,
};

PublicFooter.propTypes = {
  chromeExtLink: PropTypes.string,
};

const mapStateToProps = (state) => {
  const {step_3} = state.Company.companyDetails;
  return {
    chromeExtLink: step_3?.chrome_ex_link,
  };
};

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PublicFooter);
