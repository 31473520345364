import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import { Helmet } from 'react-helmet';
import carosel from './Carosel.module.scss';

const Carosel = ({data, settings}) => {
  return (
    <div>
      <Helmet>
        <link
          rel="stylesheet"
          type="text/css"
          charSet="UTF-8"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
        />
      </Helmet>
      <Slider {...settings} className={carosel.carosel}>
        {
          data.map(({image, header, sub_header, id}) => {
            return (
              <div className={carosel.item} key={`testing-carusel-${id}`}>
                {header && <p className={carosel.heading}>{header}</p>}
                {sub_header && <p className={carosel.subheading}>{sub_header}</p>}
                {image && <img className={carosel.image} src={image} alt="carosel img" />}
              </div>
            );
          })
        }
      </Slider>
    </div>
  )
}

Carosel.propTypes = {
  data: PropTypes.array,
  options: PropTypes.object,
}

export default Carosel;
