import { takeEvery, fork, put, all } from 'redux-saga/effects';

import { FETCH_TEST_RESULTS } from './actionTypes';
import { fetchTestResultsSuccess, fetchTestResultsApiError } from './actions';
import apiClient from '../../../gears/apiClient';

function* fetchTestResults({ payload: { data } }) {
  try {
    const response = yield apiClient.get('/activities', data);
    yield put(fetchTestResultsSuccess(response));
  } catch (error) {
    yield put(fetchTestResultsApiError(error));
  }
}

export function* watchTestResultsFetch() {
  yield takeEvery(FETCH_TEST_RESULTS, fetchTestResults);
}

function* testResultsSaga() {
  yield all([fork(watchTestResultsFetch)]);
}

export default testResultsSaga;
