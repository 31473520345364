import React from 'react';
import PropTypes from 'prop-types';
import ReactTable from '../ReactTable';

const ApplicationsTable = ({data, isLoading}) => {
  const colorByStatus = (value) => {
    if (value < 100) {
      return 'good';
    }
    if (value >= 100 && value <= 999) {
      return 'descent';
    }
    return 'poor';
  };

  const avgPageLoadTimeColor = (value) => {
    if (value >= 0 && value <= 5) {
      return 'good';
    }
    if (value > 5 && value <= 10) {
      return 'descent';
    }
    return 'poor';
  };

  const columns = [
    {
      Header: 'Service',
      accessor: 'name',
      Cell: ({row: {original}}) => (
        <a target="_blank" rel="noreferrer" href={original.url} className="applications-link-blok">
          <span className="logo">
            {original.name ? original.name.slice(0, 1) : 'N/A'}
          </span>
          <span className="name">{original.name}</span>
        </a>
      ),
    },
    {
      Header: 'Application Status',
      accessor: 'reachable',
      Cell: ({row: {original}}) => (
        <span className={`application-status-text ${original?.reachable ? 'good' : 'poor'}`}>
          {original.reachable ? 'Reachable' : 'Not Reachable'}
        </span>
      ),
    },
    {
      Header: 'Response Time',
      accessor: 'latency_response',
      Cell: ({row: {original}}) => (
        <div className={`${original?.reachable ? colorByStatus(original.latency_response) : 'text poor'}`}>
          {original.latency_response ? `${original.latency_response}ms` : 'N/A'}
        </div>
      ),
    },
    {
      Header: 'Page load time',
      accessor: 'average_page_load_time',
      Cell: ({row: {original}}) => (
        <div className={`application-page-load ${original?.average_page_load_time ? avgPageLoadTimeColor(original?.average_page_load_time) : 'poor'}`}>
          {original?.average_page_load_time ? `${original?.average_page_load_time}s` : 'N/A'}
        </div>
      ),
    },
  ];

  return (
    <ReactTable
      className="applications-table"
      columns={columns}
      data={data}
      onSortChange={() => {}}
      loading={isLoading}
      manualSortBy
      disableMultiSort
      disableSortBy
    />
  );
};

ApplicationsTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.object,
  ])),
  isLoading: PropTypes.bool,
};

export default ApplicationsTable;
