import { FETCH_USER_RECENT_ACTIVITIES, FETCH_USER_RECENT_ACTIVITIES_SUCCESS, FETCH_USER_RECENT_ACTIVITIES_ERROR } from './actionTypes';

export const fetchUserRecentActivity = (id) => ({
  type: FETCH_USER_RECENT_ACTIVITIES,
  payload: { id },
});

export const fetchUserRecentActivitySuccess = (payload) => ({
  type: FETCH_USER_RECENT_ACTIVITIES_SUCCESS,
  payload,
});

export const fetchUserRecentActivityError = (error) => ({
  type: FETCH_USER_RECENT_ACTIVITIES_ERROR,
  payload: error,
});
