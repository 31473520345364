import {all, fork, put, takeEvery} from 'redux-saga/effects';

// Login Redux States
import {RESET_PASSWORD} from './actionTypes';
import {userResetPasswordSuccess, userResetPasswordError} from './actions';

import apiClient from '../../../gears/apiClient';

// If user is send successfully send mail link then dispatch redux action's are directly from here.
function* resetPassword({payload}) {
  try {
     yield apiClient.post('/password/reset', payload);
     yield put(userResetPasswordSuccess('Password was reset.'));
     payload.history.push('/admin/overview');
  } catch (error) {
    yield put(userResetPasswordError(error));
  }
}

export function* watchUserPasswordReset() {
  yield takeEvery(RESET_PASSWORD, resetPassword);
}

function* resetPasswordSaga() {
  yield all([fork(watchUserPasswordReset)]);
}

export default resetPasswordSaga;
