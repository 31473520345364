import React from 'react';
import PropTypes from 'prop-types';

import { capitalizeFirstLetter } from '../../../helpers/page_helper';

import ServiceExperience from '../service-experience';

const ExpectedExperience = ({ experience }) => {
  const experienceArr = Object.keys(experience).map((e) => {
    return {
      service: capitalizeFirstLetter(e),
      status: JSON.parse(experience[e]),
    };
  });

  return (
    <div className="expected-experience dashboard-experience">
      <span className="dashboard-label">Expected Experience of Popular Services</span>
      <div className="results d-flex flex-column">
        {experienceArr.map((e) => {
          return <ServiceExperience key={e.service} experience={e} />;
        })}
      </div>
    </div>
  );
};

ExpectedExperience.propTypes = {
  experience: PropTypes.object,
};

export default ExpectedExperience;
