import { Button } from "reactstrap";
import moment from 'moment';
import { inviteUser } from "../../../store/actions";
import { capitalizeFirstLetter } from "../../../helpers/page_helper";

export const invitedUsersTableColumns = (dispatch) => (
  [
    {
      Header: 'First name',
      accessor: 'firstName',
    },
    {
      Header: 'Last name',
      accessor: 'lastName',
    },
    {
      Header: 'Email',
      accessor: 'email',
    },
    {
      Header: 'Invited at',
      accessor: 'createdAt',
      Cell: ({ row: {original} }) => (
        original.createdAt ? moment(original.createdAt).format('D MMM YYYY, h:mm:ss A') : 'N/A'
      ),
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: ({row: {original}}) => (
        capitalizeFirstLetter(original.status) || 'N/A'
      ),
    },
    {
      Header: 'Actions',
      access: 'id',
      Cell: ({row: { original }}) => {
        const { firstName, lastName, email } = original;

        return(
          <Button
            className="button button-default"
            type="button"
            onClick={() => dispatch(inviteUser({
              first_name: firstName,
              last_name: lastName,
              email: email,
            }))}
            title="Resend"
          >
            Resend
          </Button>
        );
      },
    },
  ]
);

export const invitedUsersTableFilters = [
  {
    value: 'status',
    label: 'Status',
    type: 'select',
    options: [
      {
        value: 'all',
        label: 'All',
      },
      {
        value: 'pending',
        label: 'Pending',
      },
      {
        value: 'accepted',
        label: 'Accepted',
      },
      {
        value: 'inactive',
        label: 'Inactive',
      },
    ],
  },
  {
    value: 'inviteType',
    label: 'Invite Type',
    type: 'select',
    options: [
      {
        value: 'self',
        label: 'Self',
      },
      {
        value: 'single',
        label: 'Single',
      },
      {
        value: 'bulk',
        label: 'Bulk',
      },
    ],
  },
];