import {
  FETCH_USER_STATUS,
  FETCH_USER_STATUS_SUCCESS,
  FETCH_USER_STATUS_API_ERROR,
  FETCH_SCATTER_CHART,
  FETCH_SCATTER_CHART_SUCCESS,
  FETCH_SCATTER_CHART_API_ERROR,
  FETCH_USER_DEVICE,
  FETCH_USER_DEVICE_SUCCESS,
  FETCH_USER_DEVICE_ERROR,
  FETCH_USER_STATUS_ACTIVITY,
  FETCH_USER_STATUS_ACTIVITY_SUCCESS,
  FETCH_USER_STATUS_ACTIVITY_ERROR,
  FETCH_USER_DATA,
  FETCH_USER_DATA_ERROR,
  FETCH_USER_DATA_SUCCESS,
  REQUEST_REMOTE_TEST,
  REQUEST_REMOTE_TEST_ERROR,
  REQUEST_REMOTE_TEST_SUCCESS,
} from './actionTypes';

export const fetchUserStatus = (history, id, data) => {
  return {
    type: FETCH_USER_STATUS,
    payload: { history, id, data },
  };
};

export const fetchUserStatusSuccess = (payload) => {
  return {
    type: FETCH_USER_STATUS_SUCCESS,
    payload,
  };
};

export const fetchUserStatusApiError = (error) => {
  return {
    type: FETCH_USER_STATUS_API_ERROR,
    payload: error,
  };
};

export const fetchUserStatusActivity = (id) => {
  return {
    type: FETCH_USER_STATUS_ACTIVITY,
    payload: { id },
  };
};

export const fetchUserStatusActivitySuccess = (payload) => {
  return {
    type: FETCH_USER_STATUS_ACTIVITY_SUCCESS,
    payload,
  };
};

export const fetchUserStatusActivityError = (error) => {
  return {
    type: FETCH_USER_STATUS_ACTIVITY_ERROR,
    payload: error,
  };
};

export const fetchScatterChart = (id) => {
  return {
    type: FETCH_SCATTER_CHART,
    payload: { id },
  };
};

export const fetchScatterChartSuccess = (payload) => {
  return {
    type: FETCH_SCATTER_CHART_SUCCESS,
    payload,
  };
};

export const fetchScatterChartApiError = (error) => {
  return {
    type: FETCH_SCATTER_CHART_API_ERROR,
    payload: error,
  };
};

export const fetchUserDevice = (id) => {
  return {
    type: FETCH_USER_DEVICE,
    payload: { id },
  };
};

export const fetchUserDeviceSuccess = (payload) => {
  return {
    type: FETCH_USER_DEVICE_SUCCESS,
    payload,
  };
};

export const fetchUserDeviceError = (error) => {
  return {
    type: FETCH_USER_DEVICE_ERROR,
    payload: error,
  };
};

export const fetchUserData = (id) => {
  return {
    type: FETCH_USER_DATA,
    payload: { id },
  };
};

export const fetchUserDataSuccess = (payload) => {
  return {
    type: FETCH_USER_DATA_SUCCESS,
    payload,
  };
};

export const fetchUserDataError = (error) => {
  return {
    type: FETCH_USER_DATA_ERROR,
    payload: error,
  };
};

export const requestRemoteTest = (payload) => {
  return {
    type: REQUEST_REMOTE_TEST,
    payload,
  };
};

export const requestRemoteTestError = (error) => {
  return {
    type: REQUEST_REMOTE_TEST_ERROR,
    payload: error,
  };
};
export const requestRemoteTestSuccess = (payload) => {
  return {
    type: REQUEST_REMOTE_TEST_SUCCESS,
    payload,
  };
};
