import React from 'react';
import {Link} from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import './session-information.scss'

const SessionInformation = ({details}) => {
    const detailsArr = [
      {
        title: 'Session Date',
        value: details?.session_date,
        isVisible: true,
      },
      {
        title: 'Location',
        value: `${details?.city}, ${details?.region_name}`,
        isVisible: true,
      },
      {
        title: 'Reference Code',
        value: <Link to={`/admin/user-status/${details?.user_id}`}><span>{details?.guid}</span></Link>,
        isVisible: details?.guid,
      },
      {
        title: 'Service Provider',
        value: details?.isp || '-',
        isVisible: details?.isp,
      },
      {
        title: 'Device',
        value: details?.device || '-',
        isVisible: details?.device,
      },
      {
        title: 'Operating System',
        value: details?.os || '-',
        isVisible: details?.os,
      },
      {
        title: 'Service Provider',
        value: details?.service_provider || '-',
        isVisible: details?.service_provider,
      },
      {
        title: 'Browser',
        value: details?.browser || '-',
        isVisible: details?.browser,
      },
      {
        title: 'IP Address',
        value: details?.ip_address || '-',
        isVisible: details?.ip_address,
      },
      {
        title: 'Test Type',
        value: details?.test_type || '-',
        isVisible: details?.test_type,
      },
      {
        title: 'Test Trigger',
        value: details?.trigger_by || '-',
        isVisible: details?.trigger_by,
      },
      {
        title: 'Serial Number',
        value: details?.chromebook_serial || '-',
        isVisible: details?.chromebook_serial,
      },

    ];

    const renderColumns = () => {
      const result = detailsArr.filter((item) => item.isVisible).map((item) => (
        <Col xs="6" sm="4" key={item.title}>
          <div className="item">
            <div className="item-title">{item.title}</div>
            <div>{item.value}</div>
          </div>
        </Col>
      ));
      return result;
    };

  return (
    <div className="session-info">
      <Row className="d-flex flex-row flex-wrap">
        {renderColumns()}
      </Row>
    </div>
  );
};

SessionInformation.propTypes = {};

export default SessionInformation;
