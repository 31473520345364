import React, {useState, useEffect, forwardRef, useImperativeHandle} from 'react';
import PropTypes from 'prop-types';
import {Input} from 'reactstrap';

const SearchByColumn = forwardRef(({
    columnOptions,
    onChange,
    className,
    onKeyUp,
    defaultSelect,
    defaultSelectedOption = null,
    onlySelect = false,
  },
  ref) => {
  const [searchBy, setSearchBy] = useState(columnOptions[0].value);
  const [keyword, setKeyword] = useState('');

  useEffect(() => {
    return onChange({searchBy, keyword});
  }, [keyword]);

  useEffect(() => {
    const selectedColumn = columnOptions.find(({value}) => value === searchBy);
    if (selectedColumn && selectedColumn.type === 'select') {
      if (defaultSelectedOption) {
        setKeyword(defaultSelectedOption);
      } else {
        setKeyword(selectedColumn.options[0].value);
      }
    }
    if (selectedColumn && selectedColumn.type === 'single_select') {
      setKeyword(selectedColumn.value);
    }
  }, [searchBy]);

  useEffect(() => {
    if (defaultSelect) {
      setSearchBy(defaultSelect);
    }
  }, [defaultSelect]);

  useImperativeHandle(ref, () => ({
    reset() {
      setSearchBy(defaultSelect || 'email');
      setKeyword('');
    },
  }));

  const handleSelectByChange = (e) => {
    setKeyword('');
    setSearchBy(e.target.value);
  };

  const renderSearchKeywordInput = () => {
    const selectedColumn = columnOptions.find(({value}) => value === searchBy);
    if (selectedColumn && selectedColumn.type === 'select') {
      return (
        <Input
          className="form-control"
          type="select"
          onChange={(e) => {
            console.log(e.target.value, 'value')
            setKeyword(e.target.value)
          }}
          name="keyword"
          value={keyword}
        >
          {selectedColumn.options.map(({value, label}) => (
            <option
              value={value}
              key={`search-by-keyword-${value}`}
            >
              {label}
            </option>
          ))}
        </Input>
      );
    }
    if ((selectedColumn && selectedColumn.type === 'single_select') || onlySelect) {
      return null;
    }
    return (
      <input
        className="form-control"
        value={keyword}
        onChange={(e) => setKeyword(e.target.value)}
        name="keyword"
        onKeyUp={onKeyUp}
        placeholder="Value"
      />
    );
  };

  return (
    <div className={`search-by-column ${className}`}>
      <div className="input-group test-results-date-picker">
        <Input type="select" onChange={handleSelectByChange} name="select_by" value={searchBy}>
          {
            columnOptions.map(({value, label}) => (
              <option
                value={value}
                key={`search-by-col-${value}`}>
                  {label}
                </option>
            ))
          }
        </Input>
        {renderSearchKeywordInput()}

      </div>
    </div>
  );
});

SearchByColumn.propTypes = {
  columnOptions: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  })).isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default SearchByColumn;
