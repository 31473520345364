import { all, fork, put, takeEvery } from 'redux-saga/effects';
import {DELETE_ADMIN, FETCH_ADMINS, UPDATE_ADMIN_REQUEST} from './actionTypes';

import {
  deleteAdminError,
  deleteAdminSuccess,
  fetchAdminsApiError,
  fetchAdminsSuccess,
  updateAdminError,
  updateAdminSuccess,
} from './actions';
import apiClient from '../../gears/apiClient';
import { toastr } from "react-redux-toastr";

function* fetchAdmins({ payload: { data } }) {
  try {
    const response = yield apiClient.get('/administrators', data);
    yield put(fetchAdminsSuccess(response));
  } catch (error) {
    yield put(fetchAdminsApiError(error));
  }
}

function* deleteAdmin({id}) {
  try {
    yield apiClient.delete(`/administrator/${id}`);
    yield put(deleteAdminSuccess(id));
  } catch (error) {
    yield put(deleteAdminError(error));
    toastr.error('System', error);
  }
}

function* updateAdmin({id, payload, callback}) {
  try {
    yield apiClient.put(`/administrator/${id}`, payload);
    yield put(updateAdminSuccess());
    
    if (callback) {
      callback();
    }
  } catch (error) {
    yield put(updateAdminError(error));
    toastr.error('System', error.errors);
  }
}

export function* watchUpdateAdmin() {
  yield takeEvery(UPDATE_ADMIN_REQUEST, updateAdmin);
}

export function* watchAdminsFetch() {
  yield takeEvery(FETCH_ADMINS, fetchAdmins);
}
export function* watchDeleteAdmin() {
  yield takeEvery(DELETE_ADMIN, deleteAdmin);
}
function* AdminsSaga() {
  yield all([
    fork(watchAdminsFetch),
    fork(watchDeleteAdmin),
    fork(watchUpdateAdmin)
  ]);
}

export default AdminsSaga;
