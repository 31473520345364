import React from 'react';
import {connect} from 'react-redux';
import {Field, Form, Formik} from 'formik';
import * as Yup from 'yup';
import {Button, FormFeedback, FormGroup, Input, Label} from 'reactstrap';
import * as PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import { updateMyProfile} from '../../store/actions';

const validationSchema = Yup.object().shape({
  old_password: Yup.string().required('This field is required'),
  password: Yup.string()
    .min(6, 'Password must have at least 6 characters')
    .required('This field is required'),
  confirm_password: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords don\'t match ')
    .min(6, 'Password must have at least 6 characters')
    .required('This field is required'),
});

const ProfileNewPassword = ({updateMyProfile, history}) => {
  return (
    <Formik
      initialValues={{
        old_password: '',
        password: '',
        confirm_password: '',
      }}
      validationSchema={validationSchema}
      onSubmit={({password}) => {
        updateMyProfile({password}, history, 'toastr');
      }}
      enableReinitialize
    >
      {({errors, touched, handleSubmit, isValid, dirty}) => (
        <Form id="profile-new-form" className="form" onSubmit={handleSubmit}>
          <div className="form-title">Password Reset</div>
          <div className="form-text">
            You can change your password by first entering your current password, then providing a new one.
            You can reset your password from the login screen also.
          </div>
          <FormGroup>
            <Label for="profile-password">Old password</Label>
            <Input
              id="profile-password"
              type="password"
              name="old_password"
              invalid={touched.old_password && !!errors.old_password}
              tag={Field}
            />
            <FormFeedback>{errors.old_password}</FormFeedback>
          </FormGroup>

          <FormGroup>
            <Label for="profile-newPassword">New password</Label>
            <Input
              id="profile-newPassword"
              type="password"
              name="password"
              invalid={touched.password && !!errors.password}
              tag={Field}
            />
            <FormFeedback>{errors.password}</FormFeedback>
          </FormGroup>

          <FormGroup>
            <Label for="profile-repeatPassword">Repeat password</Label>
            <Input
              id="profile-repeatPassword"
              type="password"
              name="confirm_password"
              invalid={touched.confirm_password && !!errors.confirm_password}
              tag={Field}
            />
            <FormFeedback>{errors.confirm_password}</FormFeedback>
          </FormGroup>

          <FormGroup>
            <Button
              type="submit"
              color="primary"
              disabled={!isValid || !dirty}
            >Submit
            </Button>
          </FormGroup>
        </Form>
      )}
    </Formik>
  );
};

ProfileNewPassword.propTypes = {
  updateMyProfile: PropTypes.func.isRequired,
  profile: PropTypes.shape({
    id: PropTypes.number,
    email: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {updateMyProfile};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProfileNewPassword),
);
