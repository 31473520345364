import React, { useState } from 'react';
import { FormGroup } from 'reactstrap';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import * as PropTypes from 'prop-types';
import Button from '../components/Button/Button';
import ComponentLoader from './ComponentLoader';
import apiClient from '../gears/apiClient';
import { toastr } from 'react-redux-toastr';
import { useSelector } from 'react-redux';

const formSchema = Yup.object().shape({
  domain: Yup
    .string()
    .matches(/@([a-zA-Z0-9.-]+\.[a-zA-Z]{2,})/, 'Not correct format')
    .required(),
});

const DeployGoogleAdminForm = ({ close }) => {
  const { companyDetails } = useSelector(state => state.Company)
  const [isLoading, setIsLoading] = useState(false);

  const sendDomain = async (data, resetForm) => {
    setIsLoading(true);
    try {
      await apiClient.put('/update-company-domain', {...data});
      resetForm();
      close();
      toastr.success('Your request will be reviewed within 48 Hours. Once Approved, you will receive an email with automated instructions')
    } catch(e) {
      toastr.error(e.message || e.error)
    }
    setIsLoading(false);
  }

  return (
    <div className="deploy-google-admin">
      {isLoading && <ComponentLoader />}
      <div className="mb-3">Enter your domain, this is how we will verify your account with this organization. If you want to support multiple domains, please insert them with comma separated.</div>
      <Formik
        initialValues={{
          domain: '',
        }}
        validationSchema={formSchema}
        onSubmit={async (values, {resetForm}) => {
          await sendDomain(values, resetForm);
        }}
        enableReinitialize
      >
        {({ errors, touched, handleChange, isValid, dirty }) => (
          <Form id="deploy-form" className="form">
            <FormGroup>
              <Field
                id="domain"
                className="form-control"
                type="text"
                name="domain"
                placeholder="Ex. @mycompany.com"
                onChange={(e) => handleChange(e)}
              />
              {errors.domain && touched.domain ? (
                <div className="error">{errors.domain}</div>
              ) : null}
            </FormGroup>
            {companyDetails?.domain && (
              <div className="mb-4">This is your current approved domain: {companyDetails?.domain}</div>
            )}
            <div className="form-actions">
              <Button
                type="submit"
                generalType="default"
                disabled={!isValid || !dirty}
              >
                Submit Approval
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

DeployGoogleAdminForm.propTypes = {
  close: PropTypes.func,
};

export default DeployGoogleAdminForm;
