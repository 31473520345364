import React from 'react';
import PropTypes from 'prop-types';
import UserQuestions from './userQuestions';

const textStyle = {
  fontWeight: 300,
  fontSize: '12px',
  opacity: 0.85,
};

const UserInputs = ({survery, qa}) => {
  return (
    <div className="mt-40">
      <div className="mt-10">
        <UserQuestions qa={qa} />
        {
          survery.description && (
            <div className="row mt-4">
              <div className="col">
                <h6>Description</h6>
                <p style={textStyle}>{survery.description}</p>
              </div>
            </div>
          )
        }

        {
          survery.email && (
            <div className="row">
              <div className="col">
                <h6>Contact</h6>
                <div>
                  <p className="m-0" style={textStyle}>Name: {survery.name}</p>
                  <p className="m-0" style={textStyle}>E-mail: {survery.email}</p>
                </div>
              </div>
            </div>
          )
        }

      </div>
    </div>
  );
};

UserInputs.defaultProps = {
  survery: {
    description: null,
    name: null,
    email: null,
  },
  qa: [],
};

UserInputs.propTypes = {
  survery: PropTypes.shape({
    description: PropTypes.string,
    name: PropTypes.string,
    email: PropTypes.string,
  }),
  qa: PropTypes.arrayOf(PropTypes.any),
};

export default UserInputs;
