import React, {useEffect, useState} from 'react';
import styles from './on-boarding-tooltip.module.scss';
import {useDispatch, useSelector} from "react-redux";
import {updateOnBoardingStep} from "../../../store/on-boarding/actions";
import {getMyProfile} from "../../../store/profile/actions";
import {Link} from "react-router-dom/cjs/react-router-dom";
import {useHistory} from "react-router-dom";
import InstallExtensionBtn from "../../InstallExtensionBtn/InstallExtensionBtn";

function OnBoardingTooltip({ onClose, style, toggleModal }) {
	const { data: profileData } = useSelector((state) => state.Profile);
	const { onboarding_step: onBoardingStep } = profileData || {};
	const [text, setText] = useState('');
	const [title, setTitle] = useState('');
	const history = useHistory();
	const dispatch = useDispatch();
	
	useEffect(() => {
		if (onBoardingStep === 1) {
			setTitle("Welcome to HubbleIQ!");
			setText("Let’s start by testing your current network. Once complete, this demo page will refresh with your data.");
		}
		if (onBoardingStep === 2) {
			setTitle('Get Started with Real-Time Monitoring');
			setText("Click to install the extension and monitor actively. If you’re set up, invite peers and monitor their user experience with ease.");
		}
	}, [onBoardingStep]);
	
	const handleActionClick = (step) => {
		dispatch(updateOnBoardingStep(step, () => dispatch(getMyProfile(null, {}))));
		history.push("/admin/first-test");
	};
	
	const handleCloseTooltip = (step) => {
		dispatch(updateOnBoardingStep(step, () => dispatch(getMyProfile(null, {}))));
	};
	const renderContentBySteps = (step) => {
		if (step === 1) {
			return (
				<>
					<div className={styles.onBoardingHeader}>
						<p className={styles.onBoardingTitle}>{title}</p>
						<button type="button" className={styles.onBoardingCloseBtn} onClick={onClose}>
							&times;
						</button>
					</div>
					<p className={styles.onBoardingText}>{text}</p>
					<div className={styles.onBoardingBtnWrapper}>
						<button type="button" className={styles.onBoaringBtn} onClick={() => handleActionClick('next')}>Start test</button>
					</div>
				</>
			);
		}
		
		if (step === 2) {
			return (
				<>
					<div className={styles.onBoardingHeader}>
						<p className={styles.onBoardingTitle}>{title}</p>
						<button type="button" className={styles.onBoardingCloseBtn} onClick={() => handleCloseTooltip('next')}>
							&times;
						</button>
					</div>
					<p className={styles.onBoardingText}>{text}</p>
					<div className={styles.onBoardingBtnWrapper}>
					<InstallExtensionBtn type="white" text="Install Extension" />
						<button type="button" className={styles.onBoaringBtn} onClick={() => toggleModal('invite')}>Invite Others</button>
					</div>
				</>
			);
		}
	}
	
	return (
		<div className={`${styles.onBoardingWrapper} ${onBoardingStep === 1 ? styles.noArrow : ''}`} style={style}>
			{renderContentBySteps(onBoardingStep)}
		</div>
);
}

export default OnBoardingTooltip;
