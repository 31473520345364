import React from 'react';
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import AdminForm from "../../../forms/AdminForm";
import Button from "../../../Button/Button";
import * as PropTypes from "prop-types";
import {useDispatch} from "react-redux";
import {deleteAdmin} from "../../../../store/administrators/actions";
import {toastr} from "react-redux-toastr";

function UpdateAdministrator({
 selectedUser,
 isModalOpen,
 toggleModal,
 modalHeaderTitle,
 isEditMode,
 onSuccessSubmit,
}) {
	const dispatch = useDispatch();
	const deleteAdminHandler = (id) => {
		dispatch(deleteAdmin(id));
		toggleModal();
		toastr.success('System', `Administrator ${selectedUser.email} removed successfully`);
	}
	
	return (
		<Modal isOpen={isModalOpen} toggle={toggleModal} className="custom">
			<ModalHeader toggle={toggleModal}>{modalHeaderTitle}</ModalHeader>
			{isEditMode && (
				<ModalBody>
					<AdminForm
						user={selectedUser}
						onSuccessSubmit={onSuccessSubmit}
					/>
				</ModalBody>
			)}
			<ModalFooter>
				{isEditMode && (
					<>
						<Button
							generalType="default"
							onClick={toggleModal}
						>
							Cancel
						</Button>
						<Button
							type="submit"
							form="user-form"
							generalType="default"
						>
							Submit
						</Button>
					</>
				)}
				{!isEditMode && (
					<>
						<Button generalType="default" onClick={toggleModal}>No, Cancel</Button>
						<Button
							type="submit"
							form="user-form"
							generalType="default"
							onClick={() => deleteAdminHandler(selectedUser?.id)}
						>
							Yes, Remove
						</Button>
					</>
				)}
			</ModalFooter>
		</Modal>
	)
}

UpdateAdministrator.propTypes = {
	selectedUser: PropTypes.object,
	isModalOpen: PropTypes.bool,
	toggleModal: PropTypes.func,
	modalHeaderTitle: PropTypes.string,
	isEditMode: PropTypes.bool,
	onSuccessSubmit: PropTypes.func,
};

export default UpdateAdministrator;
