import * as type from './actionTypes';

const initialState = {
  error: null,
  loading: false,
  inviteRequestLoading: false,
  inviteRequestError: null,
  inviteAdminLoading: false,
  inviteAdminError: null,
  testRequestLoading: false,
  testRequestError: null,
};

const fetchUsers = (state = initialState, action) => {
  switch (action.type) {
    case type.FETCH_USERS:
      return {
        ...state,
        data: action.data,
        history: action.history,
        loading: true,
      };
    case type.FETCH_USERS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      };
    case type.FETCH_USERS_API_ERROR:
      return { ...state, error: action.payload, loading: false };
    case type.USER_INVITE_REQUEST:
      return {
        ...state,
        inviteRequestLoading: true,
      };
    case type.USER_INVITE_USERS_SUCCESS:
      return {
        ...state,
        inviteRequestLoading: false,
      };
    case type.USER_INVITE_USERS_API_ERROR:
      return {
        ...state,
        inviteRequestError: action.payload,
        inviteRequestLoading: false,
      };
    case type.ADMIN_INVITE_REQUEST:
      return {
        ...state,
        inviteAdminLoading: true,
      };
    case type.ADMIN_INVITE_SUCCESS:
      return {
        ...state,
        inviteAdminLoading: false,
      };
    case type.ADMIN_INVITE_ERROR:
      return {
        ...state,
        inviteAdminError: action.payload,
        inviteAdminLoading: false,
      };
    case type.USER_TEST_REQUEST:
      return {
        ...state,
        testRequestLoading: true,
    };
    case type.USER_TEST_SUCCESS:
      return {
        ...state,
        testRequestLoading: false,
      };
    case type.USER_TEST_API_ERROR:
      return {
        ...state,
        testRequestError: action.payload,
        testRequestLoading: false,
      };
    case type.USERS_LIST_INVITE_REQUEST:
      return {
        ...state,
        inviteRequestLoading: true,
      };
    case type.USERS_LIST_INVITE_SUCCESS:
      return {
        ...state,
        inviteRequestLoading: false,
      };
    case type.USERS_LIST_INVITE_ERROR:
      return {
        ...state,
        inviteRequestError: action.payload,
        inviteRequestLoading: false,
      };
    case type.CHANGE_USER_STATUS:
      return {
        ...state,
        loading: true,
      };
    case type.CHANGE_USER_STATUS_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          users: state.data.users.map((user) => (
            user.id === action.payload.userId ? { ...user, active: action.payload.active } : user
          )),
        },
        loading: false,
      };
    case type.CHANGE_USER_STATUS_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default fetchUsers;
