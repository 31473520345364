import {UPDATE_USER_REQUEST, UPDATE_USER_SUCCESS, UPDATE_USER_ERROR} from './actionTypes';

export const updateUser = (id, payload, callback, history) => {
  return {
    type: UPDATE_USER_REQUEST,
    id,
    payload,
    callback,
    history,
  };
};

export const updateUserSuccess = (payload) => {
  return {
    type: UPDATE_USER_SUCCESS,
    payload,
  };
};

export const updateUserError = (error) => {
  return {
    type: UPDATE_USER_ERROR,
    payload: error,
  };
};
