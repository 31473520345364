import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Map from 'google-map-react';
import { Col, Row, Tooltip } from 'reactstrap';
import GoogleMapMarker from './GoogleMapMartker';
import { mapStyle } from './mapStyle';

const GoogleMap = ({zoom, center, height, width, markers, onMarkerClick, testId}) => {
  const [localMarkers, setLocalMarkers] = useState([]);

  useEffect(() => {
    if (markers.length > 0) {
      setLocalMarkers(markers);
    }
  }, [markers]);

  const toggle = (id) => {
    const modifiedMarkers = [...localMarkers];
    const currentMarker = modifiedMarkers.find((marker) => marker.test_id === id || marker.user_id === id);
    currentMarker.isActive = !currentMarker.isActive;
    setLocalMarkers(modifiedMarkers);
  };

  const onChildClick = (key, childProps) => {
    if (onMarkerClick) {
      if (testId) {
        onMarkerClick(childProps.testId);
      } else {
        onMarkerClick(childProps.userId);
      }
    }
  };

  return (
    <div style={{height, width}}>
      <Map
        options={{styles: mapStyle}}
        bootstrapURLKeys={{key: 'AIzaSyCef0iKZyYc_qUHM9Iv79U_FEDFbZYRgTs'}}
        defaultCenter={center}
        defaultZoom={zoom}
        onChildClick={onChildClick}
      >
        {testId
          ? (
            localMarkers.map(({lat, lng, status, test_id, download, upload, date, isActive}, index) => (
              <GoogleMapMarker
                lat={lat}
                lng={lng}
                key={index}
                status={status}
                testId={test_id}
                content={(
                  <Tooltip
                    placement="top"
                    isOpen={isActive}
                    target={`test_${test_id}`}
                    toggle={() => toggle(test_id)}
                    style={{
                      width: '100%',
                      maxWidth: '224px',
                      padding: '24px 16px',
                      textAlign: 'left',
                    }}
                  >
                    <Row>
                      <Col xs="6">Download:</Col>
                      <Col xs="6">{`${download} mbps`}</Col>
                    </Row>
                    <Row>
                      <Col xs="6">Upload:</Col>
                      <Col xs="6">{`${upload} mbps`}</Col>
                    </Row>
                    <Row>
                      <Col xs="6">Date:</Col>
                      <Col xs="6">{date}</Col>
                    </Row>
                  </Tooltip>
                )}
              />
            ))
          )
          : (
            localMarkers.map((
              {lat, lng, status, user_id, download, upload, date, isActive, email}, index,
            ) => (
              <GoogleMapMarker
                lat={lat}
                lng={lng}
                key={index}
                status={status}
                userId={user_id}
                download={download}
                upload={upload}
                date={date}
                content={(
                  <Tooltip
                    placement="top"
                    isOpen={isActive}
                    target={`user_${user_id}`}
                    toggle={() => toggle(user_id)}
                    style={{
                      width: '100%',
                      maxWidth: '224px',
                      padding: '24px 16px',
                      fontSize: '12px',
                      lineHeight: '16px',
                      color: '#FFFFFF',
                      textAlign: 'left',
                    }}
                  >
                    <div style={{display: 'flex', alignItems: 'center', marginBottom: '12px'}}>
                      <div
                        style={{
                          flex: 1,
                          marginRight: '8px',
                          fontSize: '15px',
                          lineHeight: '20px',
                        }}
                      >
                        {email}
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          width: '24px',
                          minWidth: '24px',
                          height: '24px',
                          color: '#4F46E5',
                          backgroundColor: '#FAF5FF',
                          border: '1px solid #DBDBDB',
                          borderRadius: '100%',
                          overflow: 'hidden',
                        }}
                      >
                        <i className="mdi mdi-account-outline" />
                      </div>
                    </div>
                    <Row style={{marginBottom: '4px'}}>
                      <Col xs="6">Download:</Col>
                      <Col xs="6">{`${download} mbps`}</Col>
                    </Row>
                    <Row style={{marginBottom: '4px'}}>
                      <Col xs="6">Upload:</Col>
                      <Col xs="6">{`${upload} mbps`}</Col>
                    </Row>
                    <Row style={{marginBottom: '4px'}}>
                      <Col xs="6">Date:</Col>
                      <Col xs="6">{date}</Col>
                    </Row>
                  </Tooltip>
                )}
              />
            ))
          )}
      </Map>
    </div>
  );
};

GoogleMap.defaultProps = {
  center: {
    lat: 26.000133,
    lng: 4.6759309,
  },
  zoom: 0,
  height: '312px',
  width: '100%',
  markers: [],
};

GoogleMap.propTypes = {
  center: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }),
  zoom: PropTypes.number,
  height: PropTypes.string,
  width: PropTypes.string,
  onMarkerClick: PropTypes.func,
  markers: PropTypes.arrayOf(PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
    status: PropTypes.string,
    user_id: PropTypes.number,
    test_id: PropTypes.number,
  })),
};

export default GoogleMap;
