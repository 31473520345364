import React, { useEffect, useState } from 'react';
import {connect} from 'react-redux';
import { Col, Row, Spinner, Tooltip } from 'reactstrap';
import { withRouter, useHistory, Link } from 'react-router-dom';
import queryString from 'query-string';
import Pagination from '../../components/pagination';
import StatsCard from '../../components/cards/StatsCard';
import ApplicationsEventsTable from '../../components/tables/ApplicationsEventsTable';
import { getApplicationsEvents } from '../../store/actions';
import AhoyTrack from '../../modules/AhoyTrack';
import ApplicationsTable from '../../components/tables/ApplicationsTable';
import AiResponse from '../../components/aiResponse/AiResponse';
import { buildPaginationData } from '../../utility/utils';

const Applications = ({
  loading,
  appsActivity,
  appsLoading,
  appsEvents,
  testDetailsData,
  location,
  getApplicationsEvents,
  isAiFeaturesEnabled
}) => {
  AhoyTrack();
  const [ filter, setFilter ] = useState(null);
  const [ sortBy, setSortBy ] = useState(null);
  const [ paginationData, setPaginationData ] = useState(null);
  const [ tooltipOpen1, setTooltipOpen1 ] = useState(false);
  const [ tooltipOpen2, setTooltipOpen2 ] = useState(false);
  const [ tooltipOpen3, setTooltipOpen3 ] = useState(false);
  const [ tooltipOpen4, setTooltipOpen4 ] = useState(false);
  const [ tooltipOpen5, setTooltipOpen5 ] = useState(false);

  const history = useHistory();
  const {filter_by} = queryString.parse(location?.search);

  const toggle = (id) => {
    switch (id) {
      case 1:
        setTooltipOpen1(!tooltipOpen1);
        break;
      case 2:
        setTooltipOpen2(!tooltipOpen2);
        break;
      case 3:
        setTooltipOpen3(!tooltipOpen3);
        break;
      case 4:
        setTooltipOpen4(!tooltipOpen4);
        break;
      case 5:
        setTooltipOpen5(!tooltipOpen5);
        break;
    }
  };

  const fetchData = (page) => {
    let queryParams = {};

    if (page) {
      queryParams.page = page;
    }

    if (sortBy) {
      queryParams.sort_by = sortBy;
    }

    if (filter) {
      queryParams = {...queryParams, ...filter};
    }

    if (testDetailsData?.guid) {
      const myFilter = {search_by: 'guid', keyword: testDetailsData?.guid};
      queryParams = {...queryParams, ...myFilter};
    }

    getApplicationsEvents(queryParams, history);
  };

  useEffect(() => {
    if (appsEvents?.activities?.length > 0) {
      setPaginationData(buildPaginationData(appsEvents));
    }
  }, [appsEvents]);

  useEffect(() => {
    if (testDetailsData?.name) {
      fetchData();
    }
  }, [testDetailsData]);

  const onTableSortChange = (sortBy) => {
    if (sortBy) {
      setSortBy(sortBy);
      fetchData();
    }
  };

  const navigateToPage = (page) => {
    fetchData(page);
  };

  const aiPostData = {
    user_id: appsEvents?.activities?.[0].user_id
  }

  const isUserid = appsEvents?.activities?.[0].user_id

  return (
    <>
      {(isUserid && isAiFeaturesEnabled) &&  (
        <div className="mb-5">
          <AiResponse
            title="application experience summary"
            type="userApplicationTab"
            data={aiPostData}
          />
        </div>
      )}
      {appsLoading && <div className="spinner-wrapper"><Spinner /></div>}
      {!appsLoading && (
        <>
          {Boolean(appsActivity?.unencrypted_site_visit) && (
            <div className="tab-block">
              <div className="tab-block-title">
                BEHAVIORAL SECURITY
              </div>
              <div className="tab-block-subtitle">
                The gauge below is a count of unencrypted web domains visited during this time period. Visiting unencrypted sites can increase security risk and create a threat vector.
              </div>
              <Row>
                <Col xs="12" md="6" xl="4">
                  <StatsCard
                    title={(
                      <span>
                        Unencrypted sites visited
                        <span>
                          <i id="usv" className="mdi mdi-information ml-2" />
                          <Tooltip placement="top" isOpen={tooltipOpen2} target="usv" toggle={() => toggle(2)}>
                            These gauges show a rollup of this user’s overall internet experience over this time period
                          </Tooltip>
                        </span>
                      </span>
                    )}
                    text={(
                      <span className="text-decent">{appsActivity?.unencrypted_site_visit}</span>
                    )}
                    loading={appsLoading}
                  />
                </Col>
              </Row>
            </div>
          )}

          {appsActivity?.average_page_load && (
            <div className="tab-block">
              <div className="tab-block-title">
                General browsing experience
              </div>
              <div className="tab-block-subtitle">
                This gauge provides a measurement of the overall browsing experience for this user. High page load times can indicate an issue with the connection to the internet, including across WiFi and the ISP.
              </div>
              <Row>
                <Col xs="12" md="6" xl="4">
                  <StatsCard
                    title={(
                      <span>
                        Average page load
                        <span>
                          <i id="gbe" className="mdi mdi-information ml-2" />
                          <Tooltip placement="top" isOpen={tooltipOpen3} target="gbe" toggle={() => toggle(3)}>
                            General browsing experience
                          </Tooltip>
                        </span>
                      </span>
                    )}
                    text={(
                      <span className="text-good">
                        {appsActivity?.average_page_load}
                        <span>ms</span>
                      </span>
                    )}
                    loading={appsLoading}
                  />
                </Col>
              </Row>
            </div>
          )}

          <div className="tab-block">
            <div className="tab-block-title">
              {appsActivity?.recent_activities?.length > 0 ? 'Business critical applications' : 'No data to show'}
            </div>
            {appsActivity?.recent_activities?.length > 0 && (
              <>
                <div className="tab-block-subtitle">
                  The list of applications below represent the reachability of your organizations business critical applications. Each service is tested when HubbleIQ connects to the network. For business applications visited by this user, their average response time is measured and provided below.
                </div>
                <ApplicationsTable data={appsActivity?.recent_activities} isLoading={appsLoading} />
                <div className="tab-block-link text-right mt-4">
                  <Link to="/admin/applications-settings" className="button button-default-outlined">Add applications</Link>
                </div>
              </>
            )}
          </div>
        </>
      )}

      <div className="tab-block">
        <div className="tab-block-title">
          Application Test Results
          <span>All</span>
        </div>
        <div className="tab-block-subtitle">A complete record of all application tests.</div>
        <Row className="align-items-center">
          <Col xl={12}>
            <div className="table-responsive">
              <ApplicationsEventsTable
                fetch={fetchData}
                data={appsEvents?.activities}
                isLoading={loading}
                onSortChange={onTableSortChange}
                disableSortBy
              />
            </div>
            {(appsEvents?.activities?.length > 0 && paginationData) && <Pagination paginationData={paginationData} navigateTo={navigateToPage} />}
          </Col>
        </Row>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  const { error, loading, appsEvents } = state.Applications;
  const { data: testDetailsData } = state.TestDetails;
  const { ai_features_enabled: isAiFeaturesEnabled } = state.Company.companyDetails;
  return { error, loading, appsEvents, testDetailsData, isAiFeaturesEnabled };
};

export default withRouter(connect(mapStateToProps, { getApplicationsEvents })(Applications));
