import React, {useEffect, useState} from 'react';
import {Button, Card, CardBody, Col, Row, Spinner} from 'reactstrap';
import { toastr } from 'react-redux-toastr';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import Popup from '../../components/popup/Popup';
import ExportPopup from '../../components/exportPopup/ExportPopup';
import {fetchNetworkAnalytics, fetchRecentActivities, fetchUsersSpeed, fetchUsersTests} from '../../store/dashboard/activities/actions';
import AhoyTrack from '../../modules/AhoyTrack';
import MapViewFilterModal from '../../components/mapViewFilterModal/MapViewFilterModal';
import { filterMapFilters, initialFilters } from '../../components/mapViewFilterModal/mapViewFilterHelper';
import apiClient from '../../gears/apiClient';
import MapViewCards from '../../components/MapViewCards/MapViewCards';
import UsersSpeedChart from '../../components/UsersSpeedChart';
import MapBoxSwitch from '../../components/MapBox/MapBoxSwitch';
import MapBoxContainer from '../../components/MapBox/MapBoxContainer';

const MapView = () => {
  AhoyTrack();
  const {
    loading: recentActivitiesLoading,
  } = useSelector((state) => state.RecentActivities);
  const [isFilterModalOpen, setIsFilterOpenModal] = useState(false);
  const [filters, setFilters] = useState(initialFilters);
  const [isUsersTestsData, setIsUsersTestsData] = useState(true);
  const [isExportPopupOpen, setIsExportPopupOpen] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchUsersSpeed());
    dispatch(fetchNetworkAnalytics());
    dispatch(fetchUsersTests(history));
  }, []);

  const toggleModal = () => {
    setIsFilterOpenModal(!isFilterModalOpen);
  };

  const onFilterApply = () => {
    const params = filterMapFilters(filters);
    dispatch(fetchUsersSpeed(params));
    dispatch(fetchNetworkAnalytics(params));
    if (isUsersTestsData) {
      dispatch(fetchUsersTests(history, params));
    } else {
      dispatch(fetchRecentActivities(history, params));
    }
    setIsFilterOpenModal(false);
  };

  const exportData = async (email) => {
    const params = {email, ...filterMapFilters(filters)};

    const request = () => {
      if (isUsersTestsData) {
        return apiClient.get('/export_user_map_points', params);
      }
      return apiClient.get('/export_recent_activities', params);
    };
    try {
      request();
    } catch (e) {
      toastr.error('System', e.message);
    }
  };

  const exportPopupCloseHandler = () => {
    setIsExportPopupOpen(false);
  };

  const changeMapData = () => {
    const params = filterMapFilters(filters);
    setIsUsersTestsData((prevState) => !prevState);
    if (isUsersTestsData) {
      dispatch(fetchRecentActivities(history, params));
    } else {
      dispatch(fetchUsersTests(history, params));
    }
  };

  return (
    <>
      <div className="container-fluid content map-view">
        <Row className="page-title">
          <Col sm={6}>
            <div className="page-title-box">
              <h2>Broadband Insights</h2>
            </div>
          </Col>
          <Col sm={6}>
            <div className="page-actions d-flex flex-column align-items-end">
              <div className="d-flex">
                <Button
                  className="mr-2 button button-default"
                  onClick={() => setIsExportPopupOpen(true)}
                  disabled={recentActivitiesLoading}
                >
                  Export Data
                </Button>
                <Button
                  className="button button-default"
                  onClick={toggleModal}
                  disabled={recentActivitiesLoading}
                >
                  Filter
                </Button>
              </div>
              <div className="map mt-4">
                <div className="map-nav">
                  <MapBoxSwitch
                    id="map-data"
                    leftLabel="Users"
                    rightLabel="Tests"
                    value={isUsersTestsData}
                    onHandlerChange={changeMapData}
                    isChecked={!isUsersTestsData}
                  />
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card className="recent-tests-card mb-0">
              <CardBody>
                <h4 className="card-title">Map</h4>
                <div id="usa" className="vector-map-height">
                  {recentActivitiesLoading && <div className="text-center"><Spinner color="primary" /></div>}
                  {
                    !recentActivitiesLoading && (
                      <MapBoxContainer
                        filters={filters}
                        isUsersTestsData={isUsersTestsData}
                      />
                    )
                  }
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Card className="mt-4">
          <CardBody>
            <MapViewCards />
          </CardBody>
        </Card>
        <Card className="recent-tests-card">
          <CardBody>
            <h4 className="card-title">Number of Users vs. Average Speed</h4>
            <UsersSpeedChart />
          </CardBody>
        </Card>
        <MapViewFilterModal
          show={isFilterModalOpen}
          toggleModal={toggleModal}
          filters={filters}
          setFilters={setFilters}
          onFilterApply={onFilterApply}
        />
      </div>
      {isExportPopupOpen && (
        <Popup
          customClass="export"
          close={exportPopupCloseHandler}
        >
          <ExportPopup
            onSubmit={exportData}
            close={exportPopupCloseHandler}
            isMapView
            noSave
          />
        </Popup>
      )}
    </>
  );
};

export default MapView;
