import React, { useEffect, useState } from 'react';
import { Row, Col, Card, Alert } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { Helmet } from 'react-helmet';
import Loader from '../../components/Loader';
import { loginUser } from '../../store/actions';
import iconGoogle from '../../images/google-logo.svg';
import TrialPopup from './TrialPopup';
import './Authentification.scss';
import { COOKIE_NAME_FROM_PATH, COOKIE_NAME_TOKEN } from '../../gears/constants';
import AuthHelper from '../../helpers/auth_helper';

let basePath = '/admin/broadband-insights';
const subscriptionPath = '/admin/subscriptions';

const Login = () => {
  const { companyLogo, isActivePlan, ownerSetupStep } = useSelector(state => state.Company);
  const { error, loading } = useSelector(state => state.Login);
  const [googleError, setGoogleError] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [trialText, setTrialText] = useState(false);
  const [demoUserId, setDemoUserId] = useState(null);
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const isGoingToSubscription = !isActivePlan && ownerSetupStep > 5;

  useEffect(() => {
    const url = location?.search;
    const searchParamsUrl = new URLSearchParams(url);
    const trial = searchParamsUrl.get('trial');
    const hasError = searchParamsUrl.has('error');
    if (searchParamsUrl.get('token')) {
      window.Cookies.set(COOKIE_NAME_TOKEN, searchParamsUrl.get('token'));
    }
    const authHelper = new AuthHelper();
    const authToken = authHelper.getAuthenticatedUser();
    const isTokenExpired = authHelper.isTokenExpired();
    setDemoUserId(searchParamsUrl.get('demo_user_id')); // we need to ask RUBY to add this in the cookie

    if(isGoingToSubscription) {
      if (authToken && !isTokenExpired) {
        window.location.assign(subscriptionPath);
      }
    } else {
      if (location?.state?.from) {
        const { pathname, search} = location.state.from;
        window.Cookies.set(COOKIE_NAME_FROM_PATH, pathname);
        if (search) {
          window.Cookies.set(COOKIE_NAME_FROM_PATH, `${pathname}${search}`);
          basePath = `${pathname}${search}`;
        } else {
          basePath = pathname;
        }
      } else if (window.Cookies.get(COOKIE_NAME_FROM_PATH)) {
        basePath = window.Cookies.get(COOKIE_NAME_FROM_PATH);
      }
  
      if (hasError) {
        setGoogleError(searchParamsUrl.get('error'))
      }
  
      if (authToken && !isTokenExpired) {
        if (url.includes('unsubscribe')) {
          window.location.assign('/admin/profile?unsubscribe=true');
        } else {
          window.location.assign(basePath);
        }
      } else if (trial) {
        showPopup(true);
        setTrialText(true);
      }
    }
  }, [])

  const handleValidSubmit = (event, values) => {
    event.preventDefault();
    dispatch(loginUser(values, history, () => {
      if(isGoingToSubscription) {
        console.log('HERE')
        basePath = subscriptionPath
      } else {
        if (window.Cookies.get(COOKIE_NAME_FROM_PATH)) {
          basePath = window.Cookies.get(COOKIE_NAME_FROM_PATH);
        } else if (demoUserId) {
          basePath = `/admin/user-status/${demoUserId}`; // we need to ask RUBY to add this in the cookie
        }
      }
      window.location.assign(basePath);
    }));
  }

  const showError = () => {
    if (error || googleError) {
      if (error) {
        return <Alert color="danger">{error.message || error.error}</Alert>;
      }

      if (googleError) {
        return <Alert color="danger">{googleError}</Alert>;
      }
    }

    return null;
  }

  return (
    <>
      <Helmet>
        <title>Login</title>
      </Helmet>
      <div className="account-pages">
        {loading && <Loader />}
        <div className="blue-header" />
        <div className="form-container">
          <Card className="login-form">
            <div className="logo-container">
              <div className="logo-img">
                <img src={companyLogo} alt="logo" />
              </div>
            </div>
            <div className="login-form-content">
              <div className="mb-3 text-center">
                <span className="login-header">
                  {trialText ? 'Admin Overview Login' : 'Welcome back!'}
                </span>
              </div>
              <div className="login-google">
                <a
                  className="link_google"
                  href="/api-v2/google_sign_in?source=web"
                >
                  <span className="icon">
                    <img src={iconGoogle} alt="google icon" className="img-responsive" />
                  </span>
                  Sign in with Google
                </a>
                <div className="login-google-separator"><span>Or</span></div>
              </div>
              <AvForm className="form-horizontal mt-4" onValidSubmit={handleValidSubmit}>
                {showError()}
                <div className="form-group">
                  <AvField
                    name="email"
                    label="Email"
                    className="form-control email"
                    placeholder="Enter your email"
                    type="email"
                  />
                </div>
                <div className="form-group">
                  <AvField
                    name="password"
                    label="Password"
                    type="password"
                    className="password"
                    placeholder="Password"
                  />
                </div>

                <Row className="form-group">
                  <Col sm={6}>
                    &nbsp;
                    {/* <div className="custom-control custom-checkbox">
                                                            <input type="checkbox" className="custom-control-input" id="customControlInline" />
                                                            <label className="custom-control-label" for="customControlInline">Remember me</label>
                                                        </div> */}
                  </Col>
                  <Col lg={12} className="login-button-container">
                    <button className="btn auth-button" type="submit">
                      Log In
                    </button>
                  </Col>
                  <Col lg={12} className="forgot-password-container">
                    <div className="col-12 mt-4 forgot-password mb-3">
                      <Link to="/admin/forget-password">
                        <i className="mdi mdi-lock" />
                        {' '}
                        Forgot your password?
                      </Link>
                    </div>
                  </Col>
                </Row>
              </AvForm>
              <div className="hubbleiq-copy">
                <p className="mb-0">©{new Date().getFullYear()} HubbleIQ INC. All Right Reserved.</p>
              </div>
            </div>
          </Card>
        </div>
        {showPopup && <TrialPopup closePopup={() => setShowPopup(false)} />}
      </div>
    </>
  );
}

export default Login;
