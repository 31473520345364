import React, { useState } from 'react'
import HopsDetailCards from './HopsDetailCards'
import Averages from '../averages/Averages'

const Hops = ({hops}) => {
  const [isDetailCardOpen, setIsDetailCarsOpen] = useState(false)

  return (
    <div className="mb-6">
      <Averages
        hops={hops}
        valueClick={isDetailCardOpen}
        onValueClick={setIsDetailCarsOpen}
      />
      {isDetailCardOpen && (
        <HopsDetailCards hops={hops} />
      )}
    </div>
  )
}

export default Hops