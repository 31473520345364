import React from 'react'
import SummaryAverages from "../../summaryAverages/SummaryAverages";

const HopsSummary = ({hops}) => {
	return (
		<div className="mb-6">
			<SummaryAverages
				hops={hops}
			/>
		</div>
	)
}

export default HopsSummary;
