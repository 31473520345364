import {
  GET_ISSUE_LOGS,
  GET_ISSUE_LOGS_SUCCESS,
  GET_ISSUE_LOGS_ERROR,
  GET_REPORED_LOGS,
  GET_REPORED_LOGS_SUCCESS,
  GET_REPORED_LOGS_ERROR
} from "./actionTypes";

const initialState = {
  data: [],
  loading: false,
  error: null,
};

const IssueLogsList = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_ISSUE_LOGS:
      return {
        ...state,
        loading: true,
      };
    case GET_ISSUE_LOGS_SUCCESS:
      return {
        ...state,
        data: payload,
        loading: false,
      };
    case GET_ISSUE_LOGS_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case GET_REPORED_LOGS:
      return {
        ...state,
        loading: true,
      };
    case GET_REPORED_LOGS_SUCCESS:
      return {
        ...state,
        data: payload,
        loading: false,
      };
    case GET_REPORED_LOGS_ERROR:
      return {
        ...state,
        data: [],
        loading: false,
        error: payload,
      };
    default:
      return state;
  }
};

export default IssueLogsList;
