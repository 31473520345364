import React, { useState } from 'react';
import {Col, Input, FormGroup, Label, Row, Tooltip} from 'reactstrap';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import {connect} from 'react-redux';
import * as PropTypes from 'prop-types';
import { updateHelpdeskMessage } from '../../store/actions';
import AhoyTrack from '../../modules/AhoyTrack';
import Button from '../Button/Button';

const HelpDeskForm = ({
  dispatchUpdateHelpdeskMessage,
  isHelpdeskMessageLoading,
  helpdeskMessage,
  imageSrc,
  currentEXLogo,
}) => {
  const extensionSchema = Yup.object().shape({
    chrome_head_request: Yup.string().max(50, 'Maximum 50 characters').required('Field is required'),
    chrome_sub_head_request: Yup.string().required('Field is required'),
    support_email: Yup.string().email().required('Field is required'),
    custom_redirect_url: Yup.string().url().required('Field is required'),
  });

  const [ preview, setPreview ] = useState(true);
  const [ tooltipOpen1, setTooltipOpen1 ] = useState(false);
  const [ tooltipOpen2, setTooltipOpen2 ] = useState(false);

  const onPreviewClick = () => {
    setPreview(!preview);
  };

  const toggle = (id) => {
    switch (id) {
      case 1:
        setTooltipOpen1(!tooltipOpen1);
        break;
      case 2:
        setTooltipOpen2(!tooltipOpen2);
        break;
    }
  };

  AhoyTrack('button');

  return (
    <div className="settings-tab-block">
      <div className="title">Helpdesk Integration</div>
      <div className="subtitle">
        By configuring this page below, users will be able to request help directly from their Chrome extension.
        You will be notified of help requests in the agent portal, and an e-mail will generated and sent to your desired location, such as a helpdesk qeue for automatic ticket generation.
      </div>
      <Formik
        initialValues={{
          chrome_head_request: helpdeskMessage?.chrome_head_request || '',
          chrome_sub_head_request: helpdeskMessage?.chrome_sub_head_request || '',
          support_email: helpdeskMessage?.support_email || '',
          custom_redirect_url: helpdeskMessage?.custom_redirect_url || '',
        }}
        validationSchema={extensionSchema}
        onSubmit={(values) => dispatchUpdateHelpdeskMessage(values)}
        enableReinitialize
      >
        {({ handleChange, values, errors }) => (
          <Form className="form form-helpdesk">
            <Row>
              <Col xl="6">
                <div className="settings-tab-item">
                  <Row>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="helpdesk-headline">Headline Text</Label>
                        <Input
                          id="helpdesk-headline"
                          className={`form-control ${errors.chrome_head_request ? 'is-invalid' : ''}`}
                          maxLength="50"
                          name="chrome_head_request"
                          onChange={(e) => handleChange(e)}
                          tag={Field}
                          disabled={isHelpdeskMessageLoading}
                        />
                        <div className="character-count">
                          Character count: <span>{values.chrome_head_request?.length}</span>/<span>50</span>
                        </div>
                      </FormGroup>
                      <FormGroup>
                        <Label for="helpdesk-subheadline">Sub-headline Text</Label>
                        <Field
                          id="helpdesk-subheadline"
                          className={`form-control ${errors.chrome_sub_head_request ? 'is-invalid' : ''}`}
                          name="chrome_sub_head_request"
                          type="textarea"
                          onChange={(e) => handleChange(e)}
                          as="textarea"
                          disabled={isHelpdeskMessageLoading}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label for="helpdesk-email">
                          Helpdesk Email address
                          <span className="card-tooltip">
                            <i id="hea" className="mdi mdi-help-circle-outline text-primary font-size-16 ml-1" />
                            <Tooltip placement="right" isOpen={tooltipOpen1} target="hea" toggle={() => toggle(1)}>
                              Submitted help requests will generate an e-mail and deliver to this address. This can be a helpdesk e-mail queue for auto ticket generation.
                            </Tooltip>
                          </span>
                        </Label>
                        <Input
                          id="helpdesk-email"
                          className={`form-control ${errors.support_email ? 'is-invalid' : ''}`}
                          name="support_email"
                          type="email"
                          onChange={(e) => handleChange(e)}
                          tag={Field}
                          disabled={isHelpdeskMessageLoading}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label for="extension-url">
                          Submit Redirect URL
                          <span className="card-tooltip">
                            <i id="sru" className="mdi mdi-help-circle-outline text-primary font-size-16 ml-1" />
                            <Tooltip placement="right" isOpen={tooltipOpen2} target="sru" toggle={() => toggle(2)}>
                              Once a request is submitted by the end-user, they will be redirected to this specificed page.
                            </Tooltip>
                          </span>
                        </Label>
                        <Input
                          id="extension-url"
                          className={`form-control ${errors.custom_redirect_url ? 'is-invalid' : ''}`}
                          name="custom_redirect_url"
                          onChange={(e) => handleChange(e)}
                          tag={Field}
                          disabled={isHelpdeskMessageLoading}
                        />
                      </FormGroup>
                      <div className="form-btn d-flex justify-content-between">
                        <Button type="submit" generalType="default" disabled={isHelpdeskMessageLoading}>Save changes</Button>
                        <Button generalType="default" onClick={onPreviewClick}>Preview</Button>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col xl="6">
                {preview && (
                  <div className="extension-preview-wrapper">
                    <div className="extension-preview helpdesk">
                      <div className="extension-preview-header">
                        <div className="extension-preview-header-top">
                          <div className="extension-preview-update">
                            <div className="icon">
                              <i className="mdi mdi-arrow-left font-size-20" />
                            </div>
                          </div>
                          <div className="extension-preview-logo">
                            { imageSrc && <img src={imageSrc} alt="logo" className="img-responsive" /> }
                            { !imageSrc && currentEXLogo && <img src={currentEXLogo} alt="logo" className="img-responsive" /> }
                          </div>
                        </div>
                        <div
                          className="extension-preview-title"
                          dangerouslySetInnerHTML={{__html: values?.chrome_head_request}}
                        />
                        <div
                          className="extension-preview-subtitle"
                          dangerouslySetInnerHTML={{__html: values?.chrome_sub_head_request}}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </div>
  );
};

HelpDeskForm.defaultProps = {
};

HelpDeskForm.propTypes = {
  dispatchUpdateHelpdeskMessage: PropTypes.func.isRequired,
  isHelpdeskMessageLoading: PropTypes.bool.isRequired,
  helpdeskMessage: PropTypes.shape({
    chrome_head_request: PropTypes.string,
    chrome_sub_head_request: PropTypes.string,
    support_email: PropTypes.string,
    custom_redirect_url: PropTypes.string,
  }),
};

const mapStateToProps = (state) => {
  const {isHelpdeskMessageLoading, helpdeskMessage = {}} = state.EXConfig;
  return {isHelpdeskMessageLoading, helpdeskMessage};
};

const mapDispatchToProps = {
  dispatchUpdateHelpdeskMessage: updateHelpdeskMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(HelpDeskForm);
