import {
  HELP_REQUEST,
  HELP_REQUEST_SUCCESS,
  HELP_REQUEST_API_ERROR,
 } from './actionTypes';

const initialState = {
  error: null,
  loading: false,
  successSubmit: false,
};

const helpRequest = (state = initialState, action) => {
  switch (action.type) {
    case HELP_REQUEST:
      return {
        ...state,
        data: action.data,
        loading: true,
      };
    case HELP_REQUEST_SUCCESS:
      return {
        ...state,
        data: action.payload,
        successSubmit: true,
        loading: false,
      };
    case HELP_REQUEST_API_ERROR:
      return { ...state, error: action.payload, loading: false };
    default:
      return { ...state };
  }
};

export default helpRequest;
