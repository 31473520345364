import React from 'react';

const AthertonfiberFooter = () => {
  return (
    <div className="athertonfiber-footer ">
      <div className="container">
        <div className="row">
          <div className="col-md-8 col-sm-6 col-xs-12">
            <a href="https://www.athertonfiber.com" target="_blank" rel="noreferrer">
              <img
                src="https://www.athertonfiber.com/wp-content/uploads/2020/05/af-logo-hdr-TEXTONLY.png"
                width="200"
                height="47"
                alt=""
              />
            </a>
            <p className="socialLinks">
              <a
                href="https://www.facebook.com/Atherton-Fiber-103330767679188"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="https://www.athertonfiber.com/wp-content/uploads/2019/06/Social-Facebook.png"
                  width="25"
                  height="25"
                  alt=""
                />
              </a>
              <a href="https://twitter.com/AthertonFiber" target="_blank" rel="noreferrer">
                <img
                  src="https://www.athertonfiber.com/wp-content/uploads/2019/06/Social-Twitter.png"
                  width="25"
                  height="25"
                  alt=""
                />
              </a>
              <a href="https://www.instagram.com/athertonfibernet/" target="_blank" rel="noreferrer">
                <img
                  src="https://www.athertonfiber.com/wp-content/uploads/2019/08/Social-Instagram.png"
                  width="25"
                  height="25"
                  alt=""
                />
              </a>
            </p>
            <p>
              <a href="https://www.athertonfiber.com/privacy-policy/" target="_blank" rel="noreferrer">
                <span>Privacy Policy</span>
              </a>
              <a
                href="https://www.athertonfiber.com/careers/"
                target="_blank"
                rel="noreferrer"
              >
                <span>Careers</span>
              </a>
            </p>
          </div>
          <div className="col-md-4 col-sm-6 col-xs-12 column-2">
            <div className="textwidget">
              <p>
                <strong>Atherton Fiber Inc.</strong><br />
                5758 Geary Blvd #423<br />
                San Francisco, CA 94121
              </p>
              <p>
                <span style={{fontWeight: 800}}>833-OPEN5GN<br />
                  (833-673-6546)
                </span>
              </p>
              <p className="left-foot-item">
                <a href="mailto:support@athertonfiber.com">support@athertonfiber.com</a>
                <a
                  href="mailto:sales@athertonfiber.com"
                >sales@athertonfiber.com
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AthertonfiberFooter;
