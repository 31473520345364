export const GET_APPLICATIONS = 'GET_APPLICATIONS';
export const GET_APPLICATIONS_SUCCESS = 'GET_APPLICATIONS_SUCCESS';
export const GET_APPLICATIONS_ERROR = 'GET_APPLICATIONS_ERROR';

export const ADD_APPLICATION = 'ADD_APPLICATION';
export const ADD_APPLICATION_SUCCESS = 'ADD_APPLICATION_SUCCES';
export const ADD_APPLICATION_ERROR = 'ADD_APPLICATION_ERROR';

export const DELETE_APPLICATION = 'DELETE_APPLICATION';
export const DELETE_APPLICATION_SUCCESS = 'DELETE_APPLICATION_SUCCES';
export const DELETE_APPLICATION_ERROR = 'DELETE_APPLICATION_ERROR';

export const UPDATE_APPLICATION = 'UPDATE_APPLICATION';
export const UPDATE_APPLICATION_SUCCESS = 'UPDATE_APPLICATION_SUCCES';
export const UPDATE_APPLICATION_ERROR = 'UPDATE_APPLICATION_ERROR';

export const GET_APPLICATIONS_ACTIVITY = 'GET_APPLICATIONS_ACTIVITY';
export const GET_APPLICATIONS_ACTIVITY_SUCCESS = 'GET_APPLICATIONS_ACTIVITY_SUCCES';
export const GET_APPLICATIONS_ACTIVITY_ERROR = 'GET_APPLICATIONS_ACTIVITY_ERROR';

export const GET_APPLICATIONS_EVENTS = 'GET_APPLICATIONS_EVENTS';
export const GET_APPLICATIONS_EVENTS_SUCCESS = 'GET_APPLICATIONS_EVENTS_SUCCES';
export const GET_APPLICATIONS_EVENTS_ERROR = 'GET_APPLICATIONS_EVENTS_ERROR';
