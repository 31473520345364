import React, { useEffect } from 'react';
import { Switch, BrowserRouter as Router } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// Import Routes
import { authProtectedRoutes, publicRoutes } from './routes';
import AppRoute from './routes/route';

// layouts
import VerticalLayout from './components/VerticalLayout';
import NonAuthLayout from './components/NonAuthLayout';

import { fetchCompanyLogo, fetchCompanyDetails } from './store/actions';
import GoogleAnalytics from './components/GoogleAnalytics';

// Import scss
// import './stylesheets/scss/theme.scss';
// import '../packs/main.scss';

const App = () => {
  const { layoutType } = useSelector(state => state.Layout);
  const { companyDetailsLoading } = useSelector(state => state.Company)
  const dispatch = useDispatch();

  useEffect(() => {
    const windowWidth = window.innerWidth;
    if (typeof window !== 'undefined' && windowWidth < 768) {
      window.location = 'hubbleiq://';
    }

    dispatch(fetchCompanyLogo());
    dispatch(fetchCompanyDetails());
  }, []);

  const getLayout = () => {
    let layoutCls = VerticalLayout;

    switch (layoutType) {
      // case 'horizontal':
      //   layoutCls = HorizontalLayout;
      //   break;
      default:
        layoutCls = VerticalLayout;
        break;
    }
    return layoutCls;
  };

  const Layout = getLayout();

  if (companyDetailsLoading) {
    return '';
  }

  return (
    <>
      <GoogleAnalytics />
      <Router>
        <Switch>
          {publicRoutes.map((route, idx) => (
            <AppRoute
              path={route.path}
              layout={NonAuthLayout}
              component={route.component}
              key={idx}
              isAuthProtected={false}
              exact={route.exact}
            />
          ))}

          {authProtectedRoutes.map((route, idx) => (
            <AppRoute
              path={route.path}
              layout={Layout}
              component={route.component}
              key={idx}
              isAuthProtected
              exact={route.exact}
            />
          ))}
        </Switch>
      </Router>
    </>
  );
}

export default App;
