import * as type from './actionTypes';

const initialState = {
  error: null,
  loading: false,
};

const AdminsStore = (state = initialState, action) => {
  switch (action.type) {
    case type.FETCH_ADMINS:
      return {
        ...state,
        data: action.data,
        loading: true,
      };
    case type.FETCH_ADMINS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      };
    case type.FETCH_ADMINS_API_ERROR:
      return { ...state, error: action.payload, loading: false };
    case type.DELETE_ADMIN:
      return {
        ...state,
        loading: true,
      };
    case type.DELETE_ADMIN_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          users: state.data.users.filter((user) => user.id !== action.payload),
        },
        loading: false,
      };
    case type.DELETE_ADMIN_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case type.UPDATE_ADMIN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case type.UPDATE_ADMIN_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      };
    case type.UPDATE_ADMIN_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default AdminsStore;
