import {all, fork, put, takeEvery} from 'redux-saga/effects';

import {UPDATE_ONBOARDING_STEP} from './actionTypes';
import {updateOnBoardingStepError, updateOnBoardingStepSuccess} from './actions';
import apiClient from '../../gears/apiClient';

function* updateOnBoardingStep({ payload: { step, cb } }) {
  try {
    const response = yield apiClient.post('/update-onboarding-step', {'navigate': step});
    if (response) {
      if (typeof cb === 'function') {
        cb();
      }
    }
    yield put(updateOnBoardingStepSuccess(response));
  } catch (error) {
    yield put(updateOnBoardingStepError(error));
  }
}

export function* watchUpdateOnBoardingStep() {
  yield takeEvery(UPDATE_ONBOARDING_STEP, updateOnBoardingStep);
}

function* OnBoardingSaga() {
  yield all([
    fork(watchUpdateOnBoardingStep),
  ]);
}

export default OnBoardingSaga;
