import * as c from './actionTypes';

export const fetchRecentActivities = (history, params = {}) => {
  return {
    type: c.FETCH_RECENT_ACTIVITIES,
    payload: {history, params},
  };
};

export const fetchRecentActivitiesSuccess = (payload) => {
  return {
    type: c.FETCH_RECENT_ACTIVITIES_SUCCESS,
    payload,
  };
};

export const fetchRecentActivitiesApiError = (error) => {
  return {
    type: c.FETCH_RECENT_ACTIVITIES_API_ERROR,
    payload: error,
  };
};

export const fetchNetworkAnalytics = (payload) => {
  return {
    type: c.FETCH_NETWORK_ANALYTICS,
    payload,
  };
};

export const fetchNetworkAnalyticsSuccess = (payload) => {
  return {
    type: c.FETCH_NETWORK_ANALYTICS_SUCCESS,
    payload,
  };
};

export const fetchNetworkAnalyticsError = (error) => {
  return {
    type: c.FETCH_NETWORK_ANALYTICS_ERROR,
    payload: error,
  };
};

export const fetchUsersSpeed = (payload) => {
  return {
    type: c.FETCH_USERS_SPEED,
    payload,
  };
};

export const fetchUsersSpeedSuccess = (payload) => {
  return {
    type: c.FETCH_USERS_SPEED_SUCCESS,
    payload,
  };
};

export const fetchUsersSpeedError = (error) => {
  return {
    type: c.FETCH_USERS_SPEED_ERROR,
    payload: error,
  };
};

export const createActivity = (data) => ({
  type: c.CREATE_ACTIVITY_REQUEST,
  payload: {data},
});

export const createActivitySuccess = (payload) => ({
  type: c.CREATE_ACTIVITY_SUCCESS,
  payload,
});

export const createActivityError = (error) => ({
  type: c.CREATE_ACTIVITY_ERROR,
  payload: error,
});

export const fetchUsersTests = (history, params = {}) => {
  return {
    type: c.FETCH_USERS_TESTS,
    payload: {history, params},
  };
};

export const fetchUsersTestsSuccess = (payload) => {
  return {
    type: c.FETCH_USERS_TESTS_SUCCESS,
    payload,
  };
};

export const fetchUsersTestsError = (error) => {
  return {
    type: c.FETCH_USERS_TESTS_ERROR,
    payload: error,
  };
};
