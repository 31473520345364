import React, { useEffect, useState } from 'react'
import { useHistory } from "react-router-dom";
import { Col, Row } from 'reactstrap';
import { issuesTabs, renderTabs } from '../../helpers/tabs_helper';

const Issues = () => {
  const history = useHistory();
  const query = new URLSearchParams(history.location.search);
  const [activeTab, setActiveTab] = useState(query.get('tab') || 'network');
  
  useEffect(() => {
    history.replace(`?tab=${activeTab}`);
  }, [activeTab, history]);

  return (
    <div className="container-fluid content">
      <Row className="page-title align-items-center">
        <Col sm={6}>
          <div className="page-title-box">
            <h2>Issues</h2>
          </div>
        </Col>
      </Row>
      <div className="settings-customization recent-tests-card">
        {renderTabs(issuesTabs, activeTab, setActiveTab)}
      </div>
    </div>
  )
};

export default Issues
