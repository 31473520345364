import {
	FETCH_USER_ISSUES_LOGS,
	FETCH_USER_ISSUES_LOGS_SUCCESS,
	FETCH_USER_ISSUES_LOGS_ERROR,
	CHANGE_ISSUE_STATUS,
	CHANGE_ISSUE_STATUS_SUCCESS,
	CHANGE_ISSUE_STATUS_ERROR
} from "./actionTypes";

export const fetchUserIssuesLogs = (id) => ({
	type: FETCH_USER_ISSUES_LOGS,
	payload: { id },
});

export const fetchUserIssuesLogsSuccess = (payload) => ({
	type: FETCH_USER_ISSUES_LOGS_SUCCESS,
	payload,
});

export const fetchUserIssuesLogsError = (error) => ({
	type: FETCH_USER_ISSUES_LOGS_ERROR,
	payload: error,
});

export const changeIssueStatus = (id, status, completionNotes, sendEmail) => ({
	type: CHANGE_ISSUE_STATUS,
	payload: { id, status, completionNotes, sendEmail },
});

export const changeIssueStatusSuccess = (payload) => ({
	type: CHANGE_ISSUE_STATUS_SUCCESS,
	payload,
});

export const changeIssueStatusError = (error) => ({
	type: CHANGE_ISSUE_STATUS_ERROR,
	payload: error,
});

