import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import {fetchUserIssuesDetails} from "../../../../store/user-issue-details/actions";
import styles from './issue-descripton.module.scss'

function IssueDescription({ issueId }) {
	const dispatch = useDispatch();
	const { data, loading } = useSelector((state) => state.UserIssuesDetails);

	useEffect(() => {
		if (issueId) {
			dispatch(fetchUserIssuesDetails(issueId));
		}
	}, [dispatch, issueId]);
	const firstCityAndCountry = (data && data?.location?.length > 0) && `${data?.location[0].city || '-'}, ${data?.location[0]?.country || '-'}`;

	return (
		<div className={styles.issueDescription}>
			<div className={styles.issueDescriptionTop}>
				<div>
					<p className={styles.issueDescriptionTitle}>Reported Date</p>
					<p className={styles.issueDescriptionValue}>{data?.createdAt}</p>
				</div>
				<div>
					<p className={styles.issueDescriptionTitle}>Location</p>
					<p className={styles.issueDescriptionValue}>{firstCityAndCountry || '-'}</p>
				</div>
				<div className={styles.issueDescriptionReference}>
					<p className={styles.issueDescriptionTitle}>Reference Code</p>
					<p className={styles.issueDescriptionValue}>
						<Link to={`/admin/user-status/${data.userId}`}>{data?.guid}</Link>
					</p>
				</div>
			</div>
			{data?.description && (
				<div className={styles.issueDescriptionBottom}>
					<div>
						<p className={styles.issueDescriptionTitle}>Reported Problem</p>
						<p className={styles.issueDescriptionValue}>{data?.description}</p>
					</div>
				</div>
			)}
		</div>
	);
}

export default IssueDescription;
