import {all, call, fork, put, takeEvery} from 'redux-saga/effects';

import BluetoothHelper from '../../helpers/bluetooth_helper';
import AuthHelper from '../../helpers/auth_helper';
import {
    addBluetoothDeviceError, addBluetoothDeviceSuccess,
    deleteBluetoothDeviceError, deleteBluetoothDeviceSuccess,
    getBluetoothDevicesError, getBluetoothDevicesSuccess,
    updateBluetoothDeviceError, updateBluetoothDeviceSuccess,
} from './actions';
import {
  ADD_BLUETOOTH_DEVICE,
  DELETE_BLUETOOTH_DEVICE,
  GET_BLUETOOTH_DEVICES,
  UPDATE_BLUETOOTH_DEVICE,
} from './actionTypes';

const authHelper = new AuthHelper();
const bluetoothHelper = new BluetoothHelper();

function* getBluetoothDevices() {
  try {
    const token = authHelper.getAuthenticatedUser();
    const response = yield call(bluetoothHelper.fetchBluetoothDevices, token);
    yield put(getBluetoothDevicesSuccess(response.data));
  } catch (error) {
    yield put(getBluetoothDevicesError(error));
  }
}

export function* watchGetBluetoothDevices() {
  yield takeEvery(GET_BLUETOOTH_DEVICES, getBluetoothDevices);
}

function* addBluetoothDevice({payload}) {
  try {
    const token = authHelper.getAuthenticatedUser();
    const response = yield call(bluetoothHelper.addBluetoothDevice, token, payload);
    yield put(addBluetoothDeviceSuccess(response.data));
  } catch (error) {
    yield put(addBluetoothDeviceError(error));
  }
}

export function* watchAddBluetoothDevice() {
  yield takeEvery(ADD_BLUETOOTH_DEVICE, addBluetoothDevice);
}

function* deleteBluetoothDevice({payload}) {
  try {
    const token = authHelper.getAuthenticatedUser();
    yield call(bluetoothHelper.deleteBluetoothDevice, token, payload);
    yield put(deleteBluetoothDeviceSuccess(payload.id));
  } catch (error) {
    yield put(deleteBluetoothDeviceError(error));
  }
}

export function* watchDeleteBluetoothDevice() {
  yield takeEvery(DELETE_BLUETOOTH_DEVICE, deleteBluetoothDevice);
}

function* updateBluetoothDevice({payload}) {
  try {
    const token = authHelper.getAuthenticatedUser();
    yield call(bluetoothHelper.updateBluetoothDevice, token, payload);
    yield put(updateBluetoothDeviceSuccess(payload));
  } catch (error) {
    yield put(updateBluetoothDeviceError(error));
  }
}

export function* watchUpdateBluetoothDevice() {
  yield takeEvery(UPDATE_BLUETOOTH_DEVICE, updateBluetoothDevice);
}

function* applicationsSaga() {
  yield all([
    fork(watchGetBluetoothDevices),
    fork(watchAddBluetoothDevice),
    fork(watchDeleteBluetoothDevice),
    fork(watchUpdateBluetoothDevice),
  ]);
}

export default applicationsSaga;
