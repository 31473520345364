import React, { useMemo, useCallback, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { getColumns } from "./Issues-columns.helper";
import ReactTable from "../../ReactTable";
import IssueDetailsDrawer from "../../drawers/IssueDetailsDrawer/IssueDetailsDrawer";

const IssuesTable = ({
   data,
   isLoading,
   onSortChange,
   userId,
 }) => {
	const [isDrawerOpen, setIsDrawerOpen] = useState(false);
	const [issueDetailId, setIssueDetailId] = useState(null);

	const onDrawerClose = () => {
		setIsDrawerOpen(false);
		setIssueDetailId(null);
	};
	
	const onDrawerOpen = (id) => {
		setIssueDetailId(id);
		setIsDrawerOpen(true);
	};
	
	const filteredData = useMemo(() => {
		return data.slice(-10).sort((a, b) => new Date(b.start) - new Date(a.start));
	}, [data]);
	
	const columns = useMemo(() => getColumns(onDrawerOpen), [onDrawerOpen, userId]);
	
	const onSort = useCallback(({ sortBy }) => {
		if (onSortChange) {
			if (sortBy.length > 0) {
				const [{ id, desc }] = sortBy;
				onSortChange(`${id} ${desc ? 'desc' : 'asc'}`);
			} else {
				onSortChange();
			}
		}
	}, [onSortChange]);
	
	const handleRowClick = (rowData) => {
		onDrawerOpen(rowData.id);
	};
	
	return (
		<>
			<div style={{ overflowY: 'scroll', maxHeight: '125px' }}>
				<ReactTable
					columns={columns}
					data={filteredData}
					onSortChange={onSort}
					loading={isLoading}
					manualSortBy
					disableMultiSort
					disableSortBy={true}
					isSticky={true}
					onRowClick={handleRowClick}
				/>
			</div>
			<IssueDetailsDrawer
				isOpen={isDrawerOpen}
				onHandlerClose={onDrawerClose}
				issueId={issueDetailId}
			/>
		</>
	);
};

export default withRouter(IssuesTable);
