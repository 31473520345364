// globals NDTWrapper, startTest, downloadSpeed, uploadSpeed, currentPhase, PHASE_UPLOAD, PHASE_DOWNLOAD
// globals PHASE_RESULTS, readNDTvar, jitter

import axios from 'axios';

const ndt7Host = 'https://locate.measurementlab.net/ndt7';
const nd7Scripts = [
  '/ndt7/script.js',
  '/ndt7/ndt-browser-client.js',
  '/ndt7/ndt-wrapper.js',
];

class Mlab {
  loadScripts = () => {
    return new Promise((resolve) => {
      const existingScript = document.getElementById('ndt7-script-0');
      if (!existingScript) {
        nd7Scripts.forEach((scriptPath, index) => {
          const script = document.createElement('script');
          script.src = scriptPath;
          script.id = `ndt7-script-${index}`;
          script.async = true;

          if (index === nd7Scripts.length - 1) {
            script.onload = () => {
              resolve();
            };
          }
          document.body.appendChild(script);
        });
      } else {
        resolve();
      }
    });
  }

  runTest = async (callback) => {
    await this.loadScripts();
    await this.setNdtServer();
    console.log('start test')

    window.startTest();

    const interval = setInterval(() => {
      const downloadSpeedVal = window.downloadSpeed();
      const uploadSpeedVal = window.uploadSpeed(false);

      if (window.currentPhase === window.PHASE_RESULTS) {
        const params = {
          download: Number(downloadSpeedVal).toFixed(2),
          upload: Number(uploadSpeedVal).toFixed(2),
          jitter: Number(window.jitter()).toFixed(),
          latency: Number(window.readNDTvar('MinRTT')).toFixed(),
        };

        callback(params);
        clearInterval(interval);
      }
    }, 100);
  };

  setNdtServer = async () => {
    if (!/^ndt.iupui.mlab.+\.measurement-lab.org$/.test(window.location.hostname)) {
      const { data } = await axios.get(ndt7Host);
      if (data) {
        window.ndtServer = data.fqdn || 'ndt-iupui-mlab3-beg01.mlab-oti.measurement-lab.org';
      } else {
        throw new Error('Ndt server not set!!!');
      }
    }
  };
}

export default Mlab;
