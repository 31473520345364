import { jwtDecode } from 'jwt-decode';
import { COOKIE_NAME_TOKEN } from '../gears/constants';
class AuthHelper {
  /**
   * Returns the authenticated user
   */
  getAuthenticatedUser = () => {
    if (!window.Cookies?.get(COOKIE_NAME_TOKEN)) return null;
    return window.Cookies.get(COOKIE_NAME_TOKEN);
  };
  
  isTokenExpired = () => {
    const token = this.getAuthenticatedUser();
    if (token) {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000;
      return decodedToken.exp < currentTime;
    }
    return true; //We will return true if we don't have a token.
  };

  /**
   * Handle the error
   * @param {*} error
   */
  handleError = (error) => {
    // var errorCode = error.code;
    return error.message;
  };
}

export default AuthHelper;
