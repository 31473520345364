export const FETCH_TEST_DETAILS = 'FETCH_TEST_DETAILS';
export const FETCH_TEST_DETAILS_SUCCESS = 'FETCH_TEST_DETAILS_SUCCESS';
export const FETCH_TEST_DETAILS_API_ERROR = 'FETCH_TEST_DETAILS_API_ERROR';

export const FETCH_TEST_DETAILS_ACTIVITY = 'FETCH_TEST_DETAILS_ACTIVITY';
export const FETCH_TEST_DETAILS_ACTIVITY_SUCCESS = 'FETCH_TEST_DETAILS_ACTIVITY_SUCCESS';
export const FETCH_TEST_DETAILS_ACTIVITY_ERROR = 'FETCH_TEST_DETAILS_ACTIVITY_ERROR';

export const FETCH_TEST_DETAILS_AVERAGES = 'FETCH_TEST_DETAILS_AVERAGES';
export const FETCH_TEST_DETAILS_AVERAGES_SUCCESS = 'FETCH_TEST_DETAILS_AVERAGES_SUCCESS';
export const FETCH_TEST_DETAILS_AVERAGES_ERROR = 'FETCH_TEST_DETAILS_AVERAGES_ERROR';
