import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link, withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import {Collapse} from 'reactstrap';
import {Link as LinkScroll} from 'react-scroll';
import {createActivity} from '../../store/dashboard/activities/actions';

class TestResultBlock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAdvanceResult: false,
    };
  }

  async componentDidMount() {
    const {createActivity, webTestResult} = this.props;
    const submitTestResult = !!+localStorage.getItem('submitTestResult');

    if (submitTestResult) {
      await createActivity(webTestResult);
      localStorage.setItem('submitTestResult', 0);
    }
  }

  toggleShowAdvanceResult = () => {
    this.setState(({showAdvanceResult}) => ({
      showAdvanceResult: !showAdvanceResult,
    }));
  };

  renderHtml = (html) => {
    return {__html: html || ''};
  }

  render() {
    const {webTestResult, activityData, activityLoading, requestHelpBtnLabel} = this.props;
    if (activityLoading) {
      return <></>;
    }
    const healthMaping = {
      decent: 'yellow',
      good: 'green',
      bad: 'red',
    };
    const {showAdvanceResult} = this.state;

    const renderPacketLoss = () => {
      if (webTestResult.packet_loss === null || webTestResult.packet_loss === undefined) {
        return '';
      }

      const isGood = webTestResult.packet_loss < 6;

      return (
        <div className="col results-table__col">
          <p className="results-table__heading">Packet loss</p>
          <div className="results-table__speed">
            <div>
              <span>{webTestResult.packet_loss}</span>
              <span>%</span>
            </div>
            <div className={`wifi-status-icon${isGood ? ' good' : ''}`}>
              <i className={`mdi${isGood ? ' mdi-wifi' : ' mdi-wifi-strength-4-alert'}`} />
            </div>
          </div>
        </div>
      );
    };

    return (
      <>
        <div>
          <div className="container">
            <div className="row">
              <div className="col-lg-10 col-12 or-1">
                <p className="heading">{activityData?.headline}</p>
              </div>
              <div className="col-lg-10 col-12 or-2">
                <p className="subheading" dangerouslySetInnerHTML={this.renderHtml(activityData?.body)} />
              </div>
            </div>
          </div>
        </div>

        <div className="results-table">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-12 results-table__col">
                <p className="results-table__heading">CONNECTION HEALTH</p>
                <div className="results-table__details">
                  <p className={healthMaping[activityData?.connection_health?.hd_streaming]}>HD Streaming Video</p>
                  <p className={healthMaping[activityData?.connection_health?.screen_sharing]}>Screen Sharing</p>
                  <p className={healthMaping[activityData?.connection_health?.streaming]}>Streaming Video</p>
                  <p className={healthMaping[activityData?.connection_health?.file_sharing]}>File Sharing</p>
                  <p className={healthMaping[activityData?.connection_health?.internet_voice]}>Internet Voice</p>
                </div>
              </div>
              <div className="col-lg-6 col-12 results-table__col mb-0">
                <div className="row">
                  <div className="col">
                    <div className="row">
                      <div className="col-6 results-table__col">
                        <p className="results-table__heading">DOWNLOAD SPEED</p>
                        <div className="results-table__speed">
                          <div>
                            <span>{webTestResult.download}</span>
                            <span>mbps</span>
                          </div>
                        </div>
                      </div>
                      <div className="col-6 results-table__col">
                        <p className="results-table__heading">UPLOAD SPEED</p>
                        <div className="results-table__speed">
                          <div>
                            <span>{webTestResult.upload}</span>
                            <span>mbps</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <Collapse isOpen={showAdvanceResult}>
                      <div id="advance-tet-result" className="row">
                        <div className="col">
                          <div className="row">
                            <div className="col results-table__col">
                              <p className="results-table__heading">JITTER</p>
                              <div className="results-table__speed">
                                <div>
                                  <span>{webTestResult.jitter}</span>
                                  <span>ms</span>
                                </div>
                              </div>
                            </div>
                            <div className="col results-table__col">
                              <p className="results-table__heading">LATENCY</p>
                              <div className="results-table__speed">
                                <div>
                                  <span>{webTestResult.latency}</span>
                                  <span>ms</span>
                                </div>
                              </div>
                            </div>
                            {renderPacketLoss()}
                          </div>
                          <div className="row">
                            <div className="col results-table__col last_column">
                              <p className="results-table__heading">ISP</p>
                              <div>
                                <span>{activityData?.isp}</span>
                              </div>
                            </div>
                            <div className="col results-table__col">
                              <p className="results-table__heading">IP</p>
                              <div>
                                <span>{activityData?.ip_address}</span>
                              </div>
                            </div>
                            <div className="col results-table__col">
                              <p className="results-table__heading">Test ID</p>
                              <div>
                                <span>{activityData?.test_id}</span>
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                    </Collapse>

                  </div>
                </div>
              </div>

              <div className="col-12 results-table__advanced">
                <button
                  type="button"
                  onClick={this.toggleShowAdvanceResult}
                  className={showAdvanceResult ? 'opened' : ''}
                >Advanced
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="page-result_mid">
          <div className="container">
            <div className="row">
              <div className="col-6">
                <Link to="/testing" className="button button-dark button-md">RESTART TEST</Link>
              </div>
              <div className="col-6 text-right">
                <LinkScroll
                  className="button button-transparent button-md"
                  to="help-section"
                  spy
                  smooth
                  duration={700}
                >{requestHelpBtnLabel}
                </LinkScroll>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

TestResultBlock.propTypes = {
  webTestResult: PropTypes.shape({
    clientIp: PropTypes.string.isRequired,
    download: PropTypes.string.isRequired,
    jitter: PropTypes.string.isRequired,
    latency: PropTypes.string.isRequired,
    upload: PropTypes.string.isRequired,
    packet_loss: PropTypes.number.isRequired,
  }).isRequired,
  activityLoading: PropTypes.bool,
  requestHelpBtnLabel: PropTypes.string,
};

const mapStateToProps = (state) => {
  const {data, loading} = state.RecentActivities;
  const {step_3} = state.Company.companyDetails;
  return {
    activityData: data || {},
    activityLoading: loading,
    requestHelpBtnLabel: step_3?.custom_request_btn_label || 'Request help',
  };
};

const mapDispatchToProps = {
  createActivity,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TestResultBlock));
