import $ from 'jquery';
//https://packetlosstest.com/
import WebsocketClient from './socket';
import RTCClient from './rtc';

class PingTest extends EventTarget {
    constructor(configuration = {}) {
        //Handle any setup in parent
        super();

        //Track connection state
        this._state = {
            timeout: null,
            socket: null,
            rtc: null,
            running: false,
            connection: {
                socket: false,
                rtc: false
            }
        };

        //Initialize the data
        this._reset();

        //Set the configuration
        this.update(configuration);
    }

    update(configuration = {}) {
        if (this._state.running === true) { return; }

        //Store the configuration
        // eslint-disable-next-line no-undef
        this._configuration = $.extend(true, {},
            {
                test: {
                    count: 50,
                    frequency: 50,
                    delay: 25,
                    size: {
                        length: 35,
                        character: ' '
                    },
                    wait: 500
                },
                socket: {
                    host: 'ws://near.hubbleiq.com:10000'
                },
                rtc: {
                    initiator: true,
                    channelConfig: {
                        ordered: false,
                        maxRetransmits: 0
                    }
                }
            },
            configuration
        );
    }

    run() {
        //Scope this
        const self = this;

        //Early out if running
        if (self._state.running === true) { return; }

        //Set running state
        self._state.running = true;

        //Emit start event
        self.dispatchEvent(new Event('start'));

        //Setup
        self._initialize();

        //Start communication channels
        self._state.socket.connect();

        //Wait for tests to complete
        self._state.timeout = setTimeout(() => {
            //Close down connections
            self._close();

            //Emit done event
            self.dispatchEvent(
                new CustomEvent("done", {detail: {
                    tcp: self._processResults('tcp'),
                    udp: self._processResults('udp')
                }})
            );

            //Set running state
            self._state.running = false;
        }, Math.round(self._configuration.test.frequency * self._configuration.test.count + self._configuration.test.wait));
    }

    _initialize() {
        //Close existing connections if open
        this._close();

        //Clear stats
        this._reset();

        //Create connections
        this._connect();

        //Set up event handlers
        this._handlers();
    }

    _connect() {
        //Create communication channels
        this._state.socket = new WebsocketClient(this._configuration.socket);
        this._state.rtc = new RTCClient(this._configuration.rtc);
    }

    _handlers() {
        this._socketHandlers();
        this._rtcHandlers();
    }

    _socketHandlers() {
        //Scope this
        const self = this;

        //handle open event
        self._state.socket.addEventListener('open', () => {
            //Update connection state
            self._state.connection.socket = true;

            //Emit connected event
            self.dispatchEvent(new Event('ping-tcp-connected'));

            //Start test
            self._runTCPPingTest();

            //Start udp client
            self._state.rtc.start();
        });

        //Handle message event
        self._state.socket.addEventListener('message', event => {
            //Parse message
            const message = JSON.parse(event.detail.message);

            //Process messages
            switch (message.type.toLowerCase()) {
                //Update the rtc connection with the signal information from the host
                case 'signal':
                    //Send signal to client
                    self._state.rtc.signal(message.data);

                    break;

                //Process tcp ping
                case 'ping':
                    //Process the ping
                    let ping = self._processPing(self._data.tcp, message.data);

                    //Emit ping event
                    self.dispatchEvent(new CustomEvent("ping-tcp-received", {detail: {ping: ping}}));

                    break;

                case 'results':
                case 'connect':
                case 'close':
                    break;

                default:
                    console.error(message);
            }
        });

        //Handle close event
        self._state.socket.addEventListener('close', () => {
            //Update connection state
            self._state.connection.socket = false;

            //Terminate test
            self._terminateTests();

            //Emit close event
            self.dispatchEvent(new Event('ping-tcp-close'));
        });

        //Handle error event
        self._state.socket.addEventListener('error', event => {
            //Update connection state
            self._state.connection.socket = false;

            //Terminate test
            self._terminateTests();

            //Emit error event
            self.dispatchEvent(new CustomEvent('ping-tcp-error', {detail: {message: event.detail?.error}}));
        });
    }

    _rtcHandlers() {
        //Scope this
        const self = this;

        //handle signal event
        self._state.rtc.addEventListener('signal', event => {
            self._state.socket.send(
                self._toSocketMessage('signal', event.detail.signal)
            );
        });

        //Handle connect event
        self._state.rtc.addEventListener('connect', () => {
            //Update connection state
            self._state.connection.rtc = true;

            //Emit connected event
            self.dispatchEvent(new Event('ping-udp-connected'));

            //Start test
            self._runUDPPingTest();
        });

        //Handle data event
        self._state.rtc.addEventListener('data', event => {
            //Process the ping
            let ping = self._processPing(self._data.udp, event.detail.data);

            //Emit received event
            self.dispatchEvent(new CustomEvent("ping-udp-received", {detail: {ping: ping}}));
        });

        //Handle close event
        self._state.rtc.addEventListener('close', () => {
            //Update connection state
            self._state.connection.rtc = false;

            //Terminate test
            self._terminateTests();

            //Emit close event
            self.dispatchEvent(new Event('ping-udp-close'));
        });

        //Handle error event
        self._state.rtc.addEventListener('error', event => {
            //Update connection state
            self._state.connection.rtc = false;

            //Terminate test
            self._terminateTests();

            //Emit error event
            self.dispatchEvent(new CustomEvent('ping-udp-error', {detail: {message: event.detail.error}}));
        });
    }

    _runTCPPingTest() {
        //Scope this
        const self = this;

        //Test data
        const data = self._data.tcp;

        //Emit start event
        self.dispatchEvent(new Event("ping-tcp-start"));

        //Start test
        data.interval = setInterval(() => {
            //Terminate when requested pings have been sent
            if (data.terminated || data.sent >= self._configuration.test.count) {
                //Clear interval
                clearInterval(data.interval);

                //Emit done event
                self.dispatchEvent(new Event("ping-tcp-done"));

                //Abort further processing
                return;
            }

            //Create the pine
            let ping = {
                index : ++data.sent,
                sent  : new Date().getTime()
            };

            //Send ping
            try {
                //Send the ping
                self._state.socket.send(
                    self._toSocketMessage(
                        'ping',
                        ping,
                        self._configuration.test.size.length,
                        self._configuration.test.size.character
                    )
                );

                //Emit ping event
                self.dispatchEvent(new CustomEvent("ping-tcp-sent", {detail: {error: false, ping: ping}}));
            } catch(error) {
                //Emit ping event
                self.dispatchEvent(new CustomEvent("ping-tcp-sent", {detail: {error: true, ping: ping, message: error}}));
            }
        }, self._configuration.test.frequency);
    }

    _runUDPPingTest() {
        //Scope this
        const self = this;

        //Test data
        const data = self._data.udp;

        //Emit start event
        self.dispatchEvent(new Event("ping-udp-start"));

        //Start test
        data.interval = setInterval(() => {
            //Terminate when requested pings have been sent
            if (data.terminated === true || data.sent >= self._configuration.test.count) {
                //Clear interval
                clearInterval(data.interval);

                //Emit done event
                self.dispatchEvent(new Event("ping-udp-done"));

                //Abort further processing
                return;
            }

            //Create the pine
            let ping = {
                index : ++data.sent,
                sent  : new Date().getTime()
            };

            //Send ping
            try {
                //Send the ping
                self._state.rtc.send(
                    self._padString(
                        JSON.stringify(ping),
                        self._configuration.test.size.length,
                        self._configuration.test.size.character
                    )
                );

                //Emit ping event
                self.dispatchEvent(new CustomEvent("ping-udp-sent", {detail: {error: false, ping: ping}}));
            } catch(error) {
                //Emit ping event
                self.dispatchEvent(new CustomEvent("ping-udp-sent", {detail: {error: true, ping: ping, message: error}}));
            }
        }, self._configuration.test.frequency);
    }

    _terminateTests() {
        this._data.udp.terminated = true;
        this._data.tcp.terminated = true;
    }

    _close() {
        //Terminate tests
        this._terminateTests();

        //Clear timeout
        clearTimeout(this._state.timeout);

        //Update connection state
        this._state.connection.socket = false;
        this._state.connection.rtc    = false;

        //Close socket channel
        try {
            if (this._state.socket !== null) {
                this._state.socket.close();
            }
        } catch (error) {
            console.error(error);
        }

        //Close rtc channel
        try {
            if (this._state.rtc !== null) {
                this._state.rtc.close();
            }
        } catch (error) {
            console.error(error);
        }
    }

    _reset() {
        //Libraries
        const Stats = require('fast-stats').Stats;

        this._data = {
            udp : {
                stats          : new Stats(),
                terminated     : false,
                sent           : 0,
                last           : 0,
                late           : 0,
                'out-of-order' : 0,
                raw            : []
            },
            tcp : {
                stats          : new Stats(),
                terminated     : false,
                sent           : 0,
                last           : 0,
                late           : 0,
                'out-of-order' : 0,
                raw            : []
            }
        };
    }

    _processPing(results, ping) {
        //Set receive time
        ping.received = new Date().getTime();

        //Set duration
        ping.duration = ping.received - ping.sent;

        //Check if ping is late
        ping.late = ping.duration > this._configuration.test.delay;

        //Check if ping is out of order
        if (ping.index < results.last) {
            //Set state
            ping['out-of-order'] = true;
        } else {
            //Update last seen ping
            results.last = ping.index;

            //Set state
            ping['out-of-order'] = false;
        }

        //Store the ping for processing
        results.stats.push(ping.duration);
        results.late            += ping.late            ? 1 : 0;
        results['out-of-order'] += ping['out-of-order'] ? 1 : 0;
        results.raw.push(ping);

        //Return the formatted ping
        return ping;
    }

    _processResults(method) {
        //Get the data to be processed
        const data = this._data[method];

        //Process results
        return {
            count          : this._configuration.test.count,
            lost           : data.sent - data.raw.length, // Version 1.1
            // lost           : this._configuration.test.count - data.raw.length, // Version 1.0
            late           : data.late,
            'out-of-order' : data['out-of-order'],
            statistics     : {
                'arithmetic-mean': data.stats.amean().toFixed(3),
                'geometric-mean': data.stats.gmean().toFixed(3),
                median: data.stats.median().toFixed(3),
                percentile: {
                    10: data.stats.percentile(10).toFixed(3),
                    50: data.stats.percentile(50).toFixed(3),
                    90: data.stats.percentile(90).toFixed(3),
                },
                'arithmetic-standard-deviation': data.stats.stddev().toFixed(3),
                'geometric-standard-deviation': data.stats.gstddev().toFixed(3),
                'confidence-margin-of-error': data.stats.moe().toFixed(3)
            }
        };
    }

    _toSocketMessage(type, data, size = 0, character = ' ') {
        return this._padString(
            JSON.stringify({
                type: type.toUpperCase(),
                data: data
            }),
            size,
            character
        );
    }

    _padString(message, size = 0, character = ' ') {
        return message.padEnd(size, character)
    }
}

export default PingTest;
