import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Container } from 'reactstrap';
import { HubbleIQLib } from 'hubbleiq-services';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { toastr } from 'react-redux-toastr';
import Carosel from '../../components/carosel/Carosel';
import { sliderData, sliderSettings, testsTitleData } from './FirstTestHelper';
import CarouselGif5 from '../../images/carousel/carousel_gif_5.gif';
import firstTest from './FirstTest.module.scss';

const FirstTest = () => {
  const { company_key, public_key } = useSelector(state => state.Company.companyDetails);
  const { data } = useSelector(state => state.Profile);
  const [hubbleIQLib, setHubbleIQLib] = useState({});
  const [progressValue, setProgressValue] = useState(0);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const history = useHistory();
  const hubbleIQLibrary = () => {
    return new HubbleIQLib (
      {
        apiKey: public_key,
        companyKey: company_key,
        guid: data?.guid,
      },
      {
        enablePageLoadCalculation: false,
        env: process.env.REACT_APP_ENV || 'prod',
      }
    );
  }

  useEffect(() => {
    if (company_key && public_key && data?.guid) {
      setHubbleIQLib(hubbleIQLibrary())
    }
  }, []);

  const startTesting = async (userId) => {
    hubbleIQLib.on("upload-measurement", () => {
      if (progressValue !== 75) {
        setProgressValue(75);
      }
    });
    hubbleIQLib.on("download-measurement", () => {
      if (progressValue !== 90) {
        setProgressValue(90);
      }
    });
    hubbleIQLib.on("test-results", () => {
      setProgressValue(100);
      toastr.success('Test is finished');
      history.push(`/admin/user-status/${userId}`);
    });
    hubbleIQLib.on("latencyTest:result", () => {
      if (progressValue < 40) {
        setProgressValue(40);
        hubbleIQLib.calculatePacketLoss();
      }
    });
    hubbleIQLib.on('latencyTest:error', () => {
      if (progressValue < 40) {
        setProgressValue(40);
        hubbleIQLib.calculatePacketLoss();
      }
    });
    hubbleIQLib.on("packet-loss", () => {
      setProgressValue(60);
      hubbleIQLib.run();
    });
    hubbleIQLib.on("packet-loss:error", () => {
      setProgressValue(60);
      hubbleIQLib.run();
    });
  
    await hubbleIQLib.init();
    setProgressValue(20);
    hubbleIQLib.startLatencyCheck();
    setTimeout(() => {
      if (progressValue < 40) {
        setProgressValue(40);
        hubbleIQLib.calculatePacketLoss();
      }
    }, 10000); // we shall wait only 10s for hop-to-hop
  };

  useEffect(() => {
    const isReady = Object.keys(hubbleIQLib).length > 0;
    if (isReady && data) {
      startTesting(data?.id);
    }
  }, [hubbleIQLib, data])

  useEffect(() => {
    if(currentSlide === 3) {
      const timeOut = setTimeout(() => {
        setCurrentSlide(4);
      }, 6500);

      return () => clearTimeout(timeOut);
    }
  }, [currentSlide])
  
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex(prevIndex => (prevIndex === testsTitleData.length - 1 ? prevIndex : prevIndex + 1));
    }, 5000);
    
    return () => clearInterval(intervalId);
  }, []);
  
  return (
    <Container className="content">
      <div className={firstTest.first_test}>
        <div className={firstTest.header}>
          {testsTitleData.map((test, index) => (
            <div key={test.title} style={{ display: index === currentIndex ? 'block' : 'none' }} className={firstTest.progress_text}>
              {test.title}
            </div>
          ))}
          <div className={firstTest.progress_bar}>
            <div className={firstTest.progress_bar_fill} style={{width: `${progressValue}%`}} />
          </div>
        </div>
        {currentSlide < 4 && (
          <div className={firstTest.carosel}>
            <Carosel data={sliderData} settings={sliderSettings(setCurrentSlide)} />
          </div>
        )}
        {currentSlide >= 4 && (
          <div className={firstTest.last_slide}>
            <div className={firstTest.text}>Testing is almost finished. This page will refresh once its ready!</div>
            <div className={firstTest.image}>
              <img src={CarouselGif5} alt="watch" />
            </div>
          </div>
        )}
      </div>
    </Container>
  )
}

export default FirstTest;
