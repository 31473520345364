import React, {useRef, useState} from 'react';
import * as PropTypes from 'prop-types';
import {isEmpty} from 'lodash';
import SearchByColumn from './SearchByColumn';
import {mapDataToLegend} from '../../helpers/page_helper';
import Button from '../Button/Button';

const DeviceEventsTableFilter = ({onFilterChange}) => {
  const [filter, setFilter] = useState({});
  const [showResetBtn, setShowResetBtn] = useState(false);
  const [isFilterReady, setIsFilterReady] = useState(false);
  const dateRangeFilterRef = useRef();
  const searchFilterRef = useRef();
  const SearchByColumnOptions = [
    {
      value: 'guid',
      label: 'UID/GUID',
    },
    {
      value: 'email',
      label: 'Name',
    },
    {
      value: 'battery_health',
      label: 'Battery health',
      type: 'select',
      options: [
        {
          value: '0',
          label: 'Poor',
        },
        {
          value: '1',
          label: 'Good',
        },
      ],
    },
    {
      value: 'os',
      label: 'Operating system',
      type: 'select',
      options: [
        {
          value: 'win',
          label: 'Windows',
        },
        {
          value: 'mac',
          label: 'macOS',
        },
        {
          value: 'linux',
          label: 'Linux',
        },
        {
          value: 'cros',
          label: 'Chrome OS',
        },
      ],
    },
  ];

  const onSearchChange = (value) => {
    const {searchBy, keyword} = value;
    
    if (!keyword) {
      setIsFilterReady(false);
    } else {
      setIsFilterReady(true);
    }
    
    if (searchBy && keyword) {
      setFilter({...filter, ...value});
    } else {
      const {searchBy, keyword, ...rest} = filter;
      setFilter({...rest});
    }
  };

  const handleSubmit = () => {
    const legend = {
      searchBy: 'search_by',
      keyword: 'keyword',
    };

    setShowResetBtn(!isEmpty(filter));
    return onFilterChange(mapDataToLegend(legend, filter));
  };

  const resetFilter = () => {
    if (searchFilterRef && dateRangeFilterRef) {
      searchFilterRef.current.reset();
      setFilter({});
      onFilterChange();
      setShowResetBtn(false);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };

  return (
    <div className="d-flex align-items-center mb-3 mb-md-0 activities-filter">
      <SearchByColumn
        columnOptions={SearchByColumnOptions}
        onChange={onSearchChange}
        ref={searchFilterRef}
        onKeyUp={handleKeyPress}
        defaultSelect="guid"
      />
      <div className="ml-3">
        <Button
          type="button"
          onClick={handleSubmit}
          generalType="default"
          disabled={!isFilterReady}
        >
          Search
        </Button>
        {
          showResetBtn && (
            <Button
              className="ml-2"
              type="button"
              onClick={resetFilter}
              generalType="default"
            >
              Reset
            </Button>
          )
        }
      </div>
    </div>
  );
};

DeviceEventsTableFilter.propTypes = {
  onFilterChange: PropTypes.func.isRequired,
};

export default DeviceEventsTableFilter;
