import React from 'react';
import { useSelector } from "react-redux";
import SdkCredentialRow from "./SDKCredentialRow";
import styles from './sdk-credentials.module.scss';

function SdkCredentials() {
	const { companyDetails: {company_key, public_key} } = useSelector((state) => state.Company);
	return (
		<div className="align-items-center">
			<div className={styles.wrapper}>
				<SdkCredentialRow title="Public API Key" value={public_key}/>
				<SdkCredentialRow title="Company Key" value={company_key}/>
				<SdkCredentialRow
					title="SDK Documentation"
					value={<a href="https://www.npmjs.com/package/hubbleiq-services" target="_blank">Documentation</a>}
					noCopy
				/>
			</div>
		</div>
	);
}

export default SdkCredentials;
