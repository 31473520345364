import { FETCH_USER_ACTIVITY_ANALYTICS, FETCH_USER_ACTIVITY_ANALYTICS_SUCCESS, FETCH_USER_ACTIVITY_ANALYTICS_API_ERROR } from './actionTypes';

const initialState = {
  error: null,
  loading: false,
};

const fetchUserActivityAnalytics = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_USER_ACTIVITY_ANALYTICS:
      return {
        ...state,
        loading: true,
      };
    case FETCH_USER_ACTIVITY_ANALYTICS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      };
    case FETCH_USER_ACTIVITY_ANALYTICS_API_ERROR:
      return { ...state, error: action.payload, loading: false };
    default:
      return state;
  }
};

export default fetchUserActivityAnalytics;
