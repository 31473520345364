import React, { useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import LogoWhite from '../../images/logo_white.svg';
import IconExtension from '../../images/icon-extension.svg';
import PinExtension from '../../images/pin-extension.jpg';

const TrialPopup = ({ closePopup }) => {
  const [show, setShow] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      setShow(true);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);
  return (
    <div className={`trial-popup${show ? ' active' : ''}`}>
      <div className="trial-popup-logo">
        <img src={LogoWhite} alt="logo" className="img-responsive" />
      </div>
      <div className="trial-popup-text">
        The <span>HubbleIQ agent</span> has been successfully installed.
        Click the <span className="trial-popup-icon"><img src={IconExtension} alt="chrome extension icon" className="img-respponsive" /></span> above to pin HubbleIQ for easy access.
      </div>
      <div className="trial-popup-img">
        <img src={PinExtension} alt="pin extension" className="img-responsive" />
      </div>
      <div className="trial-popup-text">
        Once done, log in to your HubbleIQ <span>agent portal below</span> to view your results and invite remote users.
      </div>
      <Button color="link" onClick={closePopup}>Dismiss</Button>
    </div>
  );
};

export default TrialPopup;
