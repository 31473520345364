import {all, fork, put, takeEvery} from 'redux-saga/effects';
import {GET_ISSUE_LOGS, GET_REPORED_LOGS} from './actionTypes';
import {getIssueLogsError, getIssueLogsSuccess, getReportedLogsError, getReportedLogsSuccess,} from './actions';
import apiClient from '../../gears/apiClient';

function* getIssueLogs({ payload: { params } }) {
  try {
    const query = {
      source: params?.source,
      page: params?.page,
      type: params?.type || {},
    };
    if (params?.appFilter) {
      Object.entries(params.appFilter).forEach(([key, value]) => {
        if (key === 'search_by') {
          query[value] = params.appFilter.keyword;
        } else if (key !== 'keyword') {
          query[key] = value;
        }
      });
    }
    const response = yield apiClient.get('/network-disruptions', query);
    yield put(getIssueLogsSuccess(response));
  } catch (error) {
    yield put(getIssueLogsError(error));
  }
}

export function* watchIssueLogs() {
  yield takeEvery(GET_ISSUE_LOGS, getIssueLogs);
}

function* getReportedLogs({ payload: { params } }) {
  try {
    const response = yield apiClient.get('/helpdesk-requests', params);
    yield put(getReportedLogsSuccess(response));
  } catch (error) {
    yield put(getReportedLogsError(error));
  }
}

export function* watchReportedLogs() {
  yield takeEvery(GET_REPORED_LOGS, getReportedLogs);
}

function* IssueLogsSaga() {
  yield all([
    fork(watchIssueLogs),
    fork(watchReportedLogs),
  ]);
}

export default IssueLogsSaga;
