import React from 'react'
import averages from './Averages.module.css'

const AveragesBlock = ({
  icon,
  label,
  value,
  valueClick,
  onValueClick,
}) => {
  const isValue = typeof value === 'number'
  const onValueToggle = () => {
    onValueClick(!valueClick)
  }
  const isActive = valueClick ? averages.active : ''
  return (
    <div className={averages.block}>
      <div className={averages.label}>
        <div className={averages.icon}>
          <img src={icon} alt="icon" />
        </div>
        <div className={averages.name}>{label}</div>
      </div>
      {isValue &&  (
        <div
          className={`${averages.valueBlock} ${isActive}`}
          onClick={onValueToggle}
        >
          Avg
          <div className={averages.value}>{value} <span>ms</span></div>
        </div>
      )}
    </div>
  )
}

export default AveragesBlock