export const filterListOptions = [
	{
		value: 'email',
		label: 'Email',
	},
	{
		value: 'first_name',
		label: 'First name',
	},
	{
		value: 'last_name',
		label: 'Last name',
	},
];
