import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import { invitedUsersTableColumns } from './invited-users-helper';
import ReactTable from '../../ReactTable';
import { useDispatch } from 'react-redux';

const InvitedUsersTable = ({
  data,
  isLoading,
  onSortChange,
  disableSortBy = false,
}) => {
  const dispatch = useDispatch();

  const onSort = useCallback(({sortBy}) => {
    if (onSortChange) {
      if (sortBy.length > 0) {
        const [{id, desc}] = sortBy;
        onSortChange(`${[id]} ${desc ? 'desc' : 'asc'}`);
      } else {
        onSortChange();
      }
    }
  }, []);

  return (
    <ReactTable
      columns={invitedUsersTableColumns(dispatch)}
      data={data}
      onSortChange={onSort}
      loading={isLoading}
      manualSortBy
      disableMultiSort
      disableSortBy={disableSortBy}
    />
  );
};

InvitedUsersTable.defaultProps = {
  data: [],
  isLoading: false,
};

InvitedUsersTable.propTypes = {
  fetch: PropTypes.func,
  data: PropTypes.arrayOf(PropTypes.any),
  onSortChange: PropTypes.func,
  isLoading: PropTypes.bool,
};

export default InvitedUsersTable;
