import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { toastr } from 'react-redux-toastr';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Typeahead } from 'react-bootstrap-typeahead';
import Loader from '../Loader';
import apiClient from '../../gears/apiClient';

const ProvidersList = ({filters, setFilters}) => {
  const [providers, setProviders] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selected, setSelected] = useState([]);

  const buildArr = (arr) => {
    return arr.map((item) => ({name: item}));
  };

  const fetchProviders = async () => {
    try {
      const result = await apiClient.get('/isp_providers');
      const modifiedProviders = buildArr(result?.providers) ?? [];
      setProviders(modifiedProviders);
      setIsLoading(false);
    } catch (e) {
      toastr.error('System', e.message);
    }
  };

  useEffect(() => {
    fetchProviders();
  }, []);

  useEffect(() => {
    if (filters.providers.length > 0) {
      const selectedProviders = buildArr(filters.providers);
      setSelected(selectedProviders);
    } else {
      setSelected([]);
    }
  }, [filters.providers]);

  if (isLoading) {
    return <Loader />;
  }

  const checkboxToggle = (selected) => {
    const names = selected.map((item) => item.name);
    setFilters((prevState) => ({
      ...prevState, providers: names,
    }));
    setSelected(selected);
  };

  return (
    <div>
      <Typeahead
        id="isp-list"
        name="isp-list"
        onChange={checkboxToggle}
        options={providers}
        labelKey="name"
        multiple
        selected={selected}
      />
    </div>
  );
};

ProvidersList.propTypes = {
  filters: PropTypes.shape({
    providers: PropTypes.arrayOf(PropTypes.string),
  }),
  setFilters: PropTypes.func,
};

export default ProvidersList;
