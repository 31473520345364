import { takeEvery, fork, put, all } from 'redux-saga/effects';
import { COOKIE_NAME_TOKEN } from '../../../gears/constants';

// Login Redux States
import { LOGIN_USER, LOGOUT_USER } from './actionTypes';
import { loginSuccess, logoutUserSuccess, apiError } from './actions';

import apiClient from '../../../gears/apiClient';

function* loginUser({ payload: { user, successCallback } }) {
  try {
    const response = yield apiClient.post('/session/create', user);
    apiClient.setJwtToken(response.token);
    if ( process.env.REACT_APP_ENV === 'local') {
      window.Cookies.set(COOKIE_NAME_TOKEN, response.token);
    }
    yield put(loginSuccess(response));
    localStorage.setItem('trialClosed', 'false');
    successCallback();
  } catch (error) {
    yield put(apiError(error));
  }
}

function* logoutUser({ payload: { history } }) {
  window.Cookies.remove(COOKIE_NAME_TOKEN);
  const response = yield apiClient.post('/session/logout');
  apiClient.setJwtToken(null);
  if (response) {
    yield put(logoutUserSuccess());
    localStorage.setItem('trialClosed', 'false');
    history.push('/admin/login');
  }
}

export function* watchUserLogin() {
  yield takeEvery(LOGIN_USER, loginUser);
}

export function* watchUserLogout() {
  yield takeEvery(LOGOUT_USER, logoutUser);
}

function* authSaga() {
  yield all([fork(watchUserLogin), fork(watchUserLogout)]);
}

export default authSaga;
