import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import {connect} from 'react-redux';
import {Helmet} from 'react-helmet';
import queryString from 'query-string';
import PublicHeader from '../../components/public/PublicHeader';
import PublicFooter from '../../components/public/PublicFooter';

import { fetchUserIp } from '../../store/actions';
import AhoyTrack from '../../modules/AhoyTrack';

// eslint-disable-next-line react/prefer-stateless-function
class Home extends Component {
  componentDidMount() {
    AhoyTrack();
    const {location, dispatchFetchUserIp} = this.props;
    localStorage.setItem('isTestRunning', 0);
    const params = queryString.parse(location.search);
    if (params && params.guid) {
      localStorage.setItem('currentUserUUID', params.guid);
    }

    if (window.location.origin !== 'http://paxiosupport.hubbleiq.net') {
      dispatchFetchUserIp();
    }
  }

  render() {
    const {welcome_header, welcome_subheader, welcome_img, btn_test_trigger_text} = this.props;

    return (
      <>
        <Helmet>
          <title>{welcome_header}</title>
        </Helmet>

        <PublicHeader />

        <div className="page-start">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <p className="heading center">{welcome_header}</p>
                <p className="subheading center">{welcome_subheader}</p>
                <p className="center mt-3">
                  <Link to="/testing" className="button button-dark">{btn_test_trigger_text}</Link>
                </p>

                <div className="page-start__img center">
                  <img src={welcome_img} alt="logo" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <PublicFooter />
      </>
    );
  }
}

Home.defaultProps = {
  welcome_header: '',
  welcome_subheader: '',
  welcome_img: '',
  btn_test_trigger_text: '',
};

Home.propTypes = {
  welcome_header: PropTypes.string,
  welcome_subheader: PropTypes.string,
  welcome_img: PropTypes.string,
  btn_test_trigger_text: PropTypes.string,
};

const mapStateToProps = (state) => {
  const {step_1} = state.Company.companyDetails;
  return {...step_1};
};

const mapDispatchToProps = {};

export default withRouter(connect(mapStateToProps, {
  dispatchFetchUserIp: fetchUserIp,
  mapDispatchToProps,
})(Home));
