import React, {useState, useRef} from 'react';
import * as PropTypes from 'prop-types';
import {isEmpty} from 'lodash';
import DateRangeFilter from './DateRange';
import SearchByColumn from './SearchByColumn';
import {mapDataToLegend} from '../../helpers/page_helper';
import Button from '../Button/Button';

const ActivitiesTableFilter = ({onFilterChange, userId}) => {
  const [filter, setFilter] = useState({});
  const [showResetBtn, setShowResetBtn] = useState(false);
  const dateRangeFilterRef = useRef();
  const searchFilterRef = useRef();
  const SearchByColumnOptions = [
    {
      value: 'email',
      label: 'Email / Name',
      type: 'text',
    },
    {
      value: 'guid',
      label: 'Guid',
      type: 'text',
    },
    {
      value: 'city',
      label: 'City',
      type: 'text',
    },
    {
      value: 'region_name',
      label: 'Region',
      type: 'text',
    },
    {
      value: 'final_status',
      label: 'Status',
      type: 'select',
      options: [
        {
          value: 'good',
          label: 'Good',
        },
        {
          value: 'decent',
          label: 'Decent',
        },
        {
          value: 'poor',
          label: 'Poor',
        },
      ],
    },
    {
      value: 'isp',
      label: 'Service Provider',
      type: 'text',
    },
    {
      value: 'test_type',
      label: 'Test Type',
      type: 'select',
      options: [
        {
          value: 'browser',
          label: 'Browser',
        },
        {
          value: 'chrome_extension',
          label: 'Extension',
        },
      ],
    },
    {
      value: 'ip_address',
      label: 'IP Address',
      type: 'text',
    },
    // {
    //   value: 'help_request',
    //   label: 'Help Request',
    //   type: 'single_select',
    // },
  ];

  if (userId) {
    SearchByColumnOptions.splice(0, 1);
  }

  const onDateRangeChange = (range) => {
    if (range) {
      setFilter({...filter, ...range});
    } else {
      const {startDate, endDate, ...rest} = filter;
      setFilter({...rest});
    }
  };

  const onSearchChange = (value) => {
    const {searchBy, keyword} = value;
    if (searchBy && keyword) {
      setFilter({...filter, ...value});
    } else {
      const {searchBy, keyword, ...rest} = filter;
      setFilter({...rest});
    }
  };

  const handleSubmit = () => {
    const legend = {
      startDate: 'start_date',
      endDate: 'end_date',
      searchBy: 'search_by',
      keyword: 'keyword',
    };

    setShowResetBtn(!isEmpty(filter));
    return onFilterChange(mapDataToLegend(legend, filter));
  };

  const resetFilter = () => {
    if (searchFilterRef && dateRangeFilterRef) {
      searchFilterRef.current.reset();
      dateRangeFilterRef.current.reset();
      setFilter({});
      onFilterChange();
      setShowResetBtn(false);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };

  return (
    <div className="d-flex align-items-center mb-3 mb-md-0 activities-filter">
      <DateRangeFilter
        onChange={onDateRangeChange}
        className="mr-2"
        ref={dateRangeFilterRef}
        onKeyUp={handleKeyPress}
      />
      <SearchByColumn
        columnOptions={SearchByColumnOptions}
        onChange={onSearchChange}
        ref={searchFilterRef}
        onKeyUp={handleKeyPress}
      />
      <div className="ml-3">
        <Button
          type="button"
          onClick={handleSubmit}
          generalType="default"
        >
          Filter
        </Button>
        {
          showResetBtn && (
            <Button
              className="ml-2"
              type="button"
              onClick={resetFilter}
              generalType="default-outlined"
            >
              <i className="mdi mdi-refresh icon" />
              Clear
            </Button>
          )
        }
      </div>
    </div>
  );
};

ActivitiesTableFilter.propTypes = {
  onFilterChange: PropTypes.func.isRequired,
  userId: PropTypes.number,
};

export default ActivitiesTableFilter;
