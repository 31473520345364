import * as c from './actionTypes';

const initialState = {
  data: [],
  error: null,
  loading: false,
  networkAnalytics: {},
  networkdAnalyticsIsLoading: false,
  networkAnalyticsError: null,
  usersSpeed: {},
  usersSpeedIsLoading: false,
  usersSpeedError: null,
};

const fetchRecentActivities = (state = initialState, action) => {
  switch (action.type) {
    case c.FETCH_RECENT_ACTIVITIES:
      return {
        ...state,
        history: action.history,
        loading: true,
      };
    case c.FETCH_RECENT_ACTIVITIES_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      };
    case c.FETCH_RECENT_ACTIVITIES_API_ERROR:
      return {...state, error: action.payload, loading: false};
    case c.FETCH_NETWORK_ANALYTICS:
      return {
        ...state,
        networkdAnalyticsIsLoading: true,
      };
    case c.FETCH_NETWORK_ANALYTICS_SUCCESS:
      return {
        ...state,
        networkAnalytics: action.payload,
        networkdAnalyticsIsLoading: false,
      };
    case c.FETCH_NETWORK_ANALYTICS_ERROR:
      return {
        ...state,
        networkAnalyticsError: action.payload,
        networkdAnalyticsIsLoading: false,
      };
    case c.FETCH_USERS_SPEED:
      return {
        ...state,
        usersSpeedIsLoading: true,
      };
    case c.FETCH_USERS_SPEED_SUCCESS:
      return {
        ...state,
        usersSpeed: action.payload,
        usersSpeedIsLoading: false,
      };
    case c.FETCH_USERS_SPEED_ERROR:
      return {
        ...state,
        usersSpeedError: action.payload,
        usersSpeedIsLoading: false,
      };
    case c.CREATE_ACTIVITY_REQUEST:
      return {
        ...state,
        data: action.data,
        history: action.history,
        loading: true,
      };
    case c.CREATE_ACTIVITY_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      };
    case c.CREATE_ACTIVITY_ERROR:
      return {...state, error: action.payload, loading: false};
    case c.FETCH_USERS_TESTS:
      return {
        ...state,
        history: action.history,
        loading: true,
      };
    case c.FETCH_USERS_TESTS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      };
    case c.FETCH_USERS_TESTS_ERROR:
      return {...state, error: action.payload, loading: false};
    default:
      return state;
  }
};

export default fetchRecentActivities;
