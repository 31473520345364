import * as type from './actionTypes';

export const fetchUsers = (history, data) => {
  return {
    type: type.FETCH_USERS,
    payload: { data, history },
  };
};

export const fetchUsersSuccess = (payload) => {
  return {
    type: type.FETCH_USERS_SUCCESS,
    payload,
  };
};

export const fetchUsersApiError = (error) => {
  return {
    type: type.FETCH_USERS_API_ERROR,
    payload: error,
  };
};

export const inviteUser = (payload, history, callback) => {
  return {
    type: type.USER_INVITE_REQUEST,
    payload,
    history,
    callback,
  };
};

export const inviteUserSuccess = (payload) => {
  return {
    type: type.USER_INVITE_USERS_SUCCESS,
    payload,
  };
};

export const inviteUserError = (error) => {
  return {
    type: type.USER_INVITE_USERS_API_ERROR,
    payload: error,
  };
};

export const inviteAdmin = (payload, history, callback) => {
  return {
    type: type.ADMIN_INVITE_REQUEST,
    payload,
    history,
    callback,
  };
};

export const inviteAdminSuccess = (payload) => {
  return {
    type: type.ADMIN_INVITE_SUCCESS,
    payload,
  };
};

export const inviteAdminError = (error) => {
  return {
    type: type.ADMIN_INVITE_ERROR,
    payload: error,
  };
};

export const requestTest = (payload, history, callback) => {
  return {
    type: type.USER_TEST_REQUEST,
    payload,
    history,
    callback,
  };
};

export const requestTestSuccess = (payload) => {
  return {
    type: type.USER_TEST_SUCCESS,
    payload,
  };
};

export const requestTestError = (error) => {
  return {
    type: type.USER_TEST_API_ERROR,
    payload: error,
  };
};

export const inviteUsersList = (payload, history, callback) => {
  return {
    type: type.USERS_LIST_INVITE_REQUEST,
    payload,
    history,
    callback,
  };
};

export const inviteUsersListSuccess = (payload) => {
  return {
    type: type.USERS_LIST_INVITE_SUCCESS,
    payload,
  };
};

export const inviteUsersListError = (error) => {
  return {
    type: type.USERS_LIST_INVITE_ERROR,
    payload: error,
  };
};

export const changeUserStatus = (status, userId, guid) => {
  return {
    type: type.CHANGE_USER_STATUS,
    payload: {
      status,
      userId,
      guid
    },
  };
};

export const changeUserStatusSuccess = (payload) => {
  return {
    type: type.CHANGE_USER_STATUS_SUCCESS,
    payload,
  };
};

export const changeUserStatusError = (error) => {
  return {
    type: type.CHANGE_USER_STATUS_ERROR,
    payload: error,
  };
};
