/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable class-methods-use-this */
import React, { useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import RefreshButton from '../../components/refresh-button';
import Pagination from '../../components/pagination';
import { fetchUsers } from '../../store/actions';
import UsersTable from '../../components/tables/UsersTable/UsersTable';
import { InviteModalConsumer } from '../../context/Context';
import Button from '../../components/Button/Button';
import { buildPaginationData } from '../../utility/utils';
import TestFeature from '../../components/testFeature/TestFeature';
import TableFilters from '../../components/TableFilters';
import { usersTableFilters } from './users-helper';

const Users = ({history, location}) => {
  const { loading, data } = useSelector(state => state.Users);
  const { isWebTestEnabled } = useSelector(state => state.Company);
  const [filter, setFilter] = useState(null);
  const [sortBy, setSortBy] = useState(null);
  const [guids, setGuids] = useState([]);
  const dispatch = useDispatch();

  
  let paginationData = null;
  if (data?.users?.length > 0) {
    paginationData = buildPaginationData(data);
  }

  const fetchData = (page) => {
    let queryParams = {};

    if (page) {
      queryParams.page = page;
    }

    if (sortBy) {
      queryParams.sort_by = sortBy;
    }

    if (filter) {
      queryParams = {...queryParams, ...filter};
    }

    clearGuids();
    dispatch(fetchUsers(history, queryParams));
  }

  const onCkekboxClick = (guidsArr) => {
    setGuids(guidsArr);
  }

  useEffect(() => {
    const { search } = location;
    const {filter_by, search_by, keyword} = queryString.parse(search);

    if (filter_by) {
      setFilter(filter_by);
    } else if (search_by) {
      setFilter({search_by, keyword});
    }
  }, [location])

  const navigateToPage = (page) => {
    fetchData(page);
  }

  const onTableSortChange = (sortBy) => {
    if (sortBy) {
      setSortBy(sortBy);
    }
  }

  const onFilterChange = (filter) => {
    history.replace({search: ''});
    setFilter(filter);
  }

  const clearGuids = () => {
    setGuids([]);
  }

  useEffect(() => {
    fetchData()
  }, [filter, sortBy])

  return (
    <>
      <InviteModalConsumer>
        {(toggleModal) => {
          return (
            <div className="container-fluid content">
              <Row className="page-title align-items-center">
                <Col sm={6}>
                  <div className="page-title-box">
                    <h2>Users List</h2>
                  </div>
                </Col>
                <Col sm={6}>
                  <div className="page-title-box text-right">
                    <Button
                      generalType="default"
                      onClick={() => toggleModal('invite')}
                      className="mr-1"
                    >
                      Invite
                    </Button>
                    {isWebTestEnabled && (
                      <Button
                        generalType="default"
                        onClick={() => toggleModal('test')}
                      >
                        Request Test
                      </Button>
                    )}
                  </div>
                </Col>
              </Row>
              <div className="page-block page-block_border">
                <Row className="align-items-center">
                  <Col xl={12}>
                    <div className="justify-between align-items-center mb-4 d-md-flex">
                      <div className="align-items-center d-md-flex">
                        <div className="d-flex align-items-center mr-5">
                          <span className="card-title mb-0">Recent Sessions</span>
                          <RefreshButton onRefresh={fetchData} />
                        </div>
                      </div>
                    </div>
                    <div className="justify-between  mb-4 d-md-flex">
                      <TableFilters
                        currentFilter={filter}
                        onFilterChange={onFilterChange}
                        defaultSelect={usersTableFilters?.[0]?.value}
                        searchByColumnOptions={usersTableFilters}
                        legendSearchBy={{
                          searchBy: 'search_by',
                          keyword: 'keyword',
                        }}
                      />
                      <TestFeature
                        guids={guids}
                        callback={clearGuids}
                      />
                    </div>
                    <div className="table-responsive">
                      <UsersTable
                        fetch={fetchData}
                        data={data?.users}
                        isLoading={loading}
                        onSortChange={onTableSortChange}
                        guids={guids}
                        setGuids={onCkekboxClick}
                      />
                    </div>
                    {(data?.users?.length > 0 && paginationData) && <Pagination paginationData={paginationData} navigateTo={navigateToPage} />}
                  </Col>
                </Row>
              </div>
            </div>
          );
        }}
      </InviteModalConsumer>
    </>
  );
  
}

export default Users;
