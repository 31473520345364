export const FETCH_USERS = 'FETCH_USERS';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_API_ERROR = 'FETCH_USERS_API_ERROR';

export const USER_INVITE_REQUEST = 'USER_INVITE_REQUEST';
export const USER_INVITE_USERS_SUCCESS = 'USER_INVITE_USERS_SUCCESS';
export const USER_INVITE_USERS_API_ERROR = 'USER_INVITE_USERS_API_ERROR';

export const CHANGE_USER_STATUS = 'CHANGE_USER_STATUS';
export const CHANGE_USER_STATUS_SUCCESS = 'CHANGE_USER_STATUS_SUCCESS';
export const CHANGE_USER_STATUS_ERROR = 'CHANGE_USER_STATUS_ERROR';

export const ADMIN_INVITE_REQUEST = 'ADMIN_INVITE_REQUEST';
export const ADMIN_INVITE_SUCCESS = 'ADMIN_INVITE_SUCCESS';
export const ADMIN_INVITE_ERROR = 'ADMIN_INVITE_ERROR';

export const USER_TEST_REQUEST = 'USER_TEST_REQUEST';
export const USER_TEST_SUCCESS = 'USER_TEST_SUCCESS';
export const USER_TEST_API_ERROR = 'USER_TEST_API_ERROR';

export const USERS_LIST_INVITE_REQUEST = 'USERS_LIST_INVITE_REQUEST';
export const USERS_LIST_INVITE_SUCCESS = 'USERS_LIST_INVITE_SUCCESS';
export const USERS_LIST_INVITE_ERROR = 'USERS_LIST_INVITE_ERROR';
