import { takeEvery, fork, put, all } from 'redux-saga/effects';

import { FETCH_AI_CHAT_HISTORY, FETCH_AI_CHAT_SEND_MSG } from './actionTypes';
import {
	fetchAIChatHistorySuccess,
	fetchAIChatHistoryError,
	fetchAIChatSendMsgSuccess,
	fetchAIChatSendMsgError
} from './actions';
import apiClient from "../../gears/apiClient";
function* fetchAIChatHistory({ payload: { id, msg = '' } }) {
	try {
		const response = yield apiClient.post(`/disruption-log-chat/${id}`, { message: msg });
		yield put(fetchAIChatHistorySuccess(response?.messages));
	} catch (error) {
		yield put(fetchAIChatHistoryError(error));
	}
}

export function* watchFetchIssuesAIHistory() {
	yield takeEvery(FETCH_AI_CHAT_HISTORY, fetchAIChatHistory);
}

function* fetchAIChatSendMsg({ payload: { id, msg } }) {
	try {
		const response = yield apiClient.post(`/disruption-latest-chat/${id}`, { message: msg });
		yield put(fetchAIChatSendMsgSuccess(response?.messages));
	} catch (error) {
		yield put(fetchAIChatSendMsgError(error));
	}
}

export function* watchFetchAIChatSendMsg() {
	yield takeEvery(FETCH_AI_CHAT_SEND_MSG, fetchAIChatSendMsg);
}

function* issuesAIChatSaga() {
	yield all([
		fork(watchFetchIssuesAIHistory),
		fork(watchFetchAIChatSendMsg),
	]);
}

export default issuesAIChatSaga;
