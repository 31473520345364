import React from 'react';
import DoughnutChart from "../DoughnutChart/DoughnutChart";
import styles from './issue-resolved-stats.module.scss';

const IssuesResolvedStats = ({ data }) => {
	return (
		<div className={styles['issues-resolved-stats']}>
			<h4 className={styles.title}>Issues Resolved by Self-Service</h4>
			<hr />
			<div className={styles.content}>
				<div className={styles.labelsContainer}>
					<div className={styles.label}>
						<DoughnutChart values={data} />
					</div>
				</div>
			</div>
		</div>
	);
};

export default IssuesResolvedStats;
