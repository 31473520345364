import React, {useEffect} from 'react';
import {Col, FormGroup, Input, Label, Row} from 'reactstrap';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import * as PropTypes from 'prop-types';
import {CircularProgress} from '@material-ui/core';
import ProfileForm from '../../components/forms/ProfileForm';
import ProfileNewPassword from '../../components/forms/ProfileNewPassword';
import {getMyProfile, updateMyProfile} from '../../store/profile/actions';
import AhoyTrack from '../../modules/AhoyTrack';
import ColorScheme from '../../components/profile/ColorScheme';
import SubscriptionAndBilling from '../../components/SubscriptionAndBilling';

const Profile = ({profile, loading, getMyProfile, history, updateMyProfile, location}) => {
  AhoyTrack();

  useEffect(() => {
    if (!profile && !loading) {
      getMyProfile(null, history);
    }
  }, [getMyProfile, profile, loading]);

  useEffect(() => {
    if (location.search.includes('unsubscribe')) {
      updateMyProfile({send_digest: false}, history, 'toastr');
    }
  }, []);

  const updateSendDigest = () => {
    updateMyProfile({send_digest: !profile.send_digest}, history, 'toastr');
  };

  if (loading || !profile) {
    return (
      <div className="d-flex justify-content-center pt-5">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className="container-fluid content profile">
      <div className="mt-4">
        <div className="container p-0">
          <div className="profile-top">
            <SubscriptionAndBilling profile={profile} />
          </div>
          <div className="profile-top">
            <Row>
              <Col sm={6}>
                <ProfileForm profile={profile} />
              </Col>
              <Col sm={6}>
                <ProfileNewPassword profile={profile} />
              </Col>
            </Row>
          </div>
          <div className="settings settings-tab-block">
            <Row>
              <Col sm={6}>
                <div className="profile-top-title">Preferences</div>
                <FormGroup tag="fieldset">
                  <FormGroup className="form-group_checkbox">
                    <Label check>
                      E-mail me daily HubbleIQ activity summary emails
                      <Input
                        type="checkbox"
                        name="send_digest"
                        checked={profile?.send_digest}
                        onChange={updateSendDigest}
                      />{' '}
                      <span />
                    </Label>
                  </FormGroup>
                </FormGroup>
              </Col>
            </Row>
          </div>
          <div className="profile-top">
            <ColorScheme />
          </div>
        </div>
      </div>
    </div>
  );
};

Profile.propTypes = {
  profile: PropTypes.shape({
    id: PropTypes.number,
    email: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
  }),
  loading: PropTypes.bool,
};

const mapStateToProps = (state) => {
  const {data: profile, loading} = state.Profile;
  const {companyName, companyDetails: {stripe_customer_id: customerId}} = state.Company;
  return {profile, loading, customerId, companyName};
};

const mapDispatchToProps = {getMyProfile, updateMyProfile};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Profile));
