import React from 'react'
import { Card } from 'reactstrap'
import results from './ResultsCard.module.css'

const ResultsCard = ({content, title, subtitle}) => {
  return (
    <Card className={results.card}>
      <div className={results.card_header}>
        {title}
        {subtitle && <div className={results.card_subtitle}>{subtitle}</div>}
      </div>
      <div className={results.card_body}>
        {content}
      </div>
    </Card>
  )
}

export default ResultsCard