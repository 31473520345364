import React, { useState, useEffect } from 'react';
import {Col, Input, FormGroup, Label, Row} from 'reactstrap';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { toastr } from 'react-redux-toastr';
import Button from '../Button/Button';
import apiClient from '../../gears/apiClient';

const BrowserTestSurveyForm = ({data, isLoading, updateBrowserSettings, setIsLoading}) => {
  const extensionSchema = Yup.object().shape({
    help_header: Yup.string().max(50, 'Maximum 50 characters').required('Field is required'),
    help_sub_header: Yup.string().required('Field is required'),
    form_header: Yup.string(),
    custom_submit_btn_label: Yup.string(),
    chrome_ex_link: Yup.string(),
    questions: Yup.array().required(),
  });

  const [questionsArr, setQuestionsArr] = useState([]);
  // const [answersLoading, setAnswersLoading] = useState(false);

  useEffect(() => {
    setQuestionsArr(data?.questions.map((item) => (
      {...item, hide: false}
    )));
  }, [data]);

  const addQuestion = () => {
    if (questionsArr.length < 4) {
      setQuestionsArr([
        ...questionsArr,
        {
          title: '',
          options: [],
          id: questionsArr?.length + 1,
          order: questionsArr?.length + 1,
          hide: false,
        },
      ]);
    }
    const copyArr = [...questionsArr];
    const firstHidden = copyArr.find((item) => item.hide === true);
    firstHidden.hide = false;
    setQuestionsArr(copyArr);
  };

  const removeQuestion = (index, f) => {
    const copyArr = [...questionsArr];
    const currentObject = copyArr[index];
    Object.keys(currentObject).forEach((item) => {
      if (item === 'id') {
        currentObject[item] = currentObject.id;
      } else if (item === 'order') {
        currentObject[item] = currentObject.order;
      } else {
        currentObject[item] = null;
      }
    });
    f(`questions.${index}.title`, '');
    f(`questions.${index}.options`, []);
    f(`questions.${index}.id`, currentObject.id);
    f(`questions.${index}.order`, currentObject.order);
    currentObject.hide = true;
    setQuestionsArr(copyArr);
  };

  const updateQuestions = async (data) => {
    setIsLoading(true);
    try {
      await apiClient.post('/settings/update-browser-questions', data);
      setIsLoading(false);
    } catch (e) {
      toastr.error('System', 'Questions updated error');
    }
  };

  return (
    <div className="settings-tab-block">
      <div className="title">Survey Section</div>
      <Formik
        initialValues={{
          help_header: data?.help_header || '',
          help_sub_header: data?.help_sub_header || '',
          custom_form_header: data?.custom_form_header || '',
          custom_submit_btn_label: data?.custom_submit_btn_label || '',
          chrome_ex_link: data?.chrome_ex_link || '',
          questions: questionsArr?.map((item) => ({
            id: item?.id,
            order: item?.order,
            title: item?.title || '',
            options: item?.options || [],
            hide: item?.hide,
          })) || [],
        }}
        validationSchema={extensionSchema}
        onSubmit={(values) => {
          const asArray = Object.entries(values);
          const filtered = asArray.filter(([, value]) => typeof value === 'string');
          const justStrings = Object.fromEntries(filtered);
          updateQuestions({questions: values?.questions.map((item) => ({
            ...item,
            options: item.options.join(','),
          }))});
          updateBrowserSettings(justStrings);
        }}
        enableReinitialize
      >
        {({ handleChange, values, errors, isValid, dirty, setFieldValue }) => (
          <Form className="form form-survey">
            <div className="settings-tab-item">
              <Row>
                <Col xl="3" md="6">
                  <FormGroup>
                    <Label for="survey-headline">Headline Text</Label>
                    <Input
                      id="survey_help_header"
                      className={`form-control ${errors.help_header ? 'is-invalid' : ''}`}
                      maxLength="50"
                      name="help_header"
                      onChange={(e) => handleChange(e)}
                      tag={Field}
                      disabled={isLoading}
                    />
                    <div className="character-count">
                      Character count: <span>{values.help_header?.length || 0}</span>/<span>50</span>
                    </div>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xl="3" md="6">
                  <FormGroup>
                    <Label for="survey-subheadline">Form Header</Label>
                    <Field
                      id="survey_help_sub_header"
                      className={`form-control ${errors.help_sub_header ? 'is-invalid' : ''}`}
                      maxLength="80"
                      name="help_sub_header"
                      type="textarea"
                      onChange={(e) => handleChange(e)}
                      as="textarea"
                      disabled={isLoading}
                    />
                    <div className="character-count">
                      Character count: <span>{values.help_sub_header?.length || 0}</span>/<span>80</span>
                    </div>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs="12">
                  <FormGroup>
                    <div className="form-section">
                      <div className="form-section-title">Questions</div>
                      <div className="form-section-subtitle">Up to 4 questions, each with 4 possible answers with 2 minimum. User can select all answers that apply.</div>
                      <div className="form-blocks">
                        <Row>
                          {questionsArr?.map((item, i) => (
                            !item.hide && (
                              <Col xl="3" md="6" key={i}>
                                <div className="form-block">
                                  <Button
                                    type="button"
                                    generalType="default"
                                    className="btn-remove"
                                    size="small"
                                    onClick={() => removeQuestion(i, setFieldValue)}
                                  >
                                    Remove
                                  </Button>
                                  <div className="form-block-title">Question {item?.order}</div>
                                  <FormGroup>
                                    <Label for={`questions${i}_title`}>Question</Label>
                                    <Field
                                      id={`questions${i}_title`}
                                      className={`form-control ${errors.questions?.[i]?.title ? 'is-invalid' : ''}`}
                                      maxLength="50"
                                      name={`questions.${i}.title`}
                                      onChange={(e) => handleChange(e)}
                                      disabled={isLoading}
                                    />
                                    <div className="character-count">
                                      Character count: <span>{values.questions?.[i]?.title?.length || 0}</span>/<span>50</span>
                                    </div>
                                  </FormGroup>
                                  <FormGroup>
                                    <Label for={`questions${i}_answer1`}>Answer 1</Label>
                                    <Field
                                      id={`questions${i}_answer1`}
                                      className={`form-control ${errors.questions?.[i]?.options?.[0] ? 'is-invalid' : ''}`}
                                      maxLength="50"
                                      name={`questions.${i}.options.${0}`}
                                      onChange={(e) => handleChange(e)}
                                      disabled={isLoading}
                                    />
                                    <div className="character-count">
                                      Character count: <span>{values.questions?.[i]?.options?.[0]?.length || 0}</span>/<span>50</span>
                                    </div>
                                  </FormGroup>
                                  <FormGroup>
                                    <Label for={`questions${i}_answer2`}>Answer 2</Label>
                                    <Field
                                      id={`questions${i}_answer2`}
                                      className={`form-control ${errors.questions?.[i]?.options?.[1] ? 'is-invalid' : ''}`}
                                      maxLength="50"
                                      name={`questions.${i}.options.${1}`}
                                      onChange={(e) => handleChange(e)}
                                      disabled={isLoading}
                                    />
                                    <div className="character-count">
                                      Character count: <span>{values.questions?.[i]?.options[1]?.length || 0}</span>/<span>50</span>
                                    </div>
                                  </FormGroup>
                                  <FormGroup>
                                    <Label for={`questions${i}_answer3`}>Answer 3</Label>
                                    <Field
                                      id={`questions${i}_answer3`}
                                      className={`form-control ${errors.questions?.[i]?.options?.[2] ? 'is-invalid' : ''}`}
                                      maxLength="50"
                                      name={`questions.${i}.options.${2}`}
                                      onChange={(e) => handleChange(e)}
                                      disabled={isLoading}
                                    />
                                    <div className="character-count">
                                      Character count: <span>{values.questions?.[i]?.options?.[2]?.length || 0}</span>/<span>50</span>
                                    </div>
                                  </FormGroup>
                                  <FormGroup>
                                    <Label for={`questions${i}_answer4`}>Answer 4</Label>
                                    <Field
                                      id={`questions${i}_answer4`}
                                      className={`form-control ${errors.questions?.[i]?.options?.[3] ? 'is-invalid' : ''}`}
                                      maxLength="50"
                                      name={`questions.${i}.options.${3}`}
                                      onChange={(e) => handleChange(e)}
                                      disabled={isLoading}
                                    />
                                    <div className="character-count">
                                      Character count: <span>{values.questions?.[i]?.options?.[3]?.length || 0}</span>/<span>50</span>
                                    </div>
                                  </FormGroup>
                                </div>
                              </Col>
                            )
                          ))}
                          {(questionsArr.length < 4 || questionsArr?.filter((item) => item.hide === true).length > 0) && (
                            <Col xl="3" md="6">
                              <div className="form-col-add">
                                <Button
                                  generalType="default-outlined"
                                  type="button"
                                  onClick={addQuestion}
                                >
                                  + Add A Question
                                </Button>
                              </div>
                            </Col>
                          )}
                        </Row>
                      </div>
                    </div>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="3">
                  <FormGroup>
                    <Label for="custom_form_header">Sub-headline text</Label>
                    <Field
                      id="custom_form_header"
                      className={`form-control ${errors.custom_form_header ? 'is-invalid' : ''}`}
                      maxLength="80"
                      name="custom_form_header"
                      type="textarea"
                      onChange={handleChange}
                      as="textarea"
                      disabled={isLoading}
                    />
                    <div className="character-count">
                      Character count: <span>{values.custom_form_header?.length || 0}</span>/<span>80</span>
                    </div>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="3">
                  <FormGroup>
                    <Label for="survey_custom_submit_btn_label">Submit Button Text</Label>
                    <Field
                      id="survey_custom_submit_btn_label"
                      className={`form-control ${errors.custom_submit_btn_label ? 'is-invalid' : ''}`}
                      name="custom_submit_btn_label"
                      onChange={handleChange}
                      disabled={isLoading}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="3">
                  <FormGroup>
                    <Label for="survey_custom_redirect_url">URL Redirect (optional)</Label>
                    <div style={{fontSize: '12px', lineHeight: '14px', marginBottom: '0.5rem'}}>Where your customers will be redirected to upon submitting their request.</div>
                    <Field
                      id="survey_custom_redirect_url"
                      className={`form-control ${errors.chrome_ex_link ? 'is-invalid' : ''}`}
                      name="chrome_ex_link"
                      onChange={handleChange}
                      disabled={isLoading}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="3">
                  <div className="form-btn d-flex justify-content-end">
                    <Button
                      type="submit"
                      generalType="default"
                      disabled={
                        (!isValid || !dirty)
                        && !questionsArr?.filter((item) => item.hide === true).length > 0
                        && !(data.questions.length < questionsArr.length)
                      }
                    >
                      Save changes
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default BrowserTestSurveyForm;
