import { toastr } from 'react-redux-toastr';
import apiClient from '../../gears/apiClient';

export const mapCensusLayers = [
  'census-area-layer',
];

export const removeCensusLayers = (map, source) => {
  if (map.getSource(source)) {
    mapCensusLayers.map((layer) => map.removeLayer(layer));
    map.removeSource(source);
  }
};

export const addCensusLayers = (
  map,
  source,
  layer,
  type,
  options,
) => {
  if (map.getLayer(layer)) {
    map.removeLayer(layer);
  }

  map.addLayer({
    id: layer,
    type,
    source,
    ...options,
  });
};

export const coordinatesGeocoder = function (query) {
  // Match anything which looks like
  // decimal degrees coordinate pair.
  const matches = query.match(
    /^[ ]*(?:Lat: )?(-?\d+\.?\d*)[, ]+(?:Lng: )?(-?\d+\.?\d*)[ ]*$/i,
  );
  if (!matches) {
    return null;
  }

    function coordinateFeature(lng, lat) {
      return {
        center: [lng, lat],
        geometry: {
          type: 'Point',
          coordinates: [lng, lat],
        },
        place_name: `Lat: ${lat} Lng: ${lng}`,
        place_type: ['coordinate'],
        properties: {},
        type: 'Feature',
      };
    }

    const coord1 = Number(matches[1]);
    const coord2 = Number(matches[2]);
    const geocodes = [];

    if (coord1 < -90 || coord1 > 90) {
    // must be lng, lat
      geocodes.push(coordinateFeature(coord1, coord2));
    }

    if (coord2 < -90 || coord2 > 90) {
    // must be lat, lng
      geocodes.push(coordinateFeature(coord2, coord1));
    }

    if (geocodes.length === 0) {
    // else could be either lng, lat or lat, lng
      geocodes.push(coordinateFeature(coord1, coord2));
      geocodes.push(coordinateFeature(coord2, coord1));
    }

    return geocodes;
  };

  export const createPolygon = async (name, coordinates) => {
    const body = {name, coordinates};
    try {
      await apiClient.post('/create-polygon', body);
      toastr.success('Polygon created successfully!');
    } catch (e) {
      toastr.error(e);
    }
  };
