import React, {useRef, useState} from 'react';
import * as PropTypes from 'prop-types';
import {isEmpty} from 'lodash';
import SearchByColumn from './SearchByColumn';
import {mapDataToLegend} from '../../helpers/page_helper';
import Button from '../Button/Button';

const UsersTableFilter = ({filterListCustom, onFilterChange, currentFilter}) => {
  const [filter, setFilter] = useState({});
  const [showResetBtn, setShowResetBtn] = useState(false);
  const dateRangeFilterRef = useRef();
  const searchFilterRef = useRef();
  const defaultFilterList = [
    {
      value: 'email',
      label: 'Email',
    },
    {
      value: 'first_name',
      label: 'First name',
    },
    {
      value: 'last_name',
      label: 'Last name',
    },
    {
      value: 'guid',
      label: 'Guid',
    },
    {
      value: 'last_restart_time',
      label: 'Last Restart Time',
      type: 'select',
      options: [
        {
          value: 'more_than_threshold',
          label: 'More Than Threshold',
        },
        {
          value: 'less_than_threshold',
          label: 'Less Than Threshold',
        },
        {
          value: 'no_restart',
          label: 'No restart',
        },
      ],
    },
    {
      value: 'status',
      label: 'Status',
      type: 'select',
      options: [
        {
          value: 'active',
          label: 'Active',
        },
        {
          value: 'inactive',
          label: 'Inactive',
        },
      ],
    },
  ]
  const SearchByColumnOptions = filterListCustom ? filterListCustom : defaultFilterList;

  const onSearchChange = (value) => {
    const {searchBy, keyword} = value;
    if (searchBy && keyword) {
      setFilter({...filter, ...value});
    } else {
      const {searchBy, keyword, ...rest} = filter;
      setFilter({...rest});
    }
  };

  const handleSubmit = () => {
    const legend = {
      searchBy: 'search_by',
      keyword: 'keyword',
    };

    setShowResetBtn(!isEmpty(filter));
    return onFilterChange(mapDataToLegend(legend, filter));
  };

  const resetFilter = () => {
    if (searchFilterRef && dateRangeFilterRef) {
      searchFilterRef.current.reset();
      setFilter({});
      onFilterChange({});
      setShowResetBtn(false);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };

  return (
    <div className="d-flex align-items-center mb-3 mb-md-0 activities-filter">
      <SearchByColumn
        columnOptions={SearchByColumnOptions}
        onChange={onSearchChange}
        ref={searchFilterRef}
        onKeyUp={handleKeyPress}
        defaultSelect={currentFilter?.search_by || 'email'}
        defaultSelectedOption={currentFilter?.keyword || ''}
      />
      <div className="ml-3">
        <Button
          type="button"
          onClick={handleSubmit}
          generalType="default"
        >
          Filter
        </Button>
        {
          showResetBtn && (
            <Button
              className="ml-2"
              type="button"
              onClick={resetFilter}
              generalType="default-outlined"
            >
              <i className="mdi mdi-refresh icon" />
              Clear
            </Button>
          )
        }
      </div>
    </div>
  );
};

UsersTableFilter.propTypes = {
  onFilterChange: PropTypes.func.isRequired,
  currentFilter: PropTypes.objectOf(PropTypes.string),
};

export default UsersTableFilter;
