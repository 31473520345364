import React from 'react';
import {useDispatch} from 'react-redux';
import {Field, Form, Formik} from 'formik';
import * as Yup from 'yup';
import {FormFeedback, FormGroup, Input, Label} from 'reactstrap';
import * as PropTypes from 'prop-types';
import {updateAdmin} from "../../store/administrators/actions";

const validationSchema = Yup.object().shape({
	first_name: Yup.string(),
	last_name: Yup.string(),
});

const AdminForm = ({
  user,
  onSuccessSubmit,
}) => {
	const dispatch = useDispatch();
	
	return (
		<Formik
			initialValues={{
				first_name: user?.first_name || '',
				last_name: user?.last_name || '',
			}}
			validationSchema={validationSchema}
			onSubmit={async (values) => {
				const updateUserCallback = () => {
					onSuccessSubmit();
				};
				
				dispatch(updateAdmin(user.id, values, updateUserCallback));
			}}
			enableReinitialize
		>
			{({errors, touched, handleSubmit}) => (
				<Form id="user-form" onSubmit={handleSubmit}>
					<FormGroup>
						<Label for="user-edit-first_name">First name</Label>
						<Input
							id="user-edit-first_name"
							type="text"
							name="first_name"
							invalid={touched.first_name && !!errors.first_name}
							tag={Field}
						/>
						<FormFeedback>{errors.first_name}</FormFeedback>
					</FormGroup>
					
					<FormGroup>
						<Label for="user-edit-last_name">Last name</Label>
						<Input
							id="user-edit-last_name"
							type="text"
							name="last_name"
							invalid={touched.last_name && !!errors.last_name}
							tag={Field}
						/>
						<FormFeedback>{errors.last_name}</FormFeedback>
					</FormGroup>
				</Form>
			)}
		</Formik>
	);
};

AdminForm.propTypes = {
	onSuccessSubmit: PropTypes.func,
	user: PropTypes.shape({
		id: PropTypes.number.isRequired,
		first_name: PropTypes.string,
		last_name: PropTypes.string,
	}),
};

export default AdminForm;
