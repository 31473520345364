import { useEffect, useState } from 'react'
import { getDomainNameWithCompanyName } from '../../utility/utils';
import PageLoader from '../../components/PageLoader';
import { COOKIE_NAME_GOOGLE_AUTH_SOURCE, COOKIE_NAME_TOKEN } from '../../gears/constants';

const GoogleSignIn = (props) => {
  const { location } = props;
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchGoogleSignIn = async () => {
      const url = location?.search
      const searchParamsUrl = new URLSearchParams(url);
      const redirectSource = window.Cookies.get(COOKIE_NAME_GOOGLE_AUTH_SOURCE) ?? 'web';
      let modifiedUrl = getDomainNameWithCompanyName();
      try {
        const response = await fetch(
          `${modifiedUrl}/api-v2/google_sign_in?${searchParamsUrl}`,
          {
            method: 'GET',
            credentials: 'include',
          }
        );
        const responseData = await response.json();
        const isOnBaseDomain = modifiedUrl.split('.').length < 3;
        modifiedUrl = getDomainNameWithCompanyName(); // This is needed to make sure we get latest cookie
  
        if(redirectSource === 'extension' && responseData.company_key && responseData.guid) {
          const queryParams = new URLSearchParams();
          queryParams.append('companyKey', responseData.company_key);
          queryParams.append('guid', responseData.guid);
          if (isOnBaseDomain) {
            queryParams.append('token', responseData.token);
          }
          window.location.href = `${modifiedUrl}/hub-chrome-auth?${queryParams}`;
        } else if (responseData.token) {
          const queryParams = new URLSearchParams();
          if (isOnBaseDomain) {
            queryParams.append('token', responseData.token);
          }
          window.location.href = `${modifiedUrl}/admin/login?${queryParams}`;
        } else {
          throw new Error(responseData.message);
        }
        setIsLoading(false);
      } catch (e) {
        window.location.href = `${modifiedUrl}/admin/login?error=${e.message}`
      }
    };
    fetchGoogleSignIn();
  }, []);

  return isLoading ? <PageLoader /> : null;
}

export default GoogleSignIn;
