import {
  FETCH_NOTIFICATIONS,
  FETCH_NOTIFICATIONS_SUCCESS,
  FETCH_NOTIFICATIONS_ERROR,
  MARK_NOTIFICATIONS_AS_READ,
  MARK_NOTIFICATIONS_AS_READ_SUCCESS,
  MARK_NOTIFICATIONS_AS_READ_ERROR,
} from './actionTypes';

export const fetchNotifications = (data) => {
  return {
    type: FETCH_NOTIFICATIONS,
    payload: { data },
  };
};

export const fetchNotificationsSuccess = (payload) => {
  return {
    type: FETCH_NOTIFICATIONS_SUCCESS,
    payload,
  };
};

export const fetchNotificationsError = (error) => {
  return {
    type: FETCH_NOTIFICATIONS_ERROR,
    payload: error,
  };
};

export const markNotificationsAsRead = (data) => {
  return {
    type: MARK_NOTIFICATIONS_AS_READ,
    payload: { data },
  };
};

export const markNotificationsAsReadSuccess = (payload) => {
  return {
    type: MARK_NOTIFICATIONS_AS_READ_SUCCESS,
    payload,
  };
};

export const markNotificationsAsReadError = (error) => {
  return {
    type: MARK_NOTIFICATIONS_AS_READ_ERROR,
    payload: error,
  };
};