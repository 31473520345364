import { takeEvery, fork, put, all } from 'redux-saga/effects';

import { FETCH_USER_ISSUES_DETAILS } from './actionTypes';
import { fetchUserIssuesDetailsSuccess, fetchUserIssuesDetailsError } from './actions';
import apiClient from "../../gears/apiClient";
function* fetchUserIssuesDetails({ payload: { issueId } }) {
	try {
		const response = yield apiClient.get(`/disruption-log/${issueId}`);
		yield put(fetchUserIssuesDetailsSuccess(response));
	} catch (error) {
		yield put(fetchUserIssuesDetailsError(error));
	}
}

export function* watchFetchUserIssuesDetails() {
	yield takeEvery(FETCH_USER_ISSUES_DETAILS, fetchUserIssuesDetails);
}

function* userIssuesDetailsSaga() {
	yield all([
		fork(watchFetchUserIssuesDetails),
	]);
}

export default userIssuesDetailsSaga;
