import * as type from './actionTypes';

// Logo
export const getEXLogo = () => {
  return {
    type: type.GET_EX_LOGO_REQUEST,
  };
};

export const getEXLogoSuccess = (payload) => {
  return {
    type: type.GET_EX_LOGO_SUCCESS,
    payload,
  };
};

export const getEXLogoError = (error) => {
  return {
    type: type.GET_EX_LOGO_ERROR,
    payload: error,
  };
};

export const updateEXLogo = (payload) => {
  return {
    type: type.UPDATE_EX_LOGO_REQUEST,
    payload,
  };
};

export const updateEXLogoSuccess = (payload) => {
  return {
    type: type.UPDATE_EX_LOGO_SUCCESS,
    payload,
  };
};

export const updateEXLogoError = (error) => {
  return {
    type: type.UPDATE_EX_LOGO_ERROR,
    payload: error,
  };
};

// Status message
export const getStatusMessage = (payload) => {
  return {
    type: type.GET_STATUS_MESSAGE_REQUEST,
    payload,
  };
};

export const getStatusMessageSuccess = (payload) => {
  return {
    type: type.GET_STATUS_MESSAGE_SUCCESS,
    payload,
  };
};

export const getStatusMessageError = (error) => {
  return {
    type: type.GET_STATUS_MESSAGE_ERROR,
    payload: error,
  };
};

export const updateStatusMessage = (payload) => {
  return {
    type: type.UPDATE_STATUS_MESSAGE_REQUEST,
    payload,
  };
};

export const updateStatusMessageSuccess = (payload) => {
  return {
    type: type.UPDATE_STATUS_MESSAGE_SUCCESS,
    payload,
  };
};

export const updateStatusMessageError = (error) => {
  return {
    type: type.UPDATE_STATUS_MESSAGE_ERROR,
    payload: error,
  };
};

// Helpdesk Message
export const getHelpdeskMessage = (payload) => {
  return {
    type: type.GET_HELPDESK_MESSAGE,
    payload,
  };
};

export const getHelpdeskMessageSuccess = (payload) => {
  return {
    type: type.GET_HELPDESK_MESSAGE_SUCCESS,
    payload,
  };
};

export const getHelpdeskMessageError = (error) => {
  return {
    type: type.GET_HELPDESK_MESSAGE_ERROR,
    payload: error,
  };
};

// Update Helpdesk Message
export const updateHelpdeskMessage = (payload) => {
  return {
    type: type.UPDATE_HELPDESK_MESSAGE,
    payload,
  };
};

export const updateHelpdeskMessageSuccess = (payload) => {
  return {
    type: type.UPDATE_HELPDESK_MESSAGE_SUCCESS,
    payload,
  };
};

export const updateHelpdeskMessageError = (error) => {
  return {
    type: type.UPDATE_HELPDESK_MESSAGE_ERROR,
    payload: error,
  };
};
