import React from 'react';
import PropTypes from 'prop-types';
import { Input, Label } from 'reactstrap';

const MapBoxSwitch = ({
  id,
  value,
  onHandlerChange,
  isChecked,
  leftLabel,
  rightLabel,
}) => {
  return (
    <div className="theme-menu">
      <span>
        {leftLabel}
      </span>
      <Label for={id} className="switch">
        <Input
          id={id}
          type="checkbox"
          name="mode"
          value={value}
          onChange={onHandlerChange}
          checked={isChecked}
        />
        <span className="slider round" />
      </Label>
      <span>
        {rightLabel}
      </span>
    </div>
  );
};

MapBoxSwitch.propTypes = {
  id: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onHandlerChange: PropTypes.func,
  isChecked: PropTypes.bool,
  leftLabel: PropTypes.string,
  rightLabel: PropTypes.string,
};

export default MapBoxSwitch;
