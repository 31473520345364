import React from 'react';
import PropTypes from 'prop-types';
import { Slider } from '@material-ui/core';

const ColorSchemeSlider = ({
  title,
  colorLeft,
  colorRight,
  colorMiddle,
  minDistance,
  keys,
  maxValue,
  unit,
  colorsData,
  setColorsData,
}) => {
  const inputValue = [colorsData?.[keys[0]] || 0, colorsData?.[keys[1]] || 0];
  const valuetext = (value) => `${value}°C`;

  const onInputChange = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (title === 'Jitter' || title === 'Latency') {
      setColorsData((prevState) => ({
        ...prevState,
        [keys[0]]: newValue[1],
        [keys[1]]: newValue[0],
      }));
    } else if (newValue[1] - newValue[0] < minDistance) {
      if (activeThumb === 0) {
        const clamped = Math.min(newValue[0], 100 - minDistance);
        setColorsData((prevState) => ({
          ...prevState,
          [keys[0]]: clamped,
          [keys[1]]: clamped + minDistance,
        }));
      } else {
        const clamped = Math.max(newValue[1], minDistance);
        setColorsData((prevState) => ({
          ...prevState,
          [keys[0]]: clamped - minDistance,
          [keys[1]]: clamped,
        }));
      }
    } else {
      setColorsData((prevState) => ({
        ...prevState,
        [keys[0]]: newValue[0],
        [keys[1]]: newValue[1],
      }));
    }
  };

  const marks = [
    {
      value: 0,
      label: `0 ${unit}`,
    },

    {
      value: maxValue,
      label: `${maxValue} ${unit}`,
    },
  ];

  const calculateWidth = (val) => {
    if (maxValue > 100) {
      const newVal = (val * 100) / maxValue;
      return newVal;
    }
    if (val > maxValue) {
      return 100;
    }
    return val;
  };

  return (
    <div className="color-scheme-item">
      <div className="title">{title}</div>
      <div className="color-scheme-slider">
        <div className="color-left" style={{backgroundColor: `${colorLeft}`}} />
        <Slider
          getAriaLabel={() => 'Minimum distance shift'}
          aria-label="Always visible"
          value={inputValue}
          onChange={onInputChange}
          valueLabelDisplay="on"
          getAriaValueText={valuetext}
          disableSwap
          max={maxValue}
          style={{color: `${colorMiddle}`}}
          marks={marks}
        />
        <div
          className="color-right"
          style={{
            width: `calc(100% - ${calculateWidth(inputValue?.[1])}%)`,
            backgroundColor: `${colorRight}`,
          }}
        />
      </div>
    </div>
  );
};

ColorSchemeSlider.propTypes = {
  title: PropTypes.string,
  colorLeft: PropTypes.string,
  colorRight: PropTypes.string,
  colorMiddle: PropTypes.string,
  minDistance: PropTypes.number,
  keys: PropTypes.arrayOf(PropTypes.string),
  maxValue: PropTypes.number,
  unit: PropTypes.string,
};

export default ColorSchemeSlider;
