export const GET_BLUETOOTH_DEVICES = 'GET_BLUETOOTH_DEVICES';
export const GET_BLUETOOTH_DEVICES_SUCCESS = 'GET_BLUETOOTH_DEVICES_SUCCESS';
export const GET_BLUETOOTH_DEVICES_ERROR = 'GET_BLUETOOTH_DEVICES_ERROR';

export const ADD_BLUETOOTH_DEVICE = 'ADD_BLUETOOTH_DEVICE';
export const ADD_BLUETOOTH_DEVICE_SUCCESS = 'ADD_BLUETOOTH_DEVICE_SUCCESS';
export const ADD_BLUETOOTH_DEVICE_ERROR = 'ADD_BLUETOOTH_DEVICE_ERROR';

export const DELETE_BLUETOOTH_DEVICE = 'DELETE_BLUETOOTH_DEVICE';
export const DELETE_BLUETOOTH_DEVICE_SUCCESS = 'DELETE_BLUETOOTH_DEVICE_SUCCESS';
export const DELETE_BLUETOOTH_DEVICE_ERROR = 'DELETE_BLUETOOTH_DEVICE_ERROR';

export const UPDATE_BLUETOOTH_DEVICE = 'UPDATE_BLUETOOTH_DEVICE';
export const UPDATE_BLUETOOTH_DEVICE_SUCCESS = 'UPDATE_BLUETOOTH_DEVICE_SUCCESS';
export const UPDATE_BLUETOOTH_DEVICE_ERROR = 'UPDATE_BLUETOOTH_DEVICE_ERROR';
