import React from 'react';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';
import './pagination.scss';
const Pagination = ({ paginationData, navigateTo }) => {
  return (
    <div className="pagination d-flex align-items-center justify-content-end">
      <span>{`Page ${paginationData?.currentPage || ''}`}</span>
      <div className="pagination-btns">
        <Button
          className="prev"
          variant="contained"
          color="primary"
          disabled={!paginationData?.prevPage}
          onClick={() => navigateTo(paginationData?.prevPage)}
        >
          <i className="mdi mdi-arrow-left-bold-box" />
        </Button>
        <Button
          className="next"
          variant="contained"
          color="primary"
          disabled={!paginationData?.nextPage}
          onClick={() => navigateTo(paginationData?.nextPage)}
        >
          <i className="mdi mdi-arrow-right-bold-box" />
        </Button>
      </div>
    </div>
  );
};

Pagination.propTypes = {
  navigateTo: PropTypes.func,
};

export default Pagination;
