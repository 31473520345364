// Logo
export const GET_EX_LOGO_REQUEST = 'GET_EX_LOGO_REQUEST';
export const GET_EX_LOGO_SUCCESS = 'GET_EX_LOGO_SUCCESS';
export const GET_EX_LOGO_ERROR = 'GET_EX_LOGO_ERROR';

export const UPDATE_EX_LOGO_REQUEST = 'UPDATE_EX_LOGO_REQUEST';
export const UPDATE_EX_LOGO_SUCCESS = 'UPDATE_EX_LOGO_SUCCESS';
export const UPDATE_EX_LOGO_ERROR = 'UPDATE_EX_LOGO_ERROR';

// Status message
export const GET_STATUS_MESSAGE_REQUEST = 'GET_STATUS_MESSAGE_REQUEST';
export const GET_STATUS_MESSAGE_SUCCESS = 'GET_STATUS_MESSAGE_SUCCESS';
export const GET_STATUS_MESSAGE_ERROR = 'GET_STATUS_MESSAGE_ERROR';

export const UPDATE_STATUS_MESSAGE_REQUEST = 'UPDATE_STATUS_MESSAGE_REQUEST';
export const UPDATE_STATUS_MESSAGE_SUCCESS = 'UPDATE_STATUS_MESSAGE_SUCCESS';
export const UPDATE_STATUS_MESSAGE_ERROR = 'UPDATE_STATUS_MESSAGE_ERROR';

// Helpdesk Message
export const GET_HELPDESK_MESSAGE = 'GET_HELPDESK_MESSAGE';
export const GET_HELPDESK_MESSAGE_SUCCESS = 'GET_HELPDESK_MESSAGE_SUCCESS';
export const GET_HELPDESK_MESSAGE_ERROR = 'GET_HELPDESK_MESSAGE_ERROR';

export const UPDATE_HELPDESK_MESSAGE = 'UPDATE_HELPDESK_MESSAGE';
export const UPDATE_HELPDESK_MESSAGE_SUCCESS = 'UPDATE_HELPDESK_MESSAGE_SUCCESS';
export const UPDATE_HELPDESK_MESSAGE_ERROR = 'UPDATE_HELPDESK_MESSAGE_ERROR';
