import {all, fork, put, takeEvery} from 'redux-saga/effects';

import { GET_DEVICE_ACTIVITIES, GET_USER_DEVICE_ACTIVITIES } from './actionTypes';
import {
  getDeviceActivitiesApiError,
  getDeviceActivitiesSuccess,
  getUserDeviceActivitiesApiError,
  getUserDeviceActivitiesSuccess
} from './actions';
import apiClient from '../../gears/apiClient';

// Get device activities
function* getDeviceActivities({ payload: { data } }) {
  try {
    const response = yield apiClient.get('/device-activities', data);
    yield put(getDeviceActivitiesSuccess(response));
  } catch (error) {
    yield put(getDeviceActivitiesApiError(error));
  }
}

export function* watchGetDeviceActivities() {
  yield takeEvery(GET_DEVICE_ACTIVITIES, getDeviceActivities);
}

function* getUserDeviceActivities({ payload: { data } }) {
  try {
    const response = yield apiClient.get(`/get-user-device-activities`, { user_id: data.id, page: data?.page });
    yield put(getUserDeviceActivitiesSuccess(response));
  } catch (error) {
    yield put(getUserDeviceActivitiesApiError(error));
  }
}

export function* watchGetUserDeviceActivities() {
  yield takeEvery(GET_USER_DEVICE_ACTIVITIES, getUserDeviceActivities);
}

function* deviceSaga() {
  yield all([
    fork(watchGetDeviceActivities),
    fork(watchGetUserDeviceActivities),
  ]);
}

export default deviceSaga;
