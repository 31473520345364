import React, {useEffect} from 'react';
import IssuesEventLogsTable from "../../../tables/IssuesEventLogsTable/IssuesEventLogsTable";
import styles from './issue-event-logs.module.scss';
import {useDispatch, useSelector} from "react-redux";
import {fetchIssueEventLogs} from "../../../../store/user-issue-events-logs/actions";
import {Spinner} from "reactstrap";

function IssueEventLogs({issueId}) {
	const dispatch = useDispatch();
	const { data, loading, error } = useSelector((state) => state.IssuesEventLogs);
	useEffect(() => {
		if (issueId) {
			dispatch(fetchIssueEventLogs(issueId));
		}
	}, [dispatch, issueId]);

	return (
		<div>
			<h2 className={styles.title}>Event Logs</h2>
			{loading ? <div className="spinner-wrapper text-center"><Spinner color="primary" /></div> : <IssuesEventLogsTable data={data} />}
			{data.length === 0 && (<div className="text-center font-size-16">There is no event log</div>)}
		</div>
	);
}

export default IssueEventLogs;
