import React, {useState, useEffect, forwardRef, useImperativeHandle, useRef} from 'react';
import PropTypes from 'prop-types';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';

const DateRangeFilter = forwardRef(({
  onChange,
  className,
  onKeyUp,
  dateStart = '',
  dateEnd = '',
  isCustom = false,
}, ref) => {
  const dateRangeRef = useRef();
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  useEffect(() => {
    if (startDate && endDate) {
      const dateRange = {
        startDate: moment(startDate).format('YYYY-MM-DD'),
        endDate: moment(endDate).format('YYYY-MM-DD'),
      };

      return onChange(dateRange);
    }
    return onChange(null);
  }, [startDate, endDate]);

  useImperativeHandle(ref, () => ({
    reset() {
      dateRangeRef.current.$picker.val('');
      setStartDate('');
      setEndDate('');
    },
  }));

  const handleCallback = (start, end) => {
    setStartDate(start);
    setEndDate(end);
  };

  const isDefaultValue = dateStart && dateEnd;
  const dateSelected = isDefaultValue ? `${moment(dateStart).format('YYYY-MM-DD')} - ${moment(dateEnd).format('YYYY-MM-DD')}` : '';

  const handleApply = (event, picker) => {
    picker.element.val(`${picker.startDate.format('M/DD/YYYY')} - ${picker.endDate.format('M/DD/YYYY')}`);
    if (isCustom && !isDefaultValue) {
      onChange({
        startDate: picker.startDate.format('YYYY-MM-DD'),
        endDate: picker.endDate.format('YYYY-MM-DD'),
      });
    }
  };

  const handleCancel = (event, picker) => {
    picker.element.val('');
    setStartDate('');
    setEndDate('');
    if (isDefaultValue) {
      onChange({
        created_after: '',
        created_before: '',
      });
    }
  };

  useEffect(() => {
    if (isDefaultValue) {
      dateRangeRef.current.$picker.val('');
      setStartDate('');
      setEndDate('');
    }
  }, [isDefaultValue]);

  return (
    <div className={`date-range-piker ${className}`}>
      <div className="input-group test-results-date-picker">
        <DateRangePicker
          ref={dateRangeRef}
          onCallback={handleCallback}
          onApply={handleApply}
          onCancel={handleCancel}
          initialSettings={{
            autoUpdateInput: false,
          }}
        >
          <input
            type="text"
            className="form-control"
            onKeyUp={onKeyUp}
            placeholder={isDefaultValue ? dateSelected : 'Date Range'}
          />
        </DateRangePicker>
      </div>
    </div>
  );
});

DateRangeFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  dateStart: PropTypes.string,
  dateEnd: PropTypes.string,
  isCustom: PropTypes.bool,
};

export default DateRangeFilter;
