import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { Col, Row } from 'reactstrap';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import {fetchAnalytics, fetchRecentActivities} from '../../store/actions';
import AhoyTrack from '../../modules/AhoyTrack';
import DashboardBlock from '../../components/overview/OverviewBlock';
import MapBox from '../../components/MapBox/MapBox';
import TestDetailsDrawer from "../../components/drawers/TestDetailsDrawer";
import AiSelfServiceMertics from "../../components/overview/AISelfServiceMetrics/AiSelfServiceMertics";
import AppPerformanceIndicators from "../../components/overview/AppPerformanceIndicators/AppPerformanceIndicators";
import FeaturesIssuesDrawer from "../../components/drawers/FeaturedIssuesDrawer/FeaturesIssuesDrawer";
import { ReactComponent as ChevronsIcons } from "../../images/chevrons-left.svg";
import ComponentLoader from "../../components/ComponentLoader";
import styles from './overview.module.scss';

const Overview = () => {
  const { data: activitiesData, loading: isRecentActivitiesLoading } = useSelector((state) => state.RecentActivities);
  const [testId, setTestId] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isFeaturedIssuesDrawerOpen, setIsFeaturedIssuesDrawerOpen] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    AhoyTrack();
    dispatch(fetchRecentActivities(history));
    dispatch(fetchAnalytics('active_user_result'));
  }, []);

  const onMapMarkerClick = (id) => {
    setTestId(id);
    setIsDrawerOpen(true);
  };

  const onDrawerClose = () => {
    setTestId(null);
    setIsDrawerOpen(false);
  };

  return (
    <>
      <div className="container-fluid content">
        <Row className="page-title align-items-center">
          <Col sm={12} className={styles.header}>
              <div className="page-title-box">
                <h2>Overview</h2>
              </div>
              <button
                className="featured-issue-btn"
                onClick={() => setIsFeaturedIssuesDrawerOpen(true)}
              >
                <ChevronsIcons />
                <span>Featured Issues</span>
              </button>
          </Col>
        </Row>
        <AiSelfServiceMertics/>
        <DashboardBlock>
          <div className={styles.mapWrapper}>
            {isRecentActivitiesLoading && <ComponentLoader />}
            {!isRecentActivitiesLoading &&
              <MapBox markers={activitiesData?.features || []} onMapMarkerClick={onMapMarkerClick}/>}
          </div>
        </DashboardBlock>
      <AppPerformanceIndicators />
      </div>
      <FeaturesIssuesDrawer
        isOpen={isFeaturedIssuesDrawerOpen}
        onHandlerClose={() => setIsFeaturedIssuesDrawerOpen(false)}
      />
      <TestDetailsDrawer
        isOpen={isDrawerOpen}
        onHandlerClose={onDrawerClose}
        testId={testId}
      />
    </>
  );
};

export default Overview;
