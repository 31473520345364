import React, {useState} from 'react';
import styles from './issue-header.module.scss';
import {useDispatch, useSelector} from "react-redux";
import {capitalizeFirstLetter} from "../../../../helpers/page_helper";
import {Spinner} from "reactstrap";
import {requestRemoteTest} from "../../../../store/testresults/userstatus/actions";
import {changeIssueStatus} from "../../../../store/user-issue-logs/actions";
import {renderIssueStatus} from '../issueDetailsHelper';
import IssuesModalMarkComplete from "../IssuesModalMarkComplete/IssuesModalMarkComplete";

function IssueHeader({
  closeDrawer,
  issueId,
  arrData = [],
  callBack = null,
}) {
	const dispatch = useDispatch();
	const [openConfirmModal, setOpenConfirmModal] = useState(false);
	const { data, loading: isLoading } = useSelector((state) => state.UserIssuesDetails);
	const { data: userIssueLogsData } = useSelector((state) => state.UserIssuesLogs);
	const { requestRemoteResultText, requestRemoteTestLoading } = useSelector((state) => state.UserStatus);
	
	const issueStatus = () => {
		const dataToFilter = arrData.length > 0 ? arrData : userIssueLogsData;
		return renderIssueStatus(dataToFilter, issueId)
	}

	const testNowHandler = () => {
		if(data?.guid) {
			dispatch(requestRemoteTest(data?.guid));
		}
	}
	
	const markCompletedHandler = async (message = null, sendEmail = false) => {
		if(issueId) {
			try {
        dispatch(changeIssueStatus(issueId, 'completed', message, sendEmail));
        if(callBack) {
          callBack();
        }
      } catch(e) {
        console.log(e);
      }
		}
	}
	const confirmModalToggle = () => {
		setOpenConfirmModal(!openConfirmModal);
	}
  const isStatusCompleted = issueStatus() === 'Completed';
	return (
		<>
			<div className={styles.issueHeader}>
				<div className={styles.issueHeaderWrapper}>
					{
						isLoading ? <Spinner size="sm" color="primary" /> :
						<h2 className={styles.issueTitle}>{`${capitalizeFirstLetter(data?.category)} Issue Performance ${capitalizeFirstLetter(data?.status)}`}</h2>
					}
					<button className={styles.testNowBtn} onClick={testNowHandler} disabled={requestRemoteTestLoading || !data?.userActive}>{requestRemoteResultText}</button>
				</div>
				<div className={styles.issueHeaderWrapper}>
					{openConfirmModal && (
						<IssuesModalMarkComplete openConfirmModal={openConfirmModal} confirmModalToggle={confirmModalToggle} markCompletedHandler={markCompletedHandler} />
					)}
					<button
	          className={isStatusCompleted ? styles.completeBtnSuccess : styles.completeBtn}
						onClick={() => confirmModalToggle()}
	          disabled={isStatusCompleted || !data?.userActive}
	        >
	          {isStatusCompleted ? 'Completed' : 'Mark Complete'}
	        </button>
					<button
						className={styles.issueCloseBtn}
						onClick={closeDrawer}
					>
						<i className="mdi mdi-close" />
					</button>
				</div>
			</div>
		</>
	);
}

export default IssueHeader;
