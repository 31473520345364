//https://github.com/websockets/ws
class WebsocketClient extends EventTarget {
    constructor(configuration = {}) {
        //Handle any setup in parent
        super();

        //Store the configuration
        this._configuration = configuration;

        //Flag connected as false
        this._connected = false;
    }

    setHost(host) {
        //Update the host
        this._configuration.host = host;

        //Chain
        return this;
    }

    connect() {
        //Scope this
        const self = this;

        //Early out if already connected
        if (self._connected) { return; }

        //Attempt to connect to host
        let host = this._configuration.host;

        //Attempt to connect to host
        try {
            //Create connection
            this._connection = new WebSocket(host);

            //Bind open handler
            this._connection.addEventListener('open', () => {
                //Update internal status
                self._connected = true;

                //Fire event handler
                self.dispatchEvent(new Event('open'));
            });

            //Bind message handler
            this._connection.addEventListener("message", event => {
                //Fire event handler
                self.dispatchEvent(new CustomEvent('message', {detail: {message: event.data}}));
            })

            //Bind error handler
            this._connection.addEventListener('error', () => {
                //Update internal status
                self._connected = false;

                //Fire event handler
                self.dispatchEvent(new Event('error'));
            });

            //Bind close handler
            this._connection.addEventListener('close', () => {
                //Update internal status
                self._connected = false;

                //Fire event handler
                self.dispatchEvent(new Event('close'));
            });
        } catch (error) {
            console.error(error);
        }

        //Chain
        return this;
    }

    send(message) {
        //Get connection
        let connection = this._connection;

        //Check to make sure connection is open and ready to send
        if (this._connected !== true || connection == null || connection.readyState !== 1) {
            throw new Error('connection is not established');
        }

        //Try to send message
        try {
            this._connection.send(message);
        } catch (error) {
            console.error(error);
        }

        //Chain
        return this;
    }

    close(code = 1000, message = null) {
        //Early out if not connection
        if (this._connected === true) {
            //Attempt to close connection
            try {
                //Close connection with optional code and message
                this._connection.close(code, message);

                //Update status
                this._connected = false;
            } catch (error) {
                console.error(error);
            }
        }

        //Chain
        return this;
    }

    buffered() {
        if (this._connected === true) {
            return this._connection.bufferedAmount ;
        } else {
            return -1;
        }
    }

    state() {
        if (this._connected === true) {
            return this._connection.readyState ;
        } else {
            return -1;
        }
    }
}

export default WebsocketClient;
