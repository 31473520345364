import React, {useEffect, useRef, useState} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import {Helmet} from 'react-helmet';
import {helpRequest} from '../../store/helpRequest/actions';
import {createAnswers} from '../../store/answers/actions';

const SliderSettings = {
  autoplay: false,
  arrows: false,
  dots: false,
  draggable: false,
  touchMove: false,
  adaptiveHeight: true,
};

const SurveyForm = (props) => {
  const {activityId, questions, createAnswers, createAnswersError} = props;
  const [currentSurveyQuestion, setCurrentSurveyQuestion] = useState(0);
  const [surveyAnswers, setSurveyAnswers] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const slider = useRef(null);
  const activeQuestion = questions && questions.length > 0 && questions[currentSurveyQuestion];

  const setQuestionAnswer = (question, answer) => {
    setCurrentSurveyQuestion(currentSurveyQuestion + 1);
    surveyAnswers.push({question_id: question.id, result: answer});

    setSurveyAnswers(surveyAnswers);
    slider.current.slickGoTo(currentSurveyQuestion + 1);
  };

  const submitAnswers = async () => {
    await createAnswers({
      user_activity_id: activityId,
      answers: surveyAnswers,
    });

    setIsSubmitted(true);
  };

  useEffect(() => {
    if (questions.length > 0 && currentSurveyQuestion === questions.length) {
      submitAnswers();
    }
  }, [currentSurveyQuestion]);

  if (!activityId || !questions || questions.length <= 0 || (!activeQuestion && !isSubmitted)) {
    return '';
  }

  if (createAnswersError) {
    return <div className="col-12 help-form__info submitted">{createAnswersError}</div>;
  }

  return (isSubmitted ? (
    <div className="col-12 help-form__info submitted">Thank you, please fill in the form in order to complete your
      request.
    </div>
  ) : (
    <>
      <Helmet>
        <link
          rel="stylesheet"
          type="text/css"
          charSet="UTF-8"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
        />
      </Helmet>
      <div className="survey-slider-block">
        <Slider ref={slider} {...SliderSettings}>
          {
            questions.map((question, index) => (
              <div key={`survey-question-${index}`}>
                <p className="col-12 connection-carousel-heading">{question.title}</p>
                <div className="col-12 connection-carousel m-auto">
                  <div className="answers-holder">
                    {
                      question.options.map((option, index) => (
                        <div key={`answer-${index}`} className="answer-holder">
                          <button
                            type="button"
                            className="center button button-white button-md"
                            onClick={() => setQuestionAnswer(activeQuestion, option)}
                            key={`survey-option-${index}`}
                          >{option}
                          </button>
                        </div>
                      ))
                    }
                  </div>
                </div>
              </div>
            ))
          }
        </Slider>
        <ul className="slick-dots">
          {questions.length && questions.map((question, index) => (
            <li key={index} className={index === currentSurveyQuestion ? 'slick-active' : ''}>
              <button type="button">{index + 1}</button>
            </li>
          ))}
        </ul>
      </div>

    </>
  ));
};

SurveyForm.defaultProps = {
  questions: [],
};

SurveyForm.propTypes = {
  activityId: PropTypes.number,
  questions: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    order: PropTypes.number,
    title: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(PropTypes.string),
  })),
  createAnswers: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const {data, loading} = state.RecentActivities;
  const {error: createAnswersError} = state.Answers;
  const {step_2} = state.Company.companyDetails;
  return {
    activityId: data?.activity_id || null,
    activityLoading: loading,
    questions: step_2?.questions,
    createAnswersError,
  };
};

const mapDispatchToProps = {
  helpRequest, createAnswers,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SurveyForm);
