import React, { useState } from 'react'
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Button, FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { updateBillingEmail } from '../../store/actions';

const validationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email'),
});

const BillingForm = () => {
  const { data: profile } = useSelector(state => state.Profile);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  return (
    <Formik
      initialValues={{
        email: profile?.billing_email || '',
      }}
      validationSchema={validationSchema}
      onSubmit={async (values) => {
        setIsLoading(true);
        dispatch(updateBillingEmail({billing_email: values.email}))
        setIsLoading(false);
      }}
      enableReinitialize
    >
      {({ errors, touched, handleSubmit, isValid }) => (
        <Form id="billing-form" className="form" onSubmit={handleSubmit}>
          <FormGroup>
            <Label for="profile-billing-email">Billing Email</Label>
            <Input
              id="profile-billing-email"
              type="text"
              name="email"
              invalid={touched.email && !!errors.email}
              tag={Field}
            />
            <FormFeedback>{errors.email}</FormFeedback>
          </FormGroup>
          <FormGroup>
            <Button
              type="submit"
              color="primary"
              disabled={isLoading || !isValid}
            >
              {isLoading ? 'Loading...' : 'Submit'}
            </Button>
          </FormGroup>
        </Form>
      )}
    </Formik>
  )
}

export default BillingForm