export const FETCH_DASHBOARD_ANALYTICS = 'FETCH_DASHBOARD_ANALYTICS';
export const FETCH_DASHBOARD_ANALYTICS_SUCCESS = 'FETCH_DASHBOARD_ANALYTICS_SUCCESS';
export const FETCH_DASHBOARD_ANALYTICS_API_ERROR = 'FETCH_DASHBOARD_ANALYTICS_API_ERROR';

export const FETCH_DASHBOARD_QUERY = 'FETCH_DASHBOARD_QUERY';
export const FETCH_DASHBOARD_QUERY_SUCCESS = 'FETCH_DASHBOARD_QUERY_SUCCESS';
export const FETCH_DASHBOARD_QUERY_ERROR = 'FETCH_DASHBOARD_QUERY_ERROR';

export const FETCH_ANALYTICS = 'FETCH_ANALYTICS';
export const FETCH_ANALYTICS_SUCCESS = 'FETCH_ANALYTICS_SUCCESS';
export const FETCH_ANALYTICS_ERROR = 'FETCH_ANALYTICS_ERROR';
