import React from 'react';
import PropTypes from 'prop-types';
import DateRangeFilter from '../tables-filters/DateRange';

const MapViewDatePicker = ({filters, setFilters}) => {
  const addDateToFilters = (date) => {
    if (date) {
      setFilters((prevState) => ({
        ...prevState,
        created_after: date.startDate,
        created_before: date.endDate,
      }));
    }
  };

  return (
    <DateRangeFilter
      onChange={(e) => addDateToFilters(e)}
      dateStart={filters?.created_after ?? ''}
      dateEnd={filters?.created_before ?? ''}
      isCustom
    />
  );
};

MapViewDatePicker.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  filters: PropTypes.object,
  setFilters: PropTypes.func,
};

export default MapViewDatePicker;
