import { FETCH_TEST_DETAILS, FETCH_TEST_DETAILS_SUCCESS, FETCH_TEST_DETAILS_API_ERROR, FETCH_TEST_DETAILS_ACTIVITY, FETCH_TEST_DETAILS_ACTIVITY_SUCCESS, FETCH_TEST_DETAILS_ACTIVITY_ERROR, FETCH_TEST_DETAILS_AVERAGES, FETCH_TEST_DETAILS_AVERAGES_SUCCESS, FETCH_TEST_DETAILS_AVERAGES_ERROR } from './actionTypes';

const initialState = {
  error: null,
  loading: false,
  testDetailsActivityData: {},
  testDetailsActivityLoading: false,
  testDetailsActivityError: null,
  testDetailsAveragesData: {},
  testDetailsAveragesLoading: false,
  testDetailsAveragesError: null,
};

const fetchTestDetails = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TEST_DETAILS:
      return {
        ...state,
        history: action.history,
        loading: true,
      };
    case FETCH_TEST_DETAILS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      };
    case FETCH_TEST_DETAILS_API_ERROR:
      return {
        ...state,
        data: {},
        error: action.payload,
        loading: false,
      };
    case FETCH_TEST_DETAILS_ACTIVITY:
      return {
        ...state,
        testDetailsActivityLoading: true,
      };
    case FETCH_TEST_DETAILS_ACTIVITY_SUCCESS:
      return {
        ...state,
        testDetailsActivityData: action.payload,
        testDetailsActivityLoading: false,
      };
    case FETCH_TEST_DETAILS_ACTIVITY_ERROR:
      return {
        ...state,
        testDetailsActivityError: action.payload,
        testDetailsActivityLoading: false,
      };
    case FETCH_TEST_DETAILS_AVERAGES:
      return {
        ...state,
        testDetailsAveragesLoading: true,
      };
    case FETCH_TEST_DETAILS_AVERAGES_SUCCESS:
      return {
        ...state,
        testDetailsAveragesData: action.payload,
        testDetailsAveragesLoading: false,
      };
    case FETCH_TEST_DETAILS_AVERAGES_ERROR:
      return {
        ...state,
        testDetailsAveragesError: action.payload,
        testDetailsAveragesLoading: false,
      };
    default:
      return { ...state };
  }
};

export default fetchTestDetails;
