import React, { useEffect, useState } from 'react';
import { List } from 'react-content-loader';
import { toastr } from 'react-redux-toastr';
import { Col, Row } from 'reactstrap';
import SubscriptionPlan from './SubscriptionPlan';
import apiClient from '../../gears/apiClient';
import { enterprisePlan } from './SubscriptionsHelpers';
import subscription from './subscription.module.scss';
import { useSelector } from 'react-redux';

const SubscriptionsPlans = ({
  isStripeVisible,
  onPlanChange,
  setSelectedPlanId
}) => {
  const { isCustomPlan } = useSelector(state => state.Company);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isPlanSelected, setIsPlanSelected] = useState(false);

  const fetchSubscriptionPlans = async () => {
    setIsLoading(true);
    try {
      const response = await apiClient.get('/company-available-subscriptions',
        {
          type: isCustomPlan ? 'event_based' : 'user_based',
        }
      );
      setData([...response.plans, enterprisePlan]);
      if(isPlanSelected) setIsPlanSelected(false);
    } catch(e) {
      toastr.error(e?.error)
    }

    setIsLoading(false);
  }

  useEffect(() => {
    if(!isStripeVisible && isCustomPlan !== undefined) {
      fetchSubscriptionPlans();
    }
  }, [isStripeVisible, isCustomPlan]);

  const upgradePlan = (planId) => {
    setSelectedPlanId(planId);
    const copyData = [...data];
    const findPlan = copyData.filter(plan => plan.id === planId);
    setData(findPlan);
    setIsPlanSelected(true);
    onPlanChange(true);
  }

  const renderSubscriptionPlans = () => {
    const copyData = [...data];
    const currentPlanPrice = copyData.find(plan => plan.is_current)?.price;
    return (
      <div className={subscription.plansList}>
        <Row noGutters>
          {copyData.map(plan => (
            <Col
              xs="12"
              xl={isStripeVisible ? 8 : 3}
              className="mb-4"
              key={plan.id}
            >
              <SubscriptionPlan
                planData={plan}
                key={plan.id}
                onPlanChange={upgradePlan}
                isStripeVisible={isStripeVisible}
                isDowngrade={currentPlanPrice > plan.price}
              />
            </Col>
          ))}
        </Row>
      </div>
    );
  };

  return (
    <div className={subscription.plans}>
      {isLoading && <List height={250} viewBox="0 0 200 250" />}
      {!isLoading && renderSubscriptionPlans()}
    </div>
  )
}

export default SubscriptionsPlans;