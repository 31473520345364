export const onSaveButtonClick = async (issueData, getOrUpdateIssueDetails) => {
  const dataToSend = {
    severity: issueData.severity,
    recordIssue: issueData.recordIssue,
    notifyAdmins: issueData.notifyAdmins,
    notifyUser: issueData.notifyUser
  };
  getOrUpdateIssueDetails('update', dataToSend)
}

export const issueDetailsArr = (issueData, onCheckboxClick) => [
  {
    label: "Notify End User",
    name: "notifyUser",
    value: issueData.notifyUser,
    action: onCheckboxClick,
  },
  {
    label: "Notify Admin",
    name: "notifyAdmins",
    value: issueData.notifyAdmins,
    action: onCheckboxClick,
  },
]

export const severityOptions = ['minor', 'moderate', 'significant', 'critical'];
