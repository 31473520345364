import React from 'react';
import PropTypes from 'prop-types';
import { Drawer } from '@material-ui/core';
import { Button } from 'reactstrap';
import TestDetails from '../../pages/TestDetails';

const TestDetailsDrawer = ({isOpen, onHandlerClose, testId}) => {
  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={onHandlerClose}
      className="drawer"
    >
      <>
        <Button
          className="drawer-close"
          onClick={onHandlerClose}
        >
          <i className="mdi mdi-close" />
        </Button>
        <TestDetails testId={testId} />
      </>
    </Drawer>
  );
};

TestDetailsDrawer.propTypes = {
  isOpen: PropTypes.bool,
  onHandlerClose: PropTypes.func,
  testId: PropTypes.number,
};

export default TestDetailsDrawer;
