import React from 'react';
import PropTypes from 'prop-types';
import MapViewFilterRange from './MapViewFilterRange';

const MapViewUploadFilter = ({ filters, setFilters }) => {
  const data = [
    {
      title: 'From',
      name: 'upload_lower_range',
      placeholder: 'Value in mbps',
    },
    {
      title: 'To',
      name: 'upload_upper_range',
      placeholder: 'Value in mbps',
    },
  ];

  return (
    <MapViewFilterRange filters={filters} setFilters={setFilters} data={data} />
  );
};

MapViewUploadFilter.propTypes = {
  filters: PropTypes.shape({
    latency_upper_range: PropTypes.string,
    latency_lower_range: PropTypes.string,
  }),
  setFilters: PropTypes.func,
};

export default MapViewUploadFilter;
