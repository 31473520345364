import React, {useRef, useState} from 'react';
import * as PropTypes from 'prop-types';
import {isEmpty} from 'lodash';
import SearchByColumn from './SearchByColumn';
import {mapDataToLegend} from '../../helpers/page_helper';
import Button from '../Button/Button';

const ApplicationsEventsTableFilter = ({onFilterChange, currentFilter}) => {
  const [filter, setFilter] = useState({});
  const [showResetBtn, setShowResetBtn] = useState(false);
  const [isFilterReady, setIsFilterReady] = useState(false);
  const searchFilterRef = useRef();
  const SearchByColumnOptions = [
    {
      value: 'app_label',
      label: 'Application Name',
      
    },
    {
      value: 'username',
      label: 'User Name',
    },
    {
      value: 'status',
      label: 'Application Status',
      type: 'select',
      options: [
        {
          value: true,
          label: 'Reachable',
        },
        {
          value: false,
          label: 'Not Reachable',
        },
      ],
    },
  ];

  const onSearchChange = (value) => {
    const {searchBy, keyword} = value;
    
    if (!keyword) {
      setIsFilterReady(false);
    } else {
      setIsFilterReady(true);
    }
    
    if (searchBy && keyword) {
      setFilter({...filter, ...value});
    } else {
      const {searchBy, keyword, ...rest} = filter;
      setFilter({...rest});
    }
  };

  const handleSubmit = () => {
    const legend = {
      searchBy: 'search_by',
      keyword: 'keyword',
    };

    setShowResetBtn(!isEmpty(filter));
    return onFilterChange(mapDataToLegend(legend, filter));
  };

  const resetFilter = () => {
    if (searchFilterRef) {
      searchFilterRef.current.reset();
      setFilter({});
      onFilterChange({});
      setShowResetBtn(false);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };

  return (
    <div className="d-flex align-items-center mb-3 mb-md-0 activities-filter">
      <SearchByColumn
        columnOptions={SearchByColumnOptions}
        onChange={onSearchChange}
        ref={searchFilterRef}
        onKeyUp={handleKeyPress}
        defaultSelect={(currentFilter && currentFilter.search_by) || 'app_label'}
      />
      <div className="ml-3">
        <Button
          type="button"
          onClick={handleSubmit}
          generalType="default"
          disabled={!isFilterReady}
        >
          Search
        </Button>
        {
          showResetBtn && (
            <Button
              className="ml-2"
              type="button"
              onClick={resetFilter}
              generalType="default"
            >
              Reset
            </Button>
          )
        }
      </div>
    </div>
  );
};

ApplicationsEventsTableFilter.propTypes = {
  onFilterChange: PropTypes.func.isRequired,
};

export default ApplicationsEventsTableFilter;
