import React from 'react';
import PropTypes from 'prop-types';
import Button from '../Button/Button';

const RefreshButton = ({ onRefresh }) => {
  return (
    <Button
      size="small"
      aria-label="refresh"
      className="button-default-icon button-refresh"
      onClick={onRefresh}
      generalType="default"
    >
      <i className="mdi mdi-refresh" />
    </Button>
  );
};

RefreshButton.propTypes = {
  onRefresh: PropTypes.func.isRequired,
};

export default RefreshButton;
