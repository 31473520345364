import React, { useState } from 'react';
import { Collapse } from 'reactstrap'; // импортируйте Collapse
import ExtraContent from "../ExtraContent/ExtraContent";
import styles from './featured-issue-card.module.scss';
import { Link } from "react-router-dom";

const FeaturedIssueCard = ({ data }) => {
	const { type: title, category, createdAt, guid, userId } = data || {};
	const [isOpen, setIsOpen] = useState(false);
	
	const handleClick = () => {
		setIsOpen(!isOpen);
	}
	
	return (
		<div className={`${styles.issueCard} ${isOpen ? styles.extraContent : ''}`} onClick={handleClick}>
			<div className={styles.header}>
				<h4>{title}</h4>
				<Link to={`/admin/issues?tab=${category.toLowerCase()}`} className={`${styles.tag} ${styles[category.toLowerCase()]}`}>{category}</Link>
			</div>
			<div className={styles.footer}>
				<div className={styles.reference}>
					<Link to={`/admin/user-status/${userId}`}>{guid}</Link>
				</div>
				<div className={styles.time}>{createdAt}</div>
			</div>
			<Collapse
				isOpen={isOpen}
				onExited={() => setIsOpen(false)}
			>
				<ExtraContent data={data} />
			</Collapse>
		</div>
	);
};

export default FeaturedIssueCard;
